import api from '@tyrio/api-factory';
import { DBPosTypesApi } from '@tyrio/dto';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { BadgeContent } from '../../../context/POSContext';

export const useGetSuppliersStock = (
  ean: string,
  sorting: string,
  badgeContent?: BadgeContent,
  setBadgeContent?: (a: BadgeContent) => void
) => {
  const {
    data: suppliersStock,
    isLoading,
    isFetching,
    isRefetching,
  } = useQuery(
    [`get_suppliers_stock_${ean}_${sorting}`],
    () =>
      api.fetch<DBPosTypesApi['getSuppliersStock']>(`get_suppliers_stock`, {
        ean,
        sorting,
      }),
    {
      enabled: !isEmpty(ean),
      onSuccess: (res) => {
        let sum = 0;
        res.map((r) =>
          r.lineItems.map((item) => (sum += item.quantity - item.reserved))
        );
        if (setBadgeContent && badgeContent)
          setBadgeContent({
            ...badgeContent,
            SUPPLIERS: sum,
          });
      },
    }
  );

  return {
    suppliersStock:
      suppliersStock?.filter(
        (supplier) =>
          supplier.lineItems.reduce(
            (acc, curr) => acc + (curr?.quantity ?? 0),
            0
          ) !== 0
      ) ?? [],
    showLoader: isLoading || isFetching || isRefetching,
  };
};
