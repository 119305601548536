/* eslint-disable react/jsx-no-undef */
import styled from '@emotion/styled/macro';
import CheckIcon from '@mui/icons-material/Check';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { DBClient, DBOrderStatus } from '@prisma/client';
import api, { ENDPOINTS } from '@tyrio/api-factory';
import {
  ApiConnectionDetail,
  DBBranchesApi,
  DBClientSupplierOrderApi,
  DBEdiOrderApi,
  DBSupplierApi,
  FtpConnectionDetail,
  ProductDetailsDataInterface,
} from '@tyrio/dto';
import { checkIfValidEmail } from '@tyrio/forms';
import {
  GMPItaliaOrderDetailsRequest,
  GMPItaliaOrderRequest,
} from '@tyrio/orders';
import {
  globe,
  InputCancelModal,
  MakeOrderModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
  tyrioMUI,
  tyrioUI,
} from '@tyrio/ui-library';
import axios, { AxiosError } from 'axios';
import fileDownload from 'js-file-download';
import { startCase } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import EmailLinkGenerator from './EmailLinkGenerator';
import { getFtpData } from './helpers/data-parser';
import { getEdiWheelData } from './helpers/edi-wheel-api';
import { getGmpData } from './helpers/gmp-api';
import ProductInfo from './ProductInfo';

interface OrderProps {
  supplierId: string;
  products: ProductDetailsDataInterface[];
  client?: DBClient | null | undefined;
  // eslint-disable-next-line
  connection?: any;
  refNo: string;
}

const Order = ({
  supplierId,
  products,
  client,
  connection,
  refNo,
}: OrderProps) => {
  const { data, isFetching } = useQuery(
    ['get_supplier_id', supplierId, 'get_supplier_id'],
    () =>
      api.fetch<DBSupplierApi['getOne']>(`supplier_id`, {
        id: supplierId,
      })
  );

  const { data: branchData } = useQuery(
    ['get_branch_by_client_id', {}],
    () => api.fetch<DBBranchesApi['list']>(`get_branch_by_client_id`),
    {}
  );

  const history = useHistory();
  const [isConfirmOrderModalVisible, setIsConfirmOrderModalVisible] =
    useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [warehouse, setWarehouse] = useState<number>();
  const [selectedBranch, setSelectedBranch] = useState<
    DBBranchesApi['getOne']['response'] | undefined
  >();
  const [ftpOrderFileName, setFtpOrderFileName] = useState('');
  const [orderId, setOrderId] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [apiOrderError, setApiOrderError] = useState('');
  const [orderData, setOrderData] = useState<
    DBClientSupplierOrderApi['create']['request'] | undefined
  >(undefined);

  let subtotal = 0;
  (products || []).map(
    (product) => (subtotal += product.price * product.quantity)
  );

  useMemo(() => {
    setSelectedBranch(
      branchData?.data.filter((item) => item.id === warehouse)[0]
    );
  }, [branchData?.data, warehouse]);

  const delivery = products
    .map((product: ProductDetailsDataInterface) => product.shipping)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  const ecoTax = products
    .map((product: ProductDetailsDataInterface) => product.ecoTax)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const total = subtotal + delivery + ecoTax;

  const connectionType = connection?.type;

  const [form, setForm] = useState({
    warehouse: warehouse,
    deliveryNote: '',
    internalNote: '',
    fromEmail: '',
    toEmail: connection?.connectionDetails?.supplierEmail,
    ccEmail: '',
    orderNumber: '',
    selectedBranch: undefined,
  });

  const [error, setError] = useState<Record<string, string | null>>({
    warehouse: null,
    deliveryNote: null,
    internalNote: null,
    fromEmail: null,
    toEmail: null,
    ccEmail: null,
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [orderSent, setOrderSent] = useState(false);
  const [orderNumberSent, setOrderNumberSent] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const emailLocation = EmailLinkGenerator({
    email: form.toEmail || '',
    cc: '',
    products: products,
    branchDetails: {
      address: selectedBranch?.address || '',
      zipCode: selectedBranch?.zipCode || '',
      city: selectedBranch?.city || '',
      mobilePhone: selectedBranch?.businessPhone || '',
    },
    clientName: client?.officialName || '',
    deliveryNote: form.deliveryNote,
    reference: '',
    countryId: '',
  });

  const GMP_AUTH = useMutation(
    'gmp_auth',
    (_data: GMPItaliaOrderDetailsRequest) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      api.fetch<any>('gmp_auth', {
        apiKey: (_data.connection as ApiConnectionDetail).connectionDetails
          .apiKey,
        apiClientId: (_data.connection as ApiConnectionDetail).connectionDetails
          .apiClientId,
        apiClientSecret: (_data.connection as ApiConnectionDetail)
          .connectionDetails.apiClientSecret,
      }),
    {
      onSuccess: (res, context) => {
        GMP_ORDER.mutate({ token: res, body: context });
      },
      onError: () => {
        setIsLoading(false);
        setOrderSent(false);
        setApiOrderError(
          'Error during authentication on external API. Please check your credentials!'
        );
      },
    }
  );

  const GMP_ORDER = useMutation(
    'gmp_order',
    (data: GMPItaliaOrderRequest) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      api.fetch<any>('gmp_order', {
        token: data.token,
        body: { ...data.body },
      }),
    {
      onSuccess: (res) => {
        setForm({ ...form, orderNumber: res.reference });
        if (orderData) order.mutate({ ...orderData });
      },
      onError: () => {
        setIsLoading(false);
        setOrderSent(false);
        setApiOrderError(
          'Error during saving order on external API. Please check if you entered all required data!'
        );
      },
    }
  );

  const EDI_ORDER = useMutation(
    'edi_order',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) =>
      api.fetch<DBEdiOrderApi['create_edi_order']>('edi_order', { ...data }),
    {
      onSuccess: (res) => {
        setForm({ ...form, orderNumber: res?.purchaseOrder?.orderId });
        if (orderData) order.mutate({ ...orderData });
      },
      onError: () => {
        setIsLoading(false);
        setOrderSent(false);
        setApiOrderError(
          'Error during saving order on EDI. Please check if you entered all required data!'
        );
      },
    }
  );

  const uploadFileToFtp = useMutation(
    (formData: FormData) =>
      axios.post(ENDPOINTS.upload_ftp.uri, formData, {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      }),
    {
      mutationKey: 'upload_ftp_file',
      onSuccess: (res) => {
        setFtpOrderFileName(res.data);
        setIsLoading(false);
      },
    }
  );

  const order = useMutation(
    'upsert_order',
    (data: DBClientSupplierOrderApi['create']['request']) =>
      api.fetch<DBClientSupplierOrderApi['create']>('create_order', {
        orderId,
        ...data,
      }),
    {
      onSuccess: (res) => {
        setOrderId(res.orderId);
        setOrderSent(true);
        setIsLoading(false);
      },
      onMutate: () => {
        setIsLoading(true);
      },
    }
  );

  const refMutation = useMutation('update_ref', (data: string) => {
    if (!order?.data?.id) {
      return new Promise((r) => r(null));
    }
    return api.fetch<DBClientSupplierOrderApi['updateRef']>(
      'update_order_ref',
      {
        orderNumberSufix: data,
        id: order.data.id,
      }
    );
  });

  const upsertOrderCall = useCallback(() => {
    setIsLoading(true);
    const data = {
      reference: '',
      supplierId,
      supplierClientSettingsId: 'DEPRECATED',
      lineItems: products,
      remark: form.internalNote,
      supplierRemark: form.deliveryNote,
      toEmail: form.toEmail,
      fromEmail: form.fromEmail,
      cc: form.ccEmail,
      orderNumberSufix: form.orderNumber,
      orderNumberPrefix: `PO-${refNo}`,
      deliveryAddress: {
        address_line_1: selectedBranch?.branchName ?? '',
        address_line_2: selectedBranch?.address ?? '',
        city: selectedBranch?.city ?? '',
        country: selectedBranch?.country?.name ?? '',
        zipCode: selectedBranch?.zipCode ?? '',
      },
      transportPriority: 'REPL',
      preferredDeliveryDate: '',
      connectionProtocol: connection.type,
      connection: connection,
      rulesetKey: connection.rulesetKey,
      branchId: selectedBranch?.id ?? 0,
    };

    setOrderData(data);

    //EMAIL CONNECTION
    if (connectionType === 'EMAIL') {
      setIsConfirmOrderModalVisible(false);
      setOrderSent(true);
      window.open(emailLocation, '_self');
      order.mutate({ ...data });
    }

    //API CONNECTION
    if (data?.connectionProtocol === 'API') {
      if (data.connection.connectionDetails.connectionType === 'GMP_ITALIA') {
        GMP_AUTH.mutate(getGmpData(data, client));
      }
    }

    //FTP CONNECTION
    if (data.connectionProtocol === 'FTP') {
      const ftpData = getFtpData(data, client);
      uploadFileToFtp.mutate(ftpData);
      order.mutate({ ...data });
    }

    //HTTPS CONNECTION
    if (data.connectionProtocol === 'HTTPS') {
      EDI_ORDER.mutateAsync(
        getEdiWheelData(
          { ...data, transportPriority: 'REPL', preferredDeliveryDate: '' },
          client
        )
      ).then((data) => {
        setOrderSent(true);
        setForm({ ...form, orderNumber: data.purchaseOrder.orderId });
        setOrderNumberSent(true);
        setIsLoading(false);
      });
      // order.mutate({ ...data });
    }
  }, [
    supplierId,
    products,
    form,
    refNo,
    selectedBranch?.branchName,
    selectedBranch?.address,
    selectedBranch?.city,
    selectedBranch?.country?.name,
    selectedBranch?.zipCode,
    selectedBranch?.id,
    connection,
    connectionType,
    emailLocation,
    order,
    GMP_AUTH,
    client,
    uploadFileToFtp,
    EDI_ORDER,
  ]);

  const doSubmit = () => {
    let errorList = error;

    if (warehouse === undefined) {
      ToastHelper.showToast(
        'Order',
        ToastType.WARNING,
        ToastMessageType.CUSTOM_ERROR,
        'You must choose an delivery address!'
      );
      return;
    }

    let hasError = false;
    setError({});
    setErrorMessage(null);

    if (connectionType === 'EMAIL') {
      if (form.fromEmail && !checkIfValidEmail(form.fromEmail)) {
        hasError = true;
        errorList = { ...errorList, fromEmail: 'Email format is incorrect!' };
      }

      if (!form.toEmail) {
        hasError = true;
        errorList = { ...errorList, toEmail: 'This field is required!' };
      } else if (form.toEmail && !checkIfValidEmail(form.toEmail)) {
        hasError = true;
        errorList = { ...errorList, toEmail: 'Email format is incorrect!' };
      }

      if (!warehouse) {
        hasError = true;
        errorList = { ...errorList, warehouse: 'This field is required!' };
      }
    }

    setError(errorList);

    if (hasError) {
      setIsLoading(false);
      const errorFields: string[] = [];
      for (const [key, value] of Object.entries(errorList)) {
        if (value !== null) {
          errorFields.push(key);
        }
      }
      errorFields &&
        ToastHelper.showToast(
          `Fields: ${errorFields.map((item) => startCase(item))} have errors.`,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      return;
    }

    try {
      upsertOrderCall();
    } catch (e) {
      if (e instanceof AxiosError) {
        setErrorMessage(e.response?.data.error.name);
      }
    }
    setIsConfirmOrderModalVisible(false);
  };

  const mutation = useMutation(
    ['cancel_order'],
    () =>
      api.fetch<DBClientSupplierOrderApi['cancelOrder']>('cancel_order', {
        status: DBOrderStatus.STORNED,
        cancelReason: cancelReason,
        id: orderId ?? '',
      }),
    {
      onSuccess: () => {
        ToastHelper.showToast(
          'order',
          ToastType.SUCCESS,
          ToastMessageType.CANCEL
        );
        history.push('/dashboard/price-comparison');
      },
      onError: () => {
        ToastHelper.showToast('Order', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  const connectionName = (connection as ApiConnectionDetail).connectionDetails
    .connectionType;

  const inquiryMutation = useMutation(
    ['inquiry_eans', supplierId],
    // eslint-disable-next-line
    ({ connection, supplierId, items }: any) =>
      api.fetch('inquire_eans', {
        adminConnectionId: connection.adminConnectionId,
        connection,
        supplierId,
        items,
      })
  );

  useEffect(() => {
    inquiryMutation.mutate({
      connection,
      supplierId,
      items: products.map((product) => ({
        quantity: product.quantity,
        ean: product.ean,
        ipc: product.ipc,
      })),
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {isCancelModalVisible && (
        <InputCancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            mutation.mutate();
          }}
          object="order"
          setInputText={setCancelReason}
        />
      )}
      {isConfirmOrderModalVisible && (
        <MakeOrderModal
          LBAction={() => setIsConfirmOrderModalVisible(false)}
          RBAction={() => {
            setIsConfirmOrderModalVisible(false);
            doSubmit();
          }}
        />
      )}
      {data && !isFetching && (
        <div>
          <Header>
            <div className="supplier_name">{data.companyOfficialName}</div>
            {inquiryMutation.isLoading && (
              <div className="check">
                <CircularProgress size={16} sx={{ marginRight: 2 }} />
                <div>Checking latest quantities with supplier</div>
              </div>
            )}
            <ConnectionType>
              <ButtonHeader variant="outlined" color="info" disableElevation>
                {connectionType}
              </ButtonHeader>
              <img src={globe} alt="globe" />
            </ConnectionType>
          </Header>

          <OrderWrapper>
            <OrderDetails>
              {products.length > 5 && (
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '15px',
                  }}
                >
                  <Grid item>
                    <Button
                      variant="text"
                      color={!isExpanded ? 'success' : 'warning'}
                      onClick={() => setIsExpanded(!isExpanded)}
                      disabled={false}
                    >
                      {isExpanded ? 'COLLAPSE ALL' : 'EXPAND ALL'}
                    </Button>
                  </Grid>
                </Grid>
              )}

              <OrdersListWrapper isExpanded={isExpanded}>
                {products &&
                  products.map((product) => (
                    <div
                      style={{
                        opacity: orderSent ? 0.4 : 1,
                      }}
                    >
                      <ProductInfo
                        productName={product.productName}
                        quantity={product.quantity}
                        price={product.price}
                        ean={product.ean}
                        ipc={product.ipc}
                        sku={product.sku}
                        inquiryData={
                          // eslint-disable-next-line
                          (inquiryMutation.data as any)?.[product.ean]
                        }
                      />
                    </div>
                  ))}
              </OrdersListWrapper>
              {isExpanded && (
                <Grid
                  container
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '15px',
                    marginTop: '15px',
                  }}
                >
                  <Grid item>
                    <Button
                      variant="text"
                      color={'warning'}
                      onClick={() => setIsExpanded(false)}
                      disabled={false}
                      style={{ display: `${!isExpanded} ? 'none' : 'flex'` }}
                    >
                      {'COLLAPSE ALL'}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!orderSent ? (
                <>
                  {warehouse === undefined &&
                    branchData &&
                    branchData?.data.length > 0 && (
                      <Alert severity="warning" sx={{ marginTop: '15px' }}>
                        <AlertTitle>
                          Delivery address is not selected!
                        </AlertTitle>
                        To complete your order, delivery address must be
                        selected! — <strong>Select a delivery address!</strong>
                      </Alert>
                    )}
                  {warehouse === undefined && branchData?.data.length === 0 && (
                    <Alert severity="warning" sx={{ marginTop: '15px' }}>
                      <AlertTitle>
                        You don't have any main branch configured!
                      </AlertTitle>
                      Please add main branch to your user settings!
                    </Alert>
                  )}
                  <DeliveryInfo>
                    {branchData && branchData.count > 0 ? (
                      <>
                        <FormControl sx={{ width: '40%' }}>
                          <InputLabel
                            id={'address_picker'}
                            error={!!error['warehouse']}
                          >
                            Address for delivery
                          </InputLabel>
                          <Select
                            key={'address_picker_id'}
                            id={'address_picker_id'}
                            labelId={'address_picker'}
                            label={'Address for delivery'}
                            error={!!error['warehouse']}
                            fullWidth
                            onChange={(event: SelectChangeEvent) => {
                              setWarehouse(parseInt(event.target.value));
                              setError({ ...error, warehouse: null });
                            }}
                          >
                            {branchData.data.map((branch) => (
                              <MenuItem
                                key={`warehouse_menu_item_${branch.id}`}
                                value={branch.id}
                              >
                                {branch.branchName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Contact>
                          <b>{client?.officialName}</b>
                          {/* TODO:Update country */}
                          <div>
                            {selectedBranch &&
                              `${selectedBranch.address}, ${selectedBranch.zipCode} ${selectedBranch.city}, Croatia`}
                          </div>
                          <div>{selectedBranch?.businessPhone}</div>
                        </Contact>
                      </>
                    ) : (
                      <Alert
                        severity="warning"
                        sx={{ marginTop: '15px', width: '100%' }}
                      >
                        <AlertTitle>
                          You don't have main branch configured!
                        </AlertTitle>
                        Please{' '}
                        <strong
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            history.push(
                              '/dashboard/company-settings/branches/'
                            )
                          }
                        >
                          add main branch
                        </strong>{' '}
                        to your user settings!
                      </Alert>
                    )}
                  </DeliveryInfo>
                  <Notes>
                    <TextField
                      label="Note for delivery"
                      fullWidth
                      value={form.deliveryNote}
                      onChange={(e) => {
                        setForm({ ...form, deliveryNote: e.target.value });
                      }}
                      id="deliveryNote"
                      error={!!error['deliveryNote']}
                      helperText={error['deliveryNote']}
                    />
                    <TextField
                      label="Internal note"
                      fullWidth
                      style={{ marginTop: '16px' }}
                      value={form.internalNote}
                      onChange={(e) =>
                        setForm({ ...form, internalNote: e.target.value })
                      }
                      id="internalNote"
                      error={!!error['internalNote']}
                      helperText={error['internalNote']}
                    />
                  </Notes>
                  {connectionType === 'EMAIL' && (
                    <>
                      <Divider sx={{ marginTop: 2 }} textAlign={'left'}>
                        <DividerLabel>Email</DividerLabel>
                      </Divider>
                      <Email>
                        {/* <StyledTextField
                          label="From"
                          type="email"
                          fullWidth
                          value={form.fromEmail}
                          onChange={(e) => {
                            setForm({ ...form, fromEmail: e.target.value });
                            setError({ ...error, fromEmail: null });
                          }}
                          id="fromEmail"
                          error={!!error['fromEmail']}
                          helperText={error['fromEmail']}
                        /> */}
                        <StyledTextField
                          label="To"
                          type="email"
                          fullWidth
                          disabled={
                            !!connection?.connectionDetails?.supplierEmail
                          }
                          onChange={(e) => {
                            setForm({ ...form, toEmail: e.target.value });
                            setError({ ...error, toEmail: null });
                          }}
                          value={form.toEmail}
                          id="toEmail"
                          error={!!error['toEmail']}
                          helperText={error['toEmail']}
                        />
                        <StyledTextField
                          label="cc"
                          type="email"
                          fullWidth
                          value={form.ccEmail}
                          onChange={(e) => {
                            setForm({ ...form, ccEmail: e.target.value });
                            setError({ ...error, ccEmail: null });
                          }}
                          id="ccEmail"
                          error={!!error['ccEmail']}
                          helperText={error['ccEmail']}
                        />
                      </Email>
                    </>
                  )}
                </>
              ) : (
                <OrderSentContainer>
                  {connection.type === 'API' &&
                    (isLoading && orderSent ? (
                      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size="25px" />
                        <Typography
                          sx={{ fontSize: '23px', marginLeft: '10px' }}
                        >
                          Saving order to{' '}
                          <strong>{connectionName.replace('_', ' ')} </strong>
                          API
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid sx={{ width: '100%' }}>
                        <Alert
                          severity={
                            GMP_AUTH.status === 'success' ? 'success' : 'error'
                          }
                        >
                          <AlertTitle>
                            {GMP_AUTH.status === 'success'
                              ? 'Order successfully sent!'
                              : 'An error occurred'}
                          </AlertTitle>
                          Order was{' '}
                          {GMP_AUTH.status === 'success'
                            ? 'successfully sent'
                            : 'not send'}{' '}
                          to external{' '}
                          <strong>{connectionName.replace('_', ' ')} </strong>{' '}
                          API
                          {GMP_AUTH.status === 'success' && (
                            <span>
                              Order number is{' '}
                              <strong>{form.orderNumber}</strong>
                            </span>
                          )}
                        </Alert>
                      </Grid>
                    ))}
                  {connection.type === 'HTTPS' &&
                    (EDI_ORDER.status === 'loading' ? (
                      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size="25px" />
                        <Typography
                          sx={{ fontSize: '23px', marginLeft: '10px' }}
                        >
                          Saving order to EDI Wheel API
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid sx={{ width: '100%' }}>
                        <Alert
                          severity={
                            EDI_ORDER.status === 'success' ? 'success' : 'error'
                          }
                        >
                          <AlertTitle>
                            {EDI_ORDER.status === 'success'
                              ? 'Order successfully sent!'
                              : 'An error occurred'}
                          </AlertTitle>
                          Order was{' '}
                          {EDI_ORDER.status === 'success'
                            ? 'successfully sent'
                            : 'not send'}{' '}
                          EDI Wheel API
                          {GMP_AUTH.status === 'success' && (
                            <span>
                              Order number is{' '}
                              <strong>{form.orderNumber}</strong>
                            </span>
                          )}
                        </Alert>
                      </Grid>
                    ))}
                  {connection.type === 'FTP' &&
                    (isLoading && ftpOrderFileName === '' ? (
                      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size="25px" />
                        <Typography
                          sx={{ fontSize: '23px', marginLeft: '10px' }}
                        >
                          Saving order to{' '}
                          <strong>
                            {' '}
                            {
                              (connection as FtpConnectionDetail)
                                .connectionDetails.ftpHost
                            }
                            .{' '}
                          </strong>
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid sx={{ width: '100%' }}>
                        <Alert severity="success">
                          <AlertTitle>File successfully uploaded!</AlertTitle>
                          Order file successfully uploaded on{' '}
                          <strong>
                            {
                              (connection as FtpConnectionDetail)
                                .connectionDetails.ftpHost
                            }
                          </strong>
                          . You can{' '}
                          <strong
                            style={{ cursor: 'pointer' }}
                            onClick={async () => {
                              const file = await axios.get(
                                `${ENDPOINTS.get_file.uri}/${ftpOrderFileName}`
                              );
                              fileDownload(file.data, ftpOrderFileName);
                            }}
                          >
                            download the file now!
                          </strong>
                        </Alert>
                      </Grid>
                    ))}
                  {connection.type === 'EMAIL' && (
                    <Grid sx={{ width: '100%' }}>
                      <Alert severity="warning">
                        <AlertTitle>E-mail app has been opened!</AlertTitle>
                        Please verify the accuracy of the provided email address
                        and the order details!
                      </Alert>
                    </Grid>
                  )}
                  <OrderMessage>
                    <IconBackground>
                      <StyledCheckIcon color="success" />
                    </IconBackground>
                    Order sent {refNo}
                  </OrderMessage>
                  {!orderNumberSent ? (
                    <OrderInput>
                      <TextField
                        label="Number of supplier order"
                        style={{ marginRight: '10px' }}
                        value={form.orderNumber}
                        onChange={(e) => {
                          setForm({ ...form, orderNumber: e.target.value });
                        }}
                      />
                      <SaveButton
                        color="success"
                        variant="outlined"
                        onClick={() => {
                          refMutation.mutate(form.orderNumber);
                          setOrderNumberSent(true);
                        }}
                      >
                        Save
                      </SaveButton>
                    </OrderInput>
                  ) : (
                    <OrderedFrom style={{ marginTop: '10px' }}>
                      Number of order supplier:{' '}
                      <b style={{ color: 'black' }}>{form.orderNumber}</b>
                    </OrderedFrom>
                  )}

                  <OrderedFrom>
                    Ordered manually from{' '}
                    {connectionType.toUpperCase() +
                      ' in: ' +
                      new Date().toLocaleDateString('hr-HR', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                  </OrderedFrom>
                </OrderSentContainer>
              )}
              {/*{connection.type === 'HTTPS' && (*/}
              {/*  <Button*/}
              {/*    onClick={() =>*/}
              {/*      inquiryMutation.mutate({*/}
              {/*        connection,*/}
              {/*        supplierId,*/}
              {/*        items: products.map((product) => ({*/}
              {/*          quantity: product.quantity,*/}
              {/*          ean: product.ean,*/}
              {/*          ipc: product.ipc,*/}
              {/*        })),*/}
              {/*      })*/}
              {/*    }*/}
              {/*  >*/}
              {/*    Inquire prices*/}
              {/*  </Button>*/}
              {/*)}*/}
            </OrderDetails>
            <Checkout>
              <Total>
                <PriceWrapper>
                  <PriceTitle>Subtotal:</PriceTitle>
                  <Price>
                    {subtotal.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </Price>
                </PriceWrapper>
                <PriceWrapper>
                  <PriceTitle> Delivery:</PriceTitle>
                  <Price>
                    {delivery.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </Price>
                </PriceWrapper>
                <PriceWrapper>
                  <PriceTitle> EcoTax:</PriceTitle>
                  <Price>
                    {ecoTax.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                      currencyDisplay: 'symbol',
                    })}
                  </Price>
                </PriceWrapper>
                <PriceWrapper>
                  <PriceTitle>
                    <b>Total:</b>
                  </PriceTitle>
                  <Price>
                    <b>
                      {total.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        currencyDisplay: 'symbol',
                      })}
                    </b>
                  </Price>
                </PriceWrapper>
                {!(orderSent && connection.type === 'HTTPS') && (
                  <Button
                    variant="contained"
                    color={orderSent ? 'error' : 'info'}
                    fullWidth
                    type="submit"
                    disabled={isLoading || inquiryMutation.isLoading}
                    onClick={
                      orderSent
                        ? () => setIsCancelModalVisible(true)
                        : () => setIsConfirmOrderModalVisible(true)
                    }
                    style={{ marginTop: '10px' }}
                    disableElevation
                  >
                    {isLoading && 'Saving order...'}
                    {!orderSent && !isLoading && 'Order'}
                    {orderSent && !isLoading && 'Cancel order'}
                  </Button>
                )}
                {apiOrderError !== '' && (
                  <Alert
                    severity="error"
                    sx={{ marginTop: '15px', width: '100%' }}
                  >
                    <AlertTitle>An error occured!</AlertTitle>
                    <Typography>{apiOrderError}</Typography>
                  </Alert>
                )}
              </Total>
            </Checkout>
          </OrderWrapper>
          {errorMessage && (
            <Grid item xs={12}>
              <Typography
                color={tyrioMUI.muiTheme.palette.error.main}
                variant="subtitle1"
              >
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  margin-top: 16px;
`;

const OrderWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
`;

const OrderDetails = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 0;
`;

const Checkout = styled.div`
  width: 30%;
  padding: 16px;
  min-height: 495px;
  height: 100%;
  background: #f9fafb;
  border-radius: 16px;
  display: flex;
  align-items: end;
`;

const DeliveryInfo = styled.div`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const Notes = styled.div``;

const Email = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  margin-top: 11px;
  padding: 5px;
`;

const DividerLabel = styled.label`
  color: ${tyrioUI.colors.darkGrey};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Total = styled.div`
  width: 100%;
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Contact = styled.div`
  background: #f4f6f8;
  width: 100%;
  border-radius: 16px;
  margin-left: 16px;
  height: 130px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PriceTitle = styled.div``;

const Price = styled.div``;

const ButtonHeader = styled(Button)`
  padding: 0;
  width: 44px;
  border-radius: 16px;
`;

const ConnectionType = styled.div`
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  padding: 16px;
  width: 100%;
  height: 45px;
  background: #f4f6f8;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 20px;
  .supplier_name {
    flex: 1;
  }
  .check {
    padding: 0 16px;
    font-size: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const OrderSentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OrderMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  font-weight: 600;
  font-size: 24px;
`;

const IconBackground = styled.div`
  width: 40px;
  height: 40px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #4caf50;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 10px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  margin: 7px 0 0 0;
  padding: 0;
  height: 24px;
  width: 24px;
`;

const OrderInput = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const SaveButton = styled(Button)`
  width: 175px;
  height: 42px;
`;

const OrderedFrom = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #919eab;
`;

const OrdersListWrapper = styled.div<{ isExpanded?: boolean }>`
  border: 1px solid #dfe3e8;
  border-radius: 8px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.8);
    border-radius: 10px;
  }
  ${(props) => (props.isExpanded ? 'height: max-content' : 'height: 380px')}
`;

export default Order;
