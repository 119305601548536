import { InboundInvoiceItem, InboundLineItem, VatChargeType } from '@tyrio/dto';

export const useCalculatePricePerRow = (
  item: InboundLineItem,
  vatChargeType: VatChargeType
) => {
  const vat = 0;
  let itemsPrice = 0;
  let grandTotal = 0;

  const customsPrice = vatChargeType === 'V05D' ? item.customsPrice : 0;

  const purchasePrice = item.quantity * item.purchasePrice;

  const discountAmount = (purchasePrice * item.rebate) / 100;

  const priceWithRebate = purchasePrice - discountAmount;

  itemsPrice = priceWithRebate + item.variableCost + customsPrice;

  item.sellingPrice =
    itemsPrice / item.quantity +
    (item.margin / 100) * (itemsPrice / item.quantity);

  grandTotal = item.sellingPrice * item.quantity;

  return {
    itemsPrice,
    grandTotal,
    vat,
  };
};

export const useCalculatePrice = (
  items?: InboundInvoiceItem[],
  vatChargeType?: VatChargeType
) => {
  const vat = 25;
  let itemsPrice = 0;
  let totalQty = 0;
  let variableCost = 0;
  let customsTotal = 0;
  let subTotalExlVat = 0;
  let grandTotal = 0;
  let vatAmount = 0;
  let rounded = 0;

  if (items && items?.length > 0) {
    items.forEach((item) => {
      //quantity
      const itemQty = item.lineItems.reduce(
        (acc, curr) => acc + curr.quantity,
        0
      );
      // price
      const itemPrice = item.lineItems.reduce(
        (acc, curr) =>
          acc +
          curr.purchasePrice * curr.quantity -
          curr.purchasePrice * curr.quantity * (curr.rebate / 100),
        0
      );
      // variable cost
      const varCost = item.lineItems.reduce(
        (acc, curr) => acc + curr.variableCost,
        0
      );
      // customs
      const customs =
        vatChargeType !== 'V05D'
          ? 0
          : item.lineItems.reduce((acc, curr) => acc + curr.customsPrice, 0);
      totalQty += itemQty;
      itemsPrice += itemPrice;
      variableCost += varCost;
      customsTotal += customs;
    });

    subTotalExlVat = itemsPrice + variableCost + customsTotal;
    grandTotal =
      vatChargeType !== 'V05'
        ? subTotalExlVat
        : subTotalExlVat + subTotalExlVat * (vat / 100);

    vatAmount = (vat / 100) * subTotalExlVat;

    rounded = Math.round(vatAmount * 100) / 100;
  }

  return {
    itemsPrice,
    subTotalExlVat,
    grandTotal,
    vat,
    totalQty,
    variableCost,
    customsTotal,
    vatAmount: rounded,
  };
};
