import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DBCustomerOrderItemApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/forms';

interface SelectInputProps {
  attributeKey: string;
  valueName: keyof DBCustomerOrderItemApi['getOne']['response'];
  label: string;
  dropdownData: TyrioSelectInputOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOrderData: any | null;
  setSelectedOrderData: (
    value: DBCustomerOrderItemApi['getOne']['response']
  ) => void;
  isDisabled: boolean;
}

const SelectInput = (props: SelectInputProps) => {
  return (
    props.selectedOrderData && (
      <FormControl fullWidth>
        <InputLabel>{props.label}</InputLabel>
        <Select
          label={props.label}
          value={props.selectedOrderData[props.valueName][props.attributeKey]}
          disabled={props.isDisabled}
          onChange={(e) => {
            if (props.valueName === 'orderStatus') {
              if (
                !(
                  e.target.value === 'ORDERED' ||
                  e.target.value === 'IN_PROCESS'
                )
              ) {
                props.selectedOrderData.orderStatus.subStatus = '';
              }
              props.setSelectedOrderData({
                ...props.selectedOrderData,
                orderStatus: {
                  ...props.selectedOrderData.orderStatus,
                  [props.attributeKey as string]: e.target.value,
                },
              });
            } else {
              props.selectedOrderData &&
                props.setSelectedOrderData({
                  ...props.selectedOrderData,
                  [props.valueName]: e.target.value,
                });
            }
          }}
        >
          {props.dropdownData.map((item) => (
            <MenuItem value={item.value} key={item.label}>
              <Typography> {item.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  );
};

export default SelectInput;
