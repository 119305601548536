import * as OPERATIONS from './operations';
import { RegexList } from './regex';
import { PostProcessHelpers } from './postprocess.helpers';
export type MatchOutputType =
  | 'number'
  | 'forced_integer'
  | 'string'
  | 'boolean';

export type ConditionType =
  | 'equals'
  | 'startsWith'
  | 'endsWith'
  | 'contains'
  | 'not.equals'
  | 'not.startsWith'
  | 'not.endsWith'
  | 'not.contains'
  | 'in';

export interface TMapRule {
  type: 'rule.map';
  destination: 'model' | 'product';
  sourceKey: string;
  targetKey: string;
  formatFunction?: (key: string) => string;
  /**
   * Default: string;
   */
  outputType?: MatchOutputType;
  postProcess?: Array<keyof typeof PostProcessHelpers>;
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}

export interface TCombineRule {
  type: 'rule.combine';
  destination: 'model' | 'product';
  sourceKeys: string[];
  targetKey: string;
  delimiter: string;
  formatFunction?: (key: string) => string;
  postProcess?: Array<keyof typeof PostProcessHelpers>;
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}

export interface TOperateRule {
  type: 'rule.operate';
  destination: 'model' | 'product';
  sourceKey?: string;
  targetKey: string;
  operationName: keyof typeof OPERATIONS;
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}

export interface TGlobalOperateRule {
  type: 'rule.global.operate';
  destination: 'model' | 'product';
  sourceKey?: string;
  targetKey: string;
  operationName: keyof typeof OPERATIONS;
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}

export interface TReplaceRule {
  type: 'rule.replace';
  targetKey: string;
  matchValue: string[];
  replaceValue: string[];
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}
export interface TMapArrayRule {
  type: 'rule.map_array';
  targetKey: string;
  sourceKey: string;
  delimiter: string;
  destination: 'model' | 'product';
  outputType?: MatchOutputType;
  postProcess?: Array<keyof typeof PostProcessHelpers>;
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}

export interface TRegexpRule {
  type: 'rule.regex';
  destination: 'model' | 'product';
  sourceKey: string;
  targetKey: string;
  regexName: keyof typeof RegexList;
  outputMapping: string[];
  matchMappingIdx: number[];
  outputTypes?: MatchOutputType[];
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}

export interface TSimpleExpRule {
  type: 'rule.simple_exp';
  destination: 'model' | 'product';
  sourceKey: string;
  targetKey: string;
  expression: string;
  outputMapping: string[];
  matchMappingIdx: string[];
  outputTypes?: MatchOutputType[];
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}

export interface TConditionalRuleBase {
  type: 'rule.condition';
  destination: 'model' | 'product';
  sourceKey: string;
  condition: ConditionType;
  conditionValue: string;
  outputLogic: 'map_from_import' | 'custom' | 'append';
  outputValue?: string | boolean | number;
  targetKey: string;
  outputType?: MatchOutputType;
  postProcess?: Array<keyof typeof PostProcessHelpers>;
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}
export interface TConditionalRuleMap extends TConditionalRuleBase {
  outputLogic: 'map_from_import';
  conditionMapSourceKey: string;
}
export interface TConditionalRuleCustom extends TConditionalRuleBase {
  outputLogic: 'custom';
  outputValue: string | boolean | number;
}
export interface TConditionalRuleAppend extends TConditionalRuleBase {
  outputLogic: 'append';
  outputValue: string;
}

export interface TConditionalBooleanRule {
  type: 'rule.condition_boolean';
  destination: 'model' | 'product';
  sourceKey: string;
  condition: ConditionType;
  conditionValues: string[];
  conditionOutputs: Array<string | boolean | number>;
  targetKey: string;
  outputType?: MatchOutputType;
  postProcess?: Array<keyof typeof PostProcessHelpers>;
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}

export type TConditionalRule =
  | TConditionalRuleMap
  | TConditionalRuleCustom
  | TConditionalRuleAppend;

export interface TTemplateRule {
  type: 'rule.template';
  destination: 'model' | 'product';
  targetKey: string;
  /** #{t.product.uid}_#{c.DESIGN_1}*/
  template: string;
  postProcess?: Array<keyof typeof PostProcessHelpers>;
  outputType?: MatchOutputType;
  applyOnlyForCategories?: number[];
  ignoreForCategories?: number[];
}
export type TRule =
  | TMapRule
  | TCombineRule
  | TOperateRule
  | TGlobalOperateRule
  | TRegexpRule
  | TSimpleExpRule
  | TConditionalRule
  | TConditionalBooleanRule
  | TTemplateRule
  | TReplaceRule
  | TMapArrayRule;
