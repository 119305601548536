import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import DeliveryTruckIcon from './delivery-truck.svg';
import { SalesOrdersFlowContext } from '../SalesOrdersFlowContext';
import { DBProduct, DBSupplier } from '@prisma/client';
import * as dateFns from 'date-fns';
import { roundPriceToTwoDecimals } from '@tyrio/shared-vars';
import { CircularProgress, Tooltip } from '@mui/material';
import { get, sumBy } from 'lodash';

interface OrderItemProps {
  supplier: DBSupplier;
  product: DBProduct;
  selected?: boolean;
  price: number;
  originalPrice?: number;
  isLowestPrice?: boolean;
  isMainSupplier?: boolean;
  isFastestDelivery?: boolean;
  requestedQuantity: number;
  supplierQuantity: number;
  mainSupplierPrice?: number;
  lowestProductPrice?: number;
  resolvedSupplierQty?: number;
  resolvedSupplierDeliveryDate?: string;
  resolvingSupplierInquiry?: boolean;
  purchasedQuantity: number;
}
export const OrderItem = ({
  supplier,
  product,
  isMainSupplier,
  price,
  originalPrice,
  mainSupplierPrice,
  lowestProductPrice,
  requestedQuantity,
  supplierQuantity: supplierQuantityCatalog,
  resolvedSupplierQty,
  resolvedSupplierDeliveryDate,
  resolvingSupplierInquiry,
}: OrderItemProps) => {
  const supplierQuantity = resolvedSupplierQty ?? supplierQuantityCatalog;
  const ctx = useContext(SalesOrdersFlowContext);

  const canFulfillQuantity = requestedQuantity <= supplierQuantity;

  const deliveryDateString =
    ctx.data.deliveryDates?.[supplier.id]?.[product.uid];
  const deliveryDate = deliveryDateString
    ? dateFns.parseISO(deliveryDateString)
    : undefined;

  const deliveryDates = useMemo(
    () =>
      Object.values(ctx.data.deliveryDates?.[product.uid] ?? {}).sort(
        (aString, bString) => {
          const a = dateFns.parseISO(aString);
          const b = dateFns.parseISO(bString);
          if (dateFns.isSameDay(a, b)) return 0;
          if (dateFns.isBefore(a, b)) return -1;
          return 1;
        }
      ),
    [ctx.data.deliveryDates, product.uid]
  );

  const shortestDeliveryDate = deliveryDates?.[0] ?? null;
  const isFastestDelivery = useMemo(
    () =>
      deliveryDate
        ? dateFns.isSameDay(
            dateFns.parseISO(shortestDeliveryDate),
            deliveryDate
          )
        : false,
    [deliveryDate, shortestDeliveryDate]
  );

  const isLowestPrice = price?.toFixed(5) === lowestProductPrice?.toFixed(5);

  const setQuantity = useCallback(
    (value: string) => {
      const converted = Number(value);
      const parsedQty = isNaN(converted) ? 0 : converted;
      // const isManufacturer = supplier.supplierType === 'MANUFACTURER';

      const supplierQty = sumBy(Object.values(ctx.qty.supplier), product.uid);
      const stockQty = sumBy(Object.values(ctx.qty.stock), product.uid);
      const filledQty = supplierQty + stockQty;

      const requestedQty = ctx.data.requested[product.uid];

      let finalValue = Math.max(0, parsedQty);

      if (
        filledQty === requestedQty &&
        finalValue + filledQty > requestedQty &&
        finalValue > get(ctx.qty.supplier[supplier.id], `${product.uid}`, 0)
      )
        return;

      // if (!isManufacturer) {
      //   finalValue = Math.min(finalValue, supplierQuantity);
      // }

      finalValue = Math.min(finalValue, requestedQty);
      ctx.setQty(supplier.id, product.uid, finalValue, 'supplier');
    },
    [ctx, product.uid, supplier.id]
  );

  const mainSupplierHasLowestPrice = mainSupplierPrice === price;

  const mainSupplierPriceChangedToMoreExpensive = useMemo(() => {
    if (!isMainSupplier) return false;
    const safeOriginal = roundPriceToTwoDecimals(originalPrice ?? 0);
    const safePrice = roundPriceToTwoDecimals(price);

    return !!(safeOriginal && safeOriginal < safePrice);
  }, [isMainSupplier, originalPrice, price]);

  const mainSupplierPriceDifferent = useMemo(() => {
    if (!isMainSupplier) return false;
    const safeOriginal = roundPriceToTwoDecimals(originalPrice ?? 0);
    const safePrice = roundPriceToTwoDecimals(price);

    return !!(safeOriginal && safeOriginal !== safePrice);
  }, [isMainSupplier, originalPrice, price]);

  const showOriginalPrice =
    originalPrice && isMainSupplier && mainSupplierPriceDifferent;

  const mainSupplierPreferred = useMemo(() => {
    if (!isMainSupplier || mainSupplierPriceChangedToMoreExpensive)
      return false;

    if (originalPrice && originalPrice >= price) return true;
    return price === lowestProductPrice;
  }, [
    isMainSupplier,
    lowestProductPrice,
    mainSupplierPriceChangedToMoreExpensive,
    originalPrice,
    price,
  ]);

  const mainSupplierHasNoStock =
    isMainSupplier &&
    supplier.supplierType !== 'MANUFACTURER' &&
    supplierQuantity === 0;

  useEffect(() => {
    if (
      mainSupplierPreferred &&
      ctx.qty.supplier?.[supplier.id]?.[product.uid] === undefined
    ) {
      setQuantity(String(requestedQuantity));
    }
  }, [
    ctx.dirty,
    ctx.qty,
    mainSupplierPreferred,
    product.uid,
    requestedQuantity,
    setQuantity,
    supplier.id,
  ]);

  const inputQty = useMemo(() => {
    const value = ctx.qty.supplier?.[supplier.id]?.[product.uid] ?? undefined;
    return String(value);
  }, [ctx.qty, product.uid, supplier.id]);

  useEffect(() => {
    if (!ctx.selected[product.uid] && mainSupplierPreferred && isMainSupplier) {
      ctx.setSelected(supplier.id, product.uid);
    }
  }, [
    ctx,
    ctx.selected,
    isMainSupplier,
    mainSupplierPreferred,
    product,
    supplier,
  ]);

  const isSelected = get(ctx.qty.supplier, [supplier.id, product.uid], 0) > 0;

  return (
    <Wrapper
      selected={isSelected}
      mainSupplierPreferred={mainSupplierPreferred}
      mainSupplierNotEligible={mainSupplierPriceChangedToMoreExpensive}
      mainSupplierHasNoStock={mainSupplierHasNoStock}
      onClick={() => {
        if (!mainSupplierHasNoStock) ctx.setSelected(supplier.id, product.uid);
      }}
    >
      <Split>
        <Title>{supplier.companyShortName}</Title>
        <Pill canFulfillQuantity={canFulfillQuantity} id="pill">
          <input
            className="light"
            value={inputQty}
            placeholder={'0'}
            type="number"
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          ></input>
          {resolvedSupplierQty ? (
            <Tooltip title={'Showing realtime quantity from supplier'}>
              <div className="blue">{resolvedSupplierQty}</div>
            </Tooltip>
          ) : (
            <div className="dark">{supplierQuantity}</div>
          )}
        </Pill>
      </Split>
      <Price
        mainSupplierPreferred={mainSupplierPreferred}
        mainSupplierNotEligible={mainSupplierPriceChangedToMoreExpensive}
        mainSupplierHasNoStock={mainSupplierHasNoStock}
        hasOriginalPrice={!!showOriginalPrice}
        isLowestPrice={isLowestPrice}
        isMainSupplier={isMainSupplier}
        mainSupplierHasLowestPrice={mainSupplierHasLowestPrice}
      >
        {price.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
      </Price>
      {showOriginalPrice && (
        <OriginalPrice>
          {originalPrice.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
          })}
        </OriginalPrice>
      )}
      {deliveryDate && (
        <DeliveryDate isFastestDelivery={isFastestDelivery}>
          {isFastestDelivery && (
            <img alt="delivery truck icon" src={DeliveryTruckIcon} />
          )}
          <span>{dateFns.format(deliveryDate, 'yyyy-MM-dd')}</span>
        </DeliveryDate>
      )}
      {resolvedSupplierDeliveryDate && (
        <DeliveryDate isFastestDelivery={isFastestDelivery}>
          {isFastestDelivery && (
            <img alt="delivery truck icon" src={DeliveryTruckIcon} />
          )}
          <Tooltip title={'Supplier delivery date provided'}>
            <span>{resolvedSupplierDeliveryDate}</span>
          </Tooltip>
        </DeliveryDate>
      )}
      {resolvingSupplierInquiry && (
        <LoadingIndicatorWrapper>
          <CircularProgress size={'1rem'} />
        </LoadingIndicatorWrapper>
      )}
    </Wrapper>
  );
};

interface WrapperProps {
  selected?: boolean;
  mainSupplierPreferred?: boolean;
  mainSupplierNotEligible?: boolean;
  hasOriginalPrice?: boolean;
  isLowestPrice?: boolean;
  isMainSupplier?: boolean;
  mainSupplierHasLowestPrice?: boolean;
  mainSupplierHasNoStock?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  border: 1px solid #dfe3e8;
  background: white;
  height: 100%;
  width: 100%;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }

  ${(props) =>
    props.selected
      ? `padding: 5px 3px 3px 3px; border: 2px solid #1976D2;`
      : 'padding: 6px 4px 4px 4px;'}
  ${(props) => (props.mainSupplierNotEligible ? `background: #FDF3EF;` : '')}
  ${(props) => (props.mainSupplierPreferred ? `background: #F0FAF4;` : '')}
  ${(props) =>
    props.mainSupplierHasNoStock
      ? `background: #F8CECE; border-color: #F8CECE;`
      : ''}
`;

const Title = styled.h3`
  padding: 0;
  margin: 0 0 4px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const Pill = styled.div<{ canFulfillQuantity: boolean }>`
  display: flex;
  width: 100px;
  min-height: 38px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 6px;

  div,
  input {
    flex: 1;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.3px;

    &.light {
      color: #212b36;
      transition: all 0.2s ease;
      padding: 0;
      margin: 0;

      text-align: center;

      &:focus {
        outline: none;
        background-color: #f8f8f8;
      }
    }

    &.blue {
      font-weight: 400;
      ${(props) =>
        props.canFulfillQuantity
          ? `
              background: #0f4d91;
              color: white;
          `
          : `
            background: #E86032;
            color: white;
      `}
    }

    &.dark {
      font-weight: 400;
      ${(props) =>
        props.canFulfillQuantity
          ? `
              background: #212b36;
              color: white;
          `
          : `
            background: #E86032;
            color: white;
      `}
    }
  }
`;

const Price = styled.div<WrapperProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: 0.3px;

  color: #212b36;

  ${(props) =>
    props.hasOriginalPrice ? `margin-bottom: 0px;` : 'margin-bottom: 6px;'}

  ${(props) =>
    props.isMainSupplier && props.mainSupplierPreferred
      ? `color: #38A169;`
      : ''}
  ${(props) =>
    props.isMainSupplier && props.mainSupplierNotEligible
      ? `color: #E86032;`
      : ''}
  ${(props) =>
    // !props.isMainSupplier &&
    props.isLowestPrice
      ? // !props.mainSupplierHasLowestPrice
        `
    background: #56C489;
    border-radius: 8px;
    color: white;
    padding: 2px 8px;
    `
      : ''}

  ${(props) => (props.mainSupplierHasNoStock ? `color: #212b36;` : '')}
`;

const OriginalPrice = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: line-through;

  margin-bottom: 2px;

  color: #919eab;
`;

const DeliveryDate = styled.div<{ isFastestDelivery?: boolean }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  letter-spacing: 0.3px;

  ${(props) =>
    props.isFastestDelivery ? `color: #38a169;` : 'color: #919EAB;'}

  img {
    margin-right: 8px;
  }
`;

const Split = styled.div`
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const LoadingIndicatorWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 5px;
`;
