import {
  DBAddress,
  DBBranch,
  DBClient,
  DBLanguage,
  DBRole,
  DBUser,
  DBUserRole,
} from '@prisma/client';

interface CreateUserPayload {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  adminId?: string;
  userRole: DBUserRole;
  clientId?: string;
  birthday?: string;
  mobilePhone: string;
  remark?: string;
  active?: boolean;
  language: DBLanguage;
  roleId: string;
  businessPhone?: string;
  personalIdentificationNumber?: string;
  pin?: string;
  mainBranchId?: number;
  currentBranchId?: number;
  secondaryBranches?: number[];
}

interface DBUserOnePayload {
  userId?: string;
  roleId?: string;
  clientId?: string;
  adminId?: string;
  mobilePhone?: string;
  businessPhone?: string;
  personalIdentificationNumber?: string;
  pin?: string;
  email?: string;
  remark?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  active?: boolean;
  language?: DBLanguage;
  firebaseId?: string;
  mainBranchId?: number;
  currentBranchId?: number;
  secondaryBranches?: number[];
}

interface DBUserListPayload {
  search?: string;
  clientId?: string;
}

interface DBUserPayload extends DBUser {
  role: DBRole;
  client?: (DBClient & { address?: DBAddress }) | null | undefined;
  secondaryBranches?: DBBranch[];
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface DBUserApi {
  list: {
    requestBody: never;
    request: DBUserListPayload;
    response: DBUserPayload[];
  };
  getOne: {
    requestBody: never;
    requestParams: DBUserOnePayload;
    request: DBUserOnePayload;
    response: DBUserPayload;
  };
  getMany: {
    requestBody: { userIds: string[] };
    request: { userIds: string[] };
    response: User[];
  };
  create: {
    requestBody: CreateUserPayload;
    requestParams: never;
    request: CreateUserPayload;
    response: { token: string; user: DBUser };
  };
  updateOne: {
    requestBody: DBUserOnePayload;
    requestParams: DBUserOnePayload;
    request: DBUserOnePayload;
    response: DBUser;
  };
}

export { DBUserApi };
