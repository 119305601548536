/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DBClient,
  DBClientPriceCalculation,
  DBMargin,
  DBPriceRound,
  DBSupplier,
  DBSupplierClientSettings,
} from '@prisma/client';

interface DBSupplierSettingsIncluded extends DBSupplierClientSettings {
  supplier: DBSupplier;
}

export interface DBClientPriceCalculationResponse
  extends DBClientPriceCalculation {
  client: DBClient;
  supplier: DBSupplierSettingsIncluded | null;
}

interface DBPriceCalculationRequest {
  search: string;
  page?: number;
  pageSize?: number;
}

interface DBPriceCalculationOnePayload {
  id: string;
}

interface DBCPriceCalculationUpsertRequest {
  isActive?: boolean;
}

interface DBPriceCalculationUpsertPayload {
  id?: string;
  supplierId: string | null;
  margin: number;
  marginType: DBMargin;
  priceRound: DBPriceRound;
  useSuggestedRetailPrice: boolean;
  rules?: any;
  name?: string;
}

interface DBClientPriceCalculationApi {
  list: {
    requestBody: DBPriceCalculationRequest;
    request: DBPriceCalculationRequest;
    response: { data: DBClientPriceCalculationResponse[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBPriceCalculationOnePayload;
    request: DBPriceCalculationOnePayload;
    response: DBClientPriceCalculationResponse | null;
  };
  create: {
    requestBody: DBPriceCalculationUpsertPayload;
    requestParams: never;
    request: DBPriceCalculationUpsertPayload;
    response: DBPriceCalculationUpsertPayload;
  };
  updateOne: {
    requestBody: DBCPriceCalculationUpsertRequest;
    requestParams: DBPriceCalculationOnePayload;
    request: DBPriceCalculationOnePayload | DBCPriceCalculationUpsertRequest;
    response: DBClientPriceCalculation;
  };
}
export { DBClientPriceCalculationApi };
