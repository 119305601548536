import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, TextField } from '@mui/material';

import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import {
  ClickableIcon,
  QuantityWrapper,
  Wrapper,
} from './QuantityComponent.style';

interface QuantityComponentProps {
  quantity: number;
  showButton?: boolean;
  isDisabled?: boolean;
  handleClick?: (newQuantity: number, index: number) => void;
  index?: number;
  maxAvailableQuantity: number;
  cart?: boolean;
  changeCartItemQuantity?: (newQuantity: number) => void;
  qtyIncrement?: number;
}

const QuantityComponent = ({
  quantity,
  showButton = true,
  isDisabled,
  handleClick,
  index,
  maxAvailableQuantity,
  cart = false,
  changeCartItemQuantity,
  qtyIncrement = 1,
}: QuantityComponentProps) => {
  const [newQuantity, setNewQuantity] = useState<number>(cart ? quantity : 0);

  useEffect(() => {
    setNewQuantity(cart ? quantity : 0);
  }, [quantity, cart]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setNewQuantity(value);
    changeCartItemQuantity && changeCartItemQuantity(value);
  };

  const handlePreventNegativeInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (isNaN(Number(value))) e.target.value = '';
    if (Number(value) > maxAvailableQuantity || Number(value) <= 0) {
      e.target.value = '';
    }
  };

  return (
    <Wrapper id="quantity_component_wrapper" showButton={showButton}>
      <QuantityWrapper id="quantity_component">
        <ClickableIcon
          onClick={() => {
            if (newQuantity - qtyIncrement < 1) return;
            setNewQuantity(newQuantity - qtyIncrement);
            changeCartItemQuantity &&
              changeCartItemQuantity(newQuantity - qtyIncrement);
          }}
        >
          <RemoveIcon sx={{ color: '#637381' }} />
        </ClickableIcon>

        <TextField
          style={{ width: '40px' }}
          variant="standard"
          value={newQuantity}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange(e)
          }
          onKeyDown={(event) => {
            if (event.key === '.' || event.key === ',') event.preventDefault();
          }}
          InputProps={{
            disableUnderline: true,
            style: {
              background: 'white',
              height: '32px',
              width: '40px',
              borderColor: '#E6E8F0',
              textAlign: 'center',
            },
            inputProps: {
              min: 1,
              max: maxAvailableQuantity,
              onInput: handlePreventNegativeInput,
              style: { textAlign: 'center', padding: 0 },
            },
          }}
        />

        <ClickableIcon
          onClick={() => {
            if (newQuantity >= maxAvailableQuantity) {
              ToastHelper.showToast(
                'Admin',
                ToastType.WARNING,
                ToastMessageType.CUSTOM_ERROR,
                'You reached maximum available quantity!'
              );
              return;
            }
            setNewQuantity(newQuantity + qtyIncrement);
            changeCartItemQuantity &&
              changeCartItemQuantity(newQuantity + qtyIncrement);
          }}
        >
          <AddIcon sx={{ color: '#637381' }} />
        </ClickableIcon>
      </QuantityWrapper>

      {showButton && (
        <Button
          variant="contained"
          disabled={isDisabled || newQuantity === 0}
          color="info"
          sx={{ height: '46px', width: '75px' }}
          onClick={() => {
            if (handleClick !== undefined)
              handleClick(newQuantity, index as number);
          }}
          disableElevation
        >
          Add
        </Button>
      )}
    </Wrapper>
  );
};

export default QuantityComponent;
