import styled from '@emotion/styled';
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { DBStockInItemStatus } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  ActivePurchaseAndTransferOrdersList,
  DBBranchesApi,
  DBStockInListApi,
  DBStockInOrderItemCountByStatus,
} from '@tyrio/dto';
import { FEATURE_FLAGS, isFlagEnabled } from '@tyrio/feature-flags';
import { TyrioSelectInputOption } from '@tyrio/forms';
import _, { isEmpty } from 'lodash';
import qs from 'qs';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useInboundInvoiceCtx } from '../../../context/InboundInvoiceContext';
import TransferDatePicker from '../../transfer-orders/filter/TransferDatePicker';
import StockInSearchTypeInput from './StockInSearchTypeInput';
import TabsFilter from './TabsFilter';

interface StockInOrderTableFiltersProps {
  isSidebarOpen: boolean;
  filters: qs.ParsedQs;
  setFilters: (data: Partial<qs.ParsedQs>) => void;
  countOrders: DBStockInOrderItemCountByStatus[];
  checked: Record<string, boolean>;
}

const StockInTableFilters = ({
  filters,
  setFilters,
  countOrders,
  isSidebarOpen,
  checked,
}: StockInOrderTableFiltersProps) => {
  const history = useHistory();
  const [branches, setBranches] = useState<TyrioSelectInputOption[]>([]);
  const [suppliersData, setSuppliersData] = useState<TyrioSelectInputOption[]>(
    []
  );

  const { setInput } = useInboundInvoiceCtx();

  const isSmallWindowWidth = window.innerWidth < 2000;

  useQuery(
    ['get_branches'],
    async () => {
      return await api.fetch<DBBranchesApi['list']>('get_branches', {
        search: '',
      });
    },
    {
      onSuccess: (data) => {
        const dropdownItems = [{ value: 'all', label: 'All' }];
        data.data.forEach((item) =>
          dropdownItems.push({
            label: item.branchName,
            value: item.id.toString(),
          })
        );

        setBranches(dropdownItems);
      },
    }
  );

  useQuery(
    ['get_all_stock_in_suppliers'],
    async () => {
      return await api.fetch<DBStockInListApi['getSuppliers']>(
        'get_all_stock_in_suppliers'
      );
    },
    {
      onSuccess: (data) => {
        const dropdownItems = data.map(
          (item: ActivePurchaseAndTransferOrdersList) => ({
            label: item.source,
            value: item.id,
          })
        );

        dropdownItems.push({
          value: 'all',
          label: 'All',
        });

        setSuppliersData(dropdownItems);
      },
    }
  );

  const prepareInvoices = () => {
    if (!isEmpty(checked)) {
      setInput({
        ids: Object.keys(checked).join(','),
        checkedStockIns: checked,
      });
      history.push('inbound-invoice');
    }
  };

  const getBranchesAutocompleteDefaultValue = () => {
    if (filters['branchId']) {
      const selectedBranch = branches.find(
        (el) => el.value === filters['branchId']
      );
      return { value: filters['branchId'], label: selectedBranch?.label || '' };
    }
    return { value: 'all', label: 'All' };
  };

  const getSupplierAutocompleteDefaultValue = () => {
    if (filters['supplierId']) {
      const selectedSupplier = suppliersData.find(
        (el) => el.value === filters['supplierId']
      );
      return {
        value: filters['supplierId'],
        label: selectedSupplier?.label || '',
      };
    }
    return { value: 'all', label: 'All' };
  };

  return (
    <Grid>
      <Grid
        container
        spacing={1.5}
        paddingLeft="16px"
        paddingTop="21px"
        paddingRight="16px"
      >
        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 6 : 4}>
          <StockInSearchTypeInput filters={filters} setFilters={setFilters} />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 3 : 1.5}>
          <Autocomplete
            disablePortal
            disableClearable
            id="branchId"
            defaultValue={
              getBranchesAutocompleteDefaultValue() as unknown as TyrioSelectInputOption
            }
            getOptionLabel={(option) => {
              if (typeof option === 'string')
                return branches.find((el) => el.value === option)?.label || '';
              else
                return (
                  branches.find((el) => el.value === option.value)?.label || ''
                );
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_event, newValue) => {
              setFilters({ ...filters, branchId: newValue.value.toString() });
            }}
            options={branches}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="Branch" />}
          />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 3 : 1.5}>
          <Autocomplete
            disablePortal
            disableClearable
            id="supplier"
            defaultValue={
              getSupplierAutocompleteDefaultValue() as unknown as TyrioSelectInputOption
            }
            getOptionLabel={(option) => {
              if (typeof option === 'string')
                return (
                  suppliersData.find((el) => el.value === option)?.label || ''
                );
              else
                return (
                  suppliersData.find((el) => el.value === option.value)
                    ?.label || ''
                );
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(_event, newValue) => {
              setFilters({ ...filters, supplierId: newValue.value.toString() });
            }}
            options={suppliersData}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="Supplier" />}
          />
        </Grid>

        <Grid item xs={isSidebarOpen && isSmallWindowWidth ? 8 : 2.5}>
          <TransferDatePicker
            isSidebarOpen={isSidebarOpen}
            filters={filters}
            setFilters={setFilters}
          />
        </Grid>

        <Grid
          item
          xs={isSidebarOpen && isSmallWindowWidth ? 4 : 2.5}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <InboundButton
            disabled={
              _.size(checked) < 1 ||
              !isFlagEnabled(FEATURE_FLAGS.SHOW_INBOUND_INVOICE)
            }
            onClick={prepareInvoices}
          >
            INBOUND INVOICE
          </InboundButton>
        </Grid>
      </Grid>

      <TabFilterContainer container>
        <TabFilterWrapper item>
          <TabsFilter
            filters={filters}
            setFilters={setFilters}
            countOrders={countOrders}
          />
          <FormControlLabel
            sx={{ marginLeft: '50px' }}
            label="Cancelled"
            control={
              <Switch
                color="info"
                checked={
                  filters['orderStatus'] === DBStockInItemStatus.CANCELLED
                }
                onChange={() => {
                  const value =
                    filters['orderStatus'] !== DBStockInItemStatus.CANCELLED
                      ? (DBStockInItemStatus.CANCELLED as keyof typeof DBStockInItemStatus)
                      : 'all';
                  setFilters({
                    ...filters,
                    orderStatus: value,
                  });
                }}
              />
            }
          />
        </TabFilterWrapper>
      </TabFilterContainer>
    </Grid>
  );
};

const TabFilterContainer = styled(Grid)`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabFilterWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: scroll;
  ms-overflow-style: none;
  scrollbar-width: none;
  scrollbar-color: red;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const InboundButton = styled(Button)`
  width: 160px;
  height: 55px;
  background: ${(props) =>
    !props.disabled ? '#1976D2' : 'rgb(0, 0, 0, 0.12)'};
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  font-family: Roboto;
  border: ${(props) => (!props.disabled ? '1px solid #1976D2' : '')};
  color: ${(props) => (!props.disabled ? 'white' : '')};

  &:hover {
    background: ${(props) =>
      !props.disabled ? '#1976D2' : 'rgb(0, 0, 0, 0.12)'};
    border: ${(props) => (!props.disabled ? '1px solid #1976D2' : '')};
    color: ${(props) => (!props.disabled ? 'white' : '')};
  }
`;

export default StockInTableFilters;
