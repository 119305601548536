import { DBSupplierLocation, DBSupplierLocationType } from '@prisma/client';

interface DBUpsertSupplierLocationPayload {
  id?: string;
  locationId: string;
  locationName: string;
  slug: string;
  countryId: string;
  zipCode: string;
  city: string;
  adress: string;
  isHeadQuarter: boolean;
  supplierLocationType: DBSupplierLocationType | string;
}

interface DBGetSupplierLocationsRequest {
  supplierId: string;
}

interface DBSupplierLocationApi {
  list: {
    requestBody: DBGetSupplierLocationsRequest;
    request: DBGetSupplierLocationsRequest;
    response: DBSupplierLocation[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierLocationPayload;
    requestParams: never;
    request: DBUpsertSupplierLocationPayload;
    response: DBSupplierLocation;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierLocationApi };
