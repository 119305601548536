import styled from '@emotion/styled';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { PosItem, PosItemResponse, PosItemSet } from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import _ from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { POSContext } from '../../../../context/POSContext';
import NoData from '../../components/DataIndicators/NoData';
import SidebarFiltersList from '../../components/FilterSidebarList';
import { useAccordion } from '../../helpers/accordion';
import { useGetPosData } from '../../query/get-pos-data';
import PosLoading from '../DataIndicators/Loading';
import { PosRowCell } from './Row';
import { RowItem } from './RowItem';

interface PosTableProps {
  onOpenSidebarFilters: () => void;
}

export const PosTable = ({ onOpenSidebarFilters }: PosTableProps) => {
  const { PosTableItems } = useAccordion();
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  const {
    myStockItems,
    branchesStockItems,
    supplierStockItems,
    filterValues,
    setFilterValue,
  } = useContext(POSContext);

  const {
    myStockItemsStatus,
    branchesStockItemsStatus,
    supplierStockItemsStatus,
  } = useGetPosData(filterValues, shouldLoadMore, setShouldLoadMore);

  const renderRows = (
    arr: PosItem[],
    showBrand?: boolean,
    isLoading?: boolean
  ) => {
    return arr.length > 0 ? (
      <Grid width={showBrand === false ? 'calc(100% - 150px)' : '100%'}>
        {arr.map((posItem, index) => (
          <RowItem
            key={`${posItem.id}_${index}`}
            posItem={posItem}
            showBrand={showBrand}
          />
        ))}
      </Grid>
    ) : isLoading ? (
      <PosLoading />
    ) : (
      <NoDataAlertWrapper>
        <NoData />
      </NoDataAlertWrapper>
    );
  };

  const renderSets = (data: PosItemSet[], isLoading: boolean) => {
    return data.map((value) => {
      return (
        <SetWrapper>
          <BrandWrapper>
            <Typography fontWeight={400}>{value.key.split('_')[0]}</Typography>
          </BrandWrapper>
          {renderRows(value.items, false, isLoading)}
        </SetWrapper>
      );
    });
  };

  const renderStock = (data: PosItemResponse, isLoading: boolean) => {
    const hasSets = !_.isEmpty(data.matchedSets);

    const header = hasSets && (
      <TitleWrapper>
        <Title>UNMATCHED TIRES</Title>
      </TitleWrapper>
    );

    return (
      <>
        {renderSets(data.matchedSets, isLoading)}
        {header}
        {renderRows(data.unmatchedItems, true, isLoading)}
      </>
    );
  };

  const supplierStockItemLength = useMemo(() => {
    const setsLength = supplierStockItems.matchedSets.map(
      (i) => i.items.length
    );

    return _.sum([...setsLength, supplierStockItems?.unmatchedItems.length]);
  }, [supplierStockItems]);

  const isFilterInactive = _.isEmpty(filterValues);

  return (
    <Table>
      <PosTableHead>
        <PosTableHeadRow>
          <PosTableCell>
            <SidebarFilterWrapper>
              <SidebarFiltersList onOpen={onOpenSidebarFilters} />
            </SidebarFilterWrapper>
            <PosTableHeadWrapper>
              <PosTableHeadItem>Price</PosTableHeadItem>
              <PosTableHeadItem>Cash</PosTableHeadItem>
              <PosTableHeadItem>Quantity</PosTableHeadItem>
            </PosTableHeadWrapper>
          </PosTableCell>
        </PosTableHeadRow>
      </PosTableHead>
      <TableBody sx={{ padding: 0 }} id="hide">
        <PosRowCell>
          <Grid>
            {myStockItems && (
              <Grid>
                <PosTableItems posItemKey="currentBranchStock">
                  {/* {renderRows(myStockItems.unmatchedItems)} */}
                  {renderStock(
                    myStockItems,
                    myStockItemsStatus === 'loading' && !isFilterInactive
                  )}
                </PosTableItems>
              </Grid>
            )}
          </Grid>
        </PosRowCell>

        <PosRowCell>
          <Grid>
            {branchesStockItems && (
              <Grid id="branches_stock">
                <PosTableItems posItemKey="branchesStock">
                  {renderStock(
                    branchesStockItems,
                    branchesStockItemsStatus === 'loading' && !isFilterInactive
                  )}
                </PosTableItems>
                {/* <PosTableItems posItemKey="branchesStock">
                  {renderRows(branchesStockItems.unmatchedItems)}
                </PosTableItems> */}
              </Grid>
            )}
          </Grid>
        </PosRowCell>

        <PosRowCell>
          <Grid>
            {supplierStockItems && (
              <Grid>
                <PosTableItems posItemKey="supplierStock">
                  <Grid>
                    {/* {renderRows(supplierStockItems.unmatchedItems)} */}
                    {renderStock(
                      supplierStockItems,
                      supplierStockItemsStatus === 'loading' &&
                        !isFilterInactive
                    )}
                    <PosTableLoadMoreWrapper>
                      {supplierStockItemLength > 0 && (
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => {
                            const page =
                              Number(filterValues['supplierStockPage'] ?? 1) +
                              1;
                            setFilterValue({
                              supplierStockPage: page.toString(),
                            });
                            setShouldLoadMore(true);
                          }}
                        >
                          LOAD MORE
                        </Button>
                      )}
                    </PosTableLoadMoreWrapper>
                  </Grid>
                </PosTableItems>
              </Grid>
            )}
          </Grid>
        </PosRowCell>
      </TableBody>
    </Table>
  );
};

const PosTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const PosTableCell = styled(TableCell)`
  padding: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
`;

export const SidebarFilterWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - 140px);
`;

const PosTableHeadWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 210px;
  width: 100%;
`;

const PosTableHeadRow = styled(TableRow)`
  background: #fff;
`;

const PosTableHeadItem = styled.span`
  color: ${tyrioUI.colors.black.B90};
  font-size: 14px;
`;

const PosTableLoadMoreWrapper = styled.span`
  padding: 16px;
  display: flex;
  justify-content: center;
`;

const NoDataAlertWrapper = styled.div`
  padding: 24px;
`;

const TitleWrapper = styled.div`
  background: #c4cdd5;
  color: white;
  height: 70px;
  width: 100%;
  align-items: center;
  display: flex;
`;

export const Title = styled(Typography)`
  margin-left: 10px;
  font-weight: 500;
`;

const SetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(223, 227, 232);
  min-width: 150px;
  max-width: 150px;
`;
