import { Grid } from '@mui/material';
import { useContext } from 'react';
import { POSContext } from '../../../context/POSContext';
import { useFilter } from '../../../hooks/useFilter';
import PosAccordion, {
  PosItemRowBackground,
} from '../components/Table/Accordion';

export interface PosItemConfigProps {
  isAccordion: boolean;
  background: PosItemRowBackground;
  title: string;
  length: number;
  isExpanded: boolean;
  onExpand: () => void;
}

interface ConditionalWrapProps {
  condition: boolean;
  wrap: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

type PosItemKey = 'currentBranchStock' | 'branchesStock' | 'supplierStock';

interface PosTableIemsProps {
  children: JSX.Element;
  posItemKey: PosItemKey;
}

const ConditionalWrap = ({ condition, wrap, children }: ConditionalWrapProps) =>
  condition ? wrap(children) : children;

export const useAccordion = () => {
  const { ...filtersHook } = useFilter();
  const {
    setBranchesStockItems,
    setSupplierStockItems,
    isBranchesStockExpanded,
    setIsBranchesStockExpanded,
    isSupplierStockExpanded,
    setIsSupplierStockExpanded,
  } = useContext(POSContext);

  const config = (att: PosItemKey) => {
    return {
      currentBranchStock: {
        isExpanded: false,
        onExpand: () => {
          console.log('NOT_IMPLEMENTED');
        },
        isAccordion: false,
        background: {
          default: '#fff',
          hover: '#fff',
        },
        title: 'MY STOCK',
      },
      branchesStock: {
        isExpanded: isBranchesStockExpanded,
        onExpand: () => {
          setIsBranchesStockExpanded(!isBranchesStockExpanded);
          setBranchesStockItems([]);
        },
        isAccordion: true,
        background: {
          default: '#1976D2CC',
          hover: '#1976D2',
        },
        title: 'BRANCHES STOCK',
      },
      supplierStock: {
        isExpanded: isSupplierStockExpanded,
        onExpand: () => {
          if (isSupplierStockExpanded) {
            setSupplierStockItems([]);
            filtersHook.setFilterValue({
              supplierStockPage: undefined,
            });
          }
          setIsSupplierStockExpanded(!isSupplierStockExpanded);
        },
        isAccordion: true,
        background: {
          default: '#DB1E1ECC',
          hover: '#DB1E1E',
        },
        title: 'SUPPLIER STOCK',
      },
    }[att];
  };

  const PosTableItems = (props: PosTableIemsProps) => {
    const { children, posItemKey } = props;
    const posItemConf = config(posItemKey);

    return (
      <ConditionalWrap
        condition={posItemConf.isAccordion}
        wrap={(wrappedChildren) => (
          <Grid sx={{ width: '100%' }}>
            <PosAccordion {...posItemConf} key={posItemKey}>
              {wrappedChildren}
            </PosAccordion>
          </Grid>
        )}
      >
        {children}
      </ConditionalWrap>
    );
  };

  return { PosTableItems };
};
