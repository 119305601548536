import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import {
  CancelModal,
  RouteRouterPrompt,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
  TyrioTabsRefType,
  backIcon,
  tyrioUI,
} from '@tyrio/ui-library';
import _, { flatten as _flatten, reduce as _reduce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DeepPartial,
  FieldValues,
  UnpackNestedValue,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ZodSchema } from 'zod';
import { TyrioFormMapping } from './FormMapping';
import { FormRepeater } from './FormRepeater';
import SourceContainer from './components/SourceContainter';
import { GeneratePartnerData } from './helper/GeneratePartnerData';
import { useStickyState } from './hooks/useStickyState';
import {
  FormGridProps,
  FormGridSize,
  FormShape,
  GetValueProps,
  PermissionsValues,
  TyrioFormInput,
  TyrioRepeater,
} from './types';
import { checkIfValidUUID } from './utils';

interface NestedErrorInterface {
  [key: string]: { message: string; ref: { name: string }; type: string };
}
interface FormControllerProps<T extends object> {
  title: string;
  active?: boolean;
  form: FormShape;
  isLoading?: boolean;
  initialValues?: Partial<T>;
  zodSchema: ZodSchema<T>;
  onSubmit: (data: T) => unknown;
  gridSpacing?: number;
  onDelete?: () => void;
  onCancel: () => void;
  onChangeStatus?: () => void;
  permissions?: PermissionsValues;
  isDeleteDisabled?: boolean;
  objectId?: string;
  cantDelete?: boolean;
  onChangeValue?: (v: GetValueProps) => void;
  shouldReset?: boolean;
}

export function FormController<T extends object>({
  title,
  active,
  form,
  isLoading,
  zodSchema,
  onSubmit,
  onDelete,
  onCancel,
  onChangeStatus,
  initialValues,
  gridSpacing,
  permissions,
  isDeleteDisabled,
  objectId,
  cantDelete,
  onChangeValue,
  shouldReset,
}: FormControllerProps<T>) {
  const ref = useRef<TyrioTabsRefType | null>(null);
  const { t } = useTranslation();
  const location = useLocation();
  const isPartnersPage = location.pathname.includes('partners');

  const {
    register,
    unregister,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm<FieldValues>({
    defaultValues: initialValues as UnpackNestedValue<DeepPartial<T>>,
    resolver: zodResolver(zodSchema),
  });

  let handlePartnerUpdate: () => void;
  const [shouldDisableEdit, setShouldDisableEdit] = useState(false);

  if (isPartnersPage) {
    const companyRegistrationNumber: string | undefined =
      watch && watch('companyRegistrationNumber');

    handlePartnerUpdate = GeneratePartnerData({
      companyId: companyRegistrationNumber ?? '',
      setShouldDisableEdit: setShouldDisableEdit,
      initialValues,
      reset,
    });
  }

  const history = useHistory();
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [stickyLoadedList, setStickyLoadedList] = useStickyState(
    [],
    'GLOBAL_STICKY_LOADER'
  );
  const clearedOnMount = useRef(false);
  const [isSaving, setIsSaving] = useState(false);

  const isNew = useMemo(
    () => location.pathname.split('/').pop() !== 'new',
    [location.pathname]
  );

  useEffect(() => {
    if (!clearedOnMount.current) {
      stickyLoadedList.forEach((key: string) => {
        window.localStorage.removeItem(key);
      });
      setStickyLoadedList([]);
      clearedOnMount.current = true;
    }
  }, [setStickyLoadedList, stickyLoadedList]);

  useEffect(() => {
    if (shouldReset !== undefined && shouldReset !== false)
      reset(initialValues);
  }, [initialValues, reset, shouldReset]);

  useEffect(() => {
    if (isDirty)
      window.onbeforeunload = function (event) {
        event.preventDefault();
        event.returnValue = '';
      };
  }, [isDirty]);

  useEffect(() => {
    if (Object.values(errors).length > 0) {
      const fieldErrors: string[] = [];
      Object.values(errors).forEach((error) => {
        const obj = fieldList[error?.ref?.name];
        if (obj && 'label' in obj) fieldErrors.push(obj.label || '');

        if (Object.values(error).length) {
          Object.values(error).forEach((nestedError) => {
            const newNestedError: NestedErrorInterface =
              nestedError as unknown as NestedErrorInterface;

            try {
              Object.keys(newNestedError).forEach((el) => {
                if (newNestedError[el].ref && newNestedError[el].ref.name) {
                  const nestedKey = newNestedError[el].ref.name.slice(
                    0,
                    newNestedError[el].ref.name.indexOf('.')
                  );

                  const secondPartOfKey = newNestedError[el].ref.name.slice(
                    newNestedError[el].ref.name.indexOf('.') + 1,
                    newNestedError[el].ref.name.length
                  );
                  const fieldNameId = secondPartOfKey.slice(
                    secondPartOfKey.indexOf('.') + 1,
                    secondPartOfKey.length
                  );

                  const newInputs = (fieldList[nestedKey] as TyrioRepeater)
                    .inputs;
                  const newError = newInputs.filter(
                    (el) => el.id === fieldNameId
                  );
                  if ('label' in newError[0]) {
                    fieldErrors.push(newError[0].label || '');
                  }
                }
              });
            } catch (e) {
              console.log(e);
            }
          });
        }
      });

      const uniqFieldErrors = _.uniq(fieldErrors);
      ToastHelper.showToast(
        'Client',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        uniqFieldErrors.length === 1
          ? 'Field ' + uniqFieldErrors + ' has errors.'
          : 'Fields ' +
              uniqFieldErrors.map((err) => ' ' + err.replace('*', '')) +
              ' have errors.'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  /** Leaving if needed, list of all fields in the form in one-dimensional array */
  const fieldList = useMemo(
    () =>
      _reduce(
        _flatten(form.tabs.map((tab) => tab.inputs)),
        (obj: Record<string, TyrioFormInput>, param) => {
          obj[param.id] = param;
          return obj;
        },
        {}
      ),
    [form.tabs]
  );

  const doCancel = () => {
    if (isDirty) setIsCancelModalVisible(true);
    else onCancel();
  };

  const repeaterItemIds = useMemo(() => {
    return _.flatten(
      form.tabs.map((tab) =>
        tab.inputs
          .filter((item) => item.type === 'form.repeater')
          .map((item) =>
            objectId ? `${item.id}_${objectId}` : `${item.id}_${title}`
          )
      )
    );
  }, [form.tabs, objectId, title]);

  const doSubmit = async (data: FieldValues) => {
    setIsSaving(true);
    Object.keys(data).forEach((dataKey) => {
      if (typeof data[dataKey] === 'object' && dataKey !== 'birthday') {
        const item = data[dataKey] || {};
        if (checkIfValidUUID(Object.keys(item)?.[0])) {
          data[dataKey] = Object.keys(data[dataKey]).map((el) => ({
            ...data[dataKey][el],
            key: el,
          }));

          repeaterItemIds.forEach((id) => {
            const obj = window.localStorage.getItem(id);
            const idSubstr = obj?.slice(2, 38);
            unregister(`${dataKey}.${idSubstr}`);
          });
        }
      }
    });

    try {
      const successful = await onSubmit(data as T);
      if (successful)
        repeaterItemIds.forEach((id) => {
          window.localStorage.removeItem(id);
        });
    } catch (e: unknown) {
      console.log(e);
    }
  };

  return (
    <FormControllerWrapper>
      <form onSubmit={handleSubmit(doSubmit)} noValidate>
        {!isCancelModalVisible && (
          <RouteRouterPrompt
            when={isDirty && !isSaving}
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={() => true}
          />
        )}
        {isCancelModalVisible && (
          <CancelModal
            LBAction={() => setIsCancelModalVisible(false)}
            RBAction={() => {
              onCancel();
              setIsCancelModalVisible(false);
            }}
          />
        )}
        <HeaderWrapper>
          <Title>
            <BackButton onClick={() => onCancel()}>
              <img src={backIcon} alt="back-icon" />
            </BackButton>
            {title}
          </Title>
          {isNew && !cantDelete && (
            <FormControlLabel
              control={
                <Switch
                  data-cy="switch"
                  color="info"
                  onChange={onChangeStatus}
                  checked={active}
                  disabled={isDeleteDisabled}
                />
              }
              label={t('Active')}
            />
          )}
        </HeaderWrapper>
        <TabsWrapper>
          <TyrioTabs ref={ref}>
            {form.tabs.map((tab) => {
              const { tabTitle } = tab;
              const inputIds = tab.inputs.map((a) => a?.id);
              const hasError =
                Object.keys(errors).find((f) => inputIds.includes(f)) !==
                undefined;
              return (
                <TyrioTab title={t(tabTitle)} key={tabTitle} errored={hasError}>
                  <Grid container spacing={gridSpacing || 2}>
                    {tab.inputs.map((item, idx) => {
                      if (item.type === 'form.repeater') {
                        return (
                          <FormRepeater
                            control={control}
                            key={item.id}
                            stickyKey={
                              objectId
                                ? `${item.id}_${objectId}`
                                : `${item.id}_${title}`
                            }
                            idx={idx}
                            errors={errors[item.id]}
                            item={item}
                            register={register}
                            unregister={unregister}
                            setValue={setValue}
                            getValues={getValues}
                            gridSpacing={gridSpacing}
                            watch={watch}
                          />
                        );
                      }
                      const gridProps: FormGridProps = {
                        ...(item.width as FormGridSize),
                      };
                      return (
                        <TyrioFormMapping
                          id={item.id}
                          control={control}
                          key={`${item.id}-${idx}`}
                          error={errors[item.id]}
                          item={item}
                          gridProps={gridProps}
                          register={register}
                          setValue={setValue}
                          getValues={getValues}
                          watch={watch}
                          shouldDisableEdit={shouldDisableEdit}
                          handleCustomSubmit={handlePartnerUpdate}
                          onChangeValue={onChangeValue}
                        />
                      );
                    })}
                  </Grid>
                </TyrioTab>
              );
            })}
          </TyrioTabs>

          <SourceContainer
            permissions={permissions}
            loading={isLoading}
            data={{
              data_source: 'Manual by User',
              created: 'Antonio  Rakijar - 04.05.2022 - 16:53:04 ',
              last_edited: 'Antonio  Rakijar - 04.05.2022 - 16:53:04 ',
            }}
            onDelete={onDelete}
            onCancel={doCancel}
            disabledDelete={isDeleteDisabled}
            cantDelete={cantDelete}
          />
        </TabsWrapper>
      </form>
    </FormControllerWrapper>
  );
}

FormController.defaultProps = {
  initialValues: {},
};

export const FormControllerWrapper = styled.div`
  padding: ${tyrioUI.spacing.l}px ${tyrioUI.spacing.l}px 100px
    ${tyrioUI.spacing.l}px;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const BackButton = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #212b36;
  display: flex;
  flex-direction: row;
`;

export const TabsWrapper = styled.div`
  padding-bottom: 100px;
`;
