/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { POSContext } from '../../../../context/POSContext';
import { useVehicleCtx } from '../../../../context/VehicleContext';
import { StockInfo } from '../../components/StockInfo/StockInfo';
import { useGetBranchesStock } from '../../query/get-branches-stock';
import { useGetSuppliersStock } from '../../query/get-suppliers-stock';
import { StockWrapper } from './Details.style';

interface DetailsStockProps {
  stockType: 'CURRENT' | 'BRANCHES' | 'SUPPLIER';
  isWheels?: boolean;
}

const DetailsStock = ({ stockType, isWheels = false }: DetailsStockProps) => {
  const {
    selectedTableRow,
    shouldShowDot,
    setBadgeContent,
    selectedWarehouseId,
    selectedCustomer,
  } = useContext(POSContext);

  const { selectedProduct } = useVehicleCtx();

  const ean = isWheels ? selectedProduct?.ean : selectedTableRow?.product?.ean;
  const selectedStock = isWheels ? 'CURRENT' : selectedTableRow?.stockType;

  const { onStock, upcomingStock, warehouseStock, isOnStockFetching } =
    useGetBranchesStock(
      ean ?? '',
      selectedWarehouseId?.toString(),
      undefined,
      undefined,
      selectedStock,
      shouldShowDot,
      selectedCustomer?.id ?? 'default-customer'
    );

  const { suppliersStock } = useGetSuppliersStock(
    ean ?? '',
    'Default sorting',
    selectedWarehouseId?.toString() as string,
    selectedCustomer?.id ?? 'default-customer',
    undefined,
    undefined
  );

  useEffect(
    function updateBadgeContent() {
      let branchSum = 0;
      warehouseStock?.forEach((r) =>
        r.lineItems.forEach(
          (item) => (branchSum += item.quantity - item.reserved)
        )
      );
      let supplierSum = 0;
      suppliersStock?.forEach((r) =>
        r.lineItems.forEach(
          (item) => (supplierSum += item.quantity - item.reserved)
        )
      );

      setBadgeContent((prevState) => {
        if (
          prevState.BRANCHES !== branchSum ||
          prevState.SUPPLIERS !== supplierSum
        ) {
          return {
            ...prevState,
            BRANCHES: branchSum,
            SUPPLIERS: supplierSum,
          };
        }
        return prevState;
      });
    },
    [warehouseStock, suppliersStock, setBadgeContent]
  );

  return (
    <div>
      {stockType === 'CURRENT' ? (
        <div>
          {onStock && onStock?.length > 0 ? (
            <StockWrapper>
              <StockInfo data={onStock} text="ON STOCK" />
            </StockWrapper>
          ) : warehouseStock && warehouseStock.length > 0 ? (
            <StockWrapper>
              <StockInfo data={warehouseStock} text="BRANCHES STOCK" />
            </StockWrapper>
          ) : (
            //   TODO: fix this, this should show supplier with lowest price
            suppliersStock &&
            suppliersStock.length > 0 &&
            !isOnStockFetching && (
              <StockWrapper>
                <StockInfo data={suppliersStock} text="SUPPLIERS STOCK" />
              </StockWrapper>
            )
          )}
          {upcomingStock && upcomingStock?.length > 0 && (
            <StockWrapper style={{ marginTop: '30px' }}>
              <StockInfo data={upcomingStock} text="UPCOMING ON STOCK" />
            </StockWrapper>
          )}
        </div>
      ) : stockType === 'BRANCHES' ? (
        <div>
          {warehouseStock && warehouseStock.length > 0 && (
            <StockWrapper>
              <StockInfo data={warehouseStock} text="BRANCHES STOCK" />
            </StockWrapper>
          )}
        </div>
      ) : (
        <div>
          {suppliersStock && (
            <StockWrapper>
              <StockInfo
                data={suppliersStock}
                text="SUPPLIERS STOCK"
                showVisibility={true}
              />
            </StockWrapper>
          )}
        </div>
      )}
    </div>
  );
};

export default DetailsStock;
