import { DBCountry } from '@prisma/client';

export interface VatRates {
  type: string;
  code: string;
  name: string;
  percent: number;
}

interface DBCountryApi {
  list: {
    requestBody: never;
    request: never;
    response: DBCountry[];
  };
  getOne: {
    requestBody: never;
    requestParams: { id: string };
    request: { id: string };
    response: DBCountry;
  };
  create: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  updateOne: {
    requestBody: Partial<DBCountry>;
    requestParams: { id: string };
    request: Partial<DBCountry> & { id: string };
    response: DBCountry;
  };
}

export { DBCountryApi };
