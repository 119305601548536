import { StreamableFile } from '@nestjs/common';

interface DBSupplierFileOnePayload {
  fileName: string;
}

interface DBSupplierFtpImport {
  customFileName?: string;
  supplierName?: string;
  connectionType?: string;
  fileFormat?: string;
  fileName?: string;
  ftpHost?: string;
  ftpPort?: string;
  ftpUsername?: string;
  ftpPassword?: string;
  apiUrl?: string;
  apiKey?: string;
  apiClientId?: string;
  apiClientSecret?: string;
  httpsUrl?: string;
  httpsUsername?: string;
  httpsPassword?: string;
}

interface DBStockPriceImportRequest {
  supplierId: string;
  connectionId: string;
  clientId?: string;
}

interface DBSupplierImportApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: never;
    requestParams: DBSupplierFileOnePayload;
    request: DBSupplierFileOnePayload;
    response: StreamableFile;
  };
  create: {
    requestBody: DBSupplierFtpImport;
    requestParams: never;
    request: DBSupplierFtpImport;
    response: DBSupplierFileOnePayload;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  import_stock: {
    requestBody: DBStockPriceImportRequest;
    requestParams: never;
    request: DBStockPriceImportRequest;
    response: { ok: boolean };
  };
}

export { DBSupplierImportApi };
