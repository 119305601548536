import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TOKEN } from '@tyrio/api-factory';
import { useAuth } from './AuthContext';

import { WSState } from '@tyrio/dto';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { appConfig } from '@tyrio/config';

interface WSContextShape {
  state: WSState;
  ping: () => void;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
}

export const WSContext = React.createContext<WSContextShape>({
  state: {
    locks: {},
    activeEdits: {},
    cart: {
      userCart: {},
    },
    posCart: {
      userCart: {},
    },
    ordersInProcess: {},
    activePickingAppointmentsList: {},
    activeServicesList: {},
    appointments: {},
    activeStockIns: {},
  },
  ping: () => null,
  socket: null,
});

const connectionConfig = {
  jsonp: false,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 3,
  transports: ['websocket'],
  closeOnBeforeunload: false,
};

const socket = { current: io(appConfig.wsUrl, connectionConfig) };

const useWSContextData = (): WSContextShape => {
  const { user } = useAuth();
  const [state, setState] = useState<WSState>({
    locks: {},
    activeEdits: {},
    cart: {
      userCart: {},
    },
    posCart: {
      userCart: {},
    },
    ordersInProcess: {},
    activePickingAppointmentsList: {},
    activeServicesList: {},
    appointments: {},
    activeStockIns: {},
  });

  const handleMessage = useCallback((event: WSState): void => {
    console.log({ event });
    setState(event);
  }, []);

  const ping = useCallback(() => {
    socket.current.emit('ping');
  }, []);

  useEffect(() => {
    const s = socket.current;
    s.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    if (user && !s.connected) {
      s.on('connect', () => {
        s.emit('identify', { token: TOKEN.get() });
      });
      s.on('data', handleMessage);
    } else if (user && s.connected) {
      s.emit('identify', { token: TOKEN.get() });
      s.on('data', handleMessage);
    } else if (!user && socket.current.connected) {
      s.disconnect();
    }
  }, [handleMessage, user]);

  return { state, ping, socket: socket.current };
};

export const WSProvider = ({ children }: { children: React.ReactNode }) => {
  const data = useWSContextData();
  return <WSContext.Provider value={data}>{children}</WSContext.Provider>;
};

export const useWS = () => useContext(WSContext);
