/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { isSignInWithLink } from '../../../../../app/src/helpers/firebase';
import NoPermissionsPage from '../components/NoPermissionsPage/NoPermissionsPage';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import AuthLayout from '../layouts/AuthLayout';
import DeactivatedUser from '../layouts/DeactivatedUser';
import { FullScreenLoader } from '../layouts/FullScreenLoader';
import routeNames from '../lib/routeNames';
import { DashboardRouter } from './DashboardRouter';
import { useAuth } from '../context/AuthContext';
import { AxiosError } from 'axios';
import { NetworkError } from '../layouts/NetworkError';

export const RootRouter = () => {
  const { user, userError, userLoading } = useAuth();

  const auth = getAuth();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { apiKey } = qs.parse(location.search.slice(1));
    if (apiKey && history) {
      isSignInWithLink();
    }
  }, [location.search, history]);

  useEffect(() => {
    const AuthCheck = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoading(false);
      } else {
        console.log('unauthorized');
      }
    });

    return () => AuthCheck();
  }, [auth, history]);

  const isUserDeactivated = useMemo(() => {
    if (userError && 'response' in (userError as any)) {
      const errorWithResponse = userError as AxiosError<unknown>;
      if (errorWithResponse && 'status' in errorWithResponse) {
        const status = errorWithResponse.status;

        return status === '401' || Number(status) === 401;
      }
    }
    return false;
  }, [userError]);

  const isNetworkError = useMemo(() => {
    if (userError && 'response' in (userError as any)) {
      const errorWithResponse = userError as AxiosError<unknown>;
      if (errorWithResponse && 'code' in errorWithResponse) {
        const status = errorWithResponse.code;

        return status === 'ERR_NETWORK';
      }
    }
    return false;
  }, [userError]);

  if (loading) return <CircularProgress />;

  return (
    <Switch>
      {isNetworkError && (
        <>
          <Route path={'/error-500'}>
            <NetworkError />
          </Route>
          <Redirect to={'/error-500'} />
        </>
      )}

      {userLoading && <FullScreenLoader />}

      {isUserDeactivated && (
        <Route path={routeNames.root()}>
          <DeactivatedUser />
        </Route>
      )}

      {!user && (
        <>
          <Route exact path={routeNames.signin()}>
            <AuthLayout />
          </Route>
          {!location.search && <Redirect to={routeNames.signin()} />}
        </>
      )}

      {!!user && (
        <>
          {(location.pathname === '/' ||
            location.pathname === '/sign-in' ||
            location.pathname === '/error-500') && (
            <Redirect to={routeNames.dashboard()} />
          )}
          <Route path={routeNames.dashboard()}>
            <DashboardRouter user={user} />
          </Route>
        </>
      )}

      <Route exact path={routeNames.noPermission()}>
        <NoPermissionsPage />
      </Route>
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
