export const EUCountries = [
  '5c1a0ec0-9637-1c16-2373-66389026c6cb', // austria
  '450239b4-d4cb-2c18-99a3-62e600570a29', // belgium
  'ab01e378-7f36-4cb2-9734-542efeed7c26', // bulgaria
  '274e7e37-6d32-f25b-4a5f-daf91b949567', // croatia
  '2c32a0e5-327b-4805-51b9-2262ebc5f909', // cyprus
  '4a44da57-dbbc-a603-74cf-cf8d3e55d56d', // czechia
  '038d131f-4348-8f8a-68b5-46685c85730d', // denmark
  '4d7a97df-edd0-2654-9aaa-85a6655784d4', // estonia
  'ec388e15-3710-8e25-cde8-49d7eef27d15', // finland
  '3c0f75fd-3b73-79eb-13a4-12293811e632', // france
  'c1b450ae-7828-385e-df13-41ab5f49e0c3', // germany
  'e5f240e1-341e-d3c9-cd11-7664fcd87a5c', // greece
  '850211d7-72e4-731d-f586-9817ade536db', // hungary
  '50135aa0-2011-8e2e-cf03-357c2ea101c3', // ireland
  'c3771d14-0359-f628-e161-587d8306d67e', // italy
  '574a93fc-effa-ca36-b9ed-45c458c10177', // latvia
  'bb041318-0e4e-c6e1-a4a4-b0d1a84696dd', // lithuania
  'aeef7d5d-7720-3ab0-06dc-08aa9af33ce5', // luxemburg
  '412996e7-8540-a95a-5db2-9bf9ddd9af2c', // malta
  '220c4c01-1a76-fa47-d7d7-ab455f15d56e', // netherlands
  '080daea0-5d6e-cd07-ca33-6e10dcaa1a4c', // poland
  'b1c9dbe5-7b3a-10a9-639f-199beed8f6c6', // portugal
  'b1ca571b-2d9e-c27b-a20a-f2c9d6f159fc', // slovakia
  '87ff2e4f-e4bb-c85c-22be-2af47475bc33', // slovenia
  'b2378174-d915-df1f-c99d-0f3f443e0c83', // spain
  '3a7e56d7-ba22-2fa2-434d-fa91d51b7f07', // sweden
];

export const isEUCountry = (countryId: string) => {
  if (countryId === '274e7e37-6d32-f25b-4a5f-daf91b949567') return false;

  return EUCountries.includes(countryId);
};
