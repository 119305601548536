import styled from '@emotion/styled';
import { Button } from '@mui/material';

interface FooterProps {
  setOpen: (state: boolean) => void;
  handleSubmit: () => void;
}

export const Footer = ({ setOpen, handleSubmit }: FooterProps) => {
  return (
    <ButtonsWrapper>
      <Button
        color="info"
        style={{
          width: '100%',
          height: '60px',
          border: '1px solid rgba(25, 118, 210, 1)',
        }}
        onClick={() => setOpen(false)}
      >
        CANCEL
      </Button>
      <Button
        style={{
          width: '100%',
          height: '60px',
          backgroundColor: 'rgba(25, 118, 210, 1)',
          color: 'white',
        }}
        onClick={handleSubmit}
      >
        SUBMIT
      </Button>
    </ButtonsWrapper>
  );
};

const ButtonsWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: sticky;
  top: 100%;
  gap: 20px;
`;
