import styled from '@emotion/styled/macro';
import { CircularProgress, Divider, SelectChangeEvent } from '@mui/material';
import { DBStockInItemStatus } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBStockInItemsResponse,
  DBStockInListApi,
  DBStockInListRequest,
  DBStockInOrderItemCountByStatus,
  GetOrderPricesRes,
  SSE_ENTITY_KEYS,
  StockInOrderMeta,
} from '@tyrio/dto';
import {
  backIcon,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { queryClient } from '../../../query-client';
import NoDataFound from '../../components/NoData/NoDataFound';
import {
  LoaderWrapper,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import StyledPagination from '../../components/Pagination/Pagination';
import { useAuth } from '../../context/AuthContext';
import InboundInvoiceProvider, {
  InboundInvoiceContext,
} from '../../context/InboundInvoiceContext';
import { useWS } from '../../context/WSContext';
import { useDebounce } from '../../hooks/useDebounce';
import { useFilter } from '../../hooks/useFilter';
import StockInOrderDetails from './details/StockInOrderDetails';
import StockInTableFilters from './filter/StockInOrderTableFilters';
import StockInTable from './StockInTable';

interface StockInOverviewProps {
  sidebarOpen: boolean;
}

export const StockInOverview = ({ sidebarOpen }: StockInOverviewProps) => {
  const history = useHistory();
  const { user } = useAuth();
  const { state, socket } = useWS();
  const { input } = useContext(InboundInvoiceContext);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isUploadInProcess, setIsUploadInProcess] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [selectedOrderData, setSelectedOrderData] =
    useState<DBStockInItemsResponse | null>(null);
  const [checked, setChecked] = useState<Record<string, boolean>>(
    input.checkedStockIns ?? {}
  );
  const [areAllChecked, setAreAllChecked] = useState(false);
  const [checkedSupplier, setCheckedSupplier] = useState('');

  const { filterValues, ...filtersHook } = useFilter({
    searchType: 'reference',
    searchKeyword: '',
    branchId: 'all',
    supplierId: 'all',
    startDate: new Date(
      new Date().setDate(new Date().getDate() - 30)
    ).toDateString(),
    endDate: new Date(
      new Date().setDate(new Date().getDate() + 1)
    ).toDateString(),
    orderStatus: 'all',
  });

  const debouncedSearch = useDebounce({ filterValues, page, pageSize }, 500);

  const { data: stockInData, isFetching } = useQuery(
    ['get_stock_in_list', { debouncedSearch }],
    () =>
      searchStockIn({
        ...(filterValues as unknown as DBStockInListRequest),
        page: page,
        pageSize: pageSize,
      }),
    {
      enabled: true,
    }
  );

  useMemo(() => {
    if (filterValues) setPage(1);
  }, [filterValues]);

  const areAllTrue = useMemo(() => {
    const checkedValues = Object.values(checked).map((item) => item);
    const state =
      checkedValues.every((value) => value === true) &&
      _.size(checked) === stockInData?.count;
    return state;
  }, [checked, stockInData?.count]);

  const searchStockIn = async (filterValues: DBStockInListRequest) => {
    return await api.fetch<DBStockInListApi['list']>('get_stock_in_list', {
      ...filterValues,
    });
  };

  const { data: countData } = useQuery(
    ['get_si_count_by_status', { debouncedSearch }],
    () => getCountData(),
    { enabled: true }
  );

  const getCountData = async () => {
    return await api.fetch<{
      request: DBStockInListApi['list']['request'];
      requestBody: DBStockInListApi['list']['requestBody'];
      response: DBStockInOrderItemCountByStatus[];
    }>('get_si_count_by_status', {
      ...filterValues,
      page: page,
      pageSize: pageSize,
    });
  };

  const handleChangePageSize = (event: SelectChangeEvent) => {
    setPageSize(parseInt(event.target.value));
    setPage(1);
  };

  const count = stockInData?.count;

  const totalPageNumber =
    stockInData && count
      ? count / pageSize - Math.floor(count / pageSize) === 0
        ? count / pageSize
        : Math.floor(count / pageSize) + 1
      : 1;

  const refetchData = () => {
    queryClient.refetchQueries('get_stock_in_list');
    queryClient.refetchQueries('get_si_count_by_status');
  };

  const updateFavoriteStatusMutation = useMutation(
    (id: string) =>
      api.fetch<DBStockInListApi['getOne']>('change_stock_in_favorite_status', {
        orderId: id,
      }),
    {
      mutationKey: 'change_stock_in_favorite_status',
      onSuccess: () => {
        const isFavorited = selectedOrderData?.favoritedBy.includes(
          user?.id ?? ''
        );
        refetchData();
        selectedOrderData &&
          setSelectedOrderData({
            ...selectedOrderData,
            favoritedBy: isFavorited
              ? selectedOrderData.favoritedBy.filter(
                  (item) => item !== user?.id
                )
              : [...selectedOrderData.favoritedBy, user?.id ?? ''],
          });
        ToastHelper.showToast(
          'Order',
          ToastType.SUCCESS,
          undefined,
          `Order successfully ${
            isFavorited ? 'removed from' : 'added to'
          } favorites`
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Order',
          ToastType.ERROR,
          undefined,
          'An error occured!'
        );
      },
    }
  );

  const setFavorite = () => {
    updateFavoriteStatusMutation.mutate(selectedOrderData?.id || '');
  };

  const setLockStatus = () => {
    const key =
      state?.locks &&
      state?.locks[`${SSE_ENTITY_KEYS.STOCK_IN_ORDER}/${selectedOrderData?.id}`]
        ? 'unlock'
        : 'lock';

    socket?.emit(key, {
      entityName: SSE_ENTITY_KEYS.STOCK_IN_ORDER,
      entityId: selectedOrderData?.id,
    });
  };

  const updateOrder = useMutation(
    (request: DBStockInListApi['updateOne']['request']) =>
      api.fetch<DBStockInListApi['updateOne']>('update_stock_in', {
        orderId: selectedOrderData?.id,
        ...request,
      }),
    {
      mutationKey: 'update_stock_in',
      onSuccess: (data: DBStockInItemsResponse) => {
        data &&
          setSelectedOrderData({
            ...data,
            status: data.status,
          });
        refetchData();
        ToastHelper.showToast(
          'Order',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          if (
            errorMessage ===
              `You can't cancel this order because it's completed!` &&
            selectedOrderData
          )
            setSelectedOrderData({
              ...selectedOrderData,
              status: DBStockInItemStatus.COMPLETED,
            });

          ToastHelper.showToast(
            'Stock IN order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const handleSave = () => {
    const requestBody: DBStockInListApi['updateOne']['requestBody'] = {
      status: selectedOrderData?.status,
      orderMeta: selectedOrderData?.orderMeta as unknown as StockInOrderMeta,
      priceMeta: selectedOrderData?.priceMeta as GetOrderPricesRes,
      remark: selectedOrderData?.remark ?? '',
    };
    updateOrder.mutate(requestBody);
  };

  const handleAllChecked = () => {
    const toggleCheckedState = (newState: boolean) => {
      Object.keys(checked).forEach((item) => {
        setChecked((prevState) => ({
          ...prevState,
          [item]: newState,
        }));
      });
    };

    const updateCheckedItems = () => {
      if (!stockInData?.data) return;

      stockInData.data.forEach((item) => {
        const supplierId = (item.orderMeta as unknown as StockInOrderMeta)
          .supplierId;

        if (filterValues?.['supplierId'] !== 'all') {
          if (item.status === 'CONFIRMED') {
            setCheckedSupplier(
              (item.orderMeta as unknown as StockInOrderMeta).supplierId
            );
            setChecked((prevState) => ({
              ...prevState,
              [item.id]: true,
            }));
          }
        }

        if (supplierId === checkedSupplier && item.status === 'CONFIRMED')
          setChecked((prevState) => ({
            ...prevState,
            [item.id]: true,
          }));
      });

      setAreAllChecked(true);
    };

    if (areAllChecked) {
      toggleCheckedState(false);
      setAreAllChecked(false);
    } else {
      updateCheckedItems();
    }
  };

  useEffect(
    function handleCheckedCompleted() {
      const checkedValues = Object.values(checked).map((item) => item);
      const state = checkedValues.every((value) => value === false);
      if (state) setAreAllChecked(false);
      Object.keys(checked).forEach((item) => {
        const isCompletedOneChecked = stockInData?.data.find(
          (stockInItem) =>
            item === stockInItem.id && stockInItem.status === 'COMPLETED'
        );
        if (isCompletedOneChecked)
          setChecked((prevState) => ({
            ...prevState,
            [isCompletedOneChecked.id]: false,
          }));
      });
    },
    [checked, stockInData?.data]
  );

  return (
    <PageTemplateWrapper
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        right: 0,
        position: 'absolute',
      }}
    >
      <InboundInvoiceProvider>
        <Container
          open={sidebarOpen}
          orderSelected={selectedOrderData === null}
        >
          <HeaderWrapperTop>
            <BackButton onClick={() => history.push('/dashboard')}>
              <img src={backIcon} alt="back-icon" />
            </BackButton>
            <HeaderText>Stock IN list</HeaderText>
          </HeaderWrapperTop>
          <Divider />

          <StockInTableFilters
            isSidebarOpen={selectedOrderData !== null}
            filters={filterValues}
            setFilters={filtersHook.setFilterValue}
            countOrders={countData || []}
            checked={checked}
          />
          {isFetching ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : stockInData?.data && stockInData.count > 0 ? (
            <>
              <StockInTable
                data={stockInData?.data}
                selectedOrder={selectedOrderData}
                setSelectedOrder={setSelectedOrderData}
                isUploadInProcess={isUploadInProcess}
                isDirty={isDirty}
                setIsDirty={setIsDirty}
                checked={checked}
                setChecked={setChecked}
                areAllChecked={areAllChecked}
                checkedSupplier={checkedSupplier}
                setCheckedSupplier={setCheckedSupplier}
                handleAllChecked={handleAllChecked}
                areAllTrue={areAllTrue}
                isSupplierFiltered={filterValues['supplierId'] !== 'all'}
              />
              <StyledPagination
                totalPageNumber={totalPageNumber}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                selectedOrderData={selectedOrderData}
                handleChangePageSize={handleChangePageSize}
                menuItems={[20, 50, 100, 200]}
              />
            </>
          ) : (
            <NoDataFound />
          )}
        </Container>

        {selectedOrderData !== null && (
          <StockInDetailsWrapper>
            <StockInOrderDetails
              selectedOrderData={selectedOrderData as DBStockInItemsResponse}
              setSelectedOrderData={setSelectedOrderData}
              setFavorite={setFavorite}
              setLockStatus={setLockStatus}
              handleSave={handleSave}
              isUploadInProcess={isUploadInProcess}
              setIsUploadInProcess={setIsUploadInProcess}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
            />
          </StockInDetailsWrapper>
        )}
      </InboundInvoiceProvider>
    </PageTemplateWrapper>
  );
};

const Container = styled.div<{
  orderSelected: boolean;
  open: boolean;
}>`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  transition: linear 0.4s;
  position: relative;
  left: 0;
  height: calc(100svh - 44px);
  width: ${(prop) => (prop.orderSelected ? '100%' : `calc(100% - 716px)`)};
`;

export const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const HeaderWrapperTop = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

const StockInDetailsWrapper = styled.div`
  min-width: 700px;
  max-width: 700px;
  width: 100%;
  margin-left: 16px;
  position: relative;
  right: 0;
  height: (100svh - 44px);
`;
