import { DBBranch, DBProduct, DBSupplier } from '@prisma/client';
import { DBOrderItems } from './customer-order-item';

export type SOPriceShape = Record<
  string,
  Record<
    string,
    {
      original?: number;
      price: number;
      productName: string;
      mainSupplierId?: string;
      ipc: string;
      sku: string;
      ean: string;
      ecoTax: number;
      shipping: number;
      productYear: number;
      brand?: string;
    }
  >
>;
export interface SODataShape {
  products: Record<
    string,
    DBProduct & { model: { uid: string; brand: string | null } }
  >;
  requested: Record<string, number>;
  /**
   * `productId => supplierId`
   */
  supplierQty: Record<string, Record<string, number>>;
  suppliers: Record<string, DBSupplier>;
  /**
   * `supplierId => productId`
   */
  prices: SOPriceShape;
  /**
   * `productId => supplierId`
   */
  mainSupplierId?: Record<string, string>;
  /**
   * `supplierId => productId`
   */
  deliveryDates: Record<string, Record<string, string>>;
  orderRef: string;
  /**
   * `[supplierId]: {[productId]:quantity}`
   */
  purchased: Record<string, Record<string, number>>;
  warehouses: Record<string, DBBranch>;
  /**
   * `[productId]: {[branchId]:quantity}`
   */
  warehouseQty: Record<
    string,
    Record<number, { quantity: number; price: number }>
  >;
  lineItems: DBOrderItems[];
}
export interface DBSalesOrdersApi {
  prepareOrder: {
    requestBody: never;
    request: { orderId: string };
    response: SODataShape;
  };
}
