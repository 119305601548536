import { DBDeliveryTypes, DBWarehouseShelfs, Prisma } from '@prisma/client';
import { MoveLocations } from './stock-locations';

export interface FittingOrdersResponse {
  orderId: string | number | null;
  lineItems: Prisma.JsonValue;
  reference: string | null; // this should be B2B-000001 for example
  remark: string | null;
  customer?: Prisma.JsonValue | null;
  branchId?: number;
  orderType: string; //'TRANSFER_ORDER' | 'CUSTOMER_ORDER'
  deliveryDateFrom?: Date | null;
  deliveryDateTo: Date | null;
  delivery?: DBDeliveryTypes | null;
  services: {
    dateTime: Date;
    location: {
      id: string;
      locationName: string;
    };
    carDetails: {
      car: string;
      registrationNumber: string;
    };
    pickList: {
      ean: string;
      productName: string;
      requiredQty: number;
    }[];
    todoList: {
      id: string;
      task: string;
      points: number;
    }[];
  };
}

export interface FittingOrdersListRequest {
  fittingBox?: string;
}

export interface MoveToFittingReq {
  location: MoveLocations;
  appointmentId: string;
}

/**example:
 * {
    "newLocation":"SHELF-1-BB0-A1-0",
    "oldLocationData":{
        "SHELF-2-AA0-A1-0":{
            ean: scannedQty
            "4019238032970":2
        }
    }
}
*/
export interface FinnishOrder {
  orderId: string;
  type: string; //'TRANSFER_ORDER' | 'CUSTOMER_ORDER'
  newLocation: string;
  oldLocationData: Record<string, Record<string, number>>;
  splitServiceWith?: string;
  points: number;
}

interface DBFittingOrdersListApi {
  list: {
    requestBody: FittingOrdersListRequest;
    request: never;
    response: { data: FittingOrdersResponse[]; count: number };
  };
  getOne: {
    requestBody: { orderId: string };
    requestParams: { orderId: string };
    request: { orderId: string };
    response: never;
  };
  create: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: { orderId: string };
    request: never | { orderId: string };
    response: never;
  };
  getFittingWarehouseShelves: {
    requestBody: {
      branchId: string;
    };
    request: {
      branchId: string;
    };
    requestParams: { branchId: string };
    response: DBWarehouseShelfs[];
  };
}

export { DBFittingOrdersListApi };
