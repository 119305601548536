interface DBUpsertSupplierClientContactsPayload {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  bussinesPhone: string;
  mobilePhone: string;
  remark: string;
}

interface DBSupplierClientContactsApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierClientContactsPayload;
    requestParams: never;
    request: DBUpsertSupplierClientContactsPayload;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierClientContactsApi };
