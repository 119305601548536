/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import {
  Alert,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';
import {
  ApiConnectionDetail,
  ClientConnectionData,
  DBSupplierApi,
  DBSupplierClientB2BSettingsApi,
  DBSupplierClientContactsApi,
  DBSupplierClientPaymentSettingsApi,
  DBSupplierClientPriceCalculationApi,
  DBSupplierClientSettingsApi,
  DBSupplierClientShippingSettingsApi,
  EmailConnectionDetail,
  FtpConnectionDetail,
  HttpsConnectionDetail,
} from '@tyrio/dto';
import SourceContainer, {
  FormControllerWrapper,
  HeaderWrapper,
  TabsWrapper,
} from '@tyrio/forms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'redux';
import {
  LoaderWrapper,
  PageTemplateContent,
} from '../../components/PageTemplate/PageTemplate';
import {
  AuthState,
  changeSupplierClientModalVisibility,
} from '../auth/store/auth.reducer';

import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import api from '@tyrio/api-factory';
import {
  CancelModal,
  DeleteModal,
  RouteRouterPrompt,
  Title,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
} from '@tyrio/ui-library';
import _, { startCase } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ConnectionConfiguration } from '../supplier-client-settings/ConnectionConfiguration';
import SelectSupplierModal from '../supplier-client-settings/SelectSupplierModal';
import SupplierClientB2BSettingsTab, {
  SUPPLIER_CLIENT_B2B_SETTINGS_INITIAL_DATA,
} from '../supplier-client-settings/SupplierClientB2BSettings';
import SupplierClientCompanyInfo from '../supplier-client-settings/SupplierClientCompanyInfo';
import SupplierClientContacts from '../supplier-client-settings/SupplierClientContacts';
import SupplierClientPaymentSettings, {
  SUPPLIER_CLIENT_PAYMENT_SETTINGS_INITIAL_DATA,
} from '../supplier-client-settings/SupplierClientPaymentSettings';
import SupplierClientPriceCalculation, {
  SUPPLIER_CLIENT_PRICE_CALCULATION_INITIAL_DATA,
} from '../supplier-client-settings/SupplierClientPriceCalculation';
import SupplierClientShippingSettings from '../supplier-client-settings/SupplierClientShippingSettings';
import { AlertField } from './helpers/components';
import { handleValidate } from './helpers/validation';
import { usePrevious } from '../../hooks/usePrevious';

type PayloadType =
  | string
  | boolean
  | ClientConnectionData
  | DBSupplierClientContactsApi['create']['requestBody'][]
  | DBSupplierClientB2BSettingsApi['create']['requestBody']
  | DBSupplierClientPaymentSettingsApi['create']['requestBody']
  | DBSupplierClientPriceCalculationApi['create']['requestBody']
  | DBSupplierClientShippingSettingsApi['create']['requestBody'][]
  | (
      | FtpConnectionDetail
      | HttpsConnectionDetail
      | ApiConnectionDetail
      | EmailConnectionDetail
    )[];

const SUPPLIER_CLIENT_INITIAL_DATA: DBSupplierClientSettingsApi['create']['requestBody'] =
  {
    supplierId: '',
    isActive: false,
    connections: [],
    supplierClientB2BSettings: { ...SUPPLIER_CLIENT_B2B_SETTINGS_INITIAL_DATA },
    supplierClientPaymentSettings: {
      ...SUPPLIER_CLIENT_PAYMENT_SETTINGS_INITIAL_DATA,
    },
    supplierClientContacts: [],
    supplierClientPriceCalculation: {
      ...SUPPLIER_CLIENT_PRICE_CALCULATION_INITIAL_DATA,
    },
    supplierClientShippingSettings: [],
  };

const SupplierClientForm = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const state = useSelector((state: CombinedState<AuthState[]>) => state);

  const params = useParams<{ supplierId: string }>();
  const supplierClientSettingsId = params?.supplierId;

  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);

  const [payloads, setPayloads] = useState<
    DBSupplierClientSettingsApi['create']['requestBody']
  >({
    ...SUPPLIER_CLIENT_INITIAL_DATA,
  });

  const isNew = useMemo(
    () => location.pathname.split('/').pop() !== 'new',
    [location.pathname]
  );

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [initialData, setInitialData] = useState<
    DBSupplierClientSettingsApi['create']['requestBody']
  >({
    ...SUPPLIER_CLIENT_INITIAL_DATA,
  });

  const selectedSupplier = useMutation(
    ['get_supplier_id', payloads.supplierId],
    (supplierId?: string) =>
      api.fetch<DBSupplierApi['getOne']>(`supplier_id`, {
        id: supplierId || payloads.supplierId,
      })
  );

  const handleOpenModal = () => {
    dispatch(changeSupplierClientModalVisibility());
  };

  const saveMutation = useMutation(
    (data: DBSupplierClientSettingsApi['create']['request']) =>
      api.fetch<DBSupplierClientSettingsApi['create']>(
        'save_supplier_client_settings',
        data
      ),
    {
      mutationKey: 'save_client_supplier_details',
      onSuccess: (item) => {
        queryClient.refetchQueries('supplier_client_settings_list');
        queryClient.invalidateQueries('get_supplier_client_settings_single');
        ToastHelper.showToast(
          'Supplier',
          ToastType.SUCCESS,
          supplierClientSettingsId
            ? ToastMessageType.UPDATE
            : ToastMessageType.CREATE
        );
        history.push(`/dashboard/supplier/${item.id}`);
      },
    }
  );

  const retriveData = useCallback(
    (
      data: PayloadType,
      key: keyof DBSupplierClientSettingsApi['create']['requestBody']
    ) => {
      setPayloads({ ...payloads, [key]: data });
    },
    [payloads]
  );

  const handleSave = async () => {
    const triggerResponse = await trigger();
    const fieldErrors: string[] = [];

    if (Object.values(errors).length > 0) {
      Object.keys(errors).forEach((error) => {
        let errorKey = startCase(error);

        if (errorKey.includes('Supplier Shipping Settings'))
          errorKey = errorKey.replace('Supplier Shipping Settings ', '');

        if (errorKey.includes('Supplier Contacts'))
          errorKey = errorKey.replace('Supplier Contacts ', '');

        if (errorKey.includes('Supplier Connections'))
          errorKey = errorKey.replace('Supplier Connections ', '');

        if (errorKey.includes('Id')) errorKey = errorKey.replace('Id', '');

        const regex = /[0-9]/;
        errorKey = errorKey.replace(regex, '');

        fieldErrors.push(errorKey);
      });
    }

    const uniqFieldErrors = _.uniq(fieldErrors);

    if (!triggerResponse) {
      ToastHelper.showToast(
        'Supplier',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        uniqFieldErrors.length === 1
          ? 'Field: ' + uniqFieldErrors + ' has errors.'
          : 'Fields: ' +
              uniqFieldErrors.map((err) => ' ' + err) +
              ' have errors.'
      );
      return;
    }
    setSaving(true);
    await saveMutation.mutateAsync({
      ...payloads,
    });
    setSaving(false);
  };

  const supplierSettingsQuery = useQuery(
    ['get_supplier_client_settings_single', supplierClientSettingsId],
    () =>
      api.fetch<DBSupplierClientSettingsApi['getOne']>(
        'get_supplier_client_settings_by_id',
        {
          id: supplierClientSettingsId,
        }
      ),
    {
      enabled: !!supplierClientSettingsId,
      retry: true,
    }
  );

  const deleteSupplierConnectionMutation = useMutation(
    () =>
      api.fetch<DBSupplierClientSettingsApi['getOne']>(
        'delete_supplier_connection',
        {
          id: supplierClientSettingsId,
        }
      ),
    {
      mutationKey: 'delete_supplier_connection',
      onSuccess: () => {
        queryClient.refetchQueries('supplier_client_settings_list');
        history.push(`/dashboard/supplier`);
        ToastHelper.showToast(
          'Supplier',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Supplier',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const updateSupplierStatusMutation = useMutation(
    () =>
      api.fetch<DBSupplierClientSettingsApi['create']>(
        'change_supplier_client_connection_status',
        {
          id: supplierClientSettingsId,
          ...payloads,
        }
      ),
    {
      mutationKey: 'update_supplier_client_status',
      onSuccess: () => {
        queryClient.refetchQueries('supplier_client_settings_list');
        supplierSettingsQuery.refetch();
        ToastHelper.showToast(
          'Supplier',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Supplier',
          ToastType.ERROR,
          ToastMessageType.ERROR
        );
      },
    }
  );

  const handleDeleteSupplierConnection = () => {
    if (supplierClientSettingsId) setIsDeleteModalVisible(true);
  };

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
  });

  const prevSupplierSettingsQuery = usePrevious(supplierSettingsQuery);

  const preloadData = useCallback(async () => {
    if (_.isEmpty(params)) selectedSupplier.reset();

    let data: DBSupplierClientSettingsApi['create']['requestBody'] = {
      ...SUPPLIER_CLIENT_INITIAL_DATA,
    };
    if (supplierClientSettingsId && supplierSettingsQuery.data) {
      const queryData = supplierSettingsQuery.data as any;

      // ONLY REFETCH DATA IF THERE IS NO PREVIOUS supplierSettingsQuery DATA OR IT IS DIFFERENT FROM THE CURRENT ONE
      const refetchData =
        (prevSupplierSettingsQuery as any)?.data === undefined ||
        !_.isEqual(queryData, (prevSupplierSettingsQuery as any)?.data);

      if (queryData && refetchData)
        await selectedSupplier.mutateAsync(queryData.supplierId);

      data = queryData;
    }
    setPayloads({
      ...data,
    });
    setInitialData({ ...data });
    reset(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, supplierClientSettingsId, supplierSettingsQuery.data, reset]);

  useEffect(() => {
    if (!isNew) preloadData();
  }, [
    reset,
    supplierClientSettingsId,
    supplierSettingsQuery.data,
    preloadData,
    isNew,
  ]);

  useEffect(() => {
    if (isDirty || isChanged)
      window.onbeforeunload = function (event) {
        event.preventDefault();
        event.returnValue = '';
      };
  }, [isDirty, isChanged]);

  useMemo(() => {
    if (
      !isNew &&
      !_.isEqual(
        _.omit(initialData, ['supplierId', 'supplierClientShippingSettings']),
        _.omit(payloads, ['supplierId', 'supplierClientShippingSettings'])
      )
    )
      setIsChanged(true);
    else if (isNew && !_.isEqual(supplierSettingsQuery.data, payloads))
      setIsChanged(true);
    else setIsChanged(false);
  }, [isNew, initialData, payloads, supplierSettingsQuery.data]);

  if (supplierClientSettingsId && supplierSettingsQuery.status !== 'success')
    return null;

  const getValidationProps = (
    key: string,
    validationRules?: RegisterOptions,
    validationText?: string
  ) => {
    return handleValidate(
      errors,
      register,
      key,
      validationRules,
      validationText
    );
  };

  const handleCancelSupplier = () => {
    if (isDirty || isChanged) setIsCancelModalVisible(true);
    else history.push('/dashboard/supplier');
  };

  const shouldShowConnectionMessage =
    (selectedSupplier.data &&
      selectedSupplier.data.supplierConnections.length === 0) ||
    (selectedSupplier?.data?.supplierConnections.length === 1 &&
      selectedSupplier.data.supplierConnections[0].connectionTypes.includes(
        'CATALOGUE'
      ));

  return (
    <PageTemplateContent>
      {supplierSettingsQuery.isFetching && supplierClientSettingsId ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        <Grid>
          {!isCancelModalVisible && (
            <RouteRouterPrompt
              when={(isDirty || isChanged) && !saving}
              navigate={(path) => history.push(path)}
              shouldBlockNavigation={() => true}
            />
          )}
          {isCancelModalVisible && (
            <CancelModal
              LBAction={() => setIsCancelModalVisible(false)}
              RBAction={() => {
                history.push(`/dashboard/supplier`);
                setIsCancelModalVisible(false);
              }}
            />
          )}
          {isDeleteModalVisible && (
            <DeleteModal
              LBAction={() => setIsDeleteModalVisible(false)}
              RBAction={() => {
                deleteSupplierConnectionMutation.mutate();
                setIsDeleteModalVisible(false);
              }}
              itemName={selectedSupplier.data?.companyShortName as string}
            />
          )}
          {!selectedSupplier.isLoading && (
            <FormControllerWrapper>
              <HeaderWrapper>
                <Title>
                  {selectedSupplier.data?.companyOfficialName
                    ? selectedSupplier.data?.companyOfficialName
                    : 'Add new supplier client connection'}
                </Title>
                {selectedSupplier.data && (
                  <FormControlLabel
                    label="Active"
                    control={
                      <Switch
                        color="info"
                        onChange={() => {
                          setPayloads({
                            ...payloads,
                            isActive: !payloads.isActive,
                          });
                          if (supplierClientSettingsId)
                            updateSupplierStatusMutation.mutate();
                        }}
                        checked={payloads.isActive}
                      />
                    }
                  />
                )}
              </HeaderWrapper>
              <TabsWrapper>
                <form
                  onSubmit={handleSubmit(handleSave)}
                  key={payloads.supplierId ?? 'new'}
                >
                  <TyrioTabs>
                    <TyrioTab title="Company" key="company">
                      {payloads.supplierId === '' &&
                        selectedSupplier.data === undefined && (
                          <SelectSupplier selectSupplier={handleOpenModal} />
                        )}
                      {selectedSupplier.data && (
                        <SupplierClientCompanyInfo
                          supplierData={selectedSupplier.data}
                        />
                      )}
                    </TyrioTab>
                    <TyrioTab
                      title="Contacts"
                      key="contacts"
                      errored={
                        Object.keys(errors).find((f) =>
                          f.includes('Contacts')
                        ) !== undefined
                      }
                    >
                      {payloads.supplierId === '' &&
                        selectedSupplier.data === undefined && (
                          <SelectSupplier selectSupplier={handleOpenModal} />
                        )}
                      {selectedSupplier.data &&
                        payloads.supplierClientContacts.length === 0 && (
                          <AlertField
                            text="This supplier doesn't have any contact!"
                            type="warning"
                          />
                        )}
                      <SupplierClientContacts
                        initialData={payloads.supplierClientContacts}
                        supplierData={selectedSupplier.data}
                        getData={(data) =>
                          retriveData(data, 'supplierClientContacts')
                        }
                        getValidationProps={getValidationProps}
                      />
                    </TyrioTab>
                    <TyrioTab
                      title="Info"
                      key="info"
                      errored={
                        Object.keys(errors).find((f) => f.includes('Info')) !==
                        undefined
                      }
                    >
                      {payloads.supplierId === '' &&
                        selectedSupplier.data === undefined && (
                          <SelectSupplier selectSupplier={handleOpenModal} />
                        )}
                      {selectedSupplier.data && (
                        <Grid>
                          <SupplierClientB2BSettingsTab
                            initialValues={payloads.supplierClientB2BSettings}
                            getData={(data) =>
                              retriveData(data, 'supplierClientB2BSettings')
                            }
                            getValidationProps={getValidationProps}
                            clearErrors={clearErrors}
                          />
                          <SupplierClientPaymentSettings
                            initialData={payloads.supplierClientPaymentSettings}
                            getData={(data) =>
                              retriveData(data, 'supplierClientPaymentSettings')
                            }
                          />
                        </Grid>
                      )}
                    </TyrioTab>
                    <TyrioTab
                      title="Shipping"
                      key="shipping"
                      errored={
                        Object.keys(errors).find((f) =>
                          f.includes('Shipping')
                        ) !== undefined
                      }
                    >
                      {payloads.supplierId === '' &&
                        selectedSupplier.data === undefined && (
                          <SelectSupplier selectSupplier={handleOpenModal} />
                        )}
                      {((selectedSupplier.data &&
                        selectedSupplier.data.supplierLocations.length === 0) ||
                        payloads.supplierClientShippingSettings.length ===
                          0) && (
                        <AlertField
                          text="This supplier doesn't have any location!"
                          type="warning"
                        />
                      )}
                      {selectedSupplier.data &&
                        selectedSupplier.data.supplierLocations.length > 0 && (
                          <SupplierClientShippingSettings
                            initialData={
                              payloads.supplierClientShippingSettings
                            }
                            selectedSupplier={selectedSupplier.data}
                            getData={(data) =>
                              retriveData(
                                data,
                                'supplierClientShippingSettings'
                              )
                            }
                            getValidationProps={getValidationProps}
                            errors={errors}
                            register={register}
                            clearErrors={clearErrors}
                          />
                        )}
                    </TyrioTab>
                    <TyrioTab title="Price calculation" key="price-calculation">
                      {payloads.supplierId === '' &&
                        selectedSupplier.data === undefined && (
                          <SelectSupplier selectSupplier={handleOpenModal} />
                        )}
                      {selectedSupplier.data && (
                        <SupplierClientPriceCalculation
                          initialData={payloads.supplierClientPriceCalculation}
                          getData={(data) =>
                            retriveData(data, 'supplierClientPriceCalculation')
                          }
                        />
                      )}
                    </TyrioTab>
                    <TyrioTab
                      title="Connections"
                      errored={
                        Object.keys(errors).find((f) =>
                          f.includes('Connections')
                        ) !== undefined
                      }
                    >
                      {payloads.supplierId === '' &&
                        selectedSupplier.data === undefined && (
                          <SelectSupplier selectSupplier={handleOpenModal} />
                        )}
                      {shouldShowConnectionMessage && (
                        <AlertField
                          text="This supplier doesn't have any connections!"
                          type="warning"
                        />
                      )}
                      {selectedSupplier.data && (
                        <ConnectionConfiguration
                          initialData={payloads.connections}
                          data={selectedSupplier.data}
                          getData={(data) => retriveData(data, 'connections')}
                          getValidationProps={getValidationProps}
                          clearErrors={clearErrors}
                        />
                      )}
                    </TyrioTab>
                  </TyrioTabs>
                </form>
                <SourceContainer
                  loading={saving}
                  onSubmit={() => {
                    handleSave();
                  }}
                  disableSave={payloads.supplierId === ''}
                  disabledDelete={supplierClientSettingsId === undefined}
                  data={{
                    data_source: 'Manual by User',
                    created: 'Antonio  Rakijar - 04.05.2022 - 16:53:04 ',
                    last_edited: 'Antonio  Rakijar - 04.05.2022 - 16:53:04 ',
                  }}
                  onDelete={handleDeleteSupplierConnection}
                  onCancel={handleCancelSupplier}
                />
              </TabsWrapper>
            </FormControllerWrapper>
          )}
          {state[0].isSupplierClientModalVisible && (
            <SelectSupplierModal
              supplierId={payloads.supplierId}
              setSupplierId={(value) =>
                setPayloads({ ...payloads, supplierId: value })
              }
              onClose={() => {
                dispatch(changeSupplierClientModalVisibility());
                setPayloads({ ...payloads, supplierId: '' });
              }}
              onConfirm={() => {
                dispatch(changeSupplierClientModalVisibility());
                selectedSupplier.mutate('');
              }}
            />
          )}
        </Grid>
      )}
    </PageTemplateContent>
  );
};

interface SelectSupplierProps {
  selectSupplier: () => void;
}

const SelectSupplier = (props: SelectSupplierProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <Alert severity="warning">
          Supplier is not selected. <b>Please select a supplier!</b>
        </Alert>
      </div>
      <div
        style={{
          marginTop: '30px',
        }}
      >
        <Button
          size="medium"
          component="label"
          color="info"
          variant="outlined"
          startIcon={<PanToolAltIcon />}
          onClick={() => {
            props.selectSupplier();
          }}
        >
          CHOOSE SUPPLIER
        </Button>
      </div>
    </div>
  );
};

export const CompanyLogoImage = styled.img``;

export default SupplierClientForm;
