/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled/macro';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DBCurrency } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBBranchesApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useContext, useState } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { parseEnumToArray } from '../../../../../helpers/enum-parser';
import {
  InboundInvoiceContext,
  VAT,
} from '../../../context/InboundInvoiceContext';
import { getAutocompleteSingleField, getTextField } from '../../form-helper';
import { TotalAmount } from '../components/TotalAmount';
import { useCalculatePrice } from '../helpers/calculation';
import _ from 'lodash';
import moment from 'moment';
import { Moment } from '@react-awesome-query-builder/ui';

interface MainProps {
  errors: FieldErrors;
  control: Control;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<any>;
  onSubmit: (data: any) => void;
  handleSubmit: any;
  watch: UseFormWatch<any>;
}

const CURRENCY_TYPES = parseEnumToArray(DBCurrency);
const VAT_CHARGE_TYPES = parseEnumToArray(VAT);

export const Main = ({
  errors,
  control,
  register,
  setValue,
  onSubmit,
  handleSubmit,
  watch,
}: MainProps) => {
  const { t } = useTranslation();
  const { preparedInvoices, isEU } = useContext(InboundInvoiceContext);

  const [branches, setBranches] = useState<TyrioSelectInputOption[]>([]);
  const [openDatePicker, setOpenDatePicker] = useState<Record<number, boolean>>(
    { 0: false, 1: false, 2: false }
  );

  const [customTotalAmount, setCustomTotalAmount] = useState(0);
  const [totalAmountIcon, setTotalAmounIcon] = useState<
    'success' | 'error' | undefined
  >(undefined);

  const { grandTotal, vatAmount } = useCalculatePrice(
    preparedInvoices?.items ?? []
  );

  useQuery(
    ['get_branches'],
    async () => {
      return await api.fetch<DBBranchesApi['list']>('get_branches', {
        search: '',
      });
    },
    {
      onSuccess: (data) => {
        const dropdownItems = [{ value: 'all', label: 'All' }];
        data.data.forEach((item) =>
          dropdownItems.push({
            label: item.branchName,
            value: item.id.toString(),
          })
        );

        setBranches(dropdownItems);
      },
    }
  );

  const handleOpeningDatePicker = (index: number) => {
    const isOpen = openDatePicker[index];
    if (!isOpen)
      Object.keys(openDatePicker).forEach((item) => {
        setOpenDatePicker((prevState) => ({
          ...prevState,
          [item]: false,
        }));
      });
    setOpenDatePicker((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const doAmountsMatch = () => {
    let grandTotalCustom = grandTotal;
    if (!isEU) grandTotalCustom += vatAmount;

    const grandTotalRounded = _.round(grandTotalCustom, 2).toFixed(2);
    const customTotalAmountRounded = _.round(customTotalAmount, 2).toFixed(2);

    if (grandTotalRounded !== customTotalAmountRounded) {
      setTotalAmounIcon('error');
      return false;
    }
    setTotalAmounIcon('success');
    return true;
  };

  const getDelay = () => {
    const invoiceIssueDate = watch('invoiceIssueDate');
    const invoiceDueDate = watch('invoiceDueDate');

    const invoiceIssueMoment = moment(invoiceIssueDate)
      .hours(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    const invoiceDueMoment = moment(invoiceDueDate)
      .hours(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    let dateDelay = invoiceDueMoment.diff(invoiceIssueMoment, 'days');

    if (dateDelay < 0) dateDelay = 0;

    setValue('delay', dateDelay);
  };

  const getInvoiceDueDate = (v: string) => {
    const invoiceIssueDate = watch('invoiceIssueDate');

    const date = moment(invoiceIssueDate)
      .hours(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    date.add(v, 'days');

    setValue('invoiceDueDate', date.toDate());
    setValue('delay', v);
  };

  const getInvoiceIssueDate = () => {
    const invoiceIssueDate = watch('invoiceIssueDate');
    const delay = watch('delay');

    const date = moment(invoiceIssueDate)
      .hours(0)
      .minute(0)
      .second(0)
      .millisecond(0);

    date.add(delay, 'days');

    setValue('invoiceDueDate', date.toDate());
  };

  const issueDate = watch('invoiceIssueDate');
  const dueDate = watch('invoiceDueDate');

  const issueDateMoment = moment(issueDate)
    .hours(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  const dueDateMoment = moment(dueDate)
    .hours(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  const dateDelay = dueDateMoment.diff(issueDateMoment, 'days');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Grid style={{ width: '100%' }}>
            {getAutocompleteSingleField(
              t('Branch'),
              'branchId',
              () => true,
              errors,
              control,
              { lg: 4, xs: 12 },
              {},
              branches,
              false,
              watch('branchId')
            )}
          </Grid>
          <Grid style={{ width: '100%' }}>
            <DatePicker
              open={openDatePicker[0]}
              label="datePicker"
              inputFormat="dd.MM.yyyy"
              value={watch('date')}
              onChange={(newValue) => setValue('date', newValue)}
              renderInput={(params) => (
                <TextField
                  id="date"
                  {...params}
                  required
                  label="Date"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={() => handleOpeningDatePicker(0)}>
                          <DateRangeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: null,
                  }}
                />
              )}
            />
          </Grid>
        </div>
        <Grid item style={{ width: '100%' }}>
          {getTextField(
            t('Supplier name'),
            'supplierName',
            () => false,
            errors,
            register,
            { lg: 4, xs: 12 },
            '',
            { disabled: true }
          )}
        </Grid>

        <div style={{ display: 'flex', gap: '10px', width: '100%' }}>
          <Grid item style={{ width: '100%' }}>
            {getAutocompleteSingleField(
              t('Vat charge type'),
              'vatChargeType',
              () => true,
              errors,
              control,
              { lg: 4, xs: 12 },
              {},
              VAT_CHARGE_TYPES
            )}
          </Grid>
          <Grid item style={{ width: '70%' }}>
            {getAutocompleteSingleField(
              t('Currency'),
              'currency',
              () => true,
              errors,
              control,
              { lg: 4, xs: 12 },
              {},
              CURRENCY_TYPES
            )}
          </Grid>
          <Grid item style={{ width: '50%' }}>
            {getTextField(
              t('Exchange rate'),
              'exchangeRate',
              () => false,
              errors,
              register,
              { lg: 3, xs: 12 },
              ''
            )}
          </Grid>
        </div>

        <Divider
          variant="fullWidth"
          sx={{
            borderWidth: '2px',
            borderColor: '#E6E8F0',
          }}
        />

        <Grid item style={{ width: '100%' }}>
          {getTextField(
            t('Supplier invoice number'),
            'supplierInvoiceNumber',
            () => true,
            errors,
            register,
            { lg: 3, xs: 12 }
          )}
        </Grid>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Grid style={{ width: '100%' }}>
            <DatePicker
              label="datePicker"
              inputFormat="dd.MM.yyyy"
              value={watch('invoiceIssueDate')}
              onChange={(newValue) => {
                setValue('invoiceIssueDate', newValue);
                getInvoiceIssueDate();
              }}
              renderInput={(params) => (
                <TextField
                  id="invoiceIssueDate"
                  {...params}
                  label="Invoice issue date"
                  helperText={
                    errors['invoiceIssueDate']
                      ? 'You have to choose issue date!'
                      : ''
                  }
                />
              )}
            />
          </Grid>
          <Grid>
            {getTextField(
              t('Delay'),
              'delay',
              () => true,
              errors,
              register,
              { lg: 3, xs: 12 },
              '',
              undefined,
              undefined,
              undefined,
              (v) => {
                getInvoiceDueDate(v);
              }
            )}
          </Grid>
          <Grid style={{ width: '100%' }}>
            <DatePicker
              label="datePicker"
              inputFormat="dd.MM.yyyy"
              value={watch('invoiceDueDate')}
              onChange={(newValue) => {
                setValue('invoiceDueDate', newValue);
                getDelay();
              }}
              renderInput={(params) => (
                <TextField
                  id="invoiceDueDate"
                  {...params}
                  label="Invoice due date"
                  error={dateDelay < 0}
                  helperText={
                    dateDelay < 0
                      ? 'Must be greater than issue date'
                      : errors['invoiceIssueDate']
                      ? 'You have to choose due date!'
                      : ''
                  }
                />
              )}
            />
          </Grid>
        </div>

        <TotalAmount
          text={'Total purchase amount from supplier'}
          buttonText={'CHECK'}
          amount={0}
          showSplitInvoice={true}
          onButtonClick={doAmountsMatch}
          totalAmountIcon={totalAmountIcon}
          value={customTotalAmount}
          setValue={setCustomTotalAmount}
        />

        <RemarkWrapper>
          {getTextField(
            t('Internal remark'),
            'internalRemark',
            () => false,
            errors,
            register,
            { lg: 3, xs: 12 },
            ''
          )}
        </RemarkWrapper>
      </Wrapper>
    </form>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 16px;
`;

const RemarkWrapper = styled.div`
  position: sticky;
  top: 100%;
`;
