/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled from '@emotion/styled';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Grid from '@mui/material/Grid/Grid';
import Switch from '@mui/material/Switch/Switch';
import TextField from '@mui/material/TextField/TextField';
import { DBUserRole } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBRoleApi } from '@tyrio/dto';
import SourceContainer, {
  PermissionsValues,
  UserRolePermissions,
} from '@tyrio/forms';
import {
  backIcon,
  CancelModal,
  CloneButton,
  DeleteModal,
  RouteRouterPrompt,
  Title,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
  TyrioTabsRefType,
  tyrioUI,
} from '@tyrio/ui-library';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { TyrioPermissionsTable } from './PermissionsTable';
import { FormData } from './types/FormData';

interface RoleCustomFormProps {
  roleId: string;
  roleName: string;
  roleDescription: string;
  type: DBUserRole;
  permissions: UserRolePermissions[];
  refetch: () => void;
  active: boolean;
  userPermissions?: PermissionsValues;
}

const RoleCustomForm = ({
  roleId,
  roleName,
  roleDescription,
  type,
  permissions,
  refetch,
  active,
  userPermissions,
}: RoleCustomFormProps) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const history = useHistory();
  const location = useLocation();

  const ref = useRef<TyrioTabsRefType | null>(null);

  const [isActive, setIsActive] = useState<boolean>(active);

  const [roleError, setRoleError] = useState<string | undefined>();
  const [descriptionError, setDescriptionError] = useState<
    string | undefined
  >();
  const [hasPermissionErrors, setHasPermissionErrors] = useState(false);

  const initialData: FormData = {
    roleName: roleName,
    roleDescription: roleDescription,
    permissions: permissions,
    type: type,
    active: active,
  };

  const [formData, setFormData] = useState<FormData>(initialData);

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  formData.permissions.forEach((p) => {
    if (p.childs) Object.assign({}, p.childs);
  });

  // TO DO: Remove this part after checking that every thing is working correctly
  // formData.permissions[3].childs = Object.assign(
  //   {},
  //   formData.permissions[3].childs
  // );
  // formData.permissions[4].childs = Object.assign(
  //   {},
  //   formData.permissions[4].childs
  // );

  const [permissionData, setPermissionData] = useState(
    Object.assign({}, formData.permissions)
  );

  const onFullAccessChange = (
    index: number,
    value: boolean,
    childs?: boolean
  ) => {
    if (childs) {
      const x = Object.values(permissionData);
      const y = Object.values(x[index].childs!);

      x[index].values.create = value;
      x[index].values.view = value;
      x[index].values.delete = value;
      x[index].values.edit = value;

      y.forEach((el) => {
        el.values.create = value;
        el.values.edit = value;
        el.values.delete = value;
        el.values.view = value;
      });

      setPermissionData(Object.assign({}, x));
    } else
      setPermissionData({
        ...permissionData,
        [index]: {
          ...permissionData[index],
          values: {
            edit: value,
            view: value,
            create: value,
            delete: value,
          },
        },
      });
  };

  const onFullAccessChildChange = (
    index: number,
    childIndex: number,
    value: boolean
  ) => {
    setPermissionData({
      ...permissionData,
      [index.toString()]: {
        ...permissionData[index],
        childs: {
          ...permissionData[index].childs,
          [childIndex]: {
            ...permissionData[index].childs![childIndex],
            values: {
              edit: value,
              view: value,
              create: value,
              delete: value,
            },
          },
        },
      },
    });
  };

  const onChange = (
    index: number,
    key: string,
    value: boolean,
    childs?: boolean
  ) => {
    const parentIndex = index;
    if (childs) {
      const x = Object.values(permissionData);
      const y = Object.values(x[parentIndex].childs!);

      x[parentIndex].values[key as keyof PermissionsValues] = value;
      y.forEach((el) => (el.values[key as keyof PermissionsValues] = value));

      setPermissionData(Object.assign({}, x));
    } else
      setPermissionData({
        ...permissionData,
        [parentIndex]: {
          ...permissionData[parentIndex],
          values: {
            ...permissionData[parentIndex].values,
            [key]: value,
          },
        },
      });
  };
  const onChildChange = (
    index: number,
    childIndex: number,
    key: string,
    value: boolean
  ) => {
    setPermissionData({
      ...permissionData,
      [index.toString()]: {
        ...permissionData[index],
        childs: {
          ...permissionData[index].childs,
          [childIndex]: {
            ...permissionData[index].childs![childIndex],
            values: {
              ...permissionData[index].childs![childIndex].values,
              [key]: value,
            },
          },
        },
      },
    });
  };

  useMemo(() => {
    setFormData((prevState) => ({
      ...prevState,
      permissions: Object.values(permissionData),
    }));
  }, [permissionData]);

  //Function for text inputs
  const onValueChange = (key: string, value: string) => {
    setFormData({
      ...formData,
      ...{ [key]: value },
    });
  };

  //CREATE new role mutation
  const createRoleMutation = useMutation(
    (role: DBRoleApi['create']['request']) =>
      api.fetch<DBRoleApi['create']>('create_role', {
        ...role,
      }),
    {
      mutationKey: 'create_role',
      onSuccess: (data) => {
        queryClient.invalidateQueries('get_roles_list');
        ToastHelper.showToast(
          'User role',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        if (window.location.pathname.includes('company-settings'))
          window.location.pathname = `/dashboard/company-settings/roles/${data.id}`;
        else window.location.pathname = `/dashboard/roles/${data.id}`;
      },

      onError: () => {
        ToastHelper.showToast(
          'User role',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          'Role creation failed. Please check the input data and whether there is already Role with that name!'
        );
      },
    }
  );

  //DELETE role mutation
  const deleteRoleMutation = useMutation(
    () =>
      api.fetch<DBRoleApi['getOne']>('delete_role', {
        id: roleId,
      }),
    {
      mutationKey: 'delete_role',
      onSuccess: () => {
        queryClient.invalidateQueries('get_roles_list');
        history.push(`/dashboard/roles`);
        ToastHelper.showToast(
          'Role',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast('Role', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  const handleOnCancel = () => {
    if (_.isEqual(initialData, formData)) history.push(`/dashboard/roles`);
    else setIsCancelModalVisible(true);
  };

  useMemo(() => {
    if (!_.isEqual(initialData, formData)) setIsDirty(true);
    else setIsDirty(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (isDirty && !isSaving) {
      window.onbeforeunload = function (event) {
        event.preventDefault();
        event.returnValue = '';
      };
    }
  }, [isDirty, isSaving]);

  const handleDeleteRole = () => {
    if (roleId) {
      if (
        roleName === 'Super Admin' ||
        roleName === 'Admin' ||
        roleName === 'Developer'
      ) {
        ToastHelper.showToast(
          'Role',
          ToastType.WARNING,
          undefined,
          `You can't delete a ${roleName}!`
        );
        return;
      }
      setIsDeleteModalVisible(true);
    }
  };

  //UPDATE role mutation
  const updateRoleMutation = useMutation(
    (role: DBRoleApi['updateOne']['request']) =>
      api.fetch<DBRoleApi['updateOne']>('update_role', {
        ...role,
      }),
    {
      mutationKey: 'update_role',
      onSuccess: () => {
        queryClient.refetchQueries('get_roles_list');
        refetch();
        ToastHelper.showToast(
          'Role',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Role',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          'Role update failed. Please check the input data and whether there is already Role with that name!'
        );
      },
    }
  );

  const handleChangeRoleStatus = () => {
    if (roleId && userPermissions?.edit) {
      if (
        roleName === 'Super Admin' ||
        roleName === 'Admin' ||
        roleName === 'Developer'
      ) {
        ToastHelper.showToast(
          'Role',
          ToastType.WARNING,
          undefined,
          `You can't change status for ${roleName}!`
        );
        return;
      }
      setIsActive(!isActive);
      updateRoleStatus.mutate();
    }
  };

  //UPDATE role status mutation
  const updateRoleStatus = useMutation(
    () =>
      api.fetch<DBRoleApi['getOne']>('change_role_status', {
        id: roleId,
      }),
    {
      mutationKey: 'change_status',
      onSuccess: () => {
        queryClient.invalidateQueries('get_roles_list');
        ToastHelper.showToast(
          'Role',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: () => {
        ToastHelper.showToast('Role', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  //CLONE role mutation
  const cloneRoleMutation = useMutation(
    () =>
      api.fetch<DBRoleApi['getOne']>('clone_role', {
        id: roleId,
      }),
    {
      mutationKey: 'clone_role',
      onSuccess: () => {
        queryClient.invalidateQueries('get_roles_list');
        history.push(`/dashboard/roles`);
        ToastHelper.showToast(
          'Role',
          ToastType.SUCCESS,
          undefined,
          'Role successfully cloned!'
        );
      },
      onError: () => {
        ToastHelper.showToast('Role', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  const handleCloneRole = () => {
    if (roleId) {
      if (
        roleName === 'Super Admin' ||
        roleName === 'Admin' ||
        roleName === 'Developer'
      ) {
        ToastHelper.showToast(
          'Role',
          ToastType.WARNING,
          undefined,
          `You can't clone ${roleName}!`
        );
        return;
      }

      cloneRoleMutation.mutate();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isAnyKeyValueTrue = (obj: any) =>
    !!Object.keys(obj).find((k) => obj[k]);

  const permissionDataHasErrors = () => {
    const isChecked = Object.values(permissionData).map((p) => {
      const x = isAnyKeyValueTrue(p.values);
      if (x) return true;
      else return false;
    });

    if (isChecked.find((v) => v === true) === undefined) return true;
    else return false;
  };

  const handleUpsertRole = (data: FormData) => {
    setIsSaving(true);
    let hasError = false;

    const hasPermissions = permissionDataHasErrors();
    setHasPermissionErrors(hasPermissions);

    const noRoleName =
      !formData.roleName || formData.roleName.trim().length === 0;
    if (noRoleName) {
      hasError = true;

      noRoleName && setRoleError('This field is required!');

      ToastHelper.showToast(
        'Role',
        ToastType.ERROR,
        undefined,
        `Something went wrong ! Please check your input data.`
      );
      return;
    }

    let isAnyTrue;
    let permissionsError;
    let x;

    if (formData.permissions) {
      formData.permissions.forEach((p) => {
        x = isAnyKeyValueTrue(p.values);
        if (x) {
          isAnyTrue = true;
          permissionsError = false;
        }
        if (p.childs) {
          Object.entries(p.childs).forEach((key) => {
            x = isAnyKeyValueTrue(key[1].values);
            if (x) {
              isAnyTrue = true;
              permissionsError = false;
            }
          });
        }
      });

      if (!isAnyTrue) {
        hasError = true;
        permissionsError = true;
      }
    }

    if (hasError) {
      ToastHelper.showToast(
        'Role',
        ToastType.ERROR,
        undefined,
        `${
          permissionsError && 'You have to choose at least one role permission!'
        }`
      );
      return;
    }

    if (roleId) {
      if (
        roleName === 'Super Admin' ||
        roleName === 'Admin' ||
        roleName === 'Developer'
      ) {
        ToastHelper.showToast(
          'Role',
          ToastType.WARNING,
          undefined,
          `You can't change data for ${roleName}!`
        );
        return;
      } else if (
        data.roleName.toLowerCase() === 'super admin' ||
        data.roleName.toLowerCase() === 'admin' ||
        data.roleName.toLowerCase() === 'developer'
      ) {
        ToastHelper.showToast(
          'Role',
          ToastType.WARNING,
          undefined,
          `You can't set role name to: ${data.roleName}!`
        );
        return;
      } else {
        const role_data: DBRoleApi['updateOne']['request'] = {
          id: roleId,
          roleName: _.upperFirst(data.roleName),
          roleDescription: data.roleDescription,
          permissions: data.permissions,
        };
        updateRoleMutation.mutate(role_data);
        refetch();
      }
    } else {
      if (
        data.roleName.toLowerCase() === 'super admin' ||
        data.roleName.toLowerCase() === 'admin' ||
        data.roleName.toLowerCase() === 'developer'
      ) {
        setIsDirty(true);
        setIsSaving(true);
        ToastHelper.showToast(
          'Role',
          ToastType.WARNING,
          undefined,
          `You can't create role with name: ${data.roleName}!`
        );
        return;
      } else {
        const role_data: DBRoleApi['create']['request'] = {
          roleName: _.upperFirst(data.roleName),
          roleDescription: data.roleDescription,
          active: data.active || true,
          permissions: data.permissions,
        };
        createRoleMutation.mutate(role_data);
      }
    }
  };

  const modalRoleName = roleName;

  const disabledRoles = ['Super Admin', 'Admin', 'Developer'];

  const checkIsDisabled = () => {
    return !userPermissions?.edit || disabledRoles.includes(roleName);
  };

  return (
    <Container>
      {!isCancelModalVisible && (
        <RouteRouterPrompt
          when={isDirty && !isSaving}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={() => true}
        />
      )}
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            history.push(`/dashboard/roles`);
            setIsDirty(false);
            setIsCancelModalVisible(false);
          }}
        />
      )}
      {isDeleteModalVisible && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => {
            deleteRoleMutation.mutate();
            setIsDeleteModalVisible(false);
          }}
          itemName={modalRoleName as string}
        />
      )}
      <HeaderWrapper>
        <Title>
          <BackButton onClick={() => history.push(`/dashboard/roles`)}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          {roleName !== '' ? roleName : t('Create new role')}
        </Title>

        {roleId && (
          <FormControlLabel
            control={
              <Switch
                id="switch"
                color="info"
                onChange={handleChangeRoleStatus}
                checked={isActive}
                defaultChecked={active}
                disabled={checkIsDisabled()}
              />
            }
            label={t('Active')}
          />
        )}
      </HeaderWrapper>
      <TabsWrapper>
        <TyrioTabs ref={ref}>
          <TyrioTab
            title={t('Role')}
            key="role"
            errored={
              (roleError || descriptionError) !== undefined ? true : false
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    'data-cy': 'roleField',
                  }}
                  fullWidth
                  label={t('Role *')}
                  disabled={checkIsDisabled()}
                  value={formData.roleName}
                  error={!!roleError}
                  helperText={roleError}
                  onChange={(e) => {
                    onValueChange('roleName', e.target.value);
                    setRoleError(undefined);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{
                    'data-cy': 'roleDescriptionField',
                  }}
                  fullWidth
                  label={t('Role Description')}
                  disabled={checkIsDisabled()}
                  value={formData.roleDescription}
                  error={!!descriptionError}
                  helperText={descriptionError}
                  onChange={(e) => {
                    onValueChange('roleDescription', e.target.value);
                    setDescriptionError(undefined);
                  }}
                />
                {!location.pathname.includes('/new') && (
                  <CloneButton
                    handleCloneRole={handleCloneRole}
                    disabled={checkIsDisabled()}
                  />
                )}
              </Grid>
            </Grid>
          </TyrioTab>
          <TyrioTab
            title={t('Permissions')}
            key="permissions"
            errored={hasPermissionErrors}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TyrioPermissionsTable
                  permissionData={permissionData}
                  permissionChange={onChange}
                  fullAccessChange={onFullAccessChange}
                  fullAccessChildChange={onFullAccessChildChange}
                  permissionChildChange={onChildChange}
                  disabled={checkIsDisabled()}
                />
              </Grid>
            </Grid>
          </TyrioTab>
        </TyrioTabs>

        <SourceContainer
          permissions={userPermissions}
          loading={false}
          data={{
            data_source: 'Manual by User',
            created: 'Antonio  Rakijar - 04.05.2022 - 16:53:04 ',
            last_edited: 'Antonio  Rakijar - 04.05.2022 - 16:53:04 ',
          }}
          onSubmit={() => handleUpsertRole(formData)}
          onCancel={handleOnCancel}
          onDelete={handleDeleteRole}
          disabledDelete={checkIsDisabled()}
          disableSave={checkIsDisabled()}
        />
      </TabsWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: ${tyrioUI.spacing.l}px ${tyrioUI.spacing.l}px 100px
    ${tyrioUI.spacing.l}px;
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TabsWrapper = styled.div`
  padding-bottom: 100px;
`;

const BackButton = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
`;

export default RoleCustomForm;
