import styled from '@emotion/styled/macro';
import { PartnerResponse, PhotoShape, PosItem } from '@tyrio/dto';
import { useEffect, useState } from 'react';
import { Drawers } from '../../components/Drawers/Drawer';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import { useAuth } from '../../context/AuthContext';
import { usePosCartCtx } from '../../context/PosCartContext';
import POSProvider, {
  ActiveDrawer,
  ActiveStep,
} from '../../context/POSContext';
import { VehicleContextProvide } from '../../context/VehicleContext';
import CartLanding from '../../features/pos/CartLanding';
import { Menu } from '../../features/pos/components/Menu/Menu';
import PosSearchProvider from '../../features/pos/components/SearchPOS/SearchContext';
import Landing from '../../features/pos/Landing';
import { usePosCartData } from '../../features/pos/steps/Cart/helpers/cart-data';
import { Calendar } from '../../features/pos/steps/Cart/Steps/Appointment/components/calendar/Calendar';
import { PosSteps } from '../../features/pos/steps/Steps';
import { useFilter } from '../../hooks/useFilter';

interface POSProps {
  sidebarOpen: boolean;
}

const POS = ({ sidebarOpen }: POSProps) => {
  const { user } = useAuth();
  const { filterValues, setFilterValue } = useFilter();
  const [isBranchesStockExpanded, setIsBranchesStockExpanded] = useState(false);
  const [isSupplierStockExpanded, setIsSupplierStockExpanded] = useState(false);
  const [activeStep, setActiveStep] = useState<ActiveStep>('SEARCH');
  const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>('');

  const [shouldEnlarge, setShouldEnlarge] = useState(false);
  const [shouldShowDot, setShouldShowDot] = useState(false);

  const [myStockItems, setMyStockItems] = useState<PosItem[]>([]);
  const [branchesStockItems, setBranchesStockItems] = useState<PosItem[]>([]);
  const [supplierStockItems, setSupplierStockItems] = useState<PosItem[]>([]);

  const [selectedTableRow, setSelectedTableRow] = useState<PosItem | null>(
    null
  );
  const [selectedCustomer, setSelectedCustomer] =
    useState<PartnerResponse | null>(null);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<number | null>(
    Number(filterValues['warehouseId']) ?? user?.currentBranchId
  );

  const [isVatIncluded, setIsVatIncluded] = useState(true);

  const { modalData } = usePosCartData();

  const [badgeContent, setBadgeContent] = useState({
    BRANCHES: 0,
    SUPPLIERS: 0,
    CART: 0,
  });

  const width = shouldEnlarge
    ? activeStep !== 'CART'
      ? '60%'
      : '50%'
    : activeStep !== 'CART'
    ? '714px'
    : modalData.length > 0
    ? '630px'
    : '714px';

  useEffect(() => {
    setBadgeContent((prevState) => ({
      BRANCHES: prevState.BRANCHES,
      SUPPLIERS: prevState.SUPPLIERS,
      CART: modalData.length,
    }));
    if (selectedTableRow === null)
      setBadgeContent({
        BRANCHES: 0,
        SUPPLIERS: 0,
        CART: modalData.length,
      });
  }, [modalData.length, selectedTableRow]);

  useEffect(() => {
    if (!filterValues['warehouseId'] && user && user?.currentBranchId) {
      setFilterValue({
        ...filterValues,
        warehouseId: user.currentBranchId?.toString(),
      });
      setSelectedWarehouseId(user.currentBranchId ?? null);
    }
  }, [filterValues, setFilterValue, user]);

  const { selectedAppointment, setSelectedAppointment } = usePosCartCtx();

  return (
    <PageTemplateWrapper>
      <POSProvider
        value={{
          isBranchesStockExpanded,
          setIsBranchesStockExpanded,
          isSupplierStockExpanded,
          setIsSupplierStockExpanded,
          selectedTableRow,
          setSelectedTableRow,
          activeStep,
          setActiveStep,
          shouldEnlarge,
          setShouldEnlarge,
          shouldShowDot,
          setShouldShowDot,
          myStockItems,
          setMyStockItems,
          branchesStockItems,
          setBranchesStockItems,
          supplierStockItems,
          setSupplierStockItems,
          activeDrawer,
          setActiveDrawer,
          badgeContent,
          setBadgeContent,
          selectedCustomer,
          setSelectedCustomer,
          selectedWarehouseId,
          setSelectedWarehouseId,
          isVatIncluded,
          setIsVatIncluded,
        }}
      >
        {selectedAppointment ? (
          <Calendar
            close={() => {
              setSelectedAppointment(null);
            }}
          />
        ) : (
          <PosSearchProvider>
            <VehicleContextProvide>
              {/* This is template for left layout */}
              {modalData.length > 0 && activeStep === 'CART' ? (
                <CartLanding />
              ) : (
                <PageTemplateContent
                  style={{
                    width: shouldEnlarge
                      ? 'calc(100% - 60% - 70px)'
                      : 'calc(100% - 736px)',
                    transition: 'all 0.3s ease',
                    height: 'calc(100svh - 44px)',
                  }}
                >
                  <Landing sidebarOpen={sidebarOpen} />
                </PageTemplateContent>
              )}

              {/* This is template for right layout */}
              <PageTemplateContent
                datatype="pos_menu_wrapper"
                style={{
                  background: '#f5f6f8',
                  minWidth: width,
                  maxWidth: width,
                  marginRight: '0px',
                  height: 'calc(100svh - 44px)',
                  display: 'flex',
                  zIndex: 99,
                  transition: 'all 0.3s ease',
                }}
              >
                <DrawerWrapper>
                  {activeDrawer &&
                    Drawers({
                      activeDrawer,
                      setActiveDrawer,
                      selectedTableRow,
                      modelImages: selectedTableRow?.product?.model
                        ?.photos as unknown as PhotoShape[],
                    })}
                  {activeStep && PosSteps(activeStep)}
                </DrawerWrapper>
                <Menu
                  shouldDisableMenuItems={false}
                  shouldEnlarge={shouldEnlarge}
                  setShouldEnlarge={setShouldEnlarge}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  shouldShowDot={shouldShowDot}
                  setShouldShowDot={setShouldShowDot}
                  isVatIncluded={isVatIncluded}
                  setIsVatIncluded={setIsVatIncluded}
                />
              </PageTemplateContent>
            </VehicleContextProvide>
          </PosSearchProvider>
        )}
      </POSProvider>
    </PageTemplateWrapper>
  );
};

const DrawerWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;
  position: relative;
`;

export default POS;
