import { DBProduct, DBProductCategory, DBProductModel } from '@prisma/client';
import { DBGetProductsRequest } from './product';

export type SortType =
  | keyof DBProductModel
  | keyof DBProduct
  | keyof DBProductCategory
  | undefined;
interface DBGetProductModelsRequest {
  search?: string;
  searchFilter?: string;
  page?: number;
  pageSize?: number;
  sortKey?: SortType;
  sortParam?: 'desc' | 'asc';
  filters?: string[];
}

interface DBProductModelOnePayload extends DBGetProductsRequest {
  id?: string;
}

type DBProductModelPayload = DBProductModel & {
  category: DBProductCategory | null;
  DBProduct?: DBProduct[] | null;
};

export interface PhotoShape {
  url: string;
  uploadedBy: string;
  uploadDate: Date;
  isFavorite: boolean;
  slug: string;
  size: number;
}

export type InitialResponseItem = {
  category_id: string;
  width: string;
  height: null;
  holeCircle: string[];
  holeNumber: string[];
  rimSize: string;
  modelName: string;
  brand: string;
};

interface DBProductModelApi {
  list: {
    requestBody: DBGetProductModelsRequest;
    request: DBGetProductModelsRequest;
    response: {
      data: DBProductModelPayload[];
      count: number;
    };
  };
  getOne: {
    requestBody: never;
    requestParams: DBProductModelOnePayload;
    request: DBProductModelOnePayload;
    response: DBProductModelPayload;
  };
  upsert: {
    requestBody: Partial<DBProductModel> & { newModel?: boolean };
    requestParams: { id?: string };
    request: never;
    response: never;
  };
  deleteImage: {
    requestBody: { uid: string; url: string };
    requestParams: never;
    request: { uid: string; url: string };
    response: DBProductModelPayload | null;
  };
}

export {
  DBProductModelApi,
  DBGetProductModelsRequest,
  DBProductModelPayload,
  DBProductModelOnePayload,
};
