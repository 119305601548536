import api from '@tyrio/api-factory';
import { InboundInvoiceApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';
// import { useHistory } from 'react-router-dom';
import { InputShape } from '../../../context/InboundInvoiceContext';

export const useCreateInboundInvoice = (
  setInput: Dispatch<SetStateAction<InputShape>>,
  onSuccess: () => void
) => {
  // const history = useHistory();
  const { data, mutate } = useMutation(
    ['create_inbound_invoice'],
    (body: InboundInvoiceApi['create']['requestBody']) =>
      api.fetch<InboundInvoiceApi['create']>('create_inbound_invoice', {
        ...body,
      }),
    {
      onSuccess: () => {
        setInput({
          ids: '',
          checkedStockIns: {},
          checkedSupplier: { id: '', name: '' },
          preparedInvoices: null,
        });
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Successfully created ritam invoice.'
        );
        onSuccess();
        // history.push(
        //   `/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&supplierId=all&orderStatus=new`
        // );
      },
      onError: (err: unknown) => {
        console.log('ERROR creating ritam invoice ==>', err);
        let error = '';
        if (err instanceof AxiosError) error = err.response?.data.error.name;
        else if (typeof err === 'string') error = err;

        ToastHelper.showToast(
          'Ritam order',
          ToastType.ERROR,
          ToastMessageType.ERROR,
          error !== '' ? error : 'An error occured. Please try again!'
        );

        return;
      },
    }
  );

  return { data, createInvoice: mutate };
};
