import {
  DBConnectionType,
  DBRepeatUnit,
  DBSupplier,
  DBSupplierApiConnectionType,
  DBSupplierClientB2BSettings,
  DBSupplierClientSettings,
  DBSupplierClientShippingSettings,
  Prisma,
} from '@prisma/client';
import { DBSupplierClientB2BSettingsApi } from './supplier-client-b2b-settings';
import { DBSupplierClientContactsApi } from './supplier-client-contacts';
import { DBSupplierClientPaymentSettingsApi } from './supplier-client-payment-settings';
import { DBSupplierClientPriceCalculationApi } from './supplier-client-price-calculation';
import { DBSupplierClientShippingSettingsApi } from './supplier-client-shipping-settings';

interface Json {
  [x: string]: string | number | boolean | Date | Json | JsonArray;
}
type JsonArray = Array<string | number | boolean | Date | Json | JsonArray>;

export interface ClientFtpConnectionDetails {
  ftpHost: string;
  ftpPort: string;
  fileName: string;
  repeatEvery: number;
  repeatUnit: DBRepeatUnit;
  startUpdateTime: Date;
  username: string;
  password: string;
  remark: string;
}

export interface ClientApiConnectionDetails {
  url: string;
  apiKey: string;
  apiClientId: string;
  apiClientSecret: string;
  connectionType: DBSupplierApiConnectionType;
  remark?: string;
}
export interface ClientEmailConnectionDetails {
  supplierEmail: string;
  remark?: string;
}

export interface ClientHttpsConnectionDetails {
  url: string;
  username: string;
  password: string;
  customerId?: string;
  remark?: string;
  repeatEvery: number;
  repeatUnit: DBRepeatUnit;
  startUpdateTime: Date;
}

export type FtpConnectionDetail = {
  adminConnectionId: string;
  id: string;
  type: 'FTP';
  connectionType: DBConnectionType;
  connectionDetails: ClientFtpConnectionDetails;
};
export type HttpsConnectionDetail = {
  adminConnectionId: string;
  id: string;
  type: 'HTTPS';
  connectionType: DBConnectionType;
  connectionDetails: ClientHttpsConnectionDetails;
};
export type ApiConnectionDetail = {
  adminConnectionId: string;
  id: string;
  type: 'API';
  connectionType: DBConnectionType;
  connectionDetails: ClientApiConnectionDetails;
};
export type EmailConnectionDetail = {
  adminConnectionId: string;
  id: string;
  type: 'EMAIL';
  connectionType: DBConnectionType;
  connectionDetails: ClientEmailConnectionDetails;
};
export type ConnectionData = Record<string, ConnectionShape>;
export type ConnectionDetail =
  | FtpConnectionDetail
  | HttpsConnectionDetail
  | ApiConnectionDetail
  | EmailConnectionDetail;
export type ClientConnectionData = Record<
  string,
  | FtpConnectionDetail
  | HttpsConnectionDetail
  | ApiConnectionDetail
  | EmailConnectionDetail
>;
export type ClientConnectionDataArray = Array<
  | FtpConnectionDetail
  | HttpsConnectionDetail
  | ApiConnectionDetail
  | EmailConnectionDetail
>;

export type ConnectionShape =
  | FtpConnectionDetail
  | HttpsConnectionDetail
  | ApiConnectionDetail
  | EmailConnectionDetail;

interface DBUpsertSupplierClientSettingsPayload {
  id?: string;
  supplierId: string;
  isActive: boolean;
  supplierClientContacts: DBSupplierClientContactsApi['create']['requestBody'][];
  supplierClientB2BSettings: DBSupplierClientB2BSettingsApi['create']['requestBody'];
  supplierClientPaymentSettings: DBSupplierClientPaymentSettingsApi['create']['requestBody'];
  supplierClientPriceCalculation: DBSupplierClientPriceCalculationApi['create']['requestBody'];
  supplierClientShippingSettings: DBSupplierClientShippingSettingsApi['create']['requestBody'][];
  connections: ClientConnectionDataArray;
}

interface DBSupplierClientSettingsRequest {
  search?: string;
  clientId?: string;
  includeB2B?: string;
  page?: number;
  pageSize?: number;
  supplierIds?: string[];
}

export type DBSupplierClientSettingsPayload = DBSupplierClientSettings & {
  supplier: DBSupplier | null;
  supplierClientB2BSettings: DBSupplierClientB2BSettings | null;
  supplierClientShippingSettings: DBSupplierClientShippingSettings[] | null;
};

interface DBSupplierClientSettingsApi {
  list: {
    requestBody: DBSupplierClientSettingsRequest;
    request: DBSupplierClientSettingsRequest;
    response: { data: DBSupplierClientSettingsPayload[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: { id: string };
    request: { id: string };
    response: DBSupplierClientSettings;
  };
  create: {
    requestBody: DBUpsertSupplierClientSettingsPayload;
    requestParams: never;
    request: DBUpsertSupplierClientSettingsPayload;
    response: DBSupplierClientSettings;
  };
  updateOne: {
    requestBody: Partial<Prisma.DBSupplierClientSettingsUpdateInput>;
    requestParams: { id: string };
    request: Partial<Prisma.DBSupplierClientSettingsUpdateInput> & {
      id: string;
    };
    response: DBSupplierClientSettings;
  };
}
export { DBSupplierClientSettingsApi };
