import styled from '@emotion/styled';
import {
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import { DBOrderStatus, DBSalesChannel, Prisma } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBClientSupplierOrderItemApi,
  DBClientSupplierOrderItemCountByStatus,
  DBClientSupplierOrderItemFilters,
  DBClientSupplierOrderItemResponse,
} from '@tyrio/dto';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  backIcon,
} from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import NoDataFound from '../../components/NoData/NoDataFound';
import OrderSidebarLayout from '../../components/Orders/SidebarLayout/OrderSidebarLayout';
import { LoaderWrapper } from '../../components/PageTemplate/PageTemplate';
import StyledPagination from '../../components/Pagination/Pagination';
import { useAuth } from '../../context/AuthContext';
import { useDebounce } from '../../hooks/useDebounce';
import PurchaseOrderTable from './PurchaseOrderTable';
import PurchaseOrderTableFilters from './components/PurchaseOrderTableFilters';
import TabsFilter from './components/TabsFilter';
import { ComponentType } from './helpers/types';
import OrderDetailsSidebarTabs from './order-details/order-details-sidebar-tabs/OrderDetailsSidebarTabs';
import { useFilter } from '../../hooks/useFilter';
import { BarcodeModal } from '../../components/BarcodeModal/ModalContent';
import { OrderLineItem } from './helpers/generate-color';

const DEFAULT_FILTERS: DBClientSupplierOrderItemFilters = {
  searchType: 'reference',
  searchKeyword: '',
  salesChannelKeys: [DBSalesChannel.BULK_ORDERS],
  supplierIds: [],
  startDate: undefined,
  endDate: undefined,
  orderStatus: 'all',
};

interface PurchaseOrderOverviewProps {
  sidebarOpen: boolean;
}

const PurchaseOrderOverview = ({ sidebarOpen }: PurchaseOrderOverviewProps) => {
  const { user } = useAuth();
  const { filterValues, ...filtersHook } = useFilter({});

  const history = useHistory();
  const [selectedOrderData, setSelectedOrderData] = useState<
    DBClientSupplierOrderItemApi['getOne']['response'] | null
  >(null);

  const [filters, setFilters] = useState<DBClientSupplierOrderItemFilters>({
    ...DEFAULT_FILTERS,
    searchType:
      (filterValues?.['searchFilter'] as string) ?? DEFAULT_FILTERS.searchType,
    searchKeyword:
      (filterValues?.['searchKeyword'] as string) ??
      DEFAULT_FILTERS.searchKeyword,
  });

  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [isDirty, setIsDirty] = useState(false);
  const [isUploadInProcess, setIsUploadInProcess] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const debouncedSearchRaw = useDebounce(
    JSON.stringify(_.omit(filters, 'searchType')),
    500
  );

  const debouncedSearch = useMemo(
    () => JSON.parse(debouncedSearchRaw) as DBClientSupplierOrderItemFilters,
    [debouncedSearchRaw]
  );

  const {
    data: clientOrdersData,
    isFetching,
    refetch: fetchTableData,
  } = useQuery(
    ['client_orders', { debouncedSearch, page, pageSize }],
    () => searchClientOrders(filters, page, pageSize),
    {
      enabled: true,
      onSuccess: (d) => {
        const order = d.data.find(
          (item) => item.id === filterValues['selected']
        );

        if (!order) return;

        setSelectedOrderData(order);
      },
    }
  );

  const { data: countData, refetch: fetchCountData } = useQuery(
    ['count_orders_by_status', { debouncedSearch }],
    () => getCountData(filters),
    { enabled: true }
  );

  const getCountData = async (filters: DBClientSupplierOrderItemFilters) => {
    return await api.fetch<{
      request: DBClientSupplierOrderItemApi['list']['request'];
      requestBody: DBClientSupplierOrderItemApi['list']['requestBody'];
      response: DBClientSupplierOrderItemCountByStatus[];
    }>('count_orders_by_status', { filters: filters });
  };

  const searchClientOrders = async (
    filters: DBClientSupplierOrderItemFilters,
    page: number,
    pageSize: number
  ) => {
    return await api.fetch<DBClientSupplierOrderItemApi['list']>(
      'client_orders',
      {
        filters,
        page,
        pageSize,
      }
    );
  };

  const updateFavoriteStatusMutation = useMutation(
    (id: string) =>
      api.fetch<DBClientSupplierOrderItemApi['getOne']>(
        'change_favourite_status',
        {
          id: id,
        }
      ),
    {
      mutationKey: 'change_favorite_status',
      onSuccess: () => {
        setIsDirty(false);
        getTableCountData();
        const isFavorited = selectedOrderData?.favoritedBy.includes(
          user?.id ?? ''
        );
        selectedOrderData &&
          setSelectedOrderData({
            ...selectedOrderData,
            favoritedBy: isFavorited
              ? selectedOrderData.favoritedBy.filter(
                  (item) => item !== user?.id
                )
              : [...selectedOrderData.favoritedBy, user?.id ?? ''],
          });
        ToastHelper.showToast(
          'Order',
          ToastType.SUCCESS,
          undefined,
          `Order successfully ${
            isFavorited ? 'removed from' : 'added to'
          } favorites`
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Order',
          ToastType.ERROR,
          undefined,
          'An error occured!'
        );
      },
    }
  );

  const setFavorite = () => {
    setIsDirty(false);
    updateFavoriteStatusMutation.mutate(selectedOrderData?.id ?? '');
  };

  const updateOrderMutation = useMutation(
    (request: DBClientSupplierOrderItemApi['updateOne']['request']) =>
      api.fetch<DBClientSupplierOrderItemApi['updateOne']>('update_order', {
        id: selectedOrderData?.id,
        ...request,
      }),
    {
      mutationKey: 'update_order',
      onSuccess: () => {
        setIsDirty(false);
        getTableCountData();
        ToastHelper.showToast(
          'Order',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Customer order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const handleSave = () => {
    const requestBody: DBClientSupplierOrderItemApi['updateOne']['requestBody'] =
      {
        orderStatus: selectedOrderData?.orderStatus,
        deliveryType: selectedOrderData?.deliveryType,
        remark: selectedOrderData?.remark ?? undefined,
        orderLineItemMeta:
          selectedOrderData?.orderLineItemMeta as Prisma.InputJsonValue,
      };
    updateOrderMutation.mutate(requestBody);
  };

  const count = clientOrdersData?.count;

  const totalPageNumber =
    clientOrdersData && count
      ? count / pageSize - Math.floor(count / pageSize) === 0
        ? count / pageSize
        : Math.floor(count / pageSize) + 1
      : 1;

  const handleChangePageSize = (event: SelectChangeEvent) => {
    setPageSize(parseInt(event.target.value));
    setPage(1);
  };

  const getTableCountData = () => {
    fetchTableData();
    fetchCountData();
  };

  useEffect(() => {
    setPage(1);
    fetchTableData();
  }, [fetchTableData, filters]);

  const handleSetSelectedOrderData = useCallback(
    (value: DBClientSupplierOrderItemResponse | null) => {
      filtersHook.setFilterValue({ selected: value?.id });
      setSelectedOrderData(value);
    },
    [filtersHook]
  );

  return (
    <Container>
      <BarcodeModal
        open={openPrintModal}
        handleClose={() => setOpenPrintModal(false)}
        items={
          selectedOrderData
            ? (selectedOrderData?.lineItems as unknown as OrderLineItem[]).map(
                (item) => {
                  return {
                    ean: item.ean,
                    name: item.productName,
                    qty: item.qty,
                  };
                }
              )
            : []
        }
      />
      <PurchaseLayout selectedOrder={selectedOrderData} open={sidebarOpen}>
        <HeaderWrapperTop>
          <BackButton onClick={() => history.push('/dashboard')}>
            <img src={backIcon} alt="back-icon" />
          </BackButton>
          <HeaderText>Purchase orders</HeaderText>
        </HeaderWrapperTop>
        <Divider />
        <HeaderContent>
          <PurchaseOrderTableFilters
            isSidebarOpen={selectedOrderData !== null}
            filters={filters}
            setFilters={setFilters}
            setFilterValue={filtersHook.setFilterValue}
          />
        </HeaderContent>
        <TabsOuterWrapper container>
          <TabsInnerWrapper item>
            <TabsFilter
              filters={filters}
              setFilters={setFilters}
              setPage={setPage}
              countOrders={countData ?? []}
              componentType={ComponentType.purchase}
            />
            <FormControlLabel
              sx={{ marginLeft: '50px' }}
              label="Storned"
              control={
                <Switch
                  color="info"
                  checked={filters.orderStatus === DBOrderStatus.STORNED}
                  onChange={() => {
                    const value =
                      filters.orderStatus === DBOrderStatus.STORNED
                        ? 'all'
                        : DBOrderStatus.STORNED;
                    setFilters({
                      ...filters,
                      orderStatus: value,
                    });
                  }}
                />
              }
            />
          </TabsInnerWrapper>
        </TabsOuterWrapper>
        {isFetching ? (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        ) : clientOrdersData && clientOrdersData.count > 0 ? (
          <>
            <PurchaseOrderTable
              data={clientOrdersData?.data || []}
              setSelectedOrder={(val) => {
                handleSetSelectedOrderData(val);
              }}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
              isUploadInProcess={isUploadInProcess}
              selectedOrder={selectedOrderData}
            />
            <StyledPagination
              totalPageNumber={totalPageNumber}
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              selectedOrderData={selectedOrderData}
              handleChangePageSize={handleChangePageSize}
              menuItems={[20, 50, 100, 200]}
            />
          </>
        ) : (
          <NoDataFound />
        )}
      </PurchaseLayout>

      {selectedOrderData !== null && (
        <PurchaseOrdersDetailsWrapper>
          <OrderSidebarLayout
            selectedOrderData={selectedOrderData}
            closeOrder={() => {
              handleSetSelectedOrderData(null);
            }}
            setFavorite={setFavorite}
            handleSave={handleSave}
            isUploadInProcess={isUploadInProcess}
            orderTabs={
              <OrderDetailsSidebarTabs
                selectedOrderData={selectedOrderData}
                setSelectedOrderData={handleSetSelectedOrderData}
                setIsDirty={setIsDirty}
                setIsUploadInProcess={setIsUploadInProcess}
              />
            }
            isDirty={isDirty}
            setIsDirty={setIsDirty}
            componentType={ComponentType.purchase}
            setOpenPrintModal={setOpenPrintModal}
          />
        </PurchaseOrdersDetailsWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 22px;
  display: flex;
  height: 100%;
`;

const PurchaseLayout = styled.div<{
  selectedOrder: DBClientSupplierOrderItemResponse | null;
  open: boolean;
}>`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  width: ${(prop) =>
    prop.selectedOrder === null ? '100%' : `calc(100% - 966px)`};
  transition: linear 0.4s;
  height: calc(100svh - 44px);
`;

const PurchaseOrdersDetailsWrapper = styled.div`
  width: 950px;
  margin-left: 16px;
  height: calc(100svh - 44px);
`;

export const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const HeaderWrapperTop = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

export const HeaderContent = styled.div`
  padding: 16px 16px 0 16px;
`;

export const TableContainer = styled.div`
  width: 100%;
  padding: 0px 16px 16px 16px;
`;

const TabsOuterWrapper = styled(Grid)`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabsInnerWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: red;
  ::-webkit-scrollbar {
    width: 10px;
  }
`;

export default PurchaseOrderOverview;
