interface Valuta {
  drzava: {
    sifra: number;
    naziv: string;
    oznaka_2: string;
    oznaka_3: string;
  };
  id: number;
  sifra: number;
  naziv: string;
}

interface Tvrtka {
  ime: string;
  naznaka_imena?: string;
}

interface Sjediste {
  ulica: string;
  kucni_broj: number;
  sifra_zupanije: number;
  naziv_zupanije: string;
  sifra_opcine: number;
  naziv_opcine: string;
  sifra_naselja: number;
  naziv_naselja: string;
}

interface PravniOblik {
  vrsta_pravnog_oblika: {
    sifra: number;
    naziv: string;
    kratica: string;
  };
}

interface Djelatnost {
  djelatnost_rbr: number;
  djelatnost_tekst: string;
}

interface GrupaGFI {
  gfi_rbr: number;
  gfi_lista: {
    gfi_rbr: number;
    vrsta_dokumenta: number;
    oznaka_konsolidacije: number;
    godina_izvjestaja: number;
    datum_dostave: string;
    datum_od: string;
    datum_do: string;
  }[];
}

interface Postupak {
  vrsta: {
    postupak: number;
    znacenje: string;
  };
}

interface EmailAdresa {
  email_adresa_rbr: number;
  adresa: string;
}

interface Sud {
  id: number;
  sifra: number;
  naziv: string;
  sifra_zupanije: number;
  naziv_zupanije: string;
  sifra_opcine: number;
  naziv_opcine: string;
  sifra_naselja: number;
  naziv_naselja: string;
  sud_id_nadlezan: number;
  izdaje_izvatke: number;
}

export interface SudregResponse {
  mbs: number;
  sud_id_nadlezan: number;
  sud_id_sluzba: number;
  oib: number;
  ino_podruznica: number;
  stecajna_masa: number;
  datum_osnivanja: string;
  likvidacijska_masa: number;
  potpuni_mbs: string;
  potpuni_oib: string;
  tvrtka: Tvrtka;
  skracene_tvrtke: Tvrtka[];
  sjediste: Sjediste;
  temeljni_kapitali: {
    valuta: Valuta;
    iznos: number;
  }[];
  predmeti_poslovanja: Djelatnost[];
  pravni_oblik: PravniOblik;
  grupe_gfi: GrupaGFI[];
  postupci: Postupak[];
  email_adrese: EmailAdresa[];
  sud_sluzba: Sud;
  sud_nadlezan: Sud;
}
