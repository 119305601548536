/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box/Box';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { CodeSearchType } from '../../types';
import { PriceComparisonContext } from '../context';

interface ImportQtyKey {
  key: string;
  qty?: number;
}
export const ImportTextArea = () => {
  const ctx = useContext(PriceComparisonContext);
  const [selectedType, setSelectedType] = useState<CodeSearchType>(
    ctx.data.code?.searchType || 'ean'
  );
  const [input, setInput] = useState('');
  const [manualCodeInput, setManualCodeInput] = useState<string>('');
  const [manualQtyInput, setManualQtyInput] = useState<string>('');

  const selectedInputs = useMemo(
    () => ctx.data.code?.list || {},
    [ctx.data.code?.list]
  );
  const setSelectedInputs = useCallback(
    (data: Record<string, number>) => {
      ctx.setInputValue(
        {
          ...ctx.data,
          code: {
            ...ctx.data.code,
            list: data || {},
          },
        },
        true
      );
    },
    [ctx]
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const codeInput = useRef<HTMLDivElement | null>(null);
  const qtyInput = useRef<HTMLDivElement | null>(null);
  const finalInputs = useRef<Record<string, number>>(ctx.data.code?.list || {});

  useEffect(() => {
    finalInputs.current = ctx.data.code?.list || {};
  }, [ctx.data.code]);

  const updateInputValues = useCallback(
    (validOutputs: Record<string, number>) => {
      const oldValues = _.cloneDeep(finalInputs.current);
      const newValues = _.mergeWith(
        oldValues,
        validOutputs,
        (objValue, srcValue) =>
          _.isNumber(objValue) ? objValue + srcValue : srcValue
      );

      setSelectedInputs(newValues);
      finalInputs.current = newValues;
    },
    [setSelectedInputs]
  );

  const handleSubmit = useCallback(() => {
    const splitInput = input.split('\n');

    const lines = splitInput.map((line): ImportQtyKey => {
      const keys = line.split('\t');
      return {
        key: keys[0]?.trim(),
        qty: parseInt(keys[1]?.trim(), 10),
      };
    });

    const validOutputs: Record<string, number> = {};
    const invalidOutputs: ImportQtyKey[] = [];

    lines.forEach((line) => {
      if (ctx.data.code?.searchType === 'ean') {
        let qty = 0;
        if (!!line.qty && !isNaN(line.qty) && line.qty > 0) qty = line.qty;
        else qty = 1;

        const wholeString = (line.key + '\t' + qty).trim();

        const keyPattern = /^\d{13}$/;
        const keyQuantityPattern = /^\d{13}\t\d+$/;

        if (
          !keyPattern.test(wholeString) &&
          !keyQuantityPattern.test(wholeString)
        ) {
          invalidOutputs.push(line);
        } else {
          if (!!line.qty && !isNaN(line.qty) && line.qty > 0) {
            if (!validOutputs[line.key]) validOutputs[line.key] = line.qty;
            else validOutputs[line.key] = validOutputs[line.key] + line.qty;
          } else {
            if (!validOutputs[line.key]) validOutputs[line.key] = 1;
            else validOutputs[line.key] = validOutputs[line.key] + 1;
          }
        }
      } else {
        if (
          // Check Key
          !!line.key &&
          line.key.length > 3 &&
          // Check qty
          !!line.qty &&
          !isNaN(line.qty) &&
          line.qty > 0
        ) {
          if (!validOutputs[line.key]) validOutputs[line.key] = line.qty;
          else validOutputs[line.key] += line.qty;
        } else {
          invalidOutputs.push(line);
        }
      }
    });

    updateInputValues(validOutputs);

    const brokenInputs = splitInput.filter((item) => {
      let isInvalid = false;

      for (const invalidOutput of invalidOutputs) {
        if (item.startsWith(invalidOutput.key)) {
          isInvalid = true;
          break;
        }
      }

      return isInvalid;
    });

    setInput(brokenInputs.join('\n'));
  }, [ctx.data.code?.searchType, input, updateInputValues]);

  const selectedInputsList = useMemo(() => {
    const list = Object.keys(selectedInputs);
    if (!searchValue) return list;
    return list.filter((item) => item.indexOf(searchValue) > -1);
  }, [searchValue, selectedInputs]);

  const submitManualCode = useCallback(() => {
    codeInput.current?.focus();
    if (manualCodeInput) {
      const qtyInput = manualQtyInput ? parseInt(manualQtyInput, 10) : 1;
      if (!isNaN(qtyInput)) {
        updateInputValues({
          [manualCodeInput]: qtyInput,
        });
        setManualCodeInput('');
        setManualQtyInput('');
      }
    }
  }, [manualCodeInput, manualQtyInput, updateInputValues]);

  const selectedInputsCount = selectedInputsList.length;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="search-type">Search type</FormLabel>
          <RadioGroup
            row
            aria-labelledby="search-type-label"
            name="search-type-group"
            onChange={(e) => {
              setSelectedType(e.target.value as CodeSearchType);
              ctx.setInputValue({
                code: {
                  searchType: e.target.value as CodeSearchType,
                },
              });
            }}
            value={selectedType}
          >
            <FormControlLabel
              value="ean"
              control={<Radio color="info" />}
              label="EAN"
            />
            <FormControlLabel
              value="manufacturerCode"
              control={<Radio color="info" />}
              label="IPC/CAI"
            />
            <FormControlLabel
              value="sku"
              control={<Radio color="info" />}
              label="SKU"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        {ctx.data.type === 'code' && (
          <TextFieldTabInput
            label="Bulk paste from Excel"
            fullWidth
            multiline
            rows={15}
            InputProps={{
              sx: {
                minHeight: 390,
              },
            }}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e: any) => {
              if (e.keyCode === 9) {
                e.preventDefault();

                const value = e.target.value;
                const start = e.target.selectionStart;
                const end = e.target.selectionEnd;
                const newValue =
                  value.substring(0, start) + '\t' + value.substring(end);
                setInput(newValue);
              }
            }}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => handleSubmit()}
          color="info"
        >
          Add
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }}>
          or enter manually
        </Divider>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <TextField
              label="Code"
              inputRef={codeInput}
              fullWidth
              helperText="Press ENTER to submit"
              value={manualCodeInput}
              onChange={(e) => setManualCodeInput(e.target.value.trim())}
              onKeyDown={
                ((e) => {
                  if (e.keyCode === 13) {
                    qtyInput.current?.focus();
                  }
                }) as KeyboardEventHandler<HTMLDivElement>
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Quantity"
              inputRef={qtyInput}
              fullWidth
              type="number"
              value={manualQtyInput}
              onChange={(e) => setManualQtyInput(e.target.value)}
              onKeyDown={
                ((e) => {
                  if (e.keyCode === 13) {
                    submitManualCode();
                  }
                }) as KeyboardEventHandler<HTMLDivElement>
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              fullWidth
              sx={{ height: '56px', elevation: 0, padding: 0, margin: 0 }}
              color="success"
              onClick={() => submitManualCode()}
            >
              <AddIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '8px',
            padding: '1rem',
            width: '100%',
          }}
        >
          <TextField
            label="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            fullWidth
            sx={{ marginBottom: 1 }}
          />
          <FixedSizeList
            height={400}
            width={'100%'}
            itemSize={64}
            itemKey={(idx) => selectedInputsList[idx]}
            itemCount={selectedInputsCount}
            overscanCount={5}
          >
            {({ index, style }) => {
              const code = selectedInputsList[index];

              return (
                <VirtualizedListItem style={style} key={`${code}`}>
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Input sx={{ width: '70%' }} defaultValue={code} disabled />
                    <Input
                      inputProps={{
                        style: { textAlign: 'center' },
                        min: 0,
                      }}
                      sx={{ width: '30%' }}
                      defaultValue={finalInputs.current[code]}
                      onChange={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (value < 0) value = 0;

                        finalInputs.current = {
                          ...finalInputs.current,
                          [code]: value,
                        };
                      }}
                      onBlur={() => {
                        ctx.setInputValue({
                          code: {
                            ...ctx.data.code,
                            list: {
                              ...ctx?.data?.code?.list,
                              [code]: finalInputs.current[code],
                            },
                          },
                        });
                      }}
                      type="number"
                    />
                  </div>
                  <Button
                    color="error"
                    onClick={() => {
                      const newList = _.pickBy(
                        ctx.data.code?.list || {},
                        (__val, key) => key !== code
                      );
                      setSelectedInputs(newList);
                      finalInputs.current = newList;
                    }}
                  >
                    REMOVE
                  </Button>
                </VirtualizedListItem>
              );
            }}
          </FixedSizeList>
        </Box>
      </Grid>
    </Grid>
  );
};

const TextFieldTabInput = styled(TextField)``;
const VirtualizedListItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
`;
