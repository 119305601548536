import { CheckPriceRes } from './price-calculation';

interface DBBranchStockListOnePayload {
  ean?: string;
  warehouseId?: string;
}

export interface DBBranchStockListResponse {
  branchId: number;
  branchName: string;
  branchCity: string;
  stockLocationId: string;
  lineItems: DBBranchStockLineItems[];
}

export interface DBBranchStockLineItems {
  stockListItemId: string;
  branchId: number;
  ean: string;
  productionYear: string | null;
  quantity: number;
  reserved: number;
  uid: string;
  productName: string;
  manufacturerCode: string;
  sku: string;
  dot: string;
  price: number | null;
  calculatedPrice: Record<string, Partial<CheckPriceRes>>;
  inclusiveVatPrice?: number;
}

interface DBBranchStockListApi {
  list: {
    requestBody: DBBranchStockListOnePayload;
    request: DBBranchStockListOnePayload;
    response: DBBranchStockListResponse[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBBranchStockListResponse;
  };
  create: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBBranchStockListApi };
