import { DBBranch, DBBranchWarehouseAreas } from '@prisma/client';
import { ZoneStagingType } from './stock-locations';

type WarehousesResponse = DBBranchWarehouseAreas & {
  branch: DBBranch;
};

interface DBWarehouseSettingsApi {
  list: {
    requestBody: never;
    request: never;
    response: WarehousesResponse[];
  };
  getOne: {
    requestBody: never;
    requestParams: WarehouseDetailsReq;
    request: WarehouseDetailsReq;
    response: Record<ZoneStagingType, WarehouseDetailsRes[]>;
  };
}

export interface WarehouseDetailsRes {
  color: string;
  type: ZoneStagingType;
  qrCode: string;
  displayName: string;
  subzoneName?: string;
  width: number;
  used: number;
  count: number;
  dots: string[];
  eans: Record<
    string,
    {
      dot: string;
      qty: number;
    }
  >;
}

interface WarehouseDetailsReq {
  id: string;
}

export { DBWarehouseSettingsApi };
