import { DBClient, DBClientApiKey } from '@prisma/client';

type DBClientApiKeyApiRestResponse = {
  client: DBClient;
};

type DBClientApiKeyApiResponse = DBClientApiKey & DBClientApiKeyApiRestResponse;

interface DBApiKeyOnePayload {
  apiKey: string;
}

interface DBClientApiKeysPayload {
  clientId: string;
}

interface DBClientApiKeyApi {
  list: {
    requestBody: never;
    request: { search: string };
    response: DBClientApiKeyApiResponse[];
  };
  getOne: {
    requestBody: never;
    requestParams: DBApiKeyOnePayload | DBClientApiKeysPayload;
    request: DBApiKeyOnePayload | DBClientApiKeysPayload;
    response: DBClientApiKeyApiResponse | null;
  };
  create: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBClientApiKeyApiResponse;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}

export { DBClientApiKeyApi };
