import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import logoutRed from '../../../../src/assets/other-icons/logout-red.svg';
import CheckIcon from '@mui/icons-material/Check';
interface IModalProps {
  modalIcon: JSX.Element;
  headerText: string;
  modalBody: string;
  leftButton?: ILeftButtonProps;
  rightButton?: IRightButtonProps;
  textField?: boolean;
  setInputText?: Dispatch<SetStateAction<string>>;
}
interface IButtonProps {
  startIcon?: JSX.Element;
  color?:
    | 'inherit'
    | 'error'
    | 'info'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | undefined;
  size?: 'small' | 'large' | 'medium' | undefined;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  text?: string;
}
interface ILeftButtonActionProps {
  LBAction?: () => void;
}
interface IRightButtonActionProps {
  RBAction?: () => void;
}
type ILeftButtonProps = IButtonProps & ILeftButtonActionProps;
type IRightButtonProps = IButtonProps & IRightButtonActionProps;
type ILogOutModalProps = ILeftButtonActionProps & IRightButtonActionProps;
type ICancelModalProps = ILeftButtonActionProps &
  IRightButtonActionProps & { text?: string };
type IDeleteModalProps = ILeftButtonActionProps &
  IRightButtonActionProps & { itemName: string };

type IInputCancelModalProps = ILeftButtonActionProps &
  IRightButtonActionProps & {
    object: string;
    setInputText: Dispatch<SetStateAction<string>>;
  };

export const CancelModal = ({
  LBAction,
  RBAction,
  text,
}: ICancelModalProps) => (
  <Modal
    modalIcon={<ErrorOutlinedIcon color="warning" sx={{ fontSize: '70px' }} />}
    headerText={text ? text : 'You have unsaved changes.'}
    modalBody="Are you sure you want to continue?"
    leftButton={{
      startIcon: <CancelIcon />,
      color: 'info',
      text: 'CANCEL',
      variant: 'outlined',
      size: 'small',
      LBAction,
    }}
    rightButton={{
      startIcon: <CheckIcon />,
      color: 'error',
      text: 'YES, CONTINUE',
      variant: 'outlined',
      size: 'small',
      RBAction,
    }}
  />
);

export const DeleteModal = ({
  LBAction,
  RBAction,
  itemName,
}: IDeleteModalProps) => (
  <Modal
    modalIcon={<ErrorOutlinedIcon color="warning" sx={{ fontSize: '70px' }} />}
    headerText={`Are you sure you want to delete ${itemName} ?`}
    modalBody="Please ensure your action and then confirm !"
    leftButton={{
      startIcon: <CancelIcon />,
      color: 'info',
      text: 'CANCEL',
      variant: 'outlined',
      size: 'small',
      LBAction,
    }}
    rightButton={{
      startIcon: <DeleteIcon />,
      color: 'error',
      text: 'YES, DELETE',
      variant: 'outlined',
      size: 'small',
      RBAction,
    }}
  />
);

export const LogOutModal = ({ LBAction, RBAction }: ILogOutModalProps) => (
  <Modal
    modalIcon={<ErrorOutlinedIcon color="warning" sx={{ fontSize: '70px' }} />}
    headerText=""
    modalBody="Are you sure you want to logout?"
    leftButton={{
      startIcon: <CancelIcon />,
      color: 'info',
      text: 'CANCEL',
      variant: 'outlined',
      size: 'small',
      LBAction,
    }}
    rightButton={{
      startIcon: <LogoutIcon src={logoutRed} alt="logout-icon" />,
      color: 'error',
      text: 'YES, LOGOUT',
      variant: 'outlined',
      size: 'small',
      RBAction,
    }}
  />
);

export const InputCancelModal = ({
  LBAction,
  RBAction,
  object,
  setInputText,
}: IInputCancelModalProps) => (
  <Modal
    modalIcon={<ErrorOutlinedIcon color="warning" sx={{ fontSize: '60px' }} />}
    headerText=""
    modalBody={`Please let us know why do you want to cancel the ${object}?`}
    leftButton={{
      startIcon: <CancelIcon />,
      color: 'info',
      text: 'UNDO',
      variant: 'outlined',
      size: 'small',
      LBAction,
    }}
    rightButton={{
      startIcon: <DeleteIcon />,
      color: 'error',
      text: `CANCEL ${object}`,
      variant: 'outlined',
      size: 'small',
      RBAction,
    }}
    textField={true}
    setInputText={setInputText}
  />
);

export const MakeOrderModal = ({ LBAction, RBAction }: ILogOutModalProps) => (
  <Modal
    modalIcon={<ShoppingCartIcon sx={{ fontSize: '70px' }} />}
    headerText=""
    modalBody="Are you sure you want to place an order ?"
    leftButton={{
      startIcon: <CancelIcon />,
      color: 'info',
      text: 'CANCEL',
      variant: 'outlined',
      size: 'small',
      LBAction,
    }}
    rightButton={{
      color: 'info',
      text: 'YES',
      variant: 'contained',
      size: 'small',
      RBAction,
    }}
  />
);

export const DiscardModal = ({
  LBAction,
  RBAction,
  text,
}: ICancelModalProps) => (
  <Modal
    modalIcon={<ErrorOutlinedIcon color="warning" sx={{ fontSize: '70px' }} />}
    headerText={text ? text : 'You are about to discard all the information.'}
    modalBody="Are you sure you want to continue?"
    leftButton={{
      startIcon: <CancelIcon />,
      color: 'info',
      text: 'CANCEL',
      variant: 'outlined',
      size: 'small',
      LBAction,
    }}
    rightButton={{
      startIcon: <CheckIcon />,
      color: 'error',
      text: 'YES, CONTINUE',
      variant: 'outlined',
      size: 'small',
      RBAction,
    }}
  />
);

export const Modal = ({
  modalIcon,
  headerText,
  modalBody,
  leftButton,
  rightButton,
  textField,
  setInputText,
}: IModalProps) => {
  return (
    <ModalWrapper>
      <ModalContentWrapper>
        <ModalContent>
          <IconWrapper>{modalIcon}</IconWrapper>
          <ModalHeaderWrapper>
            <ModalHeaderText>{headerText}</ModalHeaderText>
          </ModalHeaderWrapper>
          <ModalContentText>{modalBody}</ModalContentText>
          {textField && setInputText && (
            <TextField
              fullWidth
              onChange={(e) => setInputText(e.target.value)}
              multiline
              rows={3}
              variant="outlined"
              label="Cancel Reason"
            />
          )}
          <ButtonsWrapper>
            <Button
              color={leftButton?.color}
              variant={leftButton?.variant}
              startIcon={leftButton?.startIcon}
              size={leftButton?.size}
              onClick={leftButton?.LBAction}
              data-cy={leftButton?.text}
            >
              {leftButton?.text}
            </Button>
            <Button
              color={rightButton?.color}
              variant={rightButton?.variant}
              startIcon={rightButton?.startIcon}
              size="small"
              onClick={rightButton?.RBAction}
              data-cy={rightButton?.text}
            >
              {rightButton?.text}
            </Button>
          </ButtonsWrapper>
        </ModalContent>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContentWrapper = styled.div`
  display: flex;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border-radius: 9px;
  width: 350px;
  justify-content: center;
`;

const ModalContent = styled.div``;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

const ModalHeaderWrapper = styled.div`
  text-align: center;
`;

const ModalHeaderText = styled.b``;

const ModalContentText = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
`;

const LogoutIcon = styled(motion.img)`
  width: 17px;
  height: 17px;
`;

export default Modal;
