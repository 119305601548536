import {
  DBAddress,
  DBBusinessType,
  DBClient,
  DBClientType,
  DBCompanyContact,
  DBCurrency,
  DBUser,
} from '@prisma/client';
import { DBAddressApi } from './address';
import { DBCompanyContactApi } from './company-contact';
import { DBUserApi } from './user';

interface DBCreateClientPayload {
  users: Array<DBUserApi['create']['requestBody']>;
  companyContacts: Array<DBCompanyContactApi['create']['requestBody']>;
  clientType: DBClientType;
  businessType: DBBusinessType;
  vatNumber: string;
  vies: boolean;
  euVatNumber?: string;
  slug?: string;
  officialName: string;
  shortName: string;
  address: Partial<DBAddress>;
  remark?: string;
  referralId?: string;
  IBAN?: string;
  swift?: string;
  currency?: DBCurrency;
  businessCode?: string;
  vatObligee?: string;
  intrastat?: string;
  registrationNumber?: string;
  viesVatNumber?: string;
  companyEmail?: string;
  companyWebSite?: string;
  companyBusinessPhone?: string;
  responsiblePersonJobTile?: string;
  responsiblePersonFirstName?: string;
  responsiblePersonLastName?: string;
  responsiblePersonEmail?: string;
  responsiblePersonBusinessPhone?: string;
  responsiblePersonMobilePhone?: string;
  isUpdated?: boolean;
  printNodeApiKey?: string | null;
}

interface DBUpdateClientPayload {
  companyContacts?: Array<DBCompanyContactApi['updateOne']['requestBody']>;
  users?: Array<DBUserApi['create']['requestBody']>;
  clientType?: DBClientType;
  businessType?: DBBusinessType;
  vatNumber?: string;
  vies?: boolean;
  slug?: string;
  officialName?: string;
  shortName?: string;
  address?: DBAddressApi['updateOne']['request'];
  remark?: string;
  referralId?: string;
  active?: boolean;
  euVatNumber?: string;
  IBAN?: string;
  swift?: string;
  currency?: DBCurrency;
  businessCode?: string;
  vatObligee?: string;
  intrastat?: string;
  registrationNumber?: string;
  viesVatNumber?: string;
  companyEmail?: string;
  companyWebSite?: string;
  companyBusinessPhone?: string;
  responsiblePersonJobTile?: string;
  responsiblePersonFirstName?: string;
  responsiblePersonLastName?: string;
  responsiblePersonEmail?: string;
  responsiblePersonBusinessPhone?: string;
  responsiblePersonMobilePhone?: string;
  favoriteCategories?: number[];
  isUpdated?: boolean;
  printNodeApiKey?: string | null;
}

interface DBClientOnePayload {
  id: string;
  active?: boolean;
}
interface DBClientListPayload {
  search: string;
  page?: number;
  pageSize?: number;
}

interface DBClientData {
  id: string;
  clientType: DBClientType;
  businessType: DBBusinessType;
  vatNumber: string;
  vies: boolean;
  euVatNumber: string | null;
  active: boolean;
  remark: string;
  addressId: string;
  officialName: string;
  shortName: string;
  slug?: string;
  referralId: string;
  address: DBAddress;
  IBAN: string | null;
  swift: string | null;
  currency?: DBCurrency | null;
  businessCode?: string | null;
  vatObligee?: string | null;
  intrastat?: string | null;
  registrationNumber?: string | null;
  viesVatNumber?: string | null;
  companyEmail?: string | null;
  companyWebSite?: string | null;
  companyBusinessPhone?: string | null;
  responsiblePersonJobTile?: string | null;
  responsiblePersonFirstName?: string | null;
  responsiblePersonLastName?: string | null;
  responsiblePersonEmail?: string | null;
  responsiblePersonBusinessPhone?: string | null;
  responsiblePersonMobilePhone?: string | null;
  isUpdated: boolean;
  printNodeApiKey?: string | null;
}

interface DBClientApi {
  list: {
    requestBody: never;
    request: DBClientListPayload;
    response: { data: DBClient[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBClientOnePayload;
    request: DBClientOnePayload;
    response: DBClientData & {
      users: DBUser[];
      companyContacts: DBCompanyContact[];
    };
  };
  create: {
    requestBody: DBCreateClientPayload;
    requestParams: never;
    request: DBCreateClientPayload;
    response: DBClient;
  };
  updateOne: {
    requestBody: DBUpdateClientPayload;
    requestParams: DBClientOnePayload;
    request: DBUpdateClientPayload | DBClientOnePayload;
    response: DBClient;
  };
}
export { DBClientApi };
