import {
  DBConnectionProtocol,
  DBConnectionType,
  DBFileFormat,
  DBRepeatUnit,
  DBSupplierConnection,
} from '@prisma/client';
import { DBSupplierApiConnectionApi } from './supplier-api-connection';
import { DBSupplierFtpConnectionApi } from './supplier-ftp-connection';
import { DBSupplierHttpsConnectionApi } from './supplier-https-connection';
import { DBSupplierEmailConnectionApi } from './supplier-email-connection';

interface DBUpsertSupplierConnectionsPayload {
  id?: string;
  connectionName: string;
  connectionTypes: DBConnectionType[];
  active: boolean;
  connectionProtocol: DBConnectionProtocol | string;
  fileFormat: DBFileFormat | string;
  rulesetKey: string | null;
  remark: string;
  ftpConnection?: DBSupplierFtpConnectionApi['create']['requestBody'];
  apiConnection?: DBSupplierApiConnectionApi['create']['requestBody'];
  httpsConnection?: DBSupplierHttpsConnectionApi['create']['requestBody'];
  emailConnection?: DBSupplierEmailConnectionApi['create']['requestBody'];
  minimumUpdateFrequencyUnit?: DBRepeatUnit;
  minimumUpdateFrequencyTime?: number;
}

interface DBSupplierConnectionApi {
  list: {
    requestBody: never;
    request: never;
    response: never[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierConnectionsPayload;
    requestParams: never;
    request: DBUpsertSupplierConnectionsPayload;
    response: DBSupplierConnection;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierConnectionApi };
