import { DBService, DBServiceCategory } from '@prisma/client';

export interface FixedPrice {
  fixedPrice: {
    exclusiveVat: number;
    inclusiveVat: number;
  };
}

export interface LaborTime {
  chargedByLaborTime: {
    laborTime: number;
  };
}

export interface FreeService {
  freeService: Record<string, never>;
}

export interface ServicePrice {
  servicePrice?: FixedPrice | LaborTime | FreeService;
}

export interface ObjectValues {
  label: string; // name
  value: string; // id
}

export interface ServicePriceShape {
  chargedByLaborTime?: LaborTime;
  fixedPrice?: FixedPriceShape;
}

export interface LaborTime {
  laborTime: number;
  pricePerHour: number;
  finalPrice: number;
}

export interface FixedPriceShape {
  exclusiveVat: number;
  inclusiveVat: number;
}

type CreateBody = Omit<
  DBService,
  'servicePrice' | 'subcategory' | 'rimDiameter' | 'serviceCodeId'
> &
  ServicePrice & { subcategory?: ObjectValues[] } & {
    rimDiameter?: ObjectValues[];
  };

type ServicesResponse = CreateBody;

type UpdateService = {
  id: string;
  code?: string;
  name?: string;
  description?: string;
  subcategory?: ObjectValues[];
  rimDiameter?: ObjectValues[];
  rft?: boolean;
  serviceCategoryId?: string;
  productCategoryId?: number;
} & ServicePrice;

interface ActiveStatus {
  id: string;
  active: boolean;
}

interface ServicePayload {
  search?: string;
  productEans?: string;
  branchServices?: string[];
}

export interface IServicesExcel {
  erpID: number;
  erpCode: string;
  mainCategory: string;
  name: string;
  fixedPrice: number;
  serviceDuration: number;
  crossSellCategory?: string;
  crossSellSubCategory?: string;
  rimDiameter?: string;
  rft?: string;
  description?: string;
}

export interface DBServicesApi {
  list: {
    requestBody: ServicePayload;
    requestParams: { branchId?: string };
    request: ServicePayload & { branchId?: string };
    response: (DBService & { serviceCategory: DBServiceCategory })[];
  };
  getOne: {
    requestBody: { id: string };
    requestParams: { id: string };
    request: { id: string };
    response: ServicesResponse;
  };
  create: {
    requestBody: CreateBody;
    requestParams: never;
    request: CreateBody;
    response: DBService;
  };
  updateOne: {
    requestBody: UpdateService;
    requestParams: { id: string };
    request: UpdateService;
    response: never;
  };
  recommended: {
    requestBody: never;
    request: { branchId?: string; eans: string[] };
    response: (DBService & { serviceCategory: DBServiceCategory })[];
  };
  activeStatus: {
    requestBody: ActiveStatus;
    requestParams: never;
    request: ActiveStatus;
    response: DBService;
  };
}
