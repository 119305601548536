import { DBProductCategory } from '@prisma/client';
import {
  CreateDBProductInput,
  CreateDBProductModelInput,
} from './product-import';

export interface DBProductCategoryTyped extends DBProductCategory {
  model_attributes: DBProductCategorymodelAttributes;
  item_attributes: DBProductCategoryProductAttributes;
}

interface DBProductCategoryWithChildren extends DBProductCategoryTyped {
  children?: DBProductCategory[];
}

interface DBCreateProductCategoryRequest {
  parent_category_id?: number;
  name: string;
  slug: string;
  is_tyrio_catalog: boolean;
  taric_code: string;
  remark: string;
  model_attributes?: JSON;
  item_attributes?: JSON;
  active: boolean;
  non_deletable: boolean;
  parent_category_name?: string;
}

interface DBUpdateProductCategoryRequest {
  parent_category_id?: number;
  name?: string;
  slug?: string;
  is_tyrio_catalog?: boolean;
  taric_code: string;
  remark?: string;
  model_attributes?: JSON;
  item_attributes?: JSON;
  active?: boolean;
  non_deletable?: boolean;
  parent_category_name?: string;
}

interface DBProductCategoryApi {
  list: {
    requestBody: DBGetProductCategoriesRequest;
    request: DBGetProductCategoriesRequest;
    response: DBProductCategoryWithChildren[];
  };
  getOne: {
    requestBody: never;
    requestParams: DBProductCategoryOnePayload;
    request: DBProductCategoryOnePayload;
    response: DBProductCategory;
  };
  create: {
    requestBody: DBCreateProductCategoryRequest;
    requestParams: never;
    request: Omit<DBProductCategory, 'id' | 'is_tyrio_catalog'>;
    response: DBProductCategory;
  };
  updateOne: {
    requestBody: DBUpdateProductCategoryRequest;
    requestParams: DBProductCategoryOnePayload;
    // eslint-disable-next-line
    request: any;
    response: DBProductCategory;
  };
  getSubcategory: {
    requestBody: never;
    requestParams: { categoryId: string };
    request: { categoryId: string };
    response: {
      id: number;
      name: string;
      parent_category_name: string;
      parent_category_id: number;
    }[];
  };
}

interface DBGetProductCategoriesRequest {
  search: string;
}

interface DBProductCategoryOnePayload {
  id: number;
  parent_category_id?: number;
}

/**
 * -1 = not used
 *
 *  0 = optional
 *
 *  1 = necessary
 *
 *  2 = mandatory
 */
type DBProductCategoryModelAttributeValue = -1 | 0 | 1 | 2;

type ProductKey = keyof CreateDBProductInput;
type ProductModelKey = keyof CreateDBProductModelInput;

type DBProductCategorymodelAttributes = Partial<
  Record<ProductModelKey, DBProductCategoryModelAttributeValue>
>;
type DBProductCategoryProductAttributes = Partial<
  Record<ProductKey, DBProductCategoryModelAttributeValue>
>;

export {
  DBProductCategoryApi,
  DBGetProductCategoriesRequest,
  DBProductCategoryWithChildren,
};
