interface DBUpsertSupplierClientB2BSettingsPayload {
  id?: string;
  b2bUrl: string;
  b2bUserName: string;
  b2bPassword: string;
}

interface DBSupplierClientB2BSettingsApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierClientB2BSettingsPayload;
    requestParams: never;
    request: DBUpsertSupplierClientB2BSettingsPayload;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierClientB2BSettingsApi };
