import { Grid } from '@mui/material';
import { DBSupplier } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBClientSupplierOrderItemFilters } from '@tyrio/dto';
import { useState } from 'react';
import { useQuery } from 'react-query';
import OrdersHeader from '../../../components/Orders/OrdersHeader';
import { ComponentType } from '../helpers/types';

interface PurchaseOrderTableFiltersProps {
  isSidebarOpen: boolean;
  filters: DBClientSupplierOrderItemFilters;
  setFilters: (value: DBClientSupplierOrderItemFilters) => void;
  setFilterValue?: (data: Partial<qs.ParsedQs>) => void;
}

const PurchaseOrderTableFilters = ({
  isSidebarOpen,
  filters,
  setFilters,
  setFilterValue,
}: PurchaseOrderTableFiltersProps) => {
  const [suppliers, setSuppliers] = useState<
    { value: string; label: string }[]
  >([]);

  useQuery(
    ['get_client_order_suppliers'],
    () =>
      api.fetch<{
        request: object;
        response: Partial<DBSupplier>[];
        requestBody: unknown;
      }>('get_client_order_suppliers'),
    {
      onSuccess: (data: DBSupplier[]) => {
        const dropDownData = data.map((i) => ({
          value: i.id as string,
          label: i.companyShortName,
        }));
        setSuppliers(dropDownData);
      },
    }
  );

  return (
    <Grid
      style={{ padding: '10px 0 15px 0', width: '100%', overflow: 'hidden' }}
    >
      <OrdersHeader
        filters={filters}
        isSidebarOpen={isSidebarOpen}
        setFilters={setFilters}
        suppliers={suppliers}
        componentType={ComponentType.purchase}
        setFilterValue={setFilterValue}
      />
    </Grid>
  );
};

export default PurchaseOrderTableFilters;
