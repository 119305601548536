import { DBFeatureConfig } from '@prisma/client';

interface DBFeatureConfigListPayload {
  foo: string;
}

interface DBFeatureConfigGetOneParams {
  featureId: string;
}

interface DBFeatureConfigApi {
  list: {
    requestBody: never;
    request: never;
    response: DBFeatureConfig[];
  };
  getOne: {
    requestBody: never;
    requestParams: DBFeatureConfigGetOneParams;
    request: DBFeatureConfigListPayload | DBFeatureConfigGetOneParams;
    response: DBFeatureConfig;
  };
}
export { DBFeatureConfigApi };
