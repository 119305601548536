import PurchaseOrderOverview from '../../features/purchase-orders/PurchaseOrderOverview';

interface PurchaseOrdersProps {
  sidebarOpen: boolean;
}

const PurchaseOrders = ({ sidebarOpen }: PurchaseOrdersProps) => {
  return <PurchaseOrderOverview sidebarOpen={sidebarOpen} />;
};

export default PurchaseOrders;
