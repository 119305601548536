export interface IVehicleBrand {
  logo: string;
  name: string;
  name_en: string;
  regions: string[];
  slug: string;
}

export interface VehicleBodies {
  image: string;
  name: string;
  slug: string;
}

export interface IVehicleGeneration {
  bodies: VehicleBodies[];
  end: number;
  name: string;
  platform: string;
  regions: string[];
  slug: string;
  start: number;
  year_ranger: string[];
  years: number[];
}

export interface IModifications {
  body: null;
  end_year: number;
  engine: {
    fuel: string;
    capacity: string;
    type: string;
    power: { kW: number; PS: number; hp: number };
    code: string;
  };
  generation: Record<string, string | number>;
  make: Record<string, string>;
  model: Record<string, string>;
  name: string;
  regions: string[];
  slug: string;
  start_year: number;
  trim: string;
  trim_attributes: string[];
  trim_body_types: string[];
  trim_levels: string[];
  trim_scoring: null;
}

export interface VehicleModel {
  name: string;
  name_en: string;
  regions: string[];
  slug: string;
  year_ranges: string[];
}

export interface WheelDetails {
  tire_pressure: null | number;
  rim: string;
  rim_diameter: null | number;
  rim_width: null | number;
  rim_offset: null | number;
  tire: string;
  tire_sizing_system: string | null;
  tire_construction: string | null;
  tire_width: null | number;
  tire_aspect_ratio: null | number;
  tire_diameter: null | number;
  tire_section_width: null | number;
  tire_is_82series: boolean;
  load_index: null | number;
  speed_index: string | null;
}

export interface Wheel {
  is_stock: boolean;
  showing_fp_only: boolean;
  is_recommended_for_winter: boolean;
  is_runflat_tires: boolean;
  is_pressed_steel_rims: boolean;
  front: WheelDetails;
  rear: WheelDetails;
}

export interface IWheelsApiData {
  slug: string;
  name: string;
  body: null | string;
  trim: string;
  trim_scoring: null | string;
  trim_attributes: unknown;
  trim_body_types: unknown;
  trim_levels: string[];
  make: Record<string, string>;
  model: Record<string, string>;
  generation: Record<string, string | number | Record<string, string>>;
  start_year: number;
  end_year: number;
  engine: Record<string, string | Record<string, string | number>>;
  regions: string[];
  technical: Record<string, null | string | number | Record<string, string>>;
  tire_type: string;
  wheels: Wheel[];
}

export interface WheelSizeApi {
  getBrands: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: { data: IVehicleBrand[]; meta: { count: number } };
  };
  getOneGeneration: {
    requestBody: never;
    requestParams: { make: string; model: string };
    request: { make: string; model: string };
    response: { data: IVehicleGeneration[]; meta: { count: number } };
  };
  getOneModel: {
    requestBody: never;
    requestParams: { model: string };
    request: { model: string };
    response: { data: VehicleModel[]; meta: { count: number } };
  };
  getOneModification: {
    requestBody: never;
    requestParams: { make: string; model: string; generation: string };
    request: { make: string; model: string; generation: string };
    response: { data: IModifications[]; meta: { count: number } };
  };
  getSearch: {
    requestBody: never;
    requestParams: {
      make: string;
      model: string;
      generation: string;
      modification: string;
    };
    request: {
      make: string;
      model: string;
      generation: string;
      modification: string;
    };
    response: {
      data: IWheelsApiData[];
      meta: { count: number; regions: Record<string, number> };
    };
  };
}
