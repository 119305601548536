/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '@tyrio/api-factory';
import { DBAppointmentApi, GetFittingResponse } from '@tyrio/dto';
import { useQuery } from 'react-query';

export const useGetFitting = (
  enabled: boolean,
  setWorkOrders: any,
  setReadyWorkOrders: any
) => {
  const { data, isFetched, refetch, isLoading, status } = useQuery(
    ['get_fitting'],
    () => api.fetch<DBAppointmentApi['get_fitting']>('get_fitting'),
    {
      enabled,
      onSuccess: (res) => {
        setWorkOrders((prevState: any) => ({
          ...prevState,
          FITTING: (res as GetFittingResponse).today,
        }));

        setReadyWorkOrders((prevState: any) => ({
          ...prevState,
          FITTING: (res as GetFittingResponse).tomorrow,
        }));
      },
    }
  );
  return {
    data: data,
    isFetched,
    refetch,
    status,
    isLoading,
  };
};
