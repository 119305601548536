export interface CreateShipment {
  ParcelList: Parcel[];
  ShowPrintDialog: boolean;
  TypeOfPrinter: string;
}

export interface PrintLabelsInfo {
  ClientReference: string;
  ParcelId: number;
  ParcelNumber: number;
}

export interface Parcel {
  ClientReference?: string;
  Count: number;
  Content: string;
  CODAmount: number;
  DeliveryAddress: Address;
  PickupAddress: Address;
  Username?: string;
}

interface Address {
  City: string;
  ContactEmail: string;
  ContactPhone: string;
  ContactName: string;
  CountryIsoCode: string;
  HouseNumber?: string;
  Name: string;
  Street: string;
  ZipCode: string;
}

export interface TrackShipment {
  match: number;
}
