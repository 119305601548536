import { Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SaveIcon from '@mui/icons-material/Save';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';

export const OrderSidebarLayoutButtons = (
  orderId: string,
  handleOnCancel: () => void,
  handleSave: () => void,
  isButtonDisabled: boolean,
  _handleNextStep?: (step: string) => void,
  cancelText?: string,
  handlePrint?: () => void
) => {
  const history = useHistory();

  const buttons = {
    customer: (
      <>
        <Button
          variant="text"
          color="error"
          onClick={() => {
            handleOnCancel();
          }}
          disabled={isButtonDisabled}
        >
          {cancelText ?? 'Cancel'}
        </Button>

        <ButtonWrapper>
          <Button
            variant="outlined"
            color="info"
            onClick={handleSave}
            style={{ gap: '11px' }}
            disabled={isButtonDisabled}
            disableElevation
          >
            <SaveIcon /> Save
          </Button>
          <Button
            disabled={isButtonDisabled}
            variant="contained"
            color="info"
            id="process_order_button"
            onClick={() => history.push(`sales-orders/${orderId}`)} //NOTE: this is for testing purpose only, it will change later on
            style={{ gap: '16px' }}
            disableElevation
          >
            Process the order
            <ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />
          </Button>
        </ButtonWrapper>
      </>
    ),
    purchase: (
      <>
        <Wrapper>
          <Button
            variant="text"
            color="error"
            onClick={handleOnCancel}
            disabled={isButtonDisabled}
          >
            Cancel
          </Button>
          <Button variant="outlined" color="info" onClick={handlePrint}>
            PRINT BARCODE
          </Button>
        </Wrapper>
        <Button
          variant="contained"
          color="info"
          onClick={handleSave}
          disabled={isButtonDisabled}
          disableElevation
        >
          Save
        </Button>
      </>
    ),
  };
  return buttons;
};

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;
