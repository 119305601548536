import { DBService } from '@prisma/client';

export interface DeliveryAddressShape {
  customer: string;
  recipientName: string;
  address: string;
  zipCode: string;
  city: string;
  mobilePhone?: string;
}

export interface BillingAddressShape {
  customer: string;
  vat: string;
  address: string;
  zipCode: string;
  city: string;
  email?: string;
}

type DocumentType = 'OTPREMNICA' | 'MEĐUSKLADIŠNICA' | 'WORK ORDER';

export interface LineItemPrintShape {
  ean: string;
  productName: string;
  sku: string;
  brand: string;
  dot: string;
  quantity: number;
  weight: string;
  category: string;
}

export interface ProductsTableProperties {
  showWeight: boolean;
  showQuantity: boolean;
  showTotalWeight: boolean;
  showTotalFooter: boolean;
  columnCount: number;
}

export interface AppointmentDetails {
  id: string;
  appointmentDateFrom: Date | undefined;
  box: string;
  qrCode: string;
  services: (DBService & { quantity: number })[] | undefined;
}

export interface RowDetail {
  name: string;
  details: string;
  weight?: number;
  quantity: number;
  totalWeight?: number;
}

export interface PrintDataShape {
  data: {
    documentsLook: {
      logoUrl: string;
      headerText: string;
      mainTerms: string;
      paymentTerms: string;
      footerText: string;
    };
    header: {
      documentType: DocumentType;
      documentNumber: string;
      qrCode: string;
    };
    address: {
      showDeliveryAddress: boolean;
      showBillingAddress: boolean;
      deliveryAddress?: DeliveryAddressShape;
      billingAddress?: BillingAddressShape;
    };
    table: ProductsTableProperties;
    sourceBranch: {
      branchName: string;
      city: string;
    };
    appointmentsDetails: {
      hasAppointments: boolean;
      appointments?: AppointmentDetails[];
    };
    orderDetails: {
      lineItems: LineItemPrintShape[];
      orderNumber: string;
      deliveryDate: Date;
      deliveryMethod: string;
    };
    shippingDetails: {
      showShippingDetails: boolean;
      recipientName: string;
      city: string;
      hasSideBorders: boolean;
      deliveryMethod: string;
      shippingCourier?: string;
      nrOfParcels: number;
    };
    issuedBy: string;
    showRemark: boolean;
    remark: string;
    reference: string;
    showConfirmationHeader: boolean;
  };
}

interface PdfApi {
  getOne: {
    requestBody: never;
    requestParams: { key: string };
    request: { key: string };
    response: string;
  };
}
export { PdfApi };
