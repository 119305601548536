import { DBShippingCourier } from '@prisma/client';

export interface ShippingCourierPayload {
  name: string;
  abbreviation: string;
  shippingWebsite: string;
}

export interface ShippingCourierApi {
  list: {
    request: never;
    requestBody: never;
    response: DBShippingCourier[];
  };
  create: {
    request: never;
    requestBody: ShippingCourierApi;
    response: DBShippingCourier;
  };
}
