import { Badge, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DBStockInItemStatus } from '@prisma/client';
import qs from 'qs';
import { useState } from 'react';
import { getStockInOrderStatusColor } from '../../purchase-orders/helpers/generate-color';
import { DBStockInOrderItemCountByStatus } from '@tyrio/dto';

interface TabsFilterProps {
  filters: qs.ParsedQs;
  setFilters: (value: Partial<qs.ParsedQs>) => void;
  countOrders: DBStockInOrderItemCountByStatus[];
}

export const STOCK_IN_ORDER_STATUSES = [
  {
    value: 'all',
    label: 'ALL',
    color: 'red',
    width: '30px',
  },
  {
    value: 'new',
    label: 'NEW',
    color: '#10B981',
    width: '60px',
  },
  {
    value: DBStockInItemStatus.UNCONFIRMED,
    label: 'UNCONFIRMED',
    color: '#FFCC4D',
    width: '120px',
  },
  {
    value: DBStockInItemStatus.CONFIRMED,
    label: 'CONFIRMED',
    color: '#3EB274',
    width: '110px',
  },
];

const TabsFilter = ({ filters, setFilters, countOrders }: TabsFilterProps) => {
  const [shouldResetTabs, setShouldResetTabs] = useState(false);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: DBStockInItemStatus | 'new'
  ) => {
    setFilters({
      ...filters,
      orderStatus: newValue,
    });
    setShouldResetTabs(false);
  };

  const getOrderCount = (orderStatus: DBStockInItemStatus) => {
    return (
      countOrders?.find((item) => item.status === orderStatus)?._count ?? 0
    );
  };

  const getNewCount = () => {
    const unconfirmed =
      countOrders?.find((item) => item.status === 'UNCONFIRMED')?._count ?? 0;
    const confirmed =
      countOrders?.find((item) => item.status === 'CONFIRMED')?._count ?? 0;
    return unconfirmed + confirmed ?? 0;
  };

  // leave this commented for now
  // useEffect(() => {
  //   setFilters({
  //     ...filters,
  //     orderStatus: 'all',
  //   });

  //   setShouldResetTabs(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters['searchKeyword']]);

  return (
    <Grid
      sx={{
        width: '100%',
        overflow: 'auto',
      }}
    >
      <TabsStyled
        value={shouldResetTabs ? 'all' : filters['orderStatus']}
        onChange={handleChange}
        scrollButtons={false}
        variant="scrollable"
      >
        {STOCK_IN_ORDER_STATUSES.map((status) => {
          const orderCount = getOrderCount(
            DBStockInItemStatus[
              status.value as keyof typeof DBStockInItemStatus
            ]
          );

          const color =
            status.label !== 'NEW'
              ? getStockInOrderStatusColor[
                  DBStockInItemStatus[
                    status.value as keyof typeof DBStockInItemStatus
                  ]
                ]
              : STOCK_IN_ORDER_STATUSES[1].color;

          return (
            <TabStyled
              value={status.value}
              iconPosition="start"
              sx={{
                '&.Mui-disabled': {
                  pointerEvents: 'auto',
                },
                '&:hover': {
                  color: '#1976D2',
                  opacity: 1,
                },
              }}
              key={status.label}
              icon={
                <Grid
                  sx={{
                    width: status.width,
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                    }}
                  >
                    {status.label}
                  </Typography>

                  {((status.label !== 'NEW' && orderCount > 0) ||
                    (status.label === 'NEW' && getNewCount() > 0)) && (
                    <Badge
                      badgeContent={
                        status.label !== 'NEW' ? orderCount : getNewCount()
                      }
                      sx={{
                        '& .MuiBadge-badge': {
                          color: 'white',
                          backgroundColor: color,
                        },
                      }}
                    />
                  )}
                </Grid>
              }
            />
          );
        })}
      </TabsStyled>
    </Grid>
  );
};

const TabsStyled = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1976D2',
  },
});

const TabStyled = styled(Tab)({
  '&.Mui-selected': {
    color: '#1976D2',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#1976D2',
    color: '#1976D2',
  },
});

export default TabsFilter;
