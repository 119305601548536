import { Chip, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { DBOrderStatus } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBClientSupplierOrderItemApi, DBOrderDocumentApi } from '@tyrio/dto';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { getDropdownLabel } from '../../../../../../helpers/enum-parser';
import {
  TabPanelProps,
  tabProps,
  TabsStyled,
  TabStyled,
} from '../../../../components/Tabs/Tabs';
import UploadDocuments from '../../../../components/UploadDocuments/UploadDocuments';
import { useDocuments } from '../../../../hooks/useDocuments';
import { getOrderStatusColor } from '../../helpers/generate-color';
import { PurchaseOrderDetailsOrderTab } from './OrderDetailsTab';

interface PurchaseOrderDetailsTabsProps {
  selectedOrderData: DBClientSupplierOrderItemApi['getOne']['response'] | null;
  setSelectedOrderData: (
    value: DBClientSupplierOrderItemApi['getOne']['response'] | null
  ) => void;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  setIsUploadInProcess: Dispatch<SetStateAction<boolean>>;
}

const OrderDetailsSidebarTabs = ({
  selectedOrderData,
  setSelectedOrderData,
  setIsDirty,
  setIsUploadInProcess,
}: PurchaseOrderDetailsTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const onSetSelectedOrderData = (
    value: DBClientSupplierOrderItemApi['getOne']['response'] | null
  ) => {
    setSelectedOrderData(value);
    setIsDirty && setIsDirty(true);
  };

  // get order documents
  const { data: orderDocuments, refetch } = useQuery(
    ['get_order_documents', selectedOrderData?.id],
    () =>
      api.fetch<DBOrderDocumentApi['list']>(`get_order_documents`, {
        id: selectedOrderData?.id,
        type: 'purchase',
      }),
    {
      enabled: !!selectedOrderData?.id,
    }
  );

  const { uploadOrderDocuments, deleteOrderDocument } = useDocuments(
    selectedOrderData,
    refetch,
    setIsUploadInProcess,
    'Purchase'
  );

  const onOpen = useCallback(
    (acceptedFiles: File[]) => {
      setIsUploadInProcess(true);
      const formData = new FormData();
      acceptedFiles.forEach((file) => formData.append('file', file, file.name));
      uploadOrderDocuments.mutate({
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@@apiToken')}`,
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
          type: 'purchase',
        },
        data: formData,
      });
    },
    [setIsUploadInProcess, uploadOrderDocuments]
  );

  const onDelete = (id: string) => {
    deleteOrderDocument.mutate(id);
  };

  return (
    selectedOrderData && (
      <Grid>
        <Grid
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            padding: '16px 16px 0 16px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%',
                position: 'sticky',
                top: 0,
              }}
            >
              <Box>
                <TabsStyled
                  value={activeTab}
                  onChange={(_e, v) => setActiveTab(v)}
                >
                  <TabStyled label="Order" {...tabProps(0)} />
                  <TabStyled label="Related documents" {...tabProps(0)} />
                </TabsStyled>
              </Box>
              <Box>
                <Chip
                  size="small"
                  label={getDropdownLabel(
                    DBOrderStatus,
                    selectedOrderData.orderStatus
                  ).toUpperCase()}
                  sx={{
                    marginRight: '20px',
                    background:
                      getOrderStatusColor(
                        DBOrderStatus[
                          selectedOrderData?.orderStatus as keyof typeof DBOrderStatus
                        ]
                      ) ?? 'white',
                    color: 'white',
                  }}
                />
                <Chip
                  size="small"
                  color="info"
                  label={selectedOrderData.connectionProtocol}
                  variant="outlined"
                />
              </Box>
            </Grid>

            <TabContent value={activeTab} index={0}>
              <PurchaseOrderDetailsOrderTab
                selectedOrderData={selectedOrderData}
                setSelectedOrderData={onSetSelectedOrderData}
              />
            </TabContent>
            <TabContent value={activeTab} index={1}>
              <UploadDocuments
                orderDocuments={orderDocuments}
                onOpen={onOpen}
                onDelete={onDelete}
                isLoading={uploadOrderDocuments.isLoading}
              />
            </TabContent>
          </Box>
        </Grid>
      </Grid>
    )
  );
};

const TabContent = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <Grid hidden={value !== index} {...other}>
      {value === index && (
        <Box
          sx={{
            paddingTop: '16px',
            height: '100%',
          }}
        >
          {children}
        </Box>
      )}
    </Grid>
  );
};

export default OrderDetailsSidebarTabs;
