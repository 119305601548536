/* eslint-disable @typescript-eslint/no-empty-function */
import { InboundInvoiceResponse, isEUCountry } from '@tyrio/dto';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';

export enum VAT {
  R1 = 'Pdv obračunat',
  E1 = 'Prijenos porezne obveze',
  NF = 'Otpremnica',
}

export function getVATKey(value: string): keyof typeof VAT | null {
  for (const key of Object.keys(VAT))
    if (VAT[key as keyof typeof VAT] === value) return key as keyof typeof VAT;

  return null;
}

export interface InputShape {
  ids: string;
  checkedStockIns: Record<string, boolean>;
}

interface IInboundInvoiceContext {
  remark: string;
  setRemark: (a: string) => void;
  preparedInvoices: InboundInvoiceResponse | null;
  setPreparedInvoices: Dispatch<SetStateAction<InboundInvoiceResponse | null>>;
  input: InputShape;
  setInput: Dispatch<SetStateAction<InputShape>>;
  isVatIncluded: boolean;
  setIsVatIncluded: Dispatch<SetStateAction<boolean>>;
  splitInvoice: boolean;
  setSplitInvoice: Dispatch<SetStateAction<boolean>>;
  isEU: boolean;
}

export const InboundInvoiceContext =
  React.createContext<IInboundInvoiceContext>({
    remark: '',
    setRemark: () => {},
    preparedInvoices: null,
    setPreparedInvoices: () => {},
    input: { ids: '', checkedStockIns: {} },
    setInput: () => {},
    isVatIncluded: false,
    setIsVatIncluded: () => {},
    splitInvoice: false,
    setSplitInvoice: () => {},
    isEU: false,
  });

interface InboundInvoiceProviderProps {
  children: React.ReactNode;
}

const InboundInvoiceProvider = ({ children }: InboundInvoiceProviderProps) => {
  const [remark, setRemark] = useState('');
  const [isVatIncluded, setIsVatIncluded] = useState(false);
  const [preparedInvoices, setPreparedInvoices] =
    useState<InboundInvoiceResponse | null>(null);

  const [input, setInput] = useLocalStorage<InputShape>('@@SI', {
    ids: '',
    checkedStockIns: {},
  });

  const [splitInvoice, setSplitInvoice] = useState(false);

  const dispatchingCountry = preparedInvoices?.partner?.countryId as string;

  const isEU = isEUCountry(dispatchingCountry);

  return (
    <InboundInvoiceContext.Provider
      value={{
        remark,
        setRemark,
        preparedInvoices,
        setPreparedInvoices,
        input,
        setInput,
        isVatIncluded,
        setIsVatIncluded,
        splitInvoice,
        setSplitInvoice,
        isEU,
      }}
    >
      {children}
    </InboundInvoiceContext.Provider>
  );
};

export default InboundInvoiceProvider;

export const useInboundInvoiceCtx = () =>
  React.useContext(InboundInvoiceContext);
