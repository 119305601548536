import {
  DBService,
  DBServiceCategory,
  DBUnitOfMeasurment,
} from '@prisma/client';

interface DBServiceCategoryRequestBody {
  search?: string;
  page?: number;
  pageSize?: number;
}

interface UpdateBody {
  id: string;
  name?: string;
  description?: string;
  vatRate?: string;
  unitOfMeasurment?: DBUnitOfMeasurment;
  generalLedgeAcc?: string;
  code?: string;
}

interface ActiveStatus {
  id: string;
  active: boolean;
}

export interface DBServiceCategoryApi {
  list: {
    requestBody: DBServiceCategoryRequestBody;
    request: DBServiceCategoryRequestBody;
    response: {
      data: (DBServiceCategory & { services: DBService[] })[];
      count: number;
    };
  };
  getOne: {
    requestBody: never;
    requestParams: { id: string };
    request: { id: string };
    response: DBServiceCategory;
  };
  create: {
    requestBody: Omit<DBServiceCategory, 'clientId'>;
    requestParams: never;
    request: Omit<DBServiceCategory, 'clientId'>;
    response: DBServiceCategory;
  };
  updateOne: {
    requestBody: UpdateBody;
    requestParams: never;
    request: UpdateBody;
    response: never;
  };
  activeStatus: {
    requestBody: ActiveStatus;
    requestParams: never;
    request: ActiveStatus;
    response: DBServiceCategory;
  };
  deleteOne: {
    requestBody: { id: string };
    requestParams: { id: string };
    request: { id: string };
    response: DBServiceCategory;
  };
  getByBranch: {
    requestBody: never;
    requestParams: { branchId: string };
    request: { branchId: string };
    response: DBServiceCategory[];
  };
}
