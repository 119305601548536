import styled from '@emotion/styled';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { PosItem } from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { POSContext } from '../../../../context/POSContext';
import { useFilter } from '../../../../hooks/useFilter';
import NoData from '../../components/DataIndicators/NoData';
import SidebarFiltersList from '../../components/FilterSidebarList';
import { useAccordion } from '../../helpers/accordion';
import { useGetPosData } from '../../query/get-pos-data';
import PosLoading from '../DataIndicators/Loading';
import { PosRowCell } from './Row';
import { RowItem } from './RowItem';

interface PosTableProps {
  onOpenSidebarFilters: () => void;
}

export const PosTable = ({ onOpenSidebarFilters }: PosTableProps) => {
  const { PosTableItems } = useAccordion();
  const { filterValues, setFilterValue } = useFilter();
  const [shouldLoadMore, setShouldLoadMore] = useState(false);
  const {
    myStockItemsStatus,
    branchesStockItemsStatus,
    supplierStockItemsStatus,
  } = useGetPosData(shouldLoadMore, setShouldLoadMore);

  const { myStockItems, branchesStockItems, supplierStockItems } =
    useContext(POSContext);

  const renderRows = (arr: PosItem[]) => {
    return arr.length > 0 ? (
      <Grid>
        {arr.map((posItem, index) => (
          <RowItem key={`${posItem.id}_${index}`} posItem={posItem} />
        ))}
      </Grid>
    ) : (
      <NoDataAlertWrapper>
        <NoData />
      </NoDataAlertWrapper>
    );
  };

  const isFilterInactive = _.isEmpty(filterValues);

  return (
    <Table>
      <PosTableHead>
        <PosTableHeadRow>
          <PosTableCell>
            <SidebarFilterWrapper>
              <SidebarFiltersList onOpen={onOpenSidebarFilters} />
            </SidebarFilterWrapper>
            <PosTableHeadWrapper>
              <PosTableHeadItem>Price</PosTableHeadItem>
              <PosTableHeadItem>Quantity</PosTableHeadItem>
            </PosTableHeadWrapper>
          </PosTableCell>
        </PosTableHeadRow>
      </PosTableHead>
      <TableBody sx={{ padding: 0 }} id="hide">
        <PosRowCell>
          <Grid>
            {myStockItemsStatus === 'loading' && !isFilterInactive && (
              <PosLoading />
            )}
            {myStockItems && (
              <Grid>
                <PosTableItems posItemKey="currentBranchStock">
                  {renderRows(myStockItems)}
                </PosTableItems>
              </Grid>
            )}
          </Grid>
        </PosRowCell>

        <PosRowCell>
          <Grid>
            {branchesStockItemsStatus === 'loading' && !isFilterInactive && (
              <PosLoading />
            )}
            {branchesStockItems && (
              <Grid>
                <PosTableItems posItemKey="branchesStock">
                  {renderRows(branchesStockItems)}
                </PosTableItems>
              </Grid>
            )}
          </Grid>
        </PosRowCell>

        <PosRowCell>
          <Grid>
            {supplierStockItemsStatus === 'loading' && !isFilterInactive && (
              <PosLoading />
            )}
            {supplierStockItems && (
              <Grid>
                <PosTableItems posItemKey="supplierStock">
                  <Grid>
                    {renderRows(supplierStockItems)}
                    <PosTableLoadMoreWrapper>
                      {supplierStockItems.length > 0 &&
                        supplierStockItems.length === 10 && (
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => {
                              const page =
                                Number(filterValues['supplierStockPage'] ?? 1) +
                                1;
                              setFilterValue({
                                supplierStockPage: page.toString(),
                              });
                              setShouldLoadMore(true);
                            }}
                          >
                            LOAD MORE
                          </Button>
                        )}
                    </PosTableLoadMoreWrapper>
                  </Grid>
                </PosTableItems>
              </Grid>
            )}
          </Grid>
        </PosRowCell>
      </TableBody>
    </Table>
  );
};

const PosTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const PosTableCell = styled(TableCell)`
  padding: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
`;

export const SidebarFilterWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - 140px);
`;

const PosTableHeadWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 140px;
  width: 100%;
`;

const PosTableHeadRow = styled(TableRow)`
  background: #fff;
`;

const PosTableHeadItem = styled.span`
  color: ${tyrioUI.colors.black.B90};
  font-size: 14px;
`;

const PosTableLoadMoreWrapper = styled.span`
  padding: 16px;
  display: flex;
  justify-content: center;
`;

const NoDataAlertWrapper = styled.div`
  padding: 24px;
`;
