import styled from '@emotion/styled/macro';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  CustomSummaryShape,
  useInboundInvoiceCtx,
} from '../../../context/InboundInvoiceContext';
import { useCalculatePrice } from '../helpers/calculation';

const handlePreventNegativeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  // allow only numbers and one decimal point
  const regex = /^[0-9]*\.?[0-9]*$/;
  if (!regex.test(value)) e.preventDefault();

  if (!isNaN(Number(value)) && Number(value) < 0) e.target.value = '0';
};

const renderTextField = (
  text: string,
  value: string,
  setValue: Dispatch<SetStateAction<CustomSummaryShape>>,
  amountToCheck: string,
  index: string,
  totalAmountIcon: Record<string, 'success' | 'error' | undefined>,
  setTotalAmounIcon: Dispatch<
    SetStateAction<Record<string, 'success' | 'error' | undefined>>
  >
) => {
  return (
    <TextField
      label={text}
      variant="outlined"
      autoComplete="off"
      value={value}
      onChange={(e) => {
        const newVal =
          e.target.value !== '0'
            ? e.target.value.replace(/^0+/, '').replace('.', ',')
            : '0';

        setValue((prevState) => ({
          ...prevState,
          [index]: newVal,
        }));

        if (newVal === amountToCheck.toString().replace('.', ','))
          setTotalAmounIcon((prevState) => ({
            ...prevState,
            [index]: 'success',
          }));
        else
          setTotalAmounIcon((prevState) => ({
            ...prevState,
            [index]: 'error',
          }));
      }}
      helperText={
        !totalAmountIcon[index] || totalAmountIcon[index] === 'success'
          ? ''
          : 'Total amount doesn’t match'
      }
      inputMode="decimal"
      type="string"
      InputProps={{
        inputProps: {
          onInput: handlePreventNegativeInput,
        },
        endAdornment: (
          <InputAdornment position="start">
            {totalAmountIcon[index] && totalAmountIcon[index] === 'success' ? (
              <CheckCircleIcon color="success" />
            ) : totalAmountIcon[index] ? (
              <WarningIcon color="error" />
            ) : null}
          </InputAdornment>
        ),
      }}
    />
  );
};

export const CustomSummary = () => {
  const {
    splitInvoice,
    setSplitInvoice,
    preparedInvoices,
    customSummary,
    setCustomSummary,
  } = useInboundInvoiceCtx();

  const { grandTotal, vatAmount, subTotalExlVat } = useCalculatePrice(
    preparedInvoices?.items ?? [],
    preparedInvoices?.vatChargeType ?? 'V05'
  );

  const [totalAmountIcon, setTotalAmounIcon] = useState<
    Record<string, 'success' | 'error' | undefined>
  >({ subtotalExlVat: undefined, vatAmount: undefined, grandTotal: undefined });

  return (
    <DashedWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text>{'Enter amounts from suppliers invoice'}</Text>

        <FormControlLabel
          control={
            <Switch
              data-cy="switch"
              color="info"
              size="small"
              onChange={() => setSplitInvoice(!splitInvoice)}
              checked={splitInvoice}
              disabled={false}
            />
          }
          label={'split invoice'}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Grid xs={6} lg={6}>
            {renderTextField(
              'Subtotal amount excl. VAT',
              customSummary.subtotalExlVat.toString(),
              setCustomSummary,
              subTotalExlVat.toFixed(2),
              'subtotalExlVat',
              totalAmountIcon,
              setTotalAmounIcon
            )}
          </Grid>
          <Grid xs={6} lg={6}>
            {renderTextField(
              'VAT amount',
              customSummary.vatAmount.toString(),
              setCustomSummary,
              vatAmount.toFixed(2),
              'vatAmount',
              totalAmountIcon,
              setTotalAmounIcon
            )}
          </Grid>
        </div>

        <Grid xs={12} lg={12}>
          {renderTextField(
            'Grand total amount',
            customSummary.grandTotal.toString(),
            setCustomSummary,
            grandTotal.toFixed(2),
            'grandTotal',
            totalAmountIcon,
            setTotalAmounIcon
          )}
        </Grid>
      </div>
    </DashedWrapper>
  );
};

const DashedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px dashed #e6e6e6;
  border-radius: 8px;
  padding: 16px;
  gap: 20px;
`;

const Text = styled.div`
  color: #919eab;
  text-overflow: ellipsis;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
