import styled from '@emotion/styled';
import HelpIcon from '@mui/icons-material/Help';
import KeyIcon from '@mui/icons-material/Key';
import SettingsIcon from '@mui/icons-material/Settings';
import CachedIcon from '@mui/icons-material/Cached';
import { Avatar, Chip, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LogOutModal } from '../Modal/Modal';
import logoutIcon from './../../../assets/other-icons/logout.svg';
import { LoggedUser } from './Footer';

interface SettingsProps {
  loggedUser: LoggedUser;
  onLogout: () => void;
}

interface MenuItems {
  key: SettingsBarItem;
  icon: JSX.Element;
  label: string;
  clientRouteTo: string;
  adminRouteTo: string;
  disabled: boolean;
}

enum SettingsBarItem {
  MY_PROFILE,
  SETTINGS,
  HELP_AND_SUPPORT,
  RESET_APP,
  API_KEYS,
}

const menuItems = () =>
  [
    {
      key: SettingsBarItem.SETTINGS,
      icon: <SettingsIcon color="primary" />,
      label: 'Settings',
      clientRouteTo: '/dashboard/company-settings',
      adminRouteTo: '/dashboard/roles',
      disabled: false,
    },
    {
      key: SettingsBarItem.API_KEYS,
      icon: <KeyIcon color="primary" />,
      label: 'API keys',
      clientRouteTo: '/dashboard/api-keys',
      adminRouteTo: '/dashboard/api-keys',
      disabled: false,
    },
    {
      key: SettingsBarItem.RESET_APP,
      icon: <CachedIcon color="primary" />,
      label: 'Reset app',
      clientRouteTo: '',
      adminRouteTo: '',
      disabled: false,
    },
    {
      key: SettingsBarItem.HELP_AND_SUPPORT,
      icon: <HelpIcon color="primary" />,
      label: 'Help and Support',
      clientRouteTo: '',
      adminRouteTo: '',
      disabled: true,
    },
  ] as MenuItems[];

const SettingsBar = ({ loggedUser, onLogout }: SettingsProps) => {
  const history = useHistory();

  const [selected, setSelected] = useState<SettingsBarItem | undefined>(
    undefined
  );
  const [isLogOutModalVisible, setIsLogOutModalVisible] = useState(false);

  const handleLogout = () => {
    setIsLogOutModalVisible(false);
    onLogout();
  };

  const handleResetApp = () => {
    const apiToken = window.localStorage.getItem('@@apiToken');
    const version = window.localStorage.getItem('@@V');
    window.localStorage.clear();
    if (apiToken && apiToken !== '')
      window.localStorage.setItem('@@apiToken', apiToken);
    if (version && version !== '') window.localStorage.setItem('@@V', version);
  };

  return (
    <Container>
      {isLogOutModalVisible && (
        <LogOutModal
          LBAction={() => setIsLogOutModalVisible(false)}
          RBAction={() => handleLogout()}
        />
      )}
      <UserInfoWrapper>
        <Avatar>
          {loggedUser.firstName.charAt(0)}
          {loggedUser.lastName.charAt(0)}
        </Avatar>
        <UserNameRoleInfo>
          <Username>
            {loggedUser.firstName} {loggedUser.lastName}
          </Username>
          <UserRole>{loggedUser.role.toUpperCase()}</UserRole>
        </UserNameRoleInfo>
      </UserInfoWrapper>
      <Divider />
      <MenuItemsWrapper>
        {menuItems().map((menuItem) => (
          <MenuItemStyled
            key={menuItem.key}
            isSelected={menuItem.key === selected}
            isDisabled={menuItem.disabled}
            onClick={() => {
              if (!menuItem.disabled) {
                setSelected(menuItem.key);
                loggedUser.role === 'ADMIN' || loggedUser.role === 'Super Admin'
                  ? history.push(menuItem.adminRouteTo)
                  : history.push(menuItem.clientRouteTo);
              }
              if (menuItem.key === SettingsBarItem.RESET_APP) handleResetApp();
            }}
          >
            <MenuItemContent>
              {menuItem.icon}
              <MenuItemTypography>{menuItem.label}</MenuItemTypography>
            </MenuItemContent>
            {menuItem.disabled && (
              <Chip color="warning" size="small" label="SOON" />
            )}
          </MenuItemStyled>
        ))}
      </MenuItemsWrapper>
      <Divider />
      <LogoutContainer
        data-cy="logout-button-container"
        onClick={() => setIsLogOutModalVisible(true)}
      >
        <LogoutIconTypographyWrapper>
          <LogoutIcon src={logoutIcon} alt="logout" />
          <LogoutTypography>Logout</LogoutTypography>
        </LogoutIconTypographyWrapper>
        <VersionTypography>
          {`${window.localStorage.getItem('@@V')} ` ?? 'TYRIO v1.1.0-rc1'}
        </VersionTypography>
      </LogoutContainer>
    </Container>
  );
};

const Container = styled(motion.div)`
  color: black;
  width: 300px;
  position: inherit;
  background: #ffffff;
  border: 1px solid #d1d5db;
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(100, 116, 139, 0.12);
  border-radius: 8px;
  cursor: default;
`;

const UserInfoWrapper = styled(motion.div)`
  padding: 16px;
  display: flex;
`;

const UserNameRoleInfo = styled(motion.div)`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

const Username = styled(motion.span)`
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
`;

const UserRole = styled(motion.span)`
  font-size: 12px;
  line-height: 16px;
  color: #919eab;
`;

const MenuItemsWrapper = styled(motion.div)`
  padding: 16px;
`;

const MenuItem = styled(motion.div)<{
  isSelected: boolean;
  isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 13px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: ${(props) => (props.isSelected ? '#ECF0F4' : '#fff')};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.isDisabled ? 0.15 : '')};
`;

const MenuItemContent = styled(motion.div)`
  display: flex;
  align-items: center;
`;

const MenuItemTypography = styled(motion.span)`
  font-weight: 500;
  font-size: 16px;
  color: #212b36;
  margin-left: 16px;
`;

const LogoutContainer = styled(motion.div)`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  &:hover {
    background-color: #ecf0f4;
    opacity: 0.5px;
    cursor: pointer;
  }
`;

const LogoutIconTypographyWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LogoutIcon = styled(motion.img)``;

const LogoutTypography = styled(motion.span)`
  font-weight: 500;
  font-size: 16px;
  color: #212b36;
  margin-left: 25px;
`;

const VersionTypography = styled(motion.span)`
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: #919eab;
`;

const MenuItemStyled = styled(MenuItem)(({ isDisabled }) => ({
  marginBottom: '5px',
  '&:hover': {
    backgroundColor: !isDisabled ? '#ECF0F4' : '',
    opacity: '0.5px',
  },
}));

export default SettingsBar;
