export interface CreateDPDShipment {
  name1: string; //Receiver company or personal name
  name2?: string; //Receiver additional name (if needed)
  city: string; //Receiver city
  country: string; //Receiver country code (ISO2 standard)
  phone: string; //
  email: string; //
  contact?: string;
  rPropNum: string; //Receiver house number (case-sensitive)
  weight: string; //Parcel weight (kg), mandatory for PUDO
  num_of_parcel: string; //Number of parcel labels to be generated
  parcel_type: string; //
  street: string; //Receiver street
  pcode: string; //Receiver postal code
  sender_name: string;
  sender_city: string;
  sender_pcode: string;
  sender_street: string;
  sender_phone: string;
  order_number: string;
  cod_amount: number;
  cod_purpose: string; // work order number
}

export interface CreateLabel {
  parcels: string[];
}

export interface TrackDPDShipment {
  parcelNumber: string;
}

export interface CancelShipment {
  parcels: string[];
}
