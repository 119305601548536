import { DBReservationsItems } from '@prisma/client';

export interface CreateReservationPayload {
  stockListItemId: string;
  quantity: number;
}

export interface ReservationOnePayload {
  stockListItemId: string;
}

interface DBStockReservationsApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: ReservationOnePayload;
    requestParams: ReservationOnePayload;
    request: ReservationOnePayload;
    response: DBReservationsItems;
  };
  create: {
    requestBody: CreateReservationPayload;
    requestParams: never;
    request: CreateReservationPayload;
    response: DBReservationsItems;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBStockReservationsApi };
