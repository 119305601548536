import { appConfig } from '@tyrio/config';

export const ENDPOINTS = {
  feature_config: {
    uri: '/feature-config',
    method: 'GET',
  },
  login: {
    uri: '/auth',
    method: 'POST',
  },
  token_validate: {
    uri: '/auth/validate-token',
    method: 'POST',
  },
  get_clients: {
    uri: '/client',
    method: 'GET',
  },
  client_id: {
    uri: '/client/{id}',
    method: 'GET',
  },
  create_client: {
    uri: '/client',
    method: 'POST',
  },
  update_client: {
    uri: '/client/{id}',
    method: 'PUT',
  },
  role_list: {
    uri: '/role/',
    method: 'GET',
  },
  category: {
    uri: '/product-category/',
    method: 'GET',
  },
  main_categories: {
    uri: '/product-category/main',
    method: 'GET',
  },
  category_id: {
    uri: '/product-category/{id}',
    method: 'GET',
  },
  create_category: {
    uri: '/product-category',
    method: 'POST',
  },
  update_category: {
    uri: '/product-category/{id}',
    method: 'PUT',
  },
  delete_category: {
    uri: '/product-category/{id}',
    method: 'DELETE',
  },
  get_subcategories: {
    uri: '/product-category/subcategories/{categoryId}',
    method: 'GET',
  },

  role_id: {
    uri: '/role/{id}',
    method: 'GET',
  },
  admins: {
    uri: '/admin/',
    method: 'GET',
  },
  create_admin: {
    uri: '/admin/',
    method: 'POST',
  },
  admin_id: {
    uri: '/admin/{id}',
    method: 'GET',
  },
  update_admin: {
    uri: '/admin/{id}',
    method: 'PUT',
  },
  change_status: {
    uri: '/admin/change-status/{id}',
    method: 'POST',
  },
  delete_admin: {
    uri: '/admin/{id}',
    method: 'DELETE',
  },
  get_user: {
    uri: '/auth/me',
    method: 'GET',
  },
  get_user_by_id: {
    uri: '/user/{userId}',
    method: 'GET',
  },
  get_user_by_ids: {
    uri: '/user/many',
    method: 'POST',
  },
  get_user_by_phone: {
    uri: '/user/auth/phone/{mobilePhone}',
    method: 'GET',
  },
  get_user_by_email: {
    uri: '/user/auth/email/{email}',
    method: 'GET',
  },
  get_users: {
    uri: '/user',
    method: 'GET',
  },
  create_user: {
    uri: '/user',
    method: 'POST',
  },
  update_user: {
    uri: '/user/{userId}',
    method: 'PUT',
  },
  delete_user: {
    uri: '/user/{userId}',
    method: 'DELETE',
  },
  delete_client: {
    uri: '/client/{id}',
    method: 'DELETE',
  },
  create_role: {
    uri: '/role/',
    method: 'POST',
  },
  delete_role: {
    uri: '/role/{id}',
    method: 'DELETE',
  },
  change_role_status: {
    uri: '/role/change-status/{id}',
    method: 'POST',
  },
  clone_role: {
    uri: '/role/clone/{id}',
    method: 'POST',
  },
  update_role: {
    uri: '/role/{id}',
    method: 'PUT',
  },
  suppliers: {
    uri: '/supplier/',
    method: 'GET',
  },
  create_supplier: {
    uri: '/supplier/',
    method: 'POST',
  },
  supplier_id: {
    uri: '/supplier/{id}',
    method: 'GET',
  },
  update_supplier: {
    uri: '/supplier/{id}',
    method: 'PUT',
  },
  change_status_supplier: {
    uri: '/supplier/change-status/{id}',
    method: 'POST',
  },
  delete_supplier: {
    uri: '/supplier/{id}',
    method: 'DELETE',
  },
  get_countries: {
    uri: '/country',
    method: 'GET',
  },
  products: {
    uri: '/product/',
    method: 'GET',
  },
  get_product_by_id: {
    uri: '/product/{id}',
    method: 'GET',
  },
  upsert_product: {
    uri: '/product/',
    method: 'PUT',
  },
  delete_product: {
    uri: '/product/{id}',
    method: 'DELETE',
  },
  get_rim_sizes: {
    uri: '/product/rim-sizes',
    method: 'GET',
  },
  product_models: {
    uri: '/product-model/',
    method: 'GET',
  },
  get_product_model_by_id: {
    uri: '/product-model/{id}',
    method: 'GET',
  },
  delete_product_model: {
    uri: '/product-model/{id}',
    method: 'DELETE',
  },
  upload_product_image: {
    uri: `${appConfig.apiUrl}/product-model/upload-image`,
    method: 'PUT',
  },
  delete_product_image: {
    uri: `/product-model/delete-image`,
    method: 'PUT',
  },
  upload_logo: {
    uri: `${appConfig.apiUrl}/image-upload`,
    method: 'POST',
  },
  download_ftp: {
    uri: `/supplier-import/ftp`,
    method: 'POST',
  },
  download_api: {
    uri: `/supplier-import/api`,
    method: 'POST',
  },
  download_https: {
    uri: `/supplier-import/https`,
    method: 'POST',
  },
  get_file: {
    uri: `${appConfig.apiUrl}/supplier-import`,
    method: 'GET',
  },
  get_supplier_client_settings: {
    uri: `/supplier-client-settings`,
    method: 'GET',
  },
  all_categories: {
    uri: '/product-category/get-all-categories',
    method: 'GET',
  },
  get_single_supplier_client_settings: {
    uri: `/supplier-client-settings/{id}`,
    method: 'GET',
  },
  get_single_supplier_locations: {
    uri: `/supplier-location`,
    method: 'GET',
  },
  get_dimensions: {
    uri: '/product/dimensions',
    method: 'GET',
  },

  /**
   * Price comparison endpoints
   */
  compare_prices_by_code: {
    uri: `/supplier-order/price-comparison/code`,
    method: 'PUT',
  },
  compare_prices_by_dimension: {
    uri: `/supplier-order/price-comparison/dimensions`,
    method: 'PUT',
  },
  /**
   * Catalog import endpoints
   */
  import_catalog: {
    uri: '/supplier-import/import/catalog',
    method: 'POST',
  },
  import_stock_price: {
    uri: '/supplier-import/import/price-stock',
    method: 'POST',
  },

  save_supplier_client_settings: {
    uri: '/supplier-client-settings',
    method: 'POST',
  },
  patch_supplier_client_settings: {
    uri: '/supplier-client-settings/{id}',
    method: 'PATCH',
  },
  get_supplier_client_settings_by_id: {
    uri: '/supplier-client-settings/{id}',
    method: 'GET',
  },

  /**
   * Products
   */
  create_model: {
    uri: '/product-model',
    method: 'PUT',
  },
  delete_supplier_connection: {
    uri: '/supplier-client-settings/{id}',
    method: 'DELETE',
  },
  change_supplier_client_connection_status: {
    uri: '/supplier-client-settings/change-status/{id}',
    method: 'POST',
  },

  //orders
  get_client_order_suppliers: {
    uri: '/purchase-orders/client-order-suppliers',
    method: 'GET',
  },
  client_orders: {
    uri: '/purchase-orders',
    method: 'GET',
  },
  count_orders_by_status: {
    uri: '/purchase-orders/count-orders',
    method: 'GET',
  },
  change_favourite_status: {
    uri: '/purchase-orders/change-favorite/{id}',
    method: 'POST',
  },
  update_order: {
    uri: '/purchase-orders/{id}',
    method: 'PUT',
  },
  create_order: {
    uri: '/purchase-orders',
    method: 'POST',
  },
  update_order_ref: {
    uri: '/purchase-orders/{id}/update-order-ref',
    method: 'POST',
  },
  cancel_order: {
    uri: '/purchase-orders/{id}',
    method: 'PATCH',
  },
  active_purchase_orders: {
    uri: '/purchase-orders/active-orders/{id}',
    method: 'GET',
  },

  // order documents
  get_order_documents: {
    uri: '/order-document/{id}/{type}',
    method: 'GET',
  },
  upload_order_document: {
    uri: `${appConfig.apiUrl}/order-document/`,
    method: 'PUT',
  },
  delete_order_document: {
    uri: '/order-document/{id}',
    method: 'DELETE',
  },

  // branches
  get_branches: {
    uri: '/branches',
    method: 'GET',
  },
  get_branch_by_id: {
    uri: '/branches/{id}',
    method: 'GET',
  },
  get_branch_by_client_id: {
    uri: '/branches/client/all',
    method: 'GET',
  },
  create_branch: {
    uri: '/branches/',
    method: 'POST',
  },
  update_branch: {
    uri: '/branches/{id}',
    method: 'PUT',
  },
  delete_branch: {
    uri: '/branches/{id}',
    method: 'DELETE',
  },
  change_branch_status: {
    uri: '/branches/change-status/{id}',
    method: 'POST',
  },

  //GMP ITALIA API AUTH
  gmp_auth: {
    uri: '/orders/gmp-italia/auth',
    method: 'POST',
  },
  gmp_order: {
    uri: '/orders/gmp-italia/order-write',
    method: 'POST',
  },
  upload_ftp: {
    uri: `${appConfig.apiUrl}/orders/ftp-upload`,
    method: 'POST',
  },

  //EDI WHEEL ORDER API
  edi_order: {
    uri: '/app-orders/edi-wheel/create-order',
    method: 'POST',
  },
  edi_order_status: {
    uri: '/app-orders/edi-wheel/order-status',
    method: 'GET',
  },

  // customer orders
  get_customer_orders: {
    uri: '/customer-orders',
    method: 'GET',
  },
  get_customer_order_by_id: {
    uri: '/customer-orders/{id}',
    method: 'GET',
  },
  get_customer_order_by_order_id: {
    uri: '/customer-orders/by-line/{id}',
    method: 'GET',
  },
  count_customer_orders_by_status: {
    uri: '/customer-orders/count-orders',
    method: 'GET',
  },
  update_customer_order: {
    uri: '/customer-orders/{id}',
    method: 'PUT',
  },
  customer_order_change_favourite_status: {
    uri: '/customer-orders/change-favorite/{id}',
    method: 'POST',
  },
  change_lock_status: {
    uri: '/customer-orders/lock-status/{id}',
    method: 'POST',
  },
  update_customer_order_address: {
    uri: '/customer-orders/address',
    method: 'PUT',
  },

  //api keys
  client_api_keys_list: {
    uri: '/api-key',
    method: 'GET',
  },
  api_key_status: {
    uri: '/api-key/change-status/{apiKey}',
    method: 'POST',
  },
  deleteKey: {
    uri: '/api-key/{apiKey}',
    method: 'DELETE',
  },
  generateKey: {
    uri: '/api-key',
    method: 'POST',
  },
  client_keys: {
    uri: '/api-key/client/{clientId}',
    method: 'GET',
  },

  /**
   * SSE Endpoints
   */
  sse_subscribe: {
    uri: '/sse/client/subscribe',
    method: 'POST',
  },

  //customer rebates
  get_rebates: {
    uri: '/customer-rebates',
    method: 'GET',
  },
  get_rebate_by_id: {
    uri: '/customer-rebates/{id}',
    method: 'GET',
  },
  change_rebate_status: {
    uri: '/customer-rebates/change-status/{id}',
    method: 'POST',
  },
  delete_customer_rebate: {
    uri: '/customer-rebates/{id}',
    method: 'DELETE',
  },
  create_customer_rebate: {
    uri: '/customer-rebates/',
    method: 'POST',
  },
  update_customer_rebate: {
    uri: '/customer-rebates/{id}',
    method: 'PUT',
  },

  // price calculation
  get_price_calculations: {
    uri: '/price-calculation',
    method: 'GET',
  },
  get_price_calculation_by_id: {
    uri: '/price-calculation/{id}',
    method: 'GET',
  },
  change_price_calculation_status: {
    uri: '/price-calculation/change-status/{id}',
    method: 'POST',
  },
  delete_price_calculation: {
    uri: '/price-calculation/{id}',
    method: 'DELETE',
  },
  create_price_calculation: {
    uri: '/price-calculation/',
    method: 'POST',
  },
  update_price_calculation: {
    uri: '/price-calculation/{id}',
    method: 'PUT',
  },
  get_basic_calculation: {
    uri: '/price-calculation/basic',
    method: 'GET',
  },

  //delivery-types
  get_client_delivery_types: {
    uri: '/delivery-types',
    method: 'GET',
  },
  create_delivery_type: {
    uri: '/delivery-types',
    method: 'POST',
  },
  get_delivery_method: {
    uri: '/delivery-types/{id}',
    method: 'GET',
  },
  update_delivery_method: {
    uri: '/delivery-types/{id}',
    method: 'PATCH',
  },
  change_delivery_method_status: {
    uri: '/delivery-types/change-status',
    method: 'PATCH',
  },
  get_deliveries_by_branch: {
    uri: '/delivery-types/get-by-branch/{branchId}',
    method: 'GET',
  },

  //payment-types
  get_client_payment_types: {
    uri: '/payment-types',
    method: 'GET',
  },

  //shop-types
  get_client_shop_types: {
    uri: '/shop-types',
    method: 'GET',
  },

  //stock-list
  get_stock_list_products: {
    uri: '/stock-list',
    method: 'POST',
  },
  get_available_quantity: {
    uri: '/stock-list/getavailable',
    method: 'POST',
  },
  s3_add_supplier_logo: {
    uri: `${appConfig.apiUrl}/supplier/upload/logo`,
    method: 'PUT',
  },
  stock_list_item: {
    uri: '/stock-list/{id}',
    method: 'GET',
  },
  sl_change_favorite_status: {
    uri: '/stock-list/change-favorite/{id}',
    method: 'POST',
  },
  stock_list_products: {
    uri: '/stock-list/products',
    method: 'POST',
  },
  main_branches_stock_list: {
    uri: '/stock-list/branches-stock-main/{warehouseId}/{ean}',
    method: 'GET',
  },
  create_stock_list_items: {
    uri: '/stock-list/create-more',
    method: 'POST',
  },
  edit_stock_list_item: {
    uri: '/stock-list/edit-stock-list-item',
    method: 'PATCH',
  },
  // Sales orders
  prepare_sales_order: {
    uri: '/sales-orders/{orderId}',
    method: 'GET',
  },

  // Inquiry
  inquire_eans: {
    uri: '/edi/inquiry',
    method: 'POST',
  },
  branches_stock_list: {
    uri: '/stock-list/branches-stock/{warehouseId}/{ean}',
    method: 'GET',
  },
  branches_stock_list_outbound: {
    uri: '/stock-list/branches-stock-others/{warehouseId}/{ean}',
    method: 'GET',
  },
  get_filters: {
    uri: '/stock-list/filters/get-filters',
    method: 'GET',
  },
  add_to_cart: {
    uri: '/stock-reservations/create-reservation',
    method: 'POST',
  },
  remove_from_cart: {
    uri: '/stock-reservations/delete-reservation/{stockListItemId}',
    method: 'DELETE',
  },
  change_cart_qty: {
    uri: '/stock-reservations/change-qty/{stockListItemId}',
    method: 'POST',
  },

  //transfer orders
  create_transfer_order: {
    uri: '/transfer-orders',
    method: 'POST',
  },
  create_many_transfer_orders: {
    uri: '/transfer-orders/create-many',
    method: 'POST',
  },
  get_transfer_orders: {
    uri: '/transfer-orders',
    method: 'GET',
  },
  count_transfer_orders: {
    uri: '/transfer-orders/count-orders',
    method: 'GET',
  },
  to_change_favorite_status: {
    uri: '/transfer-orders/change-favorite/{orderId}',
    method: 'POST',
  },
  update_transfer_order: {
    uri: '/transfer-orders/{orderId}',
    method: 'PUT',
  },
  main_branch_stock: {
    uri: '/stock-list/main-branch-stock/{warehouseId}/{ean}',
    method: 'GET',
  },
  get_transfer_order_ean: {
    uri: '/transfer-orders/ean/{ean}',
    method: 'GET',
  },
  change_transfer_order_status: {
    uri: '/transfer-orders/change-status/{orderId}',
    method: 'PUT',
  },
  get_transfer_data_by_ean: {
    uri: '/stock-list/ean/{ean}/{warehouseId}',
    method: 'GET',
  },
  stockByEanCodes: {
    uri: '/stock-list/stock-by-ean-codes',
    method: 'POST',
  },
  active_transfer_orders: {
    uri: '/transfer-orders/active-orders/{id}',
    method: 'GET',
  },

  // stock in
  get_stock_in_list: {
    uri: '/stock-in',
    method: 'GET',
  },
  get_si_count_by_status: {
    uri: '/stock-in/count-orders',
    method: 'GET',
  },
  change_stock_in_status: {
    uri: '/stock-in/change-status/{orderId}',
    method: 'PUT',
  },
  change_stock_in_favorite_status: {
    uri: '/stock-in/change-favorite/{orderId}',
    method: 'PUT',
  },
  update_stock_in: {
    uri: '/stock-in/{orderId}',
    method: 'PUT',
  },
  get_order_prices: {
    uri: '/stock-in/get-order-prices/{id}',
    method: 'GET',
  },
  get_all_stock_in_suppliers: {
    uri: '/stock-in/purchase-transfer-order-list',
    method: 'GET',
  },

  // fitting - users
  get_users_by_client: {
    uri: '/user/users/{clientId}',
    method: 'GET',
  },

  // DISPATCH
  change_orders_status: {
    uri: '/dispatch/change-status/{orderType}/{ids}',
    method: 'PATCH',
  },
  create_dispatched_order: {
    uri: '/dispatch',
    method: 'POST',
  },
  upload_file: {
    uri: '/dispatch/upload-signature',
    method: 'PUT',
  },
  cancel_dispatch_order: {
    uri: '/dispatch/cancel-order/{id}/order-type/{orderType}',
    method: 'PATCH',
  },

  // POS
  get_pos_list: {
    uri: '/pos',
    method: 'GET',
  },

  get_my_stock_pos_list: {
    uri: '/pos/my-stock/{warehouseId}',
    method: 'GET',
  },

  get_branches_stock_pos_list: {
    uri: '/pos/branches-stock/{warehouseId}',
    method: 'GET',
  },

  get_supplier_stock_pos_list: {
    uri: '/pos/supplier-stock',
    method: 'GET',
  },

  get_list_of_dimensions: {
    uri: '/pos/filters',
    method: 'GET',
  },
  get_branches_stock: {
    uri: '/pos/branches-stock/{ean}/{shouldShowDot}/{warehouseId}',
    method: 'GET',
  },
  get_pos_on_stock: {
    uri: '/pos/on-stock/{warehouseId}/{ean}/{shouldShowDot}',
    method: 'GET',
  },
  get_upcoming_on_stock: {
    uri: '/pos/upcoming-on-stock/{warehouseId}/{ean}/{shouldShowDot}',
    method: 'GET',
  },
  add_pos_favorite: {
    uri: '/pos/add-favorite/{ean}',
    method: 'POST',
  },
  get_suppliers_stock: {
    uri: '/pos/suppliers-stock/{ean}',
    method: 'GET',
  },
  get_price_cal_by_ean: {
    uri: '/pos/check-price/',
    method: 'POST',
  },
  get_rebate_calc: {
    uri: '/pos/check-rebate/',
    method: 'POST',
  },
  finish_pos_order: {
    uri: '/pos/create-order',
    method: 'POST',
  },

  // test endpoint for TO
  change_v2_status: {
    uri: '/transfer-orders/change-v2-status/{id}',
    method: 'PUT',
  },

  // Partner endpoints
  get_partner_by_id: {
    uri: '/partner/{partnerId}',
    method: 'GET',
  },
  get_partners: {
    uri: '/partner',
    method: 'GET',
  },
  create_partner: {
    uri: '/partner',
    method: 'POST',
  },
  update_partner: {
    uri: '/partner/{partnerId}',
    method: 'PUT',
  },
  delete_partner: {
    uri: '/partner/{partnerId}',
    method: 'DELETE',
  },
  change_status_partner: {
    uri: '/partner/change-status/{partnerId}',
    method: 'POST',
  },
  get_sudreg_response: {
    uri: '/partner/sudreg/api/{identificator}',
    method: 'GET',
  },

  // SERVICE CATEGORIES
  get_service_categories: {
    uri: '/service-category',
    method: 'GET',
  },
  get_service_category_by_id: {
    uri: '/service-category/{id}',
    method: 'GET',
  },
  create_service_categories: {
    uri: '/service-category',
    method: 'POST',
  },
  update_service_category: {
    uri: '/service-category/{id}',
    method: 'PUT',
  },
  update_service_category_active_status: {
    uri: '/service-category/active/{id}',
    method: 'PUT',
  },
  delete_service_category: {
    uri: '/service-category/delete/{id}',
    method: 'PUT',
  },
  get_service_category_by_branch: {
    uri: '/service-category/get-by-branch/{branchId}',
    method: 'GET',
  },

  // SERVICE
  get_services: {
    uri: '/services',
    method: 'GET',
  },
  get_service_by_id: {
    uri: '/services/{id}',
    method: 'GET',
  },
  get_recommended_services: {
    uri: '/services/recommended/{branchId}/{eans}',
    method: 'GET',
  },
  create_services: {
    uri: '/services',
    method: 'POST',
  },
  update_services: {
    uri: '/services/{id}',
    method: 'PUT',
  },
  update_service_active_status: {
    uri: '/services/active/{id}',
    method: 'PUT',
  },
  delete_services: {
    uri: '/services/delete/{id}',
    method: 'PUT',
  },
  get_services_by_branch_id: {
    uri: '/services/get-by-branch/{branchId}',
    method: 'GET',
  },

  //Settings
  set_settings: {
    uri: '/settings',
    method: 'POST',
  },

  // PAYMENT METHODS
  get_payment_method: {
    uri: '/payment-types',
    method: 'GET',
  },
  get_payment_by_id: {
    uri: '/payment-types/{id}',
    method: 'GET',
  },
  create_payment_method: {
    uri: '/payment-types',
    method: 'POST',
  },
  update_payment_method: {
    uri: '/payment-types/update/{id}',
    method: 'PUT',
  },
  delete_payment_method: {
    uri: '/payment-types/{id}',
    method: 'DELETE',
  },
  update_payment_status: {
    uri: '/payment-types/active/{id}',
    method: 'PUT',
  },
  get_payments_by_branch: {
    uri: '/payment-types/get-by-branch/{branchId}',
    method: 'GET',
  },

  //custom-price-catalog
  cpc_get_catalog: {
    uri: '/custom-price-catalog',
    method: 'GET',
  },
  cpc_check_ean: {
    uri: '/custom-price-catalog/check-ean/{ean}',
    method: 'GET',
  },
  cpc_add_price: {
    uri: '/custom-price-catalog/add-price',
    method: 'POST',
  },
  cpc_update_price: {
    uri: '/custom-price-catalog/{ean}',
    method: 'PUT',
  },
  cpc_update_status: {
    uri: '/custom-price-catalog/update-status/{ean}',
    method: 'PUT',
  },
  cpc_delete: {
    uri: '/custom-price-catalog/{ean}',
    method: 'DELETE',
  },

  //warehouses
  client_warehouses: {
    uri: '/stock-locations/client-warehouses',
    method: 'GET',
  },
  warehouse_details: {
    uri: '/stock-locations/warehouse/{id}',
    method: 'GET',
  },
  warehouse: {
    uri: '/stock-locations/{id}',
    method: 'GET',
  },
  upload_warehouse: {
    uri: `${appConfig.apiUrl}/stock-locations/upload-warehouse`,
    method: 'POST',
  },
  create_warehouse: {
    uri: '/stock-locations',
    method: 'POST',
  },
  warehouse_branches: {
    uri: '/stock-locations/warehouse-branches',
    method: 'GET',
  },
  get_all_warehouse_locations: {
    uri: '/stock-locations/all',
    method: 'GET',
  },
  get_selected_Warehouse_locatons: {
    uri: '/stock-locations/selected-warehouse/{warehouseId}',
    method: 'GET',
  },

  //printers
  get_printers: {
    uri: '/printers',
    method: 'GET',
  },

  print: {
    uri: '/printers/print-job',
    method: 'POST',
  },

  set_client_printers: {
    uri: '/printers/client-printers',
    method: 'PUT',
  },

  //shipping-couriers

  get_all_shipping_couriers: {
    uri: '/shipping-courier',
    method: 'GET',
  },

  create_shipping_courier: {
    uri: '/shipping-courier',
    method: 'POST',
  },

  //client shipping couriers

  get_client_shipping_couriers: {
    uri: '/client-shipping-couriers',
    method: 'GET',
  },

  create_client_shipping_couriers: {
    uri: '/client-shipping-couriers',
    method: 'POST',
  },

  update_client_shipping_courier: {
    uri: '/client-shipping-couriers/{id}',
    method: 'PATCH',
  },
  get_one_client_shipping_courier: {
    uri: '/client-shipping-couriers/{id}',
    method: 'GET',
  },
  change_courier_status: {
    uri: '/client-shipping-couriers/change-status',
    method: 'PATCH',
  },

  //track info
  delete_track_info: {
    uri: '/track-info/{trackingNumber}',
    method: 'DELETE',
  },
  get_order_track_info: {
    uri: '/track-info/{orderId}/{orderType}',
    method: 'GET',
  },
  track_info_update_many: {
    uri: '/track-info/update-many',
    method: 'PUT',
  },

  // WHEEL SIZE
  get_brands: {
    uri: '/wheel-size/all-brands',
    method: 'GET',
  },
  get_models: {
    uri: '/wheel-size/vehicle-model/{model}',
    method: 'GET',
  },
  get_generations: {
    uri: '/wheel-size/vehicle-generation/{make}/{model}',
    method: 'GET',
  },
  get_modifications: {
    uri: '/wheel-size/get-modifications/{make}/{model}/{generation}',
    method: 'GET',
  },
  search_by_model: {
    uri: '/wheel-size/search/{make}/{model}/{generation}/{modification}',
    method: 'GET',
  },
  wheels_data_scraping: {
    uri: '/wheel-size/data-scraping',
    method: 'GET',
  },
  // Fitting box settings
  get_fitting_box_settings: {
    uri: '/fitting-box-settings/get-by-branch/{branchId}',
    method: 'GET',
  },
  edit_fitting_box_settings: {
    uri: '/fitting-box-settings/{id}',
    method: 'PUT',
  },
  add_fitting_box_settings: {
    uri: '/fitting-box-settings',
    method: 'POST',
  },
  delete_fitting_box_settings: {
    uri: '/fitting-box-settings/{id}',
    method: 'DELETE',
  },

  // Fitting orders
  get_fitting_orders: {
    uri: '/fitting-orders/shelves/{branchId}',
    method: 'GET',
  },
  update_warehouse: {
    uri: '/stock-locations/update-warehouse/{id}',
    method: 'PUT',
  },

  // documents look
  get_documents_look: {
    uri: '/documents-look',
    method: 'GET',
  },
  create_documents_look: {
    uri: '/documents-look',
    method: 'POST',
  },
  upload_documents_look_logo: {
    uri: `${appConfig.apiUrl}/documents-look/upload-logo`,
    method: 'PUT',
  },
  update_documents_look: {
    uri: `/documents-look`,
    method: 'PUT',
  },

  // pdf
  get_generated_pdf: {
    uri: `/pdf/{key}`,
    method: 'GET',
  },
  get_work_orders_delivery_notes: {
    uri: `/work-orders/generated-pdf/delivery-notes`,
    method: 'GET',
  },
  get_work_orders_print_data: {
    uri: '/work-orders/print-data/{id}',
    method: 'GET',
  },

  //WORK ORDERS
  create_work_order: {
    uri: '/work-orders',
    method: 'POST',
  },
  finish_work_order: {
    uri: '/work-orders/finish-order',
    method: 'POST',
  },

  //WHEELS SEARCH
  get_wheels: {
    uri: '/pos-wheels',
    method: 'GET',
  },
  get_wheels_search_filters: {
    uri: '/pos-wheels/get-filters',
    method: 'GET',
  },
  get_wheel_details: {
    uri: '/pos-wheels/similar',
    method: 'GET',
  },

  //import stock list
  import_stock_list: {
    uri: `${appConfig.apiUrl}/stock-list/import-stock-list`,
    method: 'POST',
  },

  //appointments
  check_appointments: {
    uri: '/appointments/get-by-phone-number/{phoneNumber}',
    method: 'GET',
  },
  getServiceDetails: {
    uri: '/appointments/get-service-details',
    method: 'POST',
  },

  get_version: {
    uri: '/dev/get-version',
    method: 'GET',
  },
  update_ftp_settings: {
    uri: '/client/update-stock-list-cron-settings',
    method: 'POST',
  },
  get_ftp_logs: {
    uri: '/client/get-stock-list-cron-logs',
    method: 'GET',
  },
  get_ftp_settings: {
    uri: '/client/get-stock-list-cron-settings',
    method: 'GET',
  },
  getLogsById: {
    uri: '/stock-list-import/{id}',
    method: 'GET',
  },

  getAllAppointments: {
    uri: '/appointments/all',
    method: 'POST',
  },

  // inbound invoice
  get_prepared_invoices: {
    uri: '/inbound-invoice/get-prepared/{stockInIds}',
    method: 'GET',
  },
  get_inbound_invoices: {
    uri: '/inbound-invoice',
    method: 'GET',
  },
  get_invoice_by_id: {
    uri: '/inbound-invoice/{id}',
    method: 'GET',
  },
  create_inbound_invoice: {
    uri: '/inbound-invoice',
    method: 'POST',
  },

  //WORK ORDERS DASHBOARD
  get_stock_out_quantities: {
    uri: '/stock-out/quantities-by-delivery-type',
    method: 'GET',
  },
  dispatch_orders: {
    method: 'GET',
    uri: '/dispatch',
  },
  get_stock_out: {
    method: 'GET',
    uri: '/stock-out',
  },
  get_collections: {
    method: 'GET',
    uri: '/collection',
  },
  get_ready_shipping: {
    method: 'GET',
    uri: '/stock-out/ready-shipping-order',
  },
  get_fitting: {
    method: 'GET',
    uri: '/appointments/fitting',
  },

  print_barcode: {
    method: 'POST',
    uri: '/ship-and-print/print-barcode',
  },
};

export type ApiEndpoint = keyof typeof ENDPOINTS;
