import _ from 'lodash';
import { useFilter } from '../../../../../hooks/useFilter';
import { HeaderText } from '../../../steps/Search/SelectStepper';
import { CardWrapper, StyledCheckbox, Container, Card } from './BrandSelector';

interface SubcategorySelectorProps {
  label: string;
  selectOptions: { value: number; label: string }[];
}

export const SubcategorySelector = ({
  label,
  selectOptions,
}: SubcategorySelectorProps) => {
  const { ...filtersHook } = useFilter();

  const get = (path: string) =>
    _.get(filtersHook.filterValues, path, []) as string[];

  const initialSelectedValues: string[] = get('subcategory');

  let selectedValues = initialSelectedValues;

  const handleSelectValue = (isSelected: boolean, checkboxKey: string) => {
    if (isSelected) {
      selectedValues.push(checkboxKey);
    } else selectedValues = selectedValues.filter((el) => el !== checkboxKey);

    filtersHook.setFilterValue({
      ...filtersHook.filterValues,
      subcategory: selectedValues,
    });
  };

  return (
    <Container>
      <HeaderText>{label}</HeaderText>

      <CardWrapper>
        {selectOptions.map((subcategory, key) => {
          const isSelected = selectedValues.includes(
            subcategory.value.toString()
          );

          return (
            <Card
              onClick={() => {
                handleSelectValue(!isSelected, subcategory.value.toString());
              }}
              selected={isSelected}
              key={key}
            >
              <StyledCheckbox
                type="checkbox"
                key={`checkbox_${subcategory.label}`}
                checked={isSelected}
                onChange={() => null}
                style={{ marginRight: '10px' }}
              />
              {subcategory.label}
            </Card>
          );
        })}
      </CardWrapper>
    </Container>
  );
};
