import { DBHttpsConnection, DBRepeatUnit } from '@prisma/client';

interface DBUpsertSupplierHttpsConnectionPayload {
  id?: string;
  httpsUrl: string;
  username: string;
  password: string;
  customerId?: string;
  startUpdateTime: Date;
  repeatEvery?: number;
  repeatUntil: DBRepeatUnit | string;
  remark: string;
}

interface DBSupplierHttpsConnectionApi {
  list: {
    requestBody: never;
    request: never;
    response: never[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierHttpsConnectionPayload;
    requestParams: never;
    request: DBUpsertSupplierHttpsConnectionPayload;
    response: DBHttpsConnection;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierHttpsConnectionApi };
