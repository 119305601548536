import {
  DBApiConnection,
  DBRepeatUnit,
  DBSupplierApiConnectionType,
} from '@prisma/client';

interface DBUpsertSupplierApiConnectionPayload {
  id?: string;
  apiUrl: string;
  apiKey: string;
  apiClientId: string;
  apiClientSecret: string;
  startUpdateTime: Date;
  repeatEvery?: number;
  repeatUntil: DBRepeatUnit | string;
  remark: string;
  connectionType: DBSupplierApiConnectionType;
}

interface DBSupplierApiConnectionApi {
  list: {
    requestBody: never;
    request: never;
    response: never[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierApiConnectionPayload;
    requestParams: never;
    request: DBUpsertSupplierApiConnectionPayload;
    response: DBApiConnection;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierApiConnectionApi };
