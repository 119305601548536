import { DBClient } from '@prisma/client';
import { RULESET_KEYS } from '@tyrio/shared-vars';

interface DBCatalogImportAdminRequest {
  supplierShape: keyof typeof RULESET_KEYS;
  supplierId: string;
  connectionId: string;
}
interface DBCatalogImportApi {
  create: {
    requestBody: DBCatalogImportAdminRequest;
    requestParams: never;
    request: DBCatalogImportAdminRequest;
    response: DBClient;
  };
}
export { DBCatalogImportApi };
