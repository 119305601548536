/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import { TRule } from '../typings';
import { PreprocessKeysShape } from '../../helpers/types.helper';
import { RawImport, UnfinishedData } from '@tyrio/dto';

type UnfinishedDefaultsType = {
  product: Record<string, null | string>;
  model: Record<string, null | string>;
};

export interface SupplierShape {
  weight: number;
  override: boolean;
  ruleset: TRule[];

  pricelistRuleset: TRule[];
  stockRuleset: TRule[];
  canSaveWithoutNecessaryFields: boolean;
  canSaveWithoutEan: boolean;
  pricelistDefaults: UnfinishedDefaultsType;
  stockDefaults: UnfinishedDefaultsType;
  catalogDefaults: UnfinishedDefaultsType;
  preprocessKeys: PreprocessKeysShape;
  hardCodes: {
    [categoryId: number]: {
      product: Record<string, any>;
      model: Record<string, any>;
    };
  };
  preprocessCatalog: (file: string) => Promise<RawImport[]>;
  preprocessPricelist: (file: string) => Promise<RawImport[]>;
  preprocessStockList: (file: string) => Promise<RawImport[]>;
}
