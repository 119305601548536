import styled from '@emotion/styled/macro';
import { Chip, CircularProgress } from '@mui/material';
import { DBOrderDocument } from '@prisma/client';
import { DBCustomerOrderItemResponse, DBOrderDocumentApi } from '@tyrio/dto';
import {
  addDocument,
  arrow,
  deleteDocument,
  DeleteModal,
  documentIcon,
  hoveredEye,
  pdfFile,
} from '@tyrio/ui-library';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PrintProvider } from '../../context/PrintContext';
import { WorkOrderDetails } from '../../features/work-orders/WorkOrderDetails';
import { LoaderWrapper } from '../PageTemplate/PageTemplate';

interface UploadDocumentsProps {
  orderDocuments: DBOrderDocumentApi['list']['response'] | undefined;
  onOpen: (acceptedFiles: File[]) => void;
  onDelete: (id: string) => void;
  isLoading: boolean;
  selectedOrderData?: DBCustomerOrderItemResponse;
  generatedPdf?: string;
}

const UploadDocuments = ({
  orderDocuments,
  onOpen,
  onDelete,
  isLoading,
  selectedOrderData,
  generatedPdf,
}: UploadDocumentsProps) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxSize: 25 * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      onOpen(acceptedFiles);
    },
  });
  const [isHovered, setIsHovered] = useState<Record<string, boolean>>({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [documentName, setDocumentName] = useState('');

  const documentDetails = useMemo(() => {
    const pdfUrl = generatedPdf?.split('=');
    let reference = '';
    let printType = 'delivery-note';
    if (pdfUrl) {
      reference = pdfUrl[6];
      printType = pdfUrl[5];
    }
    return { reference, printType };
  }, [generatedPdf]);

  const { reference, printType } = documentDetails;

  return (
    <RelatedDocumentsWrapper>
      <PrintProvider>
        {isDeleteModalVisible && (
          <DeleteModal
            LBAction={() => setIsDeleteModalVisible(false)}
            RBAction={() => {
              onDelete(documentId);
              setIsDeleteModalVisible(false);
            }}
            itemName={documentName}
          />
        )}
        <SelectDocumentWrapper disabled={isLoading}>
          <input style={{ display: 'none' }} {...getInputProps()} />
          <ImgWrapper>
            <Image src={addDocument} alt="add-document-icon" onClick={open} />
          </ImgWrapper>
          <TextWrapper>
            <SelectDocumentText>Select document</SelectDocumentText>
            <DropFilesText>
              <div>Drop files</div>
              <BrowseTextWrapper {...getRootProps()}>browse</BrowseTextWrapper>
              <div>through your machine</div>
            </DropFilesText>
          </TextWrapper>
        </SelectDocumentWrapper>
        {isLoading && (
          <UploadInProgressWrapper>
            <LoaderWrapper
              style={{
                height: '15px',
                width: '15px',
              }}
            >
              <CircularProgress style={{ height: '15px', width: '15px' }} />
            </LoaderWrapper>
            <UploadingText>Uploading files, please be patient!</UploadingText>
          </UploadInProgressWrapper>
        )}

        <DocumentsWrapper disabled={isLoading}>
          {/* uploaded files */}
          {orderDocuments &&
            orderDocuments.map((item: DBOrderDocument) => (
              <DocumentDetailsWrapper
                key={item.id}
                onMouseEnter={() =>
                  setIsHovered({ ...isHovered, [item.id]: true })
                }
                onMouseLeave={() =>
                  setIsHovered({ ...isHovered, [item.id]: false })
                }
              >
                <FileIconNameWrapper>
                  {item.type === 'application/pdf' ? (
                    <Image src={pdfFile} alt="pdf-icon" />
                  ) : (
                    <Image src={documentIcon} alt="file-icon" />
                  )}
                  <p>{item.name}</p>
                </FileIconNameWrapper>
                <ReferenceWrapper>
                  <p>{item.type}</p>
                </ReferenceWrapper>
                <IconsWrapper isHovered={isHovered[item.id]}>
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: 'flex', justifyContent: 'center' }}
                    download
                  >
                    <Image src={hoveredEye} alt="hovered-eye-icon" />
                  </a>
                  {item.type === 'application/pdf' && (
                    <DeleteDocumentIcon
                      src={deleteDocument}
                      alt="delete-document-icon"
                      onClick={() => {
                        setDocumentName(item?.name ?? '');
                        setDocumentId(item?.id);
                        setIsDeleteModalVisible(true);
                      }}
                    />
                  )}
                </IconsWrapper>
              </DocumentDetailsWrapper>
            ))}

          {/* generated delivery notes */}
          {generatedPdf && (
            <DocumentDetailsWrapper
              key={reference}
              onClickCapture={() => window.open(generatedPdf, '_blank')}
              onMouseEnter={() =>
                setIsHovered({
                  ...isHovered,
                  [reference]: true,
                })
              }
              onMouseLeave={() =>
                setIsHovered({
                  ...isHovered,
                  [reference]: false,
                })
              }
              style={{ marginTop: orderDocuments ? '20px' : '' }}
            >
              <FileIconNameWrapper>
                <Image src={pdfFile} alt="pdf-icon" />

                <p>
                  {printType === 'MEĐUSKLADIŠNICA'
                    ? 'Međuskladišnica'
                    : 'Otpremnica'}
                </p>
              </FileIconNameWrapper>
              <ReferenceWrapper>
                <p>{reference}</p>
              </ReferenceWrapper>
              <IconsWrapper isHovered={isHovered[reference]}>
                <Chip
                  size="small"
                  label={'POSLANO'}
                  sx={{
                    bgcolor: '#3EB274',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                />
                <a
                  href={generatedPdf}
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  download
                >
                  <Image src={arrow} alt="hovered-eye-icon" />
                </a>
              </IconsWrapper>
            </DocumentDetailsWrapper>
          )}
        </DocumentsWrapper>

        {/* generated work orders delivery notes */}
        {selectedOrderData && (
          <WorkOrderDetails
            workOrders={selectedOrderData?.workOrders}
            customerOrderId={selectedOrderData.id}
          />
        )}
      </PrintProvider>
    </RelatedDocumentsWrapper>
  );
};

const RelatedDocumentsWrapper = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  max-height: calc(100svh - 259px);
`;

const SelectDocumentWrapper = styled.div<{ disabled: boolean }>`
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  border-style: dashed;
  height: 250px !important;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  position: sticky;
  top: 0;
`;

const ImgWrapper = styled.div`
  padding-top: 4px;
  &:hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
`;

const SelectDocumentText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 137.5%;
`;

const DropFilesText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #6b7280;
  display: flex;
  gap: 5px;
`;

export const DocumentsWrapper = styled.div<{ disabled: boolean }>`
  // max-height: calc(100% - 44px - 30%);
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${(props) => props.disabled && `opacity: 0.4;`};
  ${(props) => props.disabled && `pointer-events: none;`};
  gap: 16px;
`;

export const DocumentDetailsWrapper = styled.div`
  border: 1px solid #d2d4d2;
  border-radius: 8px;
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  &:hover {
    border: 1px solid #0990d9;
    cursor: pointer;
  }
`;

export const FileIconNameWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 30%;
`;

export const ReferenceWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 40%;
`;

export const IconsWrapper = styled.div<{ isHovered?: boolean }>`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${(props) => (props.isHovered ? '15px' : '15px')};
  cursor: ${(props) => (props.isHovered ? 'pointer' : 'default')};
`;

const BrowseTextWrapper = styled.div`
  border-bottom: 1px solid #1652f7;
  padding-bottom: 0;
  color: #1652f7;
  &:hover {
    cursor: pointer;
  }
`;

const UploadInProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const UploadingText = styled.p`
  font-size: 15px;
`;

const Image = styled.img``;

const DeleteDocumentIcon = styled.img`
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export default UploadDocuments;
