import styled from '@emotion/styled/macro';
import { tyrioUI } from '@tyrio/ui-library';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

// WO DASHBOARD PAGE
export const MainContent = styled(FlexColumn)`
  padding: 16px;
`;
export const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 24px;
`;

export const SearchWrapper = styled.div`
  width: 30%;
`;

// WO OVERVIEW
export const WorkOrdersOverviewWrapper = styled(FlexRow)`
  gap: 24px;
  overflow-x: scroll;
`;

// SINGLE CARD
export const CardWrapper = styled(FlexColumn)`
  min-width: 430px;
  padding: 12px 16px;
  border: 1px dashed ${tyrioUI.colors.black.B40};
  border-radius: 8px;
`;

export const CartTitle = styled(FlexRow)`
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
`;

export const CardQuantity = styled.div<{ noItems?: boolean }>`
  border-radius: 8px;
  background: ${({ noItems }) =>
    noItems ? tyrioUI.colors.lightGrey : tyrioUI.colors.orange.O100};
  height: 32px;
  width: 60px;
  color: ${({ noItems }) => (noItems ? tyrioUI.colors.black : 'white')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CartImageWrapper = styled(FlexRow)`
  align-items: center;
  gap: 10px;
`;

export const Content = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 300px);
`;

// LINE ITEM
export const LineItemWrapper = styled(FlexRow)<{ disabled?: boolean }>`
  justify-content: space-between;
  width: 100%;
  opacity: ${({ disabled }) => disabled && '0.4'};
  padding: 8px 0;
  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
  cursor: pointer;

  &:hover {
    border-color: ${tyrioUI.colors.blue.B100};
    background: ${tyrioUI.colors.blue.B10};
  }
`;

export const Wrapper = styled(FlexColumn)<{ hasMaxWidth?: boolean }>`
  max-width: ${({ hasMaxWidth }) => hasMaxWidth && '140px'};
`;

export const QuantityWrapper = styled(FlexRow)`
  gap: 10px;
`;

export const QuantityItem = styled.div`
  border-radius: 8px 0 0 8px;
  background: ${tyrioUI.colors.lightGrey};
  width: 70px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NextDayDiv = styled(FlexRow)`
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 5px 0px;
  align-items: center;
  justify-content: center;
  background: #ffedbf;
`;
