import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import api from '@tyrio/api-factory';
import { DBFeatureConfigApi } from '@tyrio/dto';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollToTop/scrollToTop';
import { RootRouter } from './routers/RootRouter';

export const RootComponent = () => {
  const [showSnackBar, setShowSnackbar] = useState(false);

  useQuery('featureConfig', () =>
    api.fetch<DBFeatureConfigApi['list']>('feature_config')
  );

  const { data: appVersion, refetch } = useQuery(
    'get_version',
    () => api.fetch('get_version'),
    {
      onSuccess(data: string) {
        if (!window.localStorage.getItem('@@V')) {
          window.localStorage.setItem('@@V', data);
          return;
        } else {
          if (window.localStorage.getItem('@@V') === data) {
            setShowSnackbar(false);
            return;
          } else if (window.localStorage.getItem('@@V') !== data)
            setShowSnackbar(true);
        }
      },
    }
  );

  const action = (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>
          {`Version ${appVersion} is now available! Please refresh window.`}
        </div>
        <div>{`Your
        current version is ${window.localStorage.getItem('@@V')}`}</div>
      </div>
      <Button
        color="info"
        size="small"
        onClick={() => {
          window.localStorage.setItem(
            '@@V',
            appVersion ?? window.localStorage.getItem('@@V') ?? ''
          );
          window.location.reload();
        }}
      >
        REFRESH
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowSnackbar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SlideTransition = (props: any) => {
    return <Slide {...props} direction="left" />;
  };

  useEffect(() => {
    const getVersion = setInterval(() => {
      refetch();
    }, 180000);
    return () => clearInterval(getVersion);
  }, [refetch]);

  return (
    <Router>
      <ScrollToTop />
      <RootRouter />

      {appVersion !== window.localStorage.getItem('@@V') && (
        <Snackbar
          open={showSnackBar}
          action={action}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          TransitionComponent={SlideTransition}
        />
      )}
    </Router>
  );
};
