import { DBAddress } from '@prisma/client';

interface DBCreateAddressPayload {
  city: string;
  countryId: string;
  zipCode: string;
  address1: string;
  address2?: string;
}

export type DBUpdateAddressPayload = Partial<DBCreateAddressPayload> & {
  clientId?: string;
  countryId?: string;
};

interface DBAddressOnePayload {
  id: string;
}
interface DBAddressApi {
  list: {
    requestBody: never;
    request: never;
    response: DBAddress[];
  };
  getOne: {
    requestBody: never;
    requestParams: DBAddressOnePayload;
    request: DBAddressOnePayload;
    response: DBAddress;
  };
  create: {
    requestBody: DBCreateAddressPayload;
    requestParams: never;
    request: DBCreateAddressPayload;
    response: { address: DBAddress };
  };
  updateOne: {
    requestBody: DBUpdateAddressPayload;
    requestParams: DBAddressOnePayload;
    request: DBUpdateAddressPayload | DBAddressOnePayload;
    response: DBAddress;
  };
}
export { DBAddressApi };
