import styled from '@emotion/styled';
import { Button, Modal, Typography } from '@mui/material';
import { tyrioIcons } from '@tyrio/ui-library';

interface ExpiredSessionModalProps {
  text: string;
  isOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const ExpiredSessionModal = ({
  text,
  isOpen,
  onCancel,
  onContinue,
}: ExpiredSessionModalProps) => {
  return (
    <Modal
      open={isOpen}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalWrapper>
        <img
          src={tyrioIcons.warning}
          alt="warning"
          style={{ marginTop: '22px' }}
        />
        <Typography fontSize={20} fontWeight={600} letterSpacing={0.3}>
          {text}
        </Typography>
        <Typography
          fontSize={18}
          color="#919EAB"
          letterSpacing={0.3}
          fontWeight={400}
        >
          Do you wish to continue or cancel?
        </Typography>

        <ButtonWrapper>
          <StyledButton
            variant="outlined"
            color="info"
            size="large"
            onClick={onCancel}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="info"
            size="large"
            onClick={onContinue}
          >
            CONTINUE
          </StyledButton>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 430px;
  background: #fafafa;
  box-shadow: 0px 15px 12px rgba(0, 0, 0, 0.22),
    0px 19px 38px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  height: 320px;
  padding: 32px;
`;

const StyledButton = styled(Button)`
  width: 179px;
  height: 60px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 24px;
  margin-top: 16px;
`;
