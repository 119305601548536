import { DBTrafficType } from '@prisma/client';
import { parseEnumToArray } from '../../../helpers/enum-parser';

export const transportModeData = parseEnumToArray(DBTrafficType)
  .sort((a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  })
  .map((item, index) => ({
    value: index + 1,
    label: `${index + 1} - ${item.label}`,
  }));
