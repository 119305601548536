import { Button, Divider } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from '@emotion/styled/macro';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { CancelModal } from '@tyrio/ui-library';

interface FooterProps {
  onSubmit: (data: unknown) => void;
  onCancel: () => void;
}

export const Footer = ({ onSubmit, onCancel }: FooterProps) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <FooterWrapper>
      {isModalOpen && (
        <CancelModal
          LBAction={() => setIsModalOpen(false)}
          RBAction={() => {
            setIsModalOpen(false);
            onCancel();
          }}
        />
      )}
      <Wrapper>
        <Divider
          variant="fullWidth"
          sx={{
            borderWidth: '2px',
            borderColor: '#E6E8F0',
          }}
        />

        <ButtonWrapper>
          <Button
            variant="outlined"
            color="info"
            onClick={() => history.goBack()}
            sx={{ width: '150px', height: '35px' }}
            disableElevation
            startIcon={<ArrowBack />}
          >
            BACK
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setIsModalOpen(true)}
            sx={{ width: '150px', height: '35px' }}
            disableElevation
            startIcon={<CancelIcon />}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={onSubmit}
            sx={{ width: '150px', height: '35px' }}
            disableElevation
          >
            SUBMIT
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  position: sticky;
  top: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 10px;
  width: 100%;
`;
