import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { backIcon, tyrioIcons } from '../../../index';
import FetchDataDescription, {
  FetchDataDesctiptionTypes,
} from '../FetchDataDescription/FetchDataDescription';
import MenuItem from '../Sidebar/MenuItem';

interface SettingsMenuListProps {
  header: string;
  content: Array<{
    link: string;
    icon: string;
    icon2: string;
    label: string;
    isVisible: boolean;
  }>;
  path: string;
  isFetching: boolean;
  isError: boolean;
  setSwitchMenu: (menu: boolean) => void;
}

export const SettingsMenuList = ({
  header,
  content,
  path,
  isFetching,
  isError,
  setSwitchMenu,
}: SettingsMenuListProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  return (
    <Container>
      <HeaderWrapperTop>
        <BackButton onClick={() => history.push(path)}>
          <img src={backIcon} alt="back-icon" />
        </BackButton>
        <HeaderContentWrapper>
          <HeaderText>{t(header)}</HeaderText>

          <img src={tyrioIcons.menuIcon7Black} alt="Company-Settings" />
        </HeaderContentWrapper>
      </HeaderWrapperTop>
      {isFetching && (
        <ContentWrapper>
          <CircularProgress />
        </ContentWrapper>
      )}
      {isError && (
        <ContentWrapper>
          <FetchDataDescription type={FetchDataDesctiptionTypes.FetchError} />
        </ContentWrapper>
      )}
      {content.map((item) =>
        item.isVisible ? (
          <MenuItemLink
            currentItem={item.link}
            currentLocation={location.pathname}
            to={item.link}
            key={item.link}
            onClick={
              !(
                item.link === location.pathname ||
                location.pathname.includes(item.link)
              )
                ? item.label === 'Company Info'
                  ? () => setSwitchMenu(true)
                  : () => setSwitchMenu(false)
                : undefined
            }
          >
            <MenuItem
              source={
                item.link === location.pathname ||
                location.pathname.includes(item.link)
                  ? item.icon2
                  : item.icon
              }
              label={t(item.label)}
              isOpen={true}
              key={item.label}
              color={
                item.link === location.pathname ||
                location.pathname.includes(item.link)
                  ? 'white'
                  : 'black'
              }
            />
          </MenuItemLink>
        ) : null
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
`;

const HeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  place-content: space-between;
`;

const HeaderWrapperTop = styled.div`
  display: flex;
  margin-bottom: 16px;
  border-bottom: solid 1.5px #eceef1;
  padding-bottom: 8px;
  align-items: center;
`;

const MenuItemLink = styled(NavLink)<{
  currentItem?: string;
  currentLocation?: string;
}>`
  width: 100%;
  display: block;
  opacity: 0.6;
  transition: all 0.3s ease;
  text-decoration: none;
  border-radius: 8px;
  margin-top: 16px;
  &.active {
    background: #454f5b;
    opacity: 1;
  }
  border: 0.3px solid #dfe3e8;

  :hover {
    background: ${(props) =>
      props.currentItem === props.currentLocation ? '' : '#ecf0f4'};
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

const ContentWrapper = styled.div`
  margin-top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default SettingsMenuList;
