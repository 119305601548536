import styled from '@emotion/styled/macro';
import HttpsIcon from '@mui/icons-material/Https';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import {
  ActiveOrdersType,
  CustomerPayload,
  DBStockInItemsResponse,
  SSE_ENTITY_KEYS,
  StockInCreatedBy,
  StockInLineItem,
  StockInOrderMeta,
} from '@tyrio/dto';
import { damagedIcon } from '@tyrio/ui-library';
import { Typography } from 'antd';
import _, { startCase } from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import PopoverComponent from '../../components/Popover/Popover';
import { useAuth } from '../../context/AuthContext';
import { useWS } from '../../context/WSContext';
import { getStockInOrderStatusColor } from '../purchase-orders/helpers/generate-color';

interface StockInTableProps {
  setSelectedOrder: Dispatch<SetStateAction<DBStockInItemsResponse | null>>;
  selectedOrder: DBStockInItemsResponse | null;
  data: DBStockInItemsResponse[] | undefined;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isUploadInProcess: boolean;
  checked: Record<string, boolean>;
  setChecked: Dispatch<SetStateAction<Record<string, boolean>>>;
  areAllChecked: boolean;
  checkedSupplier: string;
  setCheckedSupplier: Dispatch<SetStateAction<string>>;
  handleAllChecked: () => void;
  areAllTrue: boolean;
  isSupplierFiltered: boolean;
}

const StockInTable = ({
  data,
  selectedOrder,
  setSelectedOrder,
  isDirty,
  setIsDirty,
  isUploadInProcess,
  checked,
  setChecked,
  areAllChecked,
  checkedSupplier,
  setCheckedSupplier,
  handleAllChecked,
  areAllTrue,
  isSupplierFiltered,
}: StockInTableProps) => {
  const { user } = useAuth();
  const { state } = useWS();

  const [isOrderDesc, setIsOrderDesc] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openOrderDetails = (order: DBStockInItemsResponse) => {
    if (isDirty) {
      const feedback = window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      );
      if (feedback) {
        setSelectedOrder(order);
        setIsDirty(false);
      }
    } else if (isUploadInProcess) {
      window.alert(`You can't switch orders while uploading documents!`);
    } else setSelectedOrder(order);
  };

  const handleOrderSort = () => {
    setIsOrderDesc((prevState) => !prevState);
    return (
      data &&
      data.sort((a, b) => {
        if (a.documentId && b.documentId) {
          return !isOrderDesc
            ? a.documentId < b.documentId
              ? 1
              : -1
            : a.documentId > b.documentId
            ? 1
            : -1;
        } else return 0;
      })
    );
  };

  useEffect(() => {
    if (_.isEmpty(checked)) setCheckedSupplier('');
    Object.keys(checked).forEach((key) => {
      if (!checked[key] && _.size(checked) !== 0) {
        const filteredChecked: Record<string, boolean> = _.omit(checked, key);
        setChecked(filteredChecked);
      }
    });
  }, [checked, setChecked, setCheckedSupplier]);

  const handleCheckedChange = (id: string, orderMeta: StockInOrderMeta) => {
    if (_.isEmpty(checked)) setCheckedSupplier(orderMeta.supplierId);
    setChecked((prevValue) => ({
      ...prevValue,
      [id]: !prevValue[id],
    }));
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginTop: '8px',
        borderRadius: 0,
      }}
    >
      <StyledTable aria-label="simple table">
        <StyledTableHead>
          <StyledTableRowHeader>
            <TableCell
              style={{
                textAlign: 'center',
                width: '20px',
                padding: 0,
                paddingLeft: '16px',
              }}
            >
              <Grid item xs={12} lg={2.5} style={{ width: '20px' }}>
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      color="info"
                      checked={areAllChecked || areAllTrue}
                      onChange={handleAllChecked}
                      disabled={_.isEmpty(checked) && !isSupplierFiltered}
                    />
                  }
                  style={{ margin: 0 }}
                />
              </Grid>
            </TableCell>
            <CellWithSort>Status</CellWithSort>
            <Tooltip
              title={
                isOrderDesc
                  ? 'Click to sort ascending'
                  : 'Click to sort descending'
              }
              placement="top"
              componentsProps={{
                tooltip: { style: { background: '#1B2130' } },
                arrow: { style: { color: '#1B2130' } },
              }}
              followCursor
              arrow
            >
              <StyledTableHeaderCell>
                <TableSortLabel
                  IconComponent={UnfoldMoreIcon}
                  sx={{
                    '& .MuiTableSortLabel-icon': {
                      opacity: 1,
                    },
                  }}
                  onClick={handleOrderSort}
                >
                  docID
                </TableSortLabel>
              </StyledTableHeaderCell>
            </Tooltip>
            <StyledTableHeaderCell>Supplier</StyledTableHeaderCell>
            <StyledTableHeaderCell>Order</StyledTableHeaderCell>
            <StyledTableHeaderCell>Reference</StyledTableHeaderCell>
            <StyledTableHeaderCell>Remark</StyledTableHeaderCell>
            <TableCell />
            <StyledTableHeaderCell>
              <QuantityHeaderWrapper>Quantity</QuantityHeaderWrapper>
            </StyledTableHeaderCell>
          </StyledTableRowHeader>
        </StyledTableHead>
        <TableBody>
          {data &&
            data.map((order: DBStockInItemsResponse) => {
              const isFavorite = () => {
                if (user) return order?.favoritedBy.includes(user.id);
                return false;
              };

              const getTableRowColor = () => {
                if (selectedOrder?.id === order.id) return '#E4F5FE';
                if (isFavorite()) return '#fffbf2';
                return '#fff';
              };

              const shouldShowDamaged = (
                order.lineItems as unknown as StockInLineItem[]
              ).find((item) => item.damaged);

              const orderMeta: StockInOrderMeta =
                order?.orderMeta as unknown as StockInOrderMeta;

              const lockEntity =
                state.locks &&
                state.locks[`${SSE_ENTITY_KEYS.STOCK_IN_ORDER}/${order.id}`];

              const shouldSetOpacity =
                lockEntity !== undefined && lockEntity !== null;

              const customerDetails =
                order.customerDetails as unknown as CustomerPayload;

              const customerName = customerDetails
                ? customerDetails?.companyInfo &&
                  customerDetails?.companyInfo?.companyName &&
                  customerDetails?.companyInfo?.companyName !== ''
                  ? customerDetails?.companyInfo?.companyName
                  : customerDetails.firstName && customerDetails.lastName
                  ? `${customerDetails.firstName} ${customerDetails.lastName}`
                  : ''
                : '';

              return (
                <StyledTableRow
                  sx={{
                    background: getTableRowColor(),
                    '&:hover': {
                      background: isFavorite() ? '#fef8e9' : '#F9FAFB',
                      borderBottom: '1.5px solid rgba(224, 224, 224, 1)',
                      cursor: 'pointer',
                    },
                    position: 'relative',
                  }}
                  onClick={() => openOrderDetails(order)}
                  key={order.id}
                >
                  <StyledTableCell
                    isFavorited={isFavorite()}
                    style={{
                      textAlign: 'center',
                      width: '20px',
                      padding: '16px',
                    }}
                    onClick={(e) => e.stopPropagation()}
                    opacity={shouldSetOpacity}
                  >
                    {order?.status !== 'CANCELLED' && (
                      <Grid item xs={12} lg={2.5} style={{ width: '20px' }}>
                        <FormControlLabel
                          label=""
                          control={
                            <Checkbox
                              color="info"
                              checked={checked[order.id] || false}
                              value={checked[order.id] || false}
                              onChange={() =>
                                handleCheckedChange(order.id, orderMeta)
                              }
                              disabled={
                                (orderMeta.supplierId !== checkedSupplier &&
                                  checkedSupplier !== '') ||
                                orderMeta.requestType ===
                                  ActiveOrdersType.TRANSFER_ORDER ||
                                order?.status !== 'CONFIRMED'
                              }
                            />
                          }
                          style={{ margin: 0 }}
                        />
                      </Grid>
                    )}
                  </StyledTableCell>

                  <StyledTableCell
                    isFavorited={isFavorite()}
                    style={{ textAlign: 'center', width: '180px' }}
                    opacity={shouldSetOpacity}
                  >
                    <CenterStatus>
                      <OrderStatus
                        color={getStockInOrderStatusColor[order.status]}
                      >
                        {startCase(order.status)}
                      </OrderStatus>

                      <CellDetails>
                        {(order.createdBy as unknown as StockInCreatedBy)
                          .firstName ?? ''}{' '}
                        {(order.createdBy as unknown as StockInCreatedBy)
                          .lastName ?? ''}
                      </CellDetails>
                    </CenterStatus>
                  </StyledTableCell>

                  <StyledTableCell
                    isFavorited={isFavorite()}
                    style={{ width: '120px' }}
                    opacity={shouldSetOpacity}
                  >
                    <div>{order?.documentId ?? ''}</div>
                    <CellDetails>
                      {`${moment(order.createdAt).format('DD.MM.YYYY - h:mm')}`}
                    </CellDetails>
                  </StyledTableCell>

                  <StyledTableCell
                    isFavorited={isFavorite()}
                    style={{ width: '180px' }}
                    opacity={shouldSetOpacity}
                  >
                    <div
                      style={{
                        color:
                          orderMeta.supplierId === 'UNKNOWN' &&
                          order.orderType === 'PURCHASE_ORDER'
                            ? 'red'
                            : !_.isEmpty(order.orderMeta) ||
                              !_.isEmpty(order.receivedFromMeta)
                            ? 'black'
                            : 'red',
                      }}
                    >
                      {_.has(order.receivedFromMeta, 'branchName')
                        ? _.get(order.receivedFromMeta, 'branchName')
                        : orderMeta.supplierName &&
                          orderMeta.supplierName !== 'UNKNOWN'
                        ? orderMeta.supplierName
                        : 'Unknown'}
                    </div>
                  </StyledTableCell>

                  <StyledTableCell
                    isFavorited={isFavorite()}
                    style={{ width: '120px' }}
                    opacity={shouldSetOpacity}
                  >
                    <div
                      style={{
                        color:
                          orderMeta.orderNumber && orderMeta.orderNumber !== 0
                            ? 'black'
                            : 'red',
                      }}
                    >
                      {orderMeta.orderNumber
                        ? `${orderMeta.orderNumber}`
                        : 'Unmatched'}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    isFavorited={isFavorite()}
                    style={{ width: '180px' }}
                    opacity={shouldSetOpacity}
                  >
                    <div style={{ color: 'black' }}>{orderMeta.reference}</div>
                    {order.reference !== 'FOR STOCK' && customerName && (
                      <CellDetails>{customerName}</CellDetails>
                    )}
                  </StyledTableCell>

                  <StyledTableCell
                    isFavorited={isFavorite()}
                    style={{ width: '150px' }}
                    opacity={shouldSetOpacity}
                  >
                    {order.remark}
                  </StyledTableCell>

                  <StyledTableCell isFavorited={isFavorite()}>
                    <StyledStack>
                      {lockEntity && (
                        <Tooltip
                          title={`Locked by ${lockEntity?.firstName ?? ''}  ${
                            lockEntity?.lastName ?? ''
                          }`}
                        >
                          <HttpsIcon sx={{ height: '20px' }} color="error" />
                        </Tooltip>
                      )}

                      {shouldShowDamaged && (
                        <DamagedWrapper>
                          <Damaged>
                            <img src={damagedIcon} alt="damaged-icon" />
                            <div style={{ fontSize: '12px' }}>DAMAGED</div>
                          </Damaged>
                        </DamagedWrapper>
                      )}
                    </StyledStack>
                  </StyledTableCell>

                  <StyledTableCell
                    isFavorited={isFavorite()}
                    style={{ width: '94px', paddingRight: 0 }}
                    opacity={shouldSetOpacity}
                  >
                    <QunatityPopoverWrapper>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 400,
                          fontFamily: 'Barlow',
                        }}
                      >
                        {order.quantity}
                      </Typography>
                      <div onClick={(e) => e.stopPropagation()}>
                        <IconButton
                          aria-describedby={order.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </div>
                    </QunatityPopoverWrapper>
                  </StyledTableCell>
                  <PopoverCell>
                    {anchorEl && (
                      <PopoverComponent
                        id={order.id}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        shouldShowOrderAgain={false}
                      />
                    )}
                  </PopoverCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

const StyledTable = styled(Table)`
  white-space: nowrap;
`;

const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 2;
`;

const StyledTableRowHeader = styled(TableRow)`
  background: #ecf0f4;
`;

export const OrderStatus = styled.div<{
  color: string;
}>`
  width: fit-content;
  border-radius: 100px;
  padding: 5px;
  padding-left: 7px;
  padding-right: 7px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  height: 24px;
  background: ${(prop) => prop.color};
`;

const PopoverCell = styled(TableCell)`
  position: absolute;
  right: 0;
  border: 0;
`;

const StyledTableRow = styled(TableRow)`
  height: 60px;
`;

const CellWithSort = styled(TableCell)`
  text-align: center;
`;

const CellDetails = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #919eab;
`;

const CenterStatus = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  align-items: center;
`;

const StyledTableCell = styled(TableCell)<{
  isFavorited?: boolean;
  opacity?: boolean;
}>`
  border-bottom: ${(props) => (props.isFavorited ? '1px solid #FFCC4D' : '')};
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-family: Barlow;
  text-align: start;
  opacity: ${(props) => (props.opacity ? '0.3' : '1')};
`;

const QunatityPopoverWrapper = styled.div`
  background: #f4f6f8;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 56px;
  gap: 10px;
`;

const DamagedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 5px;
`;

const Damaged = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledStack = styled(Stack)`
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const StyledTableHeaderCell = styled(TableCell)`
  padding: 0;
  text-align: start;
  padding-left: 16px;
`;

const QuantityHeaderWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 16px;
`;

export default StockInTable;
