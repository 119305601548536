import InboundInvoiceProvider from '../../../context/InboundInvoiceContext';
import { StockInOverview } from '../../../features/stock-in/StockInOverview';

interface StockInProps {
  sidebarOpen: boolean;
}

const StockIn = ({ sidebarOpen }: StockInProps) => {
  return (
    <InboundInvoiceProvider>
      <StockInOverview sidebarOpen={sidebarOpen} />
    </InboundInvoiceProvider>
  );
};

export default StockIn;
