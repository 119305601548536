import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { SortingDropdown } from './SortingDropdown';
import styled from '@emotion/styled';

export const SuppliersHeader = ({
  visible,
  setVisible,
  sortingValue,
  setSortingValue,
}: {
  visible: boolean;
  setVisible: (a: boolean) => void;
  sortingValue: string;
  setSortingValue: (a: string) => void;
}) => {
  const renderIcon = () => {
    return (
      <div onClick={() => setVisible(!visible)}>
        {visible ? <VisibilityIcon /> : <VisibilityOffIcon opacity="0.5" />}
      </div>
    );
  };
  return (
    <Wrapper>
      {renderIcon()}
      <SortingDropdown
        sortingValue={sortingValue}
        setSortingValue={setSortingValue}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding-left: 16px;
`;
