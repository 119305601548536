import {
  DBClient,
  DBProduct,
  DBProductCategory,
  DBProductModel,
  DBStockInItemStatus,
  DBStockInItemType,
  DBStockInItems,
  DBWarehouseBins,
  DBWarehouseShelfs,
  Prisma,
} from '@prisma/client';
import { PurchaseOrderLineItem } from './client-supplier-order-item';
import { OrderLineItemMeta } from './client-supplier-order';

export interface DBStockInListRequest {
  searchType?: 'reference' | 'purchaseOrder' | 'ean' | 'sku';
  searchKeyword?: string;
  branchId?: string;
  supplierId?: string;
  startDate?: Date;
  endDate?: Date;
  orderStatus?: DBStockInItemStatus | string;
  page?: number;
  pageSize?: number;
}

interface DBStockInOnePayload {
  status?: DBStockInItemStatus;
  remark: string;
  priceMeta: Record<string, PriceMeta>;
  orderMeta?: StockInOrderMeta;
}

export interface DBStockInItemsResponse extends DBStockInItems {
  client?: DBClient | undefined;
}

export interface DBStockInOrderItemCountByStatus {
  _count: number;
  status: DBStockInItemStatus | 'all';
}

export interface StockInLineItem {
  ean: string;
  sku: string;
  uid: string;
  manufacturerCode: string;
  damaged: boolean;
  damagedItems?: [];
  productName: string;
  productionYear: string;
  ordered: number;
  backordered: number;
  received: number;
  brand: string;
  quantity: number;
}

export interface StockInOrderMeta {
  orderId: string;
  orderNumber: number;
  supplierId: string;
  supplierName: string;
  requestSource: string;
  reference: string;
  requestType: ActiveOrdersType;
}

export interface StockInDestinationMeta {
  branchId: number;
  branchName?: string;
  city: string;
  zipCode: string;
  address: string;
}

export enum ActiveOrdersType {
  PURCHASE_ORDER,
  TRANSFER_ORDER,
  UNKNOWN,
}

export interface ActivePurchaseAndTransferOrdersTypes {
  type: ActiveOrdersType;
  id: string;
  source: string;
  ordersCount: number;
  timestamp: Date;
}

export interface ActivePurchaseTransferOrdersBySupplier {
  orderId: string;
  orderNumber: number;
  supplierId: string;
  supplierName: string;
  lineItems: StockInLineItem[];
  destinationMeta: StockInDestinationMeta;
  branchId?: string | null;
  // add type for this
  orderLineItemMeta?: Prisma.JsonValue;
}

export interface ProductsWithNewCategoryPayload {
  clientId: string;
  categoryId: number;
  brand: string;
  modelName: string;
  eanSkuManufacturerCode: string;
  productName: string;
}

export interface StockInRequestProducts {
  productData: DBProduct & {
    model?: (DBProductModel & { category: DBProductCategory | null }) | null;
  };
  quantity: number;
}

export interface StockInRequest {
  body: { [orderId: string]: OrderItem[] };
  stockInDetails: {
    products: {
      [ean: string]: StockInRequestProducts;
    };
    locationDetails: {
      data: DBWarehouseShelfs | DBWarehouseBins;
      type: string;
    };
  };
  meta?: StockInRequestMeta;
}

export interface StockInRequestMeta {
  type: ActiveOrdersType;
  id: string;
  source: string;
  ordersCount: number;
  timestamp: Date;
}

export interface OrderItem {
  ean: string;
  orderDetails: OrderDetails;
  ordered: number;
  productId?: string;
  received: number;
  productBrand: string;
  productName: string;
  productionYear: string;
  toReceive?: number;
  sku: number;
  manufacturerCode: number;
  damaged: boolean;
  quantity: number;
  uid?: string;
  orderId?: string;
}

export interface OrderDetails {
  status: DBStockInItemStatus;
  documentId: string;
  documentMeta: Prisma.JsonValue;
  orderType: DBStockInItemType;
  receivedFromMeta: StockInDestinationMeta;
  quantity: number;
  orderMeta: StockInOrderMeta;
  destinationMeta: StockInDestinationMeta;
  remark: string;
  reference: string;
  isLocked: boolean;
  referenceMeta: Prisma.JsonValue;
  favoritedBy: never[];
}

export interface ProductDimensions {
  width: string | null;
  height: string | null;
  productName: string;
  productName2: string;
  ean: string;
  manufacturerCode: string;
  uid: string;
  wetGrip: string | null;
  rollingResistance: string | null;
  noisePerformance: number | null;
  size: string | null;
  weight: string | null;
  electric: boolean | null;
  rfd: string | null;
  quantity: number;
  rimDiameter: string | null;
  model: {
    modelName: string | null;
    season: string | null;
    brand: string | null;
    category: {
      id: number;
      parent_category_id: number | null;
      name: string;
      slug: string;
    };
  };
}

export interface PriceMeta {
  suggestedRetailPrice: number;
  purchasePrice: number;
}

export interface GetOrderPricesReq {
  id: string;
}

export type GetOrderPricesRes = Record<
  string,
  {
    purchasePrice: number;
    suggestedRetailPrice: number;
  }
>;

export interface ActivePurchaseAndTransferOrdersList {
  type: string;
  id: string;
  source: string;
  ordersCount: number;
  timestamp: Date;
}

export interface StockInSupplierOrdersReq {
  supplierId: string;
}

export interface DestinationMeta {
  branchId: number;
  branchName?: string;
  city: string;
  zipCode: string;
  address: string;
}

export interface StockInSupplierOrdersRes {
  orderId: string;
  lineItems: PurchaseOrderLineItem[];
  supplierId: string;
  orderNumber: number;
  internalOrderNumber: string;
  supplierName: string;
  destinationMeta: DestinationMeta;
  receivedFromMeta?: DestinationMeta;
  branchId: number;
  branchName: string;
  orderLineItemMeta: OrderLineItemMeta;
  reference: string | null;
  orderReference: string | null;
  count: number;
}

export interface StockInCreatedBy {
  firstName: string;
  lastName: string;
  userId: string;
}

export interface DBStockInListApi {
  list: {
    requestBody: DBStockInListRequest;
    request: DBStockInListRequest;
    response: { data: DBStockInItemsResponse[]; count: number };
  };
  getOne: {
    requestBody: { orderId: string };
    requestParams: { orderId: string };
    request: { orderId: string };
    response: DBStockInItemsResponse;
  };
  create: {
    requestBody: StockInRequest;
    requestParams: never;
    request: StockInRequest;
    response: DBStockInItemStatus | null;
  };
  updateOne: {
    requestBody: DBStockInOnePayload;
    requestParams: { orderId: string };
    request: DBStockInOnePayload | { orderId: string };
    response: DBStockInItems;
  };
  getOrderPrices: {
    requestBody: GetOrderPricesReq;
    requestParams: GetOrderPricesReq;
    request: GetOrderPricesReq;
    response: GetOrderPricesRes;
  };
  getSuppliers: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: ActivePurchaseAndTransferOrdersList[];
  };
}
