import {
  DBApiConnection,
  DBConnectionProtocol,
  DBConnectionType,
  DBEmailConnection,
  DBFile,
  DBFileFormat,
  DBFtpConnection,
  DBHttpsConnection,
  DBRepeatUnit,
  DBSupplier,
  DBSupplierContact,
  DBSupplierLocation,
  DBSupplierType,
} from '@prisma/client';
import { DBSupplierConnectionApi } from './supplier-connections';
import { DBSupplierContactApi } from './supplier-contact';
import { DBSupplierLocationApi } from './supplier-location';

interface DBUpsertSupplierPayload {
  id?: string;
  companyLogoId: string | null;
  supplierType: DBSupplierType | string;
  vatNumber: string;
  viesStatus: boolean;
  companyOfficialName: string;
  companyShortName: string;
  slug: string;
  countryId: string;
  zipCode: string;
  city: string;
  address: string;
  remark: string;
  isActive: boolean;
  supplierContacts: Array<DBSupplierContactApi['create']['requestBody']>;
  supplierLocations: Array<DBSupplierLocationApi['create']['requestBody']>;
  supplierConnections: Array<DBSupplierConnectionApi['create']['requestBody']>;
}

interface DBGetSuppliersRequest {
  search: string;
  clientId?: string;
  page?: number;
  pageSize?: number;
}

interface DBSupplierOnePayload {
  id: string;
}

type DBSupplierRestResponse = {
  supplierContacts: DBSupplierContact[];
  supplierLocations: DBSupplierLocation[];
  supplierConnections: DBSupplierConnectionResponse[];
  companyLogo: DBFile | null;
};

type DBSupplierConnectionResponse = {
  id: string;
  connectionName: string;
  connectionTypes: DBConnectionType[];
  active: boolean;
  connectionProtocol: DBConnectionProtocol;
  fileFormat: DBFileFormat;
  rulesetKey: string | null;
  remark: string;
  ftpConnection?: DBFtpConnection | null;
  apiConnection?: DBApiConnection | null;
  httpsConnection?: DBHttpsConnection | null;
  emailConnection?: DBEmailConnection | null;
  minimumUpdateFrequencyUnit?: DBRepeatUnit | null;
  minimumUpdateFrequencyTime?: number | null;
};

type DBSupplierResponse = DBSupplier & DBSupplierRestResponse;

interface DBSupplierApi {
  list: {
    requestBody: DBGetSuppliersRequest;
    request: DBGetSuppliersRequest;
    response: { data: DBSupplierResponse[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBSupplierOnePayload;
    request: DBSupplierOnePayload;
    response: DBSupplierResponse;
  };
  create: {
    requestBody: DBUpsertSupplierPayload;
    requestParams: never;
    request: DBUpsertSupplierPayload;
    response: DBSupplier;
  };
  updateOne: {
    requestBody: DBUpsertSupplierPayload;
    requestParams: DBSupplierOnePayload;
    request: DBUpsertSupplierPayload | DBSupplierOnePayload;
    response: DBSupplier;
  };
}

export { DBSupplierApi, DBGetSuppliersRequest, DBSupplierConnectionResponse };
