import { DBFtpConnection, DBRepeatUnit } from '@prisma/client';

interface DBUpsertSupplierFtpConnectionPayload {
  id?: string;
  supplierFileName: string;
  fileLocation: string;
  ftpHost: string;
  ftpPort: string;
  ftpUserName: string;
  ftpPassword: string;
  startUpdateTime: Date;
  repeatEvery?: number;
  repeatUntil: DBRepeatUnit | string;
  remark: string;
}

interface DBSupplierFtpConnectionApi {
  list: {
    requestBody: never;
    request: never;
    response: never[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierFtpConnectionPayload;
    requestParams: never;
    request: DBUpsertSupplierFtpConnectionPayload;
    response: DBFtpConnection;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierFtpConnectionApi };
