import { DBPaymentTypes, DBRole } from '@prisma/client';

export interface CardSettings {
  installements?: string;
  acceptedCards?: string[];
}

export interface Restrictions {
  minAmount?: number;
  maxAmount?: number;
}

type DBPaymentTypesCreatePayload = Omit<
  DBPaymentTypes,
  'id, clientId, restriction, cardSettings'
> & {
  restrictions?: Restrictions;
  cardSettings?: CardSettings;
};

type UpdateOne = DBPaymentTypesCreatePayload & { id: string };

export interface QueryOptions {
  search?: string;
  page?: number;
  pageSize?: number;
}
interface ActiveStatus {
  id: string;
  active: boolean;
}

export interface IPaymentMethods {
  [key: string]: boolean;
}

interface DBPaymentTypesApi {
  list: {
    requestParams: { branchId?: string };
    requestBody: QueryOptions;
    request: QueryOptions & { branchId?: string };
    response: { data: DBPaymentTypes[]; count: number };
  };
  getOne: {
    requestBody: { id: string };
    requestParams: { id: string };
    request: { id: string };
    response: Omit<DBPaymentTypes, 'cardSettings,restriction'> & {
      cardSettings?: CardSettings;
      restrictions?: Restrictions;
    };
  };
  create: {
    requestBody: DBPaymentTypesCreatePayload;
    requestParams: never;
    request: DBPaymentTypesCreatePayload;
    response: DBRole;
  };
  updateOne: {
    requestBody: UpdateOne;
    requestParams: { id: string };
    request: DBPaymentTypesCreatePayload;
    response: DBPaymentTypes;
  };
  deleteOne: {
    requestBody: { id: string };
    requestParams: { id: string };
    request: { id: string };
    response: DBPaymentTypes;
  };
  activeStatus: {
    requestBody: ActiveStatus;
    requestParams: never;
    request: ActiveStatus;
    response: DBPaymentTypes;
  };
}

export { DBPaymentTypesApi };
