interface ViesValidationResponse {
  countryCode: string;
  vatNumber: string;
  requestDate: Date;
  valid: boolean;
  name: string;
  address: string;
}

export { ViesValidationResponse };
