/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Slide, Snackbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import api from '@tyrio/api-factory';
import { AxiosError } from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { isSignInWithLink } from '../../../../../app/src/helpers/firebase';
import NoPermissionsPage from '../components/NoPermissionsPage/NoPermissionsPage';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import { useAuth } from '../context/AuthContext';
import AuthLayout from '../layouts/AuthLayout';
import DeactivatedUser from '../layouts/DeactivatedUser';
import { FullScreenLoader } from '../layouts/FullScreenLoader';
import { NetworkError } from '../layouts/NetworkError';
import routeNames from '../lib/routeNames';
import { DashboardRouter } from './DashboardRouter';

export const RootRouter = () => {
  const { user, userError, userLoading } = useAuth();

  const auth = getAuth();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);

  const { data: appVersion, refetch } = useQuery(
    'get_version',
    () => api.fetch('get_version'),
    {
      onSuccess(data: string) {
        if (!window.localStorage.getItem('@@V')) {
          window.localStorage.setItem('@@V', data);
          return;
        } else {
          if (window.localStorage.getItem('@@V') === data) {
            setShowSnackBar(false);
            return;
          } else if (window.localStorage.getItem('@@V') !== data) {
            console.log('this should happen');
            setShowSnackBar(true);
          }
        }
      },
    }
  );

  useEffect(() => {
    const { apiKey } = qs.parse(location.search.slice(1));
    if (apiKey && history) isSignInWithLink();
  }, [location.search, history]);

  useEffect(() => {
    const AuthCheck = onAuthStateChanged(auth, (user) => {
      if (user) setLoading(false);
      else console.log('unauthorized');
    });

    return () => AuthCheck();
  }, [auth, history]);

  useEffect(() => {
    const getVersion = setInterval(() => {
      refetch();
    }, 180000);
    return () => clearInterval(getVersion);
  }, [refetch, showSnackBar]);

  const isUserDeactivated = useMemo(() => {
    if (userError && 'response' in (userError as any)) {
      const errorWithResponse = userError as AxiosError<unknown>;
      if (errorWithResponse && 'status' in errorWithResponse) {
        const status = errorWithResponse.status;

        return status === '401' || Number(status) === 401;
      }
    }
    return false;
  }, [userError]);

  const isNetworkError = useMemo(() => {
    if (userError && 'response' in (userError as any)) {
      const errorWithResponse = userError as AxiosError<unknown>;
      if (errorWithResponse && 'code' in errorWithResponse) {
        const status = errorWithResponse.code;

        return status === 'ERR_NETWORK';
      }
    }
    return false;
  }, [userError]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SlideTransition = (props: any) => {
    return <Slide {...props} direction="left" />;
  };

  if (loading) return <CircularProgress />;

  const action = (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div>
          {`Version ${appVersion} is now available! Please refresh window.`}
        </div>
        <div>{`Your
  current version is ${window.localStorage.getItem('@@V')}`}</div>
      </div>
      <Button
        color="info"
        size="small"
        onClick={() => {
          window.localStorage.setItem(
            '@@V',
            appVersion ?? window.localStorage.getItem('@@V') ?? ''
          );
          // clear cache
          window.location.href =
            window.location.href.split('?')[0] +
            '?clearCache=' +
            new Date().getTime();
          window.location.reload();
        }}
      >
        REFRESH
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowSnackBar(false)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {appVersion !== window.localStorage.getItem('@@V') && showSnackBar && (
        <Snackbar
          open={showSnackBar}
          action={action}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          TransitionComponent={SlideTransition}
        />
      )}
      <Switch>
        {isNetworkError && (
          <>
            <Route path={'/error-500'}>
              <NetworkError />
            </Route>
            <Redirect to={'/error-500'} />
          </>
        )}

        {userLoading && <FullScreenLoader />}

        {isUserDeactivated && (
          <Route path={routeNames.root()}>
            <DeactivatedUser />
          </Route>
        )}

        {!user && (
          <>
            <Route exact path={routeNames.signin()}>
              <AuthLayout />
            </Route>
            {!location.search && <Redirect to={routeNames.signin()} />}
          </>
        )}

        {!!user && (
          <>
            {(location.pathname === '/' ||
              location.pathname === '/sign-in' ||
              location.pathname === '/error-500') && (
              <Redirect to={routeNames.dashboard()} />
            )}
            <Route path={routeNames.dashboard()}>
              <DashboardRouter user={user} />
            </Route>
          </>
        )}

        <Route exact path={routeNames.noPermission()}>
          <NoPermissionsPage />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};
