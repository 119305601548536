import { FEATURE_FLAGS, isFlagEnabled } from '@tyrio/feature-flags';
import { tyrioIcons } from '@tyrio/ui-library';

//TODO: Get exact list of menu icons.
export const menuLinksAdmin = [
  {
    link: '/dashboard/admin',
    icon: tyrioIcons.adminIcon,
    label: 'Admin Users',
    isVisible: true,
  },
  {
    link: '/dashboard/supplier',
    icon: tyrioIcons.supplierIcon,
    label: 'Suppliers',
    isVisible: true,
  },
  {
    link: '/dashboard/clients',
    icon: tyrioIcons.clientIcon,
    label: 'Clients',
    isVisible: true,
  },
  {
    link: '/dashboard/shipping-couriers',
    icon: tyrioIcons.menuIcon3,
    label: 'Shipping Couriers',
    isVisible: true,
  },
  {
    link: '/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1',
    icon: tyrioIcons.cataloguesIcon,
    label: 'Catalogues',
    isVisible: true,
    subcategories: [
      {
        link: '/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1',
        label: 'Product Items',
        isVisible: true,
      },
      {
        link: '/dashboard/categories',
        label: 'Product Categories',
        isVisible: true,
      },
      {
        link: '/dashboard/attributes',
        label: 'Product Attributes',
        isVisible: true,
      },
    ],
  },
  {
    link: '/dashboard/roles',
    icon: tyrioIcons.menuIcon7White,
    label: 'Settings',
    isVisible: true,
    subcategories: [
      {
        link: '/dashboard/roles',
        label: 'Roles',
        isVisible: true,
      },
      {
        link: '/dashboard/import-status',
        label: 'Import status',
        isVisible: true,
      },
      {
        link: '/dashboard/countries',
        label: 'Countries',
        isVisible: true,
      },
    ],
  },
];

const _menuLinksClientsBase = [
  {
    link: '/dashboard/customer-orders',
    icon: tyrioIcons.menuIcon3,
    label: 'Sales',
    isVisible: true,
    subcategories: [
      {
        link: '/dashboard/pos',
        label: 'POS',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_POS) ?? false,
      },
      {
        link: '/dashboard/appointments',
        label: 'Appointments',
        isVisible:
          isFlagEnabled(FEATURE_FLAGS.SHOW_APPOINTMENT_CALENDAR) ?? false,
      },
      {
        link: '/dashboard/customer-orders',
        label: 'Customer Orders',
        isVisible: true,
      },
      {
        link: '/dashboard/price-calculation',
        label: 'Price Calculation',
        isVisible: true,
      },
      {
        link: '/dashboard/custom-price-catalog',
        label: 'Custom Prices',
        isVisible:
          isFlagEnabled(FEATURE_FLAGS.SHOW_CUSTOM_PRICE_CATALOG) ?? false,
      },
      {
        link: '/dashboard/customer-rebates',
        label: 'Customer Rebates',
        isVisible: true,
      },
    ],
  },
  {
    link: '/dashboard/price-comparison',
    icon: tyrioIcons.menuIcon5,
    label: 'Purchasing',
    isVisible: true,
    subcategories: [
      {
        link: '/dashboard/price-comparison',
        label: 'Price Compare',
        isVisible: true,
      },
      {
        link: '/dashboard/purchase-orders',
        label: 'Purchase Orders',
        isVisible: true,
      },
      {
        link: '/dashboard/supplier',
        label: 'Supplier Settings',
        isVisible: true,
      },
    ],
  },
  {
    link: '/dashboard/wms',
    icon: tyrioIcons.menuIcon10,
    label: 'WMS',
    isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_WMS) ?? false,
    subcategories: [
      {
        link: '/dashboard/location-details',
        label: 'Stock locations',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_STOCK_LOCATIONS) ?? false,
      },
      {
        link: '/dashboard/warehouse',
        label: 'Warehouse locations',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_STOCK_LOCATIONS) ?? false,
      },
      {
        link: '/dashboard/stock-list',
        label: 'Stock list',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_STOCK_LIST) ?? false,
      },
      {
        link: '/dashboard/transfer-orders?searchType=orderId&searchKeyword=&branchId=All&type=All&orderStatus=all&pageSize=20&page=1',
        label: 'Transfer orders',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_TRANSFER_ORDERS) ?? false,
      },
      {
        link: '/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&supplier=all&orderStatus=new',
        label: 'Stock IN',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_STOCK_IN) ?? false,
      },
      {
        link: '/dashboard/stock-list-import',
        label: 'Stock list import',
        isVisible: isFlagEnabled(FEATURE_FLAGS.STOCK_LIST_IMPORT) ?? false,
      },
      {
        link: '/dashboard/work-orders',
        label: 'Stock out dashboard',
        isVisible: true,
      },
    ],
  },
  {
    link: '/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1',
    icon: tyrioIcons.menuIcon8,
    label: 'Catalogues',
    isVisible: true,
    subcategories: [
      {
        link: '/dashboard/product-models?searchFilter=modelName&search=&pageSize=10&page=1',
        label: 'Product Items',
        isVisible: true,
      },
      {
        link: '/dashboard/services-category',
        label: 'Services',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_SERVICES) ?? false,
      },
      {
        link: '/dashboard/partners',
        label: 'Partners',
        isVisible: isFlagEnabled(FEATURE_FLAGS.SHOW_PARTNERS) ?? false,
      },
    ],
  },
];

export const menuLinksClientSuperAdmin = [
  ..._menuLinksClientsBase,
  {
    link: '/dashboard/company-settings',
    icon: tyrioIcons.menuIcon7White,
    label: 'Settings',
    isVisible: true,
  },
];

export const menuLinksClientOthers = [
  ..._menuLinksClientsBase,
  {
    link: '/dashboard/roles',
    icon: tyrioIcons.menuIcon7White,
    label: 'Settings',
    isVisible: true,
    subcategories: [
      {
        link: '/dashboard/roles',
        label: 'Roles',
        isVisible: true,
      },
      {
        link: '/dashboard/company-settings',
        label: 'Company Settings',
        isVisible: true,
      },
    ],
  },
];
