import {
  DBWarehouseBins,
  DBWarehouseFloors,
  DBWarehouseRacks,
  DBWarehouseSections,
  DBWarehouseShelfs,
  DBWarehouseSubzones,
  DBWarehouseZones,
} from '@prisma/client';
import {
  Locations,
  SelectedLocation,
  ShelfMeta,
  WarehouseBranchResponse,
  WarehouseLocations,
  WarehouseSettings,
} from '@tyrio/dto';
import _ from 'lodash';
import { RefObject } from 'react';
import { PrintModeType } from '../components/sticker/PrintSticker';
import { VerticalStickerType } from '../components/sticker/VerticalSticker';

export const getCurrent = (selected: SelectedLocation, att: string) => {
  const lastNonNullAttribute = _.findLastKey(
    selected.locations,
    (value) => value !== null
  );

  const currentZone = (selected.locations.zone?.settings as WarehouseSettings)
    ?.color;

  let subzone = undefined;

  if (selected.locations.subzone) {
    subzone = selected.locations.subzone;
  }

  if (lastNonNullAttribute) {
    const obj = {
      id: _.get(selected.locations, lastNonNullAttribute).id,
      type: lastNonNullAttribute,
      code: _.get(selected.locations, lastNonNullAttribute).code,
      qrCode: _.get(selected.locations, lastNonNullAttribute).qrCode,
      isActive: _.get(selected.locations, lastNonNullAttribute).settings
        .isActive,
      displayName: _.get(selected.locations, lastNonNullAttribute).displayName,
      subzoneName: subzone?.name,
      color: currentZone,
      description: _.get(selected.locations, lastNonNullAttribute).description,
      stagingType: format(
        (subzone?.settings as WarehouseSettings)?.stagingType as string
      ),
    };
    return _.get(obj, att);
  }
};

export function replaceFromStart(newSubstring: string, endIndex: number) {
  return _.replace('***-**-*-*', new RegExp(`^.{0,${endIndex}}`), newSubstring);
}

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)) as unknown as string,
    10
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function isLetter(char: string) {
  return /^[A-Za-z]$/.test(char);
}

export function isNumber(str: string) {
  return /^(100|[0-9][0-9]?)$/.test(str);
}

export function filterLocations(
  location: string,
  warehouse: WarehouseBranchResponse | null
) {
  const splitted = location.split('');

  let obj: Locations = {
    zone: null,
    subzone: null,
    floor: null,
    rack: null,
    section: null,
    shelf: null,
    bin: null,
  };

  let zone: DBWarehouseZones | null | undefined = null;
  let subzone: DBWarehouseSubzones | null | undefined = null;
  let floor: DBWarehouseFloors | null | undefined = null;
  let rack: DBWarehouseRacks | null | undefined = null;
  let section: DBWarehouseSections | null | undefined = null;
  let shelf: DBWarehouseShelfs | null | undefined = null;
  let bin: DBWarehouseBins | null | undefined = null;

  splitted.forEach((item: string, index) => {
    const isHypen = item === '-';

    if (index === 0 && !isHypen) {
      if (!isLetter(item)) return;

      zone = warehouse?.zones?.find((zone) => zone.code === item);

      if (!zone) return;
    }

    if (index === 1 && zone && !isHypen) {
      if (!isLetter(item)) return;

      subzone = warehouse?.subzones?.find(
        (subzone) =>
          subzone?.code === item &&
          (subzone.meta as unknown as ShelfMeta).parentZone === zone?.code
      );

      if (!subzone) return;
    }

    if (index === 2 && zone && subzone && !isHypen) {
      if (!isNumber(item)) return;

      floor = warehouse?.floors?.find(
        (floor) =>
          floor?.code === Number(item) &&
          (floor?.meta as unknown as ShelfMeta).parentZone === zone?.code &&
          (floor?.meta as unknown as ShelfMeta).parentSubzone === subzone?.code
      );

      if (!floor) return;
    }

    if (index === 3 && !isHypen) return;

    if (index === 4 && zone && subzone && floor && !isHypen) {
      rack = warehouse?.racks?.find(
        (rack) =>
          rack?.code === item &&
          (rack?.meta as unknown as ShelfMeta).parentZone === zone?.code &&
          (rack?.meta as unknown as ShelfMeta).parentSubzone ===
            subzone?.code &&
          (rack?.meta as unknown as ShelfMeta).parentFloor === floor?.code
      );

      if (!rack) return;
    }

    if (index === 5 && zone && subzone && floor && rack && !isHypen) {
      section = warehouse?.sections?.find(
        (section) =>
          section?.code === Number(item) &&
          (section?.meta as unknown as ShelfMeta).parentZone === zone?.code &&
          (section?.meta as unknown as ShelfMeta).parentSubzone ===
            subzone?.code &&
          (section?.meta as unknown as ShelfMeta).parentFloor === floor?.code &&
          (section?.meta as unknown as ShelfMeta).parentRack === rack?.code
      );

      if (!section) return;
    }

    if (index === 6 && !isHypen) return;

    if (
      index === 7 &&
      zone &&
      subzone &&
      floor &&
      rack &&
      section &&
      !isHypen
    ) {
      shelf = warehouse?.shelfs?.find(
        (shelf) =>
          shelf?.code === Number(item) &&
          (shelf?.meta as unknown as ShelfMeta).parentZone === zone?.code &&
          (shelf?.meta as unknown as ShelfMeta).parentSubzone ===
            subzone?.code &&
          (shelf?.meta as unknown as ShelfMeta).parentFloor === floor?.code &&
          (shelf?.meta as unknown as ShelfMeta).parentRack === rack?.code &&
          (shelf?.meta as unknown as ShelfMeta).parentSection === section?.code
      );

      if (!shelf) return;
    }

    if (index === 8 && !isHypen) return;

    if (index === 9 && zone && subzone && floor && rack && section && shelf) {
      bin = warehouse?.bins?.find(
        (bin) =>
          bin?.code === item &&
          (bin?.meta as unknown as ShelfMeta).parentZone === zone?.code &&
          (bin?.meta as unknown as ShelfMeta).parentSubzone === subzone?.code &&
          (bin?.meta as unknown as ShelfMeta).parentFloor === floor?.code &&
          (bin?.meta as unknown as ShelfMeta).parentRack === rack?.code &&
          (bin?.meta as unknown as ShelfMeta).parentSection === section?.code &&
          (bin?.meta as unknown as ShelfMeta).parentShelf === shelf?.code
      );

      if (!bin) return;
    }

    obj = {
      zone: zone as DBWarehouseZones,
      subzone: subzone as DBWarehouseSubzones,
      floor: floor as DBWarehouseFloors,
      rack: rack as DBWarehouseRacks,
      section: section as DBWarehouseSections,
      shelf: shelf as DBWarehouseShelfs,
      bin: bin as DBWarehouseBins,
    };
  });

  const nonNullKeys = _.keys(_.pickBy(obj, (value) => !_.isNull(value)));
  const firstNullKey = _.findKey(obj, _.isNull);

  if (firstNullKey) nonNullKeys.push(firstNullKey);

  if (firstNullKey === 'floor') {
    nonNullKeys.push('rack');
  }

  if (firstNullKey === 'section') {
    nonNullKeys.push('shelf');
  }

  return {
    current: nonNullKeys as unknown as WarehouseLocations[],
    locations: obj,
  };
}

export function isShelf(qrCode: string) {
  const splitted = qrCode.split('-');

  return splitted[0] === 'SHELF';
}

export function getTitle(location: string, isShelf: boolean) {
  const splitted = location.split('-');

  return isShelf ? `${splitted[1]}` : `${splitted[1]}-${splitted[2]}`;
}

export function getReactToPrintProps(
  componentRef: RefObject<HTMLDivElement>,
  printMode: PrintModeType
) {
  return {
    content: () => componentRef.current,
    bodyClass: 'print-container',
    pageStyle: `
      @page {
        size: 329mm 483mm;
        margin-top: ${printMode === 'horizontal' ? '14.5mm' : '8mm'};
        margin-bottom: ${printMode === 'horizontal' ? '13mm' : '10mm'};
      }
      body {
        -webkit-print-color-adjust: exact;
        margin: 0;
      }
      .center-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap:3mm;
      }
    `,
  };
}

export const getDirection = (printMode: PrintModeType) => {
  const splitted = printMode.split('-');

  return splitted[1] as VerticalStickerType;
};

export const format = (att: string) => {
  return _.replace(att, /_/g, ' ');
};
