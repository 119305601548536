import React, { useContext } from 'react';
import PhotoIcon from '@mui/icons-material/NotInterested';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { SinglePriceComparisonShape } from '@tyrio/dto';
import { PriceComparisonContext } from '../context';

interface Props {
  price: number | null;
  quantity: number | null;
  inputValue: number;
  setInputValue: (a: number) => void;
  open: boolean;
  shouldShowStockZero: boolean;
  isManufacturer: boolean;
  shouldShowPercentages: boolean;
  lowestPrices: number[];
  priceObject?: SinglePriceComparisonShape;
  mainSupplierPrice: number | null;
}

export const RenderTableCell = ({
  price,
  quantity,
  open,
  shouldShowStockZero,
  inputValue,
  setInputValue,
  lowestPrices,
  isManufacturer,
  shouldShowPercentages,
  mainSupplierPrice,
}: Props) => {
  const isLowestPrice = price === lowestPrices?.[0];
  const isSecondLowestPrice = price === lowestPrices?.[1];
  const ctx = useContext(PriceComparisonContext);

  const percentageDiff =
    price && mainSupplierPrice ? 1 - mainSupplierPrice / price : 0;

  if (!price) {
    return (
      <Wrapper>
        <Tooltip title={'Not available'} placement={'top'}>
          <PhotoIcon color="warning" />
        </Tooltip>
      </Wrapper>
    );
  }

  if (!!price && !quantity && !shouldShowStockZero) {
    return (
      <Wrapper>
        <Tooltip title={'Not available'} placement={'top'}>
          <PhotoIcon color="warning" />
        </Tooltip>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Price
        isLowestPrice={isLowestPrice}
        isSecondLowestPrice={isSecondLowestPrice}
      >
        {price.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })}
        {shouldShowStockZero && quantity === 0 && (
          <Tooltip title={'Not available'} placement={'top'}>
            <StockZeroMark />
          </Tooltip>
        )}
        {shouldShowStockZero && inputValue > (quantity ?? 0) && (
          <Tooltip
            title={`Some products aren't available in stock. Products to be ordered from stock zero: ${
              inputValue - (quantity ?? 0)
            } `}
            placement={'top'}
          >
            <StockZeroMark />
          </Tooltip>
        )}
      </Price>
      {shouldShowPercentages && (
        <Percentage isLower={percentageDiff <= 0}>
          {(percentageDiff * 100).toFixed(2)}%
        </Percentage>
      )}
      {open && (
        <Input
          placeholder={'0'}
          onClick={(e) => {
            e.stopPropagation();
          }}
          value={inputValue || ''}
          type="number"
          disabled={ctx.hasPlacedOrders}
          onChange={(e) => {
            const val = Math.max(parseInt(e.target.value, 10) || 0, 0);
            if (isManufacturer) {
              setInputValue(val);
            } else {
              setInputValue(Math.min(val, quantity || 0));
            }
          }}
        />
      )}
      <Pcs>{quantity} pcs</Pcs>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Price = styled.div<{
  isLowestPrice: boolean;
  isSecondLowestPrice: boolean;
}>`
  padding: 2px 8px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  position: relative;
  margin-bottom: 8px;

  background: ${(props) => {
    if (props.isSecondLowestPrice) return '#0781BF';
    if (props.isLowestPrice) return '#56c489';
    return 'transparent';
  }};

  color: ${(props) => {
    if (props.isLowestPrice) return 'white';
    if (props.isSecondLowestPrice) return 'white';
    return '#212B36';
  }};
`;

const Percentage = styled.div<{ isLower: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 0.3px;
  color: ${(props) => (props.isLower ? '#3eb274' : '#919EAB')};
  margin-bottom: 8px;
`;

const StockZeroMark = styled.div`
  position: absolute;
  top: 50%;
  right: -4px;
  transform: translateY(-50%);
  border: 1px solid white;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #d14343;
`;

const Input = styled.input`
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 4px;
  text-align: center;
  width: 80px;

  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  display: flex;
  align-items: center;
  letter-spacing: 0.3px;

  color: #212b36;
`;

const Pcs = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #919eab;
`;
