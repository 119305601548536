/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { DBClient, DBUser, DBUserRole } from '@prisma/client';
import { LayoutGroup, motion } from 'framer-motion';
import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_OPEN_WIDTH,
} from '../../../core/Sidebar';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import TYRIO from '../../../assets/logo/full-logo-white.svg';
import T from '../../../assets/logo/short-logo-white.svg';
import close from '../../../assets/menu-icons/close.svg';
import menuIcon from '../../../assets/menu-icons/menu-icon-1.svg';
import { colors } from '../../../core';
import Footer from './Footer';
import MenuItem from './MenuItem';
import _ from 'lodash';
import { PermissionsValues } from '@tyrio/dto';
interface DBLoggedUser extends DBUser {
  client: DBClient;
}

interface ItemInterface {
  link: string;
  icon: string;
  label: string;
  isVisible: boolean;
  permissionPath: string;
  subcategories?: {
    link: string;
    label: string;
    isVisible: boolean;
    permissionPath: string;
  }[];
}

interface SidebarProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  user: Partial<DBLoggedUser>;
  roleName?: string;
  contents: Array<ItemInterface>;
  viewSettings: boolean;
  viewProductCatalog: boolean;
  permissionList: PermissionsValues;
  onLogout: () => void;
}
export const Sidebar = ({
  open,
  setOpen,
  contents,
  user,
  roleName,
  viewProductCatalog,
  viewSettings,
  permissionList,
  onLogout,
}: SidebarProps) => {
  const { t } = useTranslation();

  const [selectedMenuItem, setSelectedMenuItem] = useState<string>();
  const [isSettingsBarOpen, setIsSettingsBarOpen] = useState<boolean>(false);

  const handleOpenSidebarWithChild = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const location = useLocation();
  const handleActive = (item: ItemInterface) => {
    let includes = false;

    if (
      location.pathname.toString() === '/dashboard' ||
      location.pathname.toString() === '/dashboard/'
    )
      return false;

    if (!item.subcategories)
      includes =
        item.link === location.pathname ||
        location.pathname.includes(item.link);
    else
      Object.values(item.subcategories).forEach((subcategory: any) => {
        if (
          location.pathname.toString().includes(subcategory.link) ||
          location.pathname.toString().includes(item.link) ||
          subcategory.link.includes(location.pathname)
        )
          includes = true;
      });

    return includes;
  };

  const isSalesOrder = location.pathname.includes('sales-orders');

  return (
    <>
      <SidebarBackground open={open}>
        <motion.div
          animate={{
            width: open ? SIDEBAR_LOGO_WIDTH_OPEN : SIDEBAR_LOGO_WIDTH,
          }}
          layout
        />
      </SidebarBackground>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
          setIsSettingsBarOpen(false);
        }}
      >
        <SidebarWrapper open={open}>
          <Wrapper open={open}>
            <LayoutGroup>
              <LogoLink to="/dashboard">
                <img
                  src={open ? TYRIO : T}
                  alt="logo"
                  style={{
                    width: open ? SIDEBAR_LOGO_WIDTH_OPEN : SIDEBAR_LOGO_WIDTH,
                    marginLeft: '5px',
                    transition: 'transform 330ms ease-in-out',
                  }}
                />
              </LogoLink>
              <Tooltip
                title={!open ? t('Expand Menu') : ''}
                placement="right"
                componentsProps={{
                  tooltip: { sx: { bgcolor: '#1b2130' } },
                }}
              >
                <Button
                  data-cy="menu_item_button"
                  onClick={() => setOpen(!open)}
                  role={user.userRole}
                >
                  {open ? (
                    <MenuItem
                      source={close}
                      label={t('Menu')}
                      isOpen={open}
                      permissionList={permissionList}
                    />
                  ) : (
                    <MenuItem
                      source={menuIcon}
                      label=""
                      isOpen={open}
                      permissionList={permissionList}
                    />
                  )}
                </Button>
              </Tooltip>
              <MenuItemWrapper>
                {contents.map((item) =>
                  (item.label === 'Product Catalogue' && !viewProductCatalog) ||
                  (item.label === 'Settings' && !viewSettings) ? (
                    <div></div>
                  ) : (
                    item.isVisible &&
                    _.get(permissionList, `${item.permissionPath}.view`) ===
                      true && (
                      <Tooltip
                        title={!open ? t(item.label) : ''}
                        placement="right"
                        componentsProps={{
                          tooltip: { sx: { bgcolor: '#1b2130' } },
                        }}
                        onClick={(e) => {
                          item.subcategories && handleOpenSidebarWithChild(e);
                        }}
                        key={item.label}
                        data-cy={`sidebar-${item.label}`}
                      >
                        <MenuItemLink
                          to={item.link}
                          key={item.link}
                          isActive={() => handleActive(item)}
                        >
                          <div
                            onClick={() => {
                              setSelectedMenuItem(item.label);
                            }}
                            style={{ width: '100%' }}
                          >
                            <MenuItem
                              source={item.icon}
                              link={item.link}
                              label={t(`${item.label}`)}
                              isOpen={open}
                              key={item.label}
                              subcategories={item.subcategories}
                              id={item.label}
                              selectedMenuItem={selectedMenuItem}
                              permissionList={permissionList}
                            />
                          </div>
                        </MenuItemLink>
                      </Tooltip>
                    )
                  )
                )}
              </MenuItemWrapper>
            </LayoutGroup>
          </Wrapper>
          <FooterWrapper open={open}>
            <Footer
              loggedUser={{
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                location: 'Poslovnica Sarajevo',
                version: 'v 0.2.1',
                roleType: user.userRole || DBUserRole.ADMIN,
                role:
                  user.userRole === 'ADMIN'
                    ? roleName || ''
                    : user.client?.shortName || '',
              }}
              isOpen={open}
              onLogout={onLogout}
              isSettingsBarOpen={isSettingsBarOpen}
              setIsSettingsBarOpen={setIsSettingsBarOpen}
            />
          </FooterWrapper>
        </SidebarWrapper>
      </ClickAwayListener>
    </>
  );
};

const SIDEBAR_LOGO_WIDTH = '30px';
const SIDEBAR_LOGO_WIDTH_OPEN = '120px';

const LogoLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const MenuItemLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0);
  opacity: 0.6;
  transition: all 0.3s ease;
  text-decoration: none;
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.3px;

  &.active,
  &:hover {
    background: rgba(255, 255, 255, 0.06);
    opacity: 1;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SidebarWrapper = styled(motion.div)<{ open: boolean }>`
  background-color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  z-index: 1;
  @media (max-width: 768px) {
    width: ${(prop) => prop.open && '100%'};
  }
`;
const Wrapper = styled.div<{ open: boolean }>`
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  padding: 10px;
  width: ${(props) =>
    props.open ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH}px;
  min-width: ${(props) =>
    props.open ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH}px;
  transition: all 0.2s linear;
  @media (max-width: 768px) {
    width: ${(prop) => prop.open && '100%'};
  }
`;

const MenuItemWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 120px);

  ::-webkit-scrollbar {
    background-color: black;
    width: 5px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: ${colors.primary};
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    width: 5px;
  }
`;

const SidebarBackground = styled.div<{ open: boolean }>`
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
  width: ${(props) =>
    props.open ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH}px;
  min-width: ${(props) =>
    props.open ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH}px;
  transition: all 0.2s linear;
`;

const Button = styled(motion.button)<{ role?: string }>`
  background: none;
  width: 100%;
  padding: 0;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  border-bottom: ${(props) =>
    props.role === 'ADMIN' ? '2px solid #ffffff0f' : '1px solid #db1e1e'};
  box-sizing: border-box;
`;

const FooterWrapper = styled.div<{ open: boolean }>`
  width: ${(props) =>
    props.open ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH}px;
  transition: linear 0.2s;
  @media (max-width: 768px) {
    width: ${(prop) => prop.open && '100%'};
  }
`;
