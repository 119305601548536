import styled from '@emotion/styled';
import { Chip, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import api from '@tyrio/api-factory';
import {
  DBCustomerOrderItemApi,
  DBCustomerOrderItemDetails,
  DBCustomerOrderStatusEnum,
  DBCustomerOrderUpdateAddressPayload,
  DBOrderDocumentApi,
  OrderTypesEnum,
  SSE_ENTITY_KEYS,
} from '@tyrio/dto';
import { globe } from '@tyrio/ui-library';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { getDropdownLabel } from '../../../../helpers/enum-parser';
import {
  TabPanelProps,
  tabProps,
  TabsStyled,
  TabStyled,
} from '../../components/Tabs/Tabs';
import UploadDocuments from '../../components/UploadDocuments/UploadDocuments';
import { useAuth } from '../../context/AuthContext';
import { useWS } from '../../context/WSContext';
import { useDocuments } from '../../hooks/useDocuments';
import { CustomerOrderStatusColors } from '../purchase-orders/helpers/generate-color';
import { OrderTabs } from './tabs/OrderTabs';
import { TrackingTabs } from './tabs/TrackingTabs';

interface CustomeOrderDetailsTabsProps {
  setSelectedOrderData: (
    value: DBCustomerOrderItemApi['getOne']['response'] | null
  ) => void;
  selectedOrderData: DBCustomerOrderItemApi['getOne']['response'] | null;
  setIsDirty?: (a: boolean) => void;
  setIsUploadInProcess: Dispatch<SetStateAction<boolean>>;
  setAddressUpdateData: Dispatch<
    SetStateAction<DBCustomerOrderUpdateAddressPayload>
  >;
  hideFooter?: boolean;
}

const CustomerOrderDetailsSidebarTabs = ({
  selectedOrderData,
  setSelectedOrderData,
  setIsDirty,
  setIsUploadInProcess,
  setAddressUpdateData,
}: CustomeOrderDetailsTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const { state } = useWS();
  const { user } = useAuth();

  const onSetSelectedOrderData = (
    value: DBCustomerOrderItemApi['getOne']['response'] | null
  ) => {
    setSelectedOrderData(value);
    setIsDirty && setIsDirty(true);
  };

  // get order documents
  const { data: orderDocuments, refetch } = useQuery(
    ['get_order_documents', selectedOrderData?.id],
    () =>
      api.fetch<DBOrderDocumentApi['list']>(`get_order_documents`, {
        id: selectedOrderData?.id,
        type: 'customer',
      }),
    {
      enabled: !!selectedOrderData?.id,
    }
  );

  const { uploadOrderDocuments, deleteOrderDocument } = useDocuments(
    selectedOrderData,
    refetch,
    setIsUploadInProcess,
    'Customer'
  );

  const onOpen = useCallback(
    (acceptedFiles: File[]) => {
      setIsUploadInProcess(true);
      const formData = new FormData();
      acceptedFiles.forEach((file) => formData.append('file', file, file.name));
      uploadOrderDocuments.mutate({
        headers: {
          Authorization: `Bearer ${localStorage.getItem('@@apiToken')}`,
          'Content-Type':
            'multipart/form-data; boundary=---011000010111000001101001',
          type: 'customer',
        },
        data: formData,
      });
    },
    [setIsUploadInProcess, uploadOrderDocuments]
  );

  const onDelete = (id: string) => {
    deleteOrderDocument.mutate(id);
  };

  const lockEntity =
    state.locks &&
    state.locks[`${SSE_ENTITY_KEYS.CUSTOMER_ORDER}/${selectedOrderData?.id}`];

  const isDisabled = !(
    lockEntity === undefined ||
    lockEntity === null ||
    lockEntity?.userId === user?.id
  );

  const orderStatus = JSON.parse(
    JSON.stringify(selectedOrderData?.orderStatus)
  )?.status;

  return (
    selectedOrderData && (
      <Container>
        <Wrapper>
          <TabsWrapper>
            <Box
              style={{
                position: 'sticky',
                top: 0,
                paddingTop: '16px',
              }}
            >
              <TabsStyled
                value={activeTab}
                onChange={(_e, v) => setActiveTab(v)}
                style={{ borderBottom: 0 }}
              >
                <TabStyled label="Order" {...tabProps(0)} />
                <TabStyled label="Tracking" {...tabProps(0)} />
                <TabStyled label="Related documents" {...tabProps(0)} />
              </TabsStyled>
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '20px',
                gap: '10px',
                marginBottom: '-20px',
              }}
            >
              <Chip
                size="small"
                label={
                  getDropdownLabel(
                    DBCustomerOrderStatusEnum,
                    orderStatus
                  ).toUpperCase() || ''
                }
                sx={{
                  bgcolor:
                    CustomerOrderStatusColors[
                      DBCustomerOrderStatusEnum[
                        orderStatus as keyof typeof DBCustomerOrderStatusEnum
                      ]
                    ] ?? 'white',
                  color: orderStatus === 'NEW' ? '#919EAB' : 'white',
                  border: orderStatus === 'NEW' ? '1px solid #919EAB' : 'none',
                }}
              />
              <img
                src={globe}
                alt="globe-icon"
                onClick={() => {
                  window.open(
                    (
                      selectedOrderData.details as unknown as DBCustomerOrderItemDetails
                    ).url,
                    '_blank'
                  );
                }}
              />
            </Box>
          </TabsWrapper>
          <TabContent value={activeTab} index={0}>
            <OrderTabs
              selectedOrderData={selectedOrderData}
              setSelectedOrderData={onSetSelectedOrderData}
              isDisabled={isDisabled}
              setAddressUpdateData={setAddressUpdateData}
            />
          </TabContent>
          <TabContent value={activeTab} index={1}>
            <div
              style={{
                maxHeight: 'calc(100svh - 259px)',
                overflowY: 'auto',
              }}
            >
              <TrackingTabs
                zipCode={selectedOrderData.billingAddress?.zip || null}
                orderId={selectedOrderData?.id}
                orderType={OrderTypesEnum.CUSTOMER_ORDER}
              />
            </div>
          </TabContent>
          <TabContent value={activeTab} index={2}>
            <UploadDocuments
              orderDocuments={orderDocuments}
              onOpen={onOpen}
              onDelete={onDelete}
              isLoading={uploadOrderDocuments.isLoading}
              selectedOrderData={selectedOrderData}
            />
          </TabContent>
        </Wrapper>
      </Container>
    )
  );
};

const TabContent = (props: TabPanelProps) => {
  const { children, value, index, style, ...other } = props;

  return (
    <Grid hidden={value !== index} style={style} {...other}>
      {value === index && (
        <Box sx={{ paddingTop: '16px', height: '100%' }} style={style}>
          {children}
        </Box>
      )}
    </Grid>
  );
};

const Container = styled(Grid)`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1;
  border-color: divider;
  height: 100%;
`;

const TabsWrapper = styled(Grid)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfe3e8;
  border-color: divider;
  top: 0;
`;

export default CustomerOrderDetailsSidebarTabs;
