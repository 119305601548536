import {
  BranchesStockResponse,
  StockType,
  SupplierStockResponse,
} from '@tyrio/dto';
import { ProductsQuantity, YearChip } from '@tyrio/wms-ui-library';
import { useContext, useMemo } from 'react';
import { AddedItemsButton } from '../../../../components/Button/AddedItemsButton';
import { useAuth } from '../../../../context/AuthContext';
import { PosCartContext } from '../../../../context/PosCartContext';
import { POSContext } from '../../../../context/POSContext';
import { useWS } from '../../../../context/WSContext';
import { usePosCartData } from '../../steps/Cart/helpers/cart-data';
import QuantityComponent from './QuantityComponent/QuantityComponent';
import {
  ChipWrapper,
  ComponentYearWrapper,
  PriceText,
  PriceWrapper,
  SingleUnitWrapper,
  Wrapper,
} from './StockItemDetails.style';
import { useVehicleCtx } from '../../../../context/VehicleContext';
import {
  PosSearchActiveTab,
  usePosSearchContext,
} from '../SearchPOS/SearchContext';
import { formatCurrencyWithSymbol } from '../../../../helpers/currency-format';
import { Grid } from '@mui/material';

interface SingleStorageItemProps {
  data: BranchesStockResponse | SupplierStockResponse;
  reserved: number;
  available: number;
  dot: string;
  price: number;
  cashPrice?: number | null;
  stockType: StockType;
  oldPrice?: number;
  discount?: number;
  sale?: boolean;
  chipColor?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  retailPrice?: number;
  deliveryDate: string;
  inclusiveVatPrice: number;
  vatCashPrice: number;
}

export const StockItemDetails = ({
  data,
  reserved,
  available,
  dot,
  sale = true,
  price,
  stockType,
  // oldPrice,
  discount,
  chipColor = 'error',
  // retailPrice,
  deliveryDate,
  inclusiveVatPrice,
  cashPrice,
  vatCashPrice,
}: SingleStorageItemProps) => {
  const ws = useWS();
  const { user } = useAuth();
  const { selectedTableRow, isVatIncluded } = useContext(POSContext);
  const { cartData, setCartData } = useContext(PosCartContext);
  const { modalData } = usePosCartData();
  const { selectedProduct, selectedProductModel } = useVehicleCtx();
  const { activeTab } = usePosSearchContext();

  const isWheels =
    activeTab === PosSearchActiveTab.wheels_search ? true : false;

  const product = isWheels ? selectedProduct : selectedTableRow?.product;
  const model = isWheels
    ? selectedProductModel
    : selectedTableRow?.product.model;

  const generatedKey = useMemo(() => {
    const branchName =
      stockType !== 'SUPPLIERS STOCK'
        ? (data as unknown as BranchesStockResponse).branchName
        : (data as unknown as SupplierStockResponse).supplierName;

    return `${product?.ean}_${dot}_${branchName}_${stockType}`;
  }, [data, dot, product?.ean, stockType]);

  const prepareCartData = (newQuantity: number) => {
    if (product) {
      const dataCart = {
        key: generatedKey,
        req: {
          quantity: newQuantity,
          ean: product?.ean,
          dot: selectedTableRow?.features.outletDot ?? '',
          sku: product?.sku,
          uid: product?.uid,
          productName: product?.productName,
          productBrand: model?.brand ?? '',
          productDescription: `${product.sku ?? ''}  ${
            model?.category?.name ? `• ${model?.category?.name}` : ''
          }
          ${dot ? `• DOT ${dot}` : ''}
          ${deliveryDate ? `• ${deliveryDate}` : ''}`,
          discount: 0,
          price,
          rebate: 0,
          total: 0,
          reserved: newQuantity,
          realQuantity: available + reserved,
          categoryId: model?.category.id ?? 0, //FIX THIS!!!
          inclusiveVat: inclusiveVatPrice,
        },
        stockType: stockType,
        branchId:
          stockType !== 'SUPPLIERS STOCK'
            ? Number((data as unknown as BranchesStockResponse).branchId)
            : undefined,
        supplierId:
          stockType === 'SUPPLIERS STOCK'
            ? (data as unknown as SupplierStockResponse).supplierId
            : undefined,
      };

      if (cartData.length === 0) {
        setCartData([dataCart]);
      } else {
        const foundItem = cartData.find((item) => item.key === generatedKey);

        if (!foundItem) {
          setCartData((previousData) => [...previousData, dataCart]);
        } else {
          const filtered = cartData.filter((item) => item.key !== generatedKey);
          const newItem = foundItem;
          if (newItem && newItem.req) {
            newItem.req.quantity = newQuantity;
            newItem.req.reserved += newQuantity;
            newItem.req.total = newItem.req.price * newQuantity;
            filtered.push(newItem);
            setCartData(filtered);
          }
        }
      }
    }
  };

  const selectedItem = useMemo(() => {
    return modalData.find((item) =>
      item.key.toString().includes(generatedKey.toString())
    );
  }, [modalData, generatedKey]);

  const isItemAdded = useMemo(() => {
    const added = modalData.find(
      (item) =>
        item.req.ean === selectedTableRow?.product?.ean &&
        item.key === generatedKey.toString()
    );
    return added !== undefined;
  }, [generatedKey, modalData, selectedTableRow?.product?.ean]);

  const addedItemQuantity = useMemo(() => {
    let quantity = 0;
    const item = cartData.find((item) => {
      return item.key.toString().includes(generatedKey.toString());
    });

    if (item && !isItemAdded) quantity = item?.req.quantity;
    else quantity = selectedItem?.req.quantity ?? 0;
    return quantity;
  }, [cartData, generatedKey, isItemAdded, selectedItem?.req.quantity]);

  const removeitemFromCart = () => {
    ws.socket?.emit('remove-item-from-pos-cart', {
      userId: user?.id,
      key: selectedItem?.key,
    });
  };

  const renderQtyComponent = () => {
    return (
      <QuantityComponent
        quantity={addedItemQuantity}
        maxAvailableQuantity={available - (selectedItem?.req.reserved ?? 0)}
        cart={true}
        changeCartItemQuantity={prepareCartData}
        minQuantity={0}
      />
    );
  };

  const renderChip = () => {
    return (
      <ChipWrapper>
        {sale && (
          <YearChip
            year={discount?.toString().concat(' %') ?? ''}
            color={chipColor}
            variant="filled"
          />
        )}
        <YearChip year={`DOT ${dot}`} color={chipColor} />
      </ChipWrapper>
    );
  };

  return (
    <SingleUnitWrapper>
      <ComponentYearWrapper>
        {selectedItem ? (
          <AddedItemsButton
            quantity={selectedItem?.req.quantity ?? 0}
            onClick={removeitemFromCart}
          />
        ) : (
          renderQtyComponent()
        )}
        {dot && dot !== 'XXXX' && renderChip()}
        {/* {retailPrice && <PriceText>{retailPrice.toFixed(2)} €</PriceText>} */}
      </ComponentYearWrapper>
      <Wrapper>
        <PriceWrapper>
          {/* {sale && (
            <OldPrice>{formatCurrencyWithSymbol(oldPrice ?? 0)}</OldPrice>
          )} */}
          <PriceText sale={sale}>
            {!isVatIncluded
              ? formatCurrencyWithSymbol(price)
              : formatCurrencyWithSymbol(inclusiveVatPrice)}
          </PriceText>
        </PriceWrapper>
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minWidth: '70px', maxWidth: '70px' }}
        >
          <PriceText>
            {!isVatIncluded
              ? formatCurrencyWithSymbol(cashPrice as number)
              : formatCurrencyWithSymbol(vatCashPrice)}
          </PriceText>
        </Grid>
        <ProductsQuantity
          reserved={reserved + (selectedItem?.req.reserved ?? 0)}
          available={available - (selectedItem?.req.reserved ?? 0)}
        />
      </Wrapper>
    </SingleUnitWrapper>
  );
};
