import * as _ from 'lodash';
import {
  ConnectionDetail,
  DBEdiOrderApi,
  DBEdiOrderInquiryBody,
  DBSupplierClientSettingsPayload,
  EDIInquiryItemRequest,
  HttpsConnectionDetail,
} from '@tyrio/dto';
import api from '@tyrio/api-factory';
import { useClientSuppliersQuery } from './useClientSuppliersQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type InquiryState = Record<
  string,
  Record<string, { ean: string; deliveryDate: string; qty: number }>
>;
export type UseInquireMultipleEansState = {
  inquiryState: InquiryState;
  inquiryLoadingState: Record<string, boolean>;
  counter: number;
};

const today = () => {
  // Get today's date
  const t = new Date();

  // return yyyy-mm-dd format
  return t.toISOString().split('T')[0];
};
const getOrderConnection = (
  clientSupplierSettings: DBSupplierClientSettingsPayload
) => {
  const connections =
    clientSupplierSettings.connections as unknown as ConnectionDetail[];
  if (!connections) return null;

  return (
    (connections.find((c) =>
      c.connectionType.includes('ORDER')
    ) as HttpsConnectionDetail) ?? null
  );
};
export const useInquireEansMultipleSuppliers = ({
  supplierIds,
  items,
}: {
  supplierIds: string[];
  items: EDIInquiryItemRequest[];
}): UseInquireMultipleEansState => {
  /**
   * supplierId => ean
   */
  const [counter, setCounter] = useState(0);
  const [inquiryState, setInquiryState] = useState<InquiryState>({});
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const supplierSettings = useClientSuppliersQuery(supplierIds);

  const inquiryPreparationPayloads = useMemo(() => {
    const payloads: DBEdiOrderInquiryBody[] = [];
    supplierSettings?.data?.data?.forEach((supplierClientConfig) => {
      const connection = getOrderConnection(supplierClientConfig);
      if (!connection) return;
      let branchId: number | null =
        Number(
          Object.keys(supplierClientConfig?.branchEdiConfiguration ?? {})?.[0]
        ) ?? null;
      if (isNaN(branchId)) branchId = null;
      if (!branchId) return;

      const supplierId = supplierClientConfig.supplierId;
      const preferredDeliveryDate = today();
      const transportPriority: 'RUSH' | 'REPL' = 'RUSH';

      const payload = {
        adminConnectionId: connection.adminConnectionId,
        branchId,
        supplierClientSettingsId: supplierClientConfig.id,
        connection,
        supplierId,
        items,
        preferredDeliveryDate,
        transportPriority,
      };
      payloads.push(payload);
    });

    return payloads;
  }, [items, supplierSettings?.data?.data]);

  const inquireSupplier = useCallback(
    async (payload: DBEdiOrderInquiryBody) => {
      setLoadingState((old) => _.merge(old, { [payload.supplierId]: true }));
      setCounter((old) => old + 1);
      const response = await api.fetch<DBEdiOrderApi['inquire']>(
        'inquire_eans',
        payload
      );
      setInquiryState((old) => {
        return _.merge(old, { [payload.supplierId]: response });
      });
      setLoadingState((old) => _.merge(old, { [payload.supplierId]: false }));
      setCounter((old) => old + 1);
      // return response;
    },
    []
  );

  useEffect(() => {
    inquiryPreparationPayloads.forEach((p) => {
      inquireSupplier(p).catch((e) => {
        console.log(e);
        setCounter((old) => old + 1);
        setLoadingState((old) => _.merge(old, { [p.supplierId]: false }));
      });
    });
  }, [inquireSupplier, inquiryPreparationPayloads, inquiryState]);

  return { inquiryState, inquiryLoadingState: loadingState, counter };
};
