import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DBPaymentStatusEnum } from '@prisma/client';
import { TyrioSelectInputOption, tyrioUI } from '@tyrio/ui-library';
import {
  getDropdownLabel,
  parseEnumToArray,
} from '../../../../../helpers/enum-parser';

import api from '@tyrio/api-factory';
import {
  CustomerOrderServices,
  DBCustomerOrderStatusEnum,
  DBDeliveryTypesApi,
  DBOrderItems,
  OrderSubStatuses,
  SummaryDetails,
} from '@tyrio/dto';
import _, { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { formatDateAndTime } from '../../../../../helpers/date-helper';
import { InfoCard } from '../../../components/InfoCard/InfoCard';
import dots from '../assets/Vector.svg';
import pencil_alt from '../assets/pencil-alt.svg';
import {
  AddressCard,
  AddressType,
} from '../components/AddressCard/AddressCard';
import { PriceItem } from '../components/PriceItem';
import SelectInput from '../components/SelectInput';
import { TableProductItem } from '../components/TableProductItem';
import { PaymentDetailsModal } from '../modals/PaymentDetailsModal';
import {
  CardInfo,
  CardWrapper,
  CreatedWrapper,
  DropdownWrapper,
  InfoTypography,
  PaymentDetailsWrapper,
  PaymentNameTypography,
  PaymentTypeCodeTypography,
  PaymentTypeTitleTypography,
  ProductCardsWrapper,
  StockWrapper,
  TableHeaderWrapper,
  TotalWrapper,
  Wrapper,
} from '../styles/OrderTabs.style';
import { AppointmentList } from '../components/Appointments';

export const OrderTabs = ({
  selectedOrderData,
  setSelectedOrderData,
  isDisabled,
  setAddressUpdateData,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  const orderStatuses = parseEnumToArray(DBCustomerOrderStatusEnum);
  const orderSubstatus = parseEnumToArray(OrderSubStatuses);

  const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] =
    useState(false);

  const lineItems = selectedOrderData.lineItems;

  const [deliveryTypes, setDeliveryTypes] = useState<TyrioSelectInputOption[]>(
    []
  );
  const [shouldIncludeVat, setShouldIncludeVat] = useState(
    selectedOrderData.customer.customerType === 'B2C'
  );

  useQuery(
    'get_client_delivery_types',
    () => api.fetch<DBDeliveryTypesApi['list']>('get_client_delivery_types'),
    {
      onSuccess: (data) => {
        const dropdownData = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        setDeliveryTypes(dropdownData);
      },
    }
  );

  const getPrice = (price: number) => {
    const vat = selectedOrderData.details.vatPercent;

    const vatAmount = price * (vat / 100);

    if (shouldIncludeVat) return price + vatAmount;
    else return price;
  };

  const mapServices = () => {
    if (selectedOrderData?.services && !isEmpty(selectedOrderData?.services)) {
      return (selectedOrderData?.services as CustomerOrderServices[]).map(
        (item) => {
          const price = Number(item.price.toString().replace(',', '.'));

          const p = getPrice(price);

          return (
            <TableProductItem
              key={item.erpId}
              title={item.name}
              sku={''}
              ipc={''}
              price={p.toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: 'symbol',
              })}
              total={(p * item.quantity).toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
                currencyDisplay: 'symbol',
              })}
              discount={0}
              qty={item.quantity}
              componentType="product"
              showDate={false}
              isService={true}
              serviceErpId={item.erpId}
            />
          );
        }
      );
    }
    return null;
  };

  const priceSummary: {
    items: number;
    discount: number;
    delivery: number;
    total: number;
    vat: number;
    discountPercentage: number;
  } = useMemo(() => {
    const summary = selectedOrderData?.summary as unknown as SummaryDetails;

    const totalPriceWithoutVat =
      summary.subtotalExVat + summary.deliveryExVat + summary.servicesExVat;

    const vatPercentage = Math.trunc(
      Math.round(
        (summary.vatAmount / (totalPriceWithoutVat - summary.discountExVat)) *
          100
      )
    );

    const obj = {
      items: summary.servicesExVat + summary.subtotalExVat,
      discount: summary.discountExVat,
      delivery: summary.deliveryExVat,
      total: totalPriceWithoutVat + summary.vatAmount - summary.discountExVat,
      vat: vatPercentage,
    };

    const discountPercentage = Math.trunc(
      Math.round(
        (summary.discountExVat /
          (totalPriceWithoutVat - summary.discountExVat)) *
          100
      )
    );

    if (!shouldIncludeVat) {
      return { ...obj, discountPercentage };
    } else {
      const vat = vatPercentage / 100 + 1;

      const newDiscount = obj.discount * vat;

      return {
        items: obj.items * vat,
        discount: newDiscount,
        delivery: obj.delivery * vat,
        total: totalPriceWithoutVat * vat - newDiscount,
        vat: vatPercentage,
        discountPercentage,
      };
    }
  }, [shouldIncludeVat, selectedOrderData]);

  const remark = selectedOrderData?.customerRemark?.trim();

  return (
    <div
      style={{
        maxHeight: 'calc(100svh - 259px)',
        overflowY: 'auto',
      }}
      id="order_details"
    >
      {isPaymentDetailsModalOpen && (
        <PaymentDetailsModal
          open={isPaymentDetailsModalOpen}
          setOpen={setIsPaymentDetailsModalOpen}
          handleClose={() => setIsPaymentDetailsModalOpen(false)}
          selectedOrderData={selectedOrderData}
          setSelectedOrderData={setSelectedOrderData}
        />
      )}

      <DropdownWrapper>
        <SelectInput
          valueName="orderStatus"
          attributeKey={'status'}
          label="Order status"
          dropdownData={orderStatuses}
          selectedOrderData={selectedOrderData}
          setSelectedOrderData={setSelectedOrderData}
          isDisabled={isDisabled}
        />

        <FormControl fullWidth>
          <InputLabel>Delivery type</InputLabel>
          <Select
            label="Delivery type"
            value={selectedOrderData.deliveryId}
            disabled={isDisabled}
            onChange={(e) => {
              selectedOrderData &&
                setSelectedOrderData({
                  ...selectedOrderData,
                  deliveryId: e.target.value,
                });
            }}
          >
            {deliveryTypes.map((item) => (
              <MenuItem value={item.value} key={item.label}>
                <Typography> {item.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedOrderData.orderStatus.status ===
          DBCustomerOrderStatusEnum.ORDERED && (
          <SelectInput
            valueName="orderStatus"
            attributeKey={'subStatus'}
            label="Order status"
            dropdownData={orderSubstatus}
            selectedOrderData={selectedOrderData}
            setSelectedOrderData={setSelectedOrderData}
            isDisabled={isDisabled}
          />
        )}
      </DropdownWrapper>

      <CardWrapper>
        <AddressCard
          selectedOrderData={selectedOrderData}
          addressDetails={
            selectedOrderData?.billingAddress
              ? selectedOrderData?.billingAddress
              : ''
          }
          addressType={AddressType.billing}
          setAddressUpdateData={setAddressUpdateData}
          isDisabled={isDisabled}
        />
        <AddressCard
          selectedOrderData={selectedOrderData}
          addressDetails={
            selectedOrderData?.deliveryAddress
              ? selectedOrderData?.deliveryAddress
              : ''
          }
          addressType={AddressType.shipping}
          setAddressUpdateData={setAddressUpdateData}
          isDisabled={isDisabled}
        />
      </CardWrapper>

      <div
        style={{
          padding: '0 16px',
        }}
      >
        {remark && remark !== '' && (
          <InfoCard
            title={remark}
            subtitle={
              selectedOrderData.details.customerReference
                ? `Reference: ${selectedOrderData.details.customerReference}`
                : ''
            }
            border={`1px solid ${tyrioUI.colors.black.B40}`}
            borderRadius="8px"
          />
        )}
      </div>

      {!_.isEmpty(selectedOrderData.appointments) && (
        <Box padding={2}>
          <AppointmentList appointments={selectedOrderData.appointments} />
        </Box>
      )}

      <TableHeaderWrapper>
        <StockWrapper>
          <Typography variant="body2">Price</Typography>
          <Typography variant="body2">Discount</Typography>
          <Typography variant="body2">Qty</Typography>
          <div
            style={{ width: '90px', display: 'flex', justifyContent: 'center' }}
          >
            <Typography variant="body2">Total</Typography>
          </div>
        </StockWrapper>
      </TableHeaderWrapper>
      <ProductCardsWrapper
        style={{
          borderTop: `1px solid ${tyrioUI.colors.black.B40}`,
          borderBottom: `1px solid ${tyrioUI.colors.black.B40}`,
          borderLeft: '0',
          borderRight: '0',
          borderRadius: '0',
        }}
      >
        {selectedOrderData &&
          !_.isEmpty(lineItems) &&
          (lineItems as DBOrderItems[]).map(
            (p: DBOrderItems, index: number) => {
              const price = getPrice(p?.sellingPrice) ?? 1;

              return (
                <TableProductItem
                  key={index}
                  title={`${p?.brand} ${p?.productName}`}
                  ean={p?.ean}
                  sku={p?.sku}
                  ipc={p?.manufacturerCode}
                  price={price.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })} //fali
                  total={(price * p.quantity).toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })} //fali
                  discount={p?.cashDiscount ?? ''}
                  qty={p?.quantity}
                  componentType="product"
                  deliveryDate={
                    p.deliveryDateFrom && p.deliveryDateTo
                      ? `${p.deliveryDateFrom ?? ''} - ${
                          p.deliveryDateTo ?? ''
                        }`
                      : ''
                  }
                  slug={p?.purchasing?.supplierSettings?.supplierSlug}
                  purchasePrice={p?.purchasing?.purchasePrice}
                />
              );
            }
          )}
        {mapServices()}
      </ProductCardsWrapper>

      <div style={{ margin: '20px 0', padding: '0 16px' }}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <FormControlLabel
            control={
              <Switch
                id="vatAmount"
                color="info"
                disabled={false}
                checked={shouldIncludeVat}
                size="small"
              />
            }
            label="prices inclusive VAT"
            onClick={() => setShouldIncludeVat(!shouldIncludeVat)}
          />
        </div>
        <Wrapper>
          <ProductCardsWrapper
            style={{
              width: '45%',
              gap: '10px',
              paddingRight: '16px',
            }}
          >
            <Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <PaymentTypeTitleTypography>
                  Payment type:
                </PaymentTypeTitleTypography>
                <Tooltip title="Edit payment info">
                  <img
                    src={pencil_alt}
                    alt="pencil_alt"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (!isDisabled) setIsPaymentDetailsModalOpen(true);
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '12px',
                }}
              >
                <PaymentTypeCodeTypography>
                  {selectedOrderData.payment?.code}
                </PaymentTypeCodeTypography>
                <PaymentNameTypography>
                  {selectedOrderData.payment?.name}
                </PaymentNameTypography>
              </Grid>

              <PaymentDetailsWrapper>
                {selectedOrderData.paymentStatus && (
                  <CardInfo>
                    <div>Status:</div>
                    <InfoTypography color={'#38A169'}>
                      {getDropdownLabel(
                        DBPaymentStatusEnum,
                        selectedOrderData.paymentStatus
                      )}
                    </InfoTypography>
                  </CardInfo>
                )}
                {selectedOrderData.paymentDetails?.cardInfo &&
                  ((selectedOrderData.payment?.name !== 'Pouzeće gotovinom' &&
                    selectedOrderData.payment?.name !== 'Gotovina' &&
                    selectedOrderData.payment?.name !== 'Virman - Avans' &&
                    selectedOrderData.payment?.name !== 'Virman - Odgoda') ||
                    selectedOrderData.payment?.paymentType === 'CARD_POS' ||
                    selectedOrderData.payment?.paymentType ===
                      'CARD_ONLINE') && (
                    <>
                      {selectedOrderData?.paymentDetails.cardInfo.cardType && (
                        <CardInfo>
                          <div>Card Type:</div>
                          <InfoTypography weight={500}>
                            {selectedOrderData?.paymentDetails.cardInfo.cardType.toUpperCase()}
                          </InfoTypography>
                        </CardInfo>
                      )}
                      {selectedOrderData?.paymentDetails.cardInfo
                        .instalment && (
                        <CardInfo>
                          <div>Number of instalments:</div>
                          <InfoTypography weight={500}>
                            {
                              selectedOrderData?.paymentDetails.cardInfo
                                .instalment
                            }
                          </InfoTypography>
                        </CardInfo>
                      )}
                    </>
                  )}

                {selectedOrderData.paymentDetails?.cardInfo &&
                  selectedOrderData?.paymentDetails.cardInfo.transactionId && (
                    <CardInfo>
                      <div>Transaction ID:</div>
                      <InfoTypography>
                        {
                          selectedOrderData?.paymentDetails.cardInfo
                            .transactionId
                        }
                      </InfoTypography>
                    </CardInfo>
                  )}
              </PaymentDetailsWrapper>
            </Grid>
          </ProductCardsWrapper>

          <ProductCardsWrapper
            style={{
              width: '55%',
              background: '#DFE3E8',
              padding: 0,
            }}
          >
            <div style={{ padding: '8px' }}>
              <PriceItem
                title="Items:"
                price={priceSummary.items}
                fontSize="14px"
              />
              <PriceItem
                title={`Discount (${priceSummary.discountPercentage}%):`}
                price={priceSummary.discount}
                fontSize="14px"
              />
              <Divider style={{ padding: '5px' }} />
              <PriceItem
                title="Delivery:"
                price={priceSummary.delivery}
                fontSize="14px"
              />
              {!shouldIncludeVat && (
                <PriceItem
                  title={`VAT (${priceSummary.vat}%):`}
                  price={selectedOrderData?.summary.vatAmount ?? 1}
                  fontSize="14px"
                />
              )}
            </div>

            <TotalWrapper>
              <DropdownWrapper style={{ justifyContent: 'space-between' }}>
                <Typography color={'white'} fontSize={'18px'} fontWeight={700}>
                  Total:
                </Typography>
                <Typography color={'white'} fontWeight={700}>
                  {priceSummary.total.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    currencyDisplay: 'symbol',
                  })}
                </Typography>
              </DropdownWrapper>
            </TotalWrapper>
          </ProductCardsWrapper>
        </Wrapper>
      </div>

      <div style={{ padding: '0 16px' }}>
        <TextField
          id="internal-remark"
          variant="outlined"
          label="Internal remark"
          disabled={isDisabled}
          fullWidth
          style={{ marginTop: '16px' }}
          value={selectedOrderData?.internalRemark ?? ''}
          onChange={(e) => {
            selectedOrderData &&
              setSelectedOrderData({
                ...selectedOrderData,
                internalRemark: e.target.value,
              });
          }}
        />
      </div>

      <div style={{ padding: '0 16px' }}>
        <CreatedWrapper>
          <div style={{ width: '100%' }}>
            <Typography>Created:</Typography>
            <Typography variant="caption" color={tyrioUI.colors.black.B50}>
              {selectedOrderData?.customer?.firstName}{' '}
              {selectedOrderData?.customer?.lastName} -{' '}
              {formatDateAndTime(selectedOrderData?.orderedAt)}
            </Typography>
          </div>
          <div style={{ width: '100%' }}>
            <Typography>Last edited:</Typography>
            {selectedOrderData?.lastEditedBy?.lastUpdatedBy ? (
              <Typography variant="caption" color={tyrioUI.colors.black.B50}>
                {`${selectedOrderData?.lastEditedBy?.lastUpdatedBy} - `}
                {formatDateAndTime(
                  selectedOrderData?.lastEditedBy?.lastUpdateTime
                )}
              </Typography>
            ) : (
              <Typography variant="caption" color={tyrioUI.colors.black.B50}>
                Order was not edited
              </Typography>
            )}
          </div>
          <img src={dots} alt="dots" />
        </CreatedWrapper>
      </div>
    </div>
  );
};
