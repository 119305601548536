import api from '@tyrio/api-factory';
import { DBTrackInfoApi } from '@tyrio/dto';
import { ToastHelper, ToastType } from '@tyrio/ui-library';
import { useMutation } from 'react-query';

export const useUpdateManyTrackingItems = (refetchTrackingInfo: () => void) => {
  const updateManyTrackItems = useMutation(
    (client: DBTrackInfoApi['updateMany']['request']) =>
      api.fetch<DBTrackInfoApi['updateMany']>('track_info_update_many', {
        ...client,
      }),
    {
      mutationKey: 'delete_track_info',
      onSuccess: () => {
        refetchTrackingInfo();
      },
      onError: () => {
        ToastHelper.showToast(
          'Custom prices',
          ToastType.ERROR,
          undefined,
          'There is an error during deleting track item!'
        );
      },
    }
  );

  return { updateManyTrackItems };
};
