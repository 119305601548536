import { useCallback, useContext, useMemo } from 'react';
import styled from '@emotion/styled';

import { SalesOrdersFlowContext } from '../SalesOrdersFlowContext';
import { DBProduct } from '@prisma/client';
import { get, sumBy } from 'lodash';

interface OrderItemProps {
  branchId: string;
  product: DBProduct;
  purchasedQuantity: number;
  selected?: boolean;
}
export const LagerItem = ({
  branchId,
  product,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  purchasedQuantity,
}: OrderItemProps) => {
  const ctx = useContext(SalesOrdersFlowContext);

  const branch = ctx.data.warehouses[branchId];
  const warehouseQuantity =
    ctx.data.warehouseQty[product.uid][Number(branchId)].quantity;

  const price = ctx.data.warehouseQty[product.uid][Number(branchId)].price;

  const setQuantity = useCallback(
    (value: string) => {
      const converted = Number(value);
      const parsedQty = isNaN(converted) ? 0 : converted;

      const supplierQty = sumBy(Object.values(ctx?.qty?.supplier), product.uid);
      const stockQty = sumBy(Object.values(ctx?.qty?.stock), product.uid);
      const filledQty = supplierQty + stockQty;

      const requestedQty = ctx.data.requested[product.uid];

      let finalValue = Math.max(0, parsedQty);

      const branchQty = get(ctx.qty.stock[branchId], `${product.uid}`, 0);

      if (
        filledQty === requestedQty &&
        finalValue + filledQty > requestedQty &&
        finalValue > branchQty
      )
        return;

      finalValue = Math.min(finalValue, requestedQty);

      ctx.setQty(branchId, product.uid, finalValue, 'stock');
    },
    [branchId, ctx, product.uid]
  );

  const inputQty = useMemo(() => {
    const value = ctx.qty.stock?.[branchId]?.[product.uid] ?? undefined;
    return String(value) ?? '';
  }, [branchId, ctx.qty, product.uid]);

  const isSelected = get(ctx.qty.stock, [branchId, product.uid], 0) > 0;

  return (
    <Wrapper
      selected={isSelected}
      onClick={() => {
        ctx.setSelected(branchId, product.uid);
      }}
    >
      <Split>
        <Title>{branch?.branchName ?? ''}</Title>
        <Pill canFulfillQuantity={true}>
          <input
            className="light"
            value={inputQty}
            placeholder={'0'}
            type="number"
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          ></input>
          <div className="dark">{warehouseQuantity ?? 0}</div>
        </Pill>

        <div>
          {price.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
          })}
        </div>
      </Split>
    </Wrapper>
  );
};

interface WrapperProps {
  selected?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  border: 1px solid #dfe3e8;
  background: white;
  height: 100%;
  width: 100%;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  transition: box-shadow 0.3s ease;
  position: relative;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }

  ${(props) =>
    props.selected
      ? `padding: 5px 3px 3px 3px; border: 2px solid #1976D2;`
      : 'padding: 6px 4px 4px 4px;'}
`;

const Title = styled.h3`
  padding: 0;
  margin: 0 0 4px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #212b36;
`;

const Pill = styled.div<{ canFulfillQuantity: boolean }>`
  display: flex;
  width: 100px;
  min-height: 38px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 6px;

  div,
  input {
    flex: 1;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.3px;

    &.light {
      color: #212b36;
      transition: all 0.2s ease;
      padding: 0;
      margin: 0;

      text-align: center;

      &:focus {
        outline: none;
        background-color: #f8f8f8;
      }
    }

    &.blue {
      font-weight: 400;
      ${(props) =>
        props.canFulfillQuantity
          ? `
              background: #0f4d91;
              color: white;
          `
          : `
            background: #E86032;
            color: white;
      `}
    }

    &.dark {
      font-weight: 400;
      ${(props) =>
        props.canFulfillQuantity
          ? `
              background: #212b36;
              color: white;
          `
          : `
            background: #E86032;
            color: white;
      `}
    }
  }
`;

const Split = styled.div`
  height: 74px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
