import { DBProduct, DBProductCategory, DBProductModel } from '@prisma/client';

interface DBGetProductsRequest {
  search?: string;
  searchFilter?: string;

  sortParam?: 'asc' | 'desc';
  sortKey?: string;
  filters?: string[];

  pageSize?: string;
  page?: string;
  // category_id?: string;
  categoryId?: string;
  season?: string | string[];
  brand?: string | string[];
  wheelPosition?: string | string[];
  specialMarking?: string | string[];
  showInactive?: 'true' | 'false';
  noDescription?: 'true' | 'false';
}

interface DBProductOnePayload {
  id?: string;
  ean?: string;
}

interface UpsertProductPayload extends Partial<DBProduct> {
  productModelUid: string;
  // This is fine to assign to any, the endpoint handles all cases
  // eslint-disable-next-line
  et: any;
}

type DBProductPayload = DBProduct & {
  model?: (DBProductModel & { category: DBProductCategory | null }) | null;
};

interface DBProductApi {
  list: {
    requestBody: DBGetProductsRequest;
    request: DBGetProductsRequest;
    response: { data: DBProductPayload[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBProductOnePayload;
    request: DBProductOnePayload;
    response: DBProductPayload;
  };
  upsert: {
    requestBody: UpsertProductPayload;
    requestParams: { id?: string };
    request: never;
    response: never;
  };
  getRimSizes: {
    requestBody: { categoryId: string[] };
    requestParams: { categoryId: string[] };
    request: { categoryId: string[] };
    response: { uid: string; rimDiameter: string; rimDiameter2: string }[];
  };
}

export { DBProductApi, DBGetProductsRequest, DBProductPayload };
