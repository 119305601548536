import { ProductDetailsDataInterface } from '@tyrio/dto';
import { Language } from './EmailLinkGenerator';

interface OrderDetailsGeneratorProps {
  products: ProductDetailsDataInterface[];
  clientName: string;
  branchDetails: {
    address: string;
    zipCode: string;
    city: string;
    mobilePhone: string;
  };
  deliveryNote?: string;
  reference?: string;
  language?: Language;
}

const OrderDetailsGenerator = ({
  products,
  clientName,
  branchDetails,
  deliveryNote,
  reference,
  language = 'english',
}: OrderDetailsGeneratorProps) => {
  let orderContent = '';
  if (language === 'english')
    orderContent = `New order received from ${clientName}.\n \n Items ordered: ${products.map(
      (product) =>
        `\n
===================================================\n
${product.productName} - ${product.quantity} pieces,\n
EAN: ${product.ean},\n
Net price: ${product.price.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })} / piece`
    )}
\n
===================================================\n \n
${deliveryNote && `Note for delivery: ${deliveryNote}.\n`}
${reference && `Reference: ${reference}.\n`}
Delivery location: \n
${branchDetails.address}, ${branchDetails.zipCode} ${branchDetails.city}\n
${branchDetails.mobilePhone}\n
\n
  `;
  else
    orderContent = `U nastavku nova narudžba za ${clientName}.\n Artikli: ${products.map(
      (product) =>
        `\n
===================================================\n
${product.productName} - ${product.quantity} komada,\n
EAN: ${product.ean},\n
Netto cijena: ${product.price.toLocaleString('de-DE', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
        })} / komad`
    )}
\n
===================================================\n \n
${deliveryNote && `Napomena: ${deliveryNote}.\n`}
${reference && `Referenca: ${reference}.\n`}
Adresa dostave: \n
${branchDetails.address}, ${branchDetails.zipCode} ${branchDetails.city}\n
${branchDetails.mobilePhone}\n
\n
  `;
  return orderContent;
};

export default OrderDetailsGenerator;
