import styled from '@emotion/styled/macro';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid/Grid';
import Tooltip from '@mui/material/Tooltip';
import { DBStockInItemStatus } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBStockInItemsResponse,
  DBStockInListApi,
  SSE_ENTITY_KEYS,
} from '@tyrio/dto';
import {
  CancelModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { useMutation } from 'react-query';
import { useAuth } from '../../../context/AuthContext';
import StockInOrderDetailsSidebarTabs from './StockInOrderDetailsTabs';
import { queryClient } from './../../../../query-client';
import { useWS } from '../../../context/WSContext';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { AxiosError } from 'axios';

interface StockInOrderDetailsTabsProps {
  selectedOrderData: DBStockInItemsResponse | null;
  setSelectedOrderData: (
    item: DBStockInListApi['getOne']['response'] | null
  ) => void;
  setFavorite: () => void;
  handleSave: () => void;
  setLockStatus?: () => void;
  setIsUploadInProcess: (item: boolean) => void;
  isUploadInProcess: boolean;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const StockInOrderDetailsTabs = ({
  selectedOrderData,
  setSelectedOrderData,
  setFavorite,
  handleSave,
  setIsUploadInProcess,
  isUploadInProcess,
  isDirty,
  setIsDirty,
  setLockStatus,
}: StockInOrderDetailsTabsProps) => {
  const iconsStyledProps = { width: '20px', height: '20px', cursor: 'pointer' };
  const { user } = useAuth();
  const { state } = useWS();

  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const handleClose = () => {
    if (isDirty) {
      const feedback = window.confirm(
        'You have unsaved changes. Are you sure you want to continue?'
      );
      if (feedback) {
        setSelectedOrderData(null);
        setIsDirty(false);
      }
    } else if (isUploadInProcess) {
      window.alert(`You can't close order details while uploading documents!`);
    } else setSelectedOrderData(null);
  };

  const handleOnCancel = () => {
    if (selectedOrderData?.status === 'COMPLETED') {
      ToastHelper.showToast(
        'Stock in order',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        `You can't cancel this order because it's completed!`
      );
      return;
    }
    setIsCancelModalVisible(true);
  };

  const isFavorite = () => {
    if (user) return selectedOrderData?.favoritedBy.includes(user.id);
    return false;
  };

  const stornStockInOrder = useMutation(
    () => {
      return api.fetch<DBStockInListApi['updateOne']>(
        'change_stock_in_status',
        {
          orderId: selectedOrderData?.id || '',
          status: DBStockInItemStatus.CANCELLED,
        }
      );
    },
    {
      mutationKey: 'change_stock_in_status',
      onSuccess: () => {
        queryClient.refetchQueries('get_stock_in_list');
        queryClient.refetchQueries('get_si_count_by_status');
        ToastHelper.showToast(
          'Stock in Order ',
          ToastType.SUCCESS,
          ToastMessageType.CHANGE_STATUS
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Stock in order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const lockEntity =
    state.locks &&
    state.locks[`${SSE_ENTITY_KEYS.STOCK_IN_ORDER}/${selectedOrderData?.id}`];

  const isDisabled = !(
    lockEntity === undefined ||
    lockEntity === null ||
    lockEntity?.userId === user?.id
  );

  return (
    <Container>
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            stornStockInOrder.mutateAsync();
            setIsCancelModalVisible(false);
            setSelectedOrderData(null);
          }}
          text="You are about to cancel the order."
        />
      )}
      <Header>
        <BasicOrderInfoContainer>
          <div>{String(selectedOrderData?.documentId).padStart(5, '0')}</div>
          <TimeInfo>
            {moment(selectedOrderData?.createdAt).format(
              'DD.MM.YYYY - h:mm:ss'
            )}
          </TimeInfo>
        </BasicOrderInfoContainer>

        <Grid sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          {(lockEntity === undefined ||
            lockEntity === null ||
            lockEntity?.userId === user?.id) && (
            <Grid>
              {lockEntity ? (
                <Tooltip placement="top" title="Unlock order">
                  <LockOutlinedIcon
                    sx={{ ...iconsStyledProps }}
                    onClick={setLockStatus}
                  />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="Lock order">
                  <LockOpenOutlinedIcon
                    sx={{ ...iconsStyledProps }}
                    onClick={setLockStatus}
                  />
                </Tooltip>
              )}
            </Grid>
          )}

          {isFavorite() ? (
            <Tooltip placement="top" title="Remove from favorites">
              <FavoriteIcon
                sx={{ ...iconsStyledProps, color: '#FFDB80' }}
                onClick={setFavorite}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title="Add to favorites">
              <FavoriteBorderIcon sx={iconsStyledProps} onClick={setFavorite} />
            </Tooltip>
          )}

          <CloseIcon sx={iconsStyledProps} onClick={handleClose} />
        </Grid>
      </Header>
      <StockInOrderDetailsSidebarTabs
        selectedOrderData={selectedOrderData}
        setSelectedOrderData={setSelectedOrderData}
        setIsUploadInProcess={setIsUploadInProcess}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
      />
      <FooterContainer>
        <Footer>
          <Button
            color="error"
            variant="text"
            onClick={handleOnCancel}
            disabled={isUploadInProcess || isDisabled}
          >
            CANCEL ORDER
          </Button>
          <Button
            color="info"
            variant="outlined"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            disabled={
              isUploadInProcess ||
              isDisabled ||
              (!isDirty && selectedOrderData?.status === 'CONFIRMED')
            }
          >
            SAVE
          </Button>
        </Footer>
      </FooterContainer>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(100svh - 44px);
`;

const BasicOrderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  background: #454f5b;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: 700;
  font-size: 24px;
  color: white;
`;

const FooterContainer = styled.div`
  padding: 16px;
  padding-bottom: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 22;
  border-radius: 8px;
`;

const Footer = styled.div`
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  border-top: 4px solid #e6e8f0;
`;

export default StockInOrderDetailsTabs;
