import {
  CourierService,
  DBDCollectionOrderType,
  DBTrackingInfo,
} from '@prisma/client';
import { OrderTypesEnum } from './ship-and-print';

export interface IUpdateTrackInfo {
  courierServiceStatus?: string;
  internalStatus?: string;
}

export type CreateTrackInfo = Omit<
  DBTrackingInfo,
  'id' | 'createdAt' | 'updatedAt'
>;

export interface OrderTrackInfo {
  id: string;
  trackingInfoData: DBTrackingInfo[];
}

export interface DeleteTrackInfo {
  courierService: CourierService;
  abbreviation?: string;
}

interface TrackingInfoRequest {
  orderId: string;
  orderType: OrderTypesEnum;
}

export interface TrackInfoPreviewModel extends DBTrackingInfo {
  lastUpdated: string;
}

export interface TrackInfoOrderDetails {
  id: string;
  type: DBDCollectionOrderType;
}

interface DBTrackInfoApi {
  create: {
    requestBody: DBTrackingInfo;
    requestParams: never;
    request: DBTrackingInfo;
    response: DBTrackingInfo;
  };
  updateOne: {
    requestBody: CreateTrackInfo;
    requestParams: never;
    request: CreateTrackInfo;
    response: CreateTrackInfo;
  };
  updateMany: {
    requestBody: { trackNumbers: string[]; orderType: OrderTypesEnum };
    requestParams: never;
    request: { trackNumbers: string[]; orderType: OrderTypesEnum };
    response: string;
  };
  delete: {
    request: { courierService: string };
    requestParams: { trackingNumber: string };
    requestBody: { courierService: string };
    response: string;
  };
  getOrderTrackingInfo: {
    requestBody: TrackingInfoRequest;
    requestParams: TrackingInfoRequest;
    request: TrackingInfoRequest;
    response: DBTrackingInfo[];
  };
}

export { DBTrackInfoApi };
