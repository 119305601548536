import { DBLanguage, DBUserRole } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBBranchesApi, DBRoleApi, DBUserApi } from '@tyrio/dto';
import {
  FormController,
  generateUsersForm,
  PermissionsValues,
  TyrioSelectInputOption,
} from '@tyrio/forms';
import {
  DeleteModal,
  ToastHelper,
  ToastMessageType,
  ToastType,
} from '@tyrio/ui-library';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import * as z from 'zod';
import { Ids } from '../../../../constants/ids';
import { PageTemplateContent } from '../../components/PageTemplate/PageTemplate';
import { useAuth } from '../../context/AuthContext';

interface UserDetailsFormProps {
  clientId?: string;
  permissions: PermissionsValues;
  loggedUser: string;
}
const UserDetailsForm = ({
  clientId,
  permissions,
  loggedUser,
}: UserDetailsFormProps) => {
  const isCompanySettingsRoute = window.location.pathname
    .toString()
    .includes('company-settings');

  const { userId } = useParams<{ userId: string }>();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const queryClient = useQueryClient();
  const history = useHistory();

  const [branches, setBranches] = useState<TyrioSelectInputOption[]>([
    {
      label: '',
      value: '',
    },
  ]);

  const { user } = useAuth();

  const [partialUserData, setPartialUserData] = useState<
    Partial<DBUserApi['create']['request']> | undefined
  >(undefined);

  // GET USER data
  const {
    data: user_data,
    isFetching: isUserDataFetching,
    refetch: getUserById,
    isLoading: isUserDataLoading,
  } = useQuery(
    ['get_user_id', userId, 'update_user'],
    () =>
      api.fetch<DBUserApi['getOne']>(`get_user_by_id`, {
        userId: userId,
      }),
    {
      enabled: !!userId,
    }
  );

  const { refetch: getRoles, ...rolesListQuery } = useQuery(
    ['role_list'],
    () =>
      api.fetch<DBRoleApi['list']>('role_list', {
        isActive: true,
      }),
    {}
  );

  const hasRendered = useRef(false);

  useEffect(() => {
    if (!hasRendered.current) {
      getRoles();
      hasRendered.current = true;
    } else {
      getRoles();
    }
  }, [getRoles, userId]);

  const isSuperAdmin: boolean = user?.roleId === Ids.SUPER_ADMIN;

  const { form, zodSchema } = useMemo(
    () =>
      generateUsersForm(
        rolesListQuery.data?.data?.map((item) => {
          if (isSuperAdmin && !user?.clientId) {
            return {
              label: item.roleName,
              value: item.id,
            };
          }
          return {
            label: item.roleName,
            value: item.id,
            disabled: item.id === '00000000-0000-0000-0000-000000000006',
          };
        }) || [],
        !isSuperAdmin,
        userId !== undefined,
        branches,
        userId
      ),
    [rolesListQuery.data?.data, isSuperAdmin, userId, branches, user?.clientId]
  );

  useQuery(
    ['get_branches'],
    () =>
      api.fetch<DBBranchesApi['list']>(`get_branches`, {
        search: '',
      }),
    {
      onSuccess: (branches_data) => {
        const dropdownItems = branches_data.data.map((item) => ({
          label: item.branchName,
          value: item.id,
        }));
        setBranches(dropdownItems);
      },
    }
  );

  const createUserMutation = useMutation(
    (user: DBUserApi['create']['requestBody']) =>
      api.fetch<DBUserApi['create']>('create_user', {
        ...user,
      }),
    {
      mutationKey: 'create_user',
      onSuccess: (data) => {
        queryClient.refetchQueries('get_users');
        ToastHelper.showToast(
          'User',
          ToastType.SUCCESS,
          ToastMessageType.CREATE
        );
        if (isCompanySettingsRoute)
          history.push(`/dashboard/company-settings/users/${data.user.id}`);
        else history.push(`/dashboard/users/${data.user.id}`);
        setPartialUserData({});
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        ToastHelper.showToast(
          e.response.data.error.name !== 'TOO_LONG' &&
            e.response.data.error.name !== 'TOO_SHORT'
            ? e.response.data.error.name
            : `Phone number is ${e.response.data.error.name}`,
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR
        );
      },
    }
  );

  // UPDATE USER data
  const updateUserMutation = useMutation(
    (user: DBUserApi['updateOne']['request']) =>
      api.fetch<DBUserApi['updateOne']>('update_user', {
        userId: userId,
        ...user,
      }),
    {
      mutationKey: 'update_user',
      onSuccess: () => {
        queryClient.invalidateQueries('get_users');
        queryClient.refetchQueries('me');
        getUserById();
        ToastHelper.showToast(
          'User',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
        setPartialUserData({});
      },
      onError: () => {
        ToastHelper.showToast('User', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  const handleUpsertUser = (data: z.infer<typeof zodSchema>) => {
    setPartialUserData({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobilePhone: data.mobilePhone,
      birthday: data.birthday?.toString() || '',
      remark: data.remark || '',
      userRole: DBUserRole.CLIENT,
      roleId: data.role,
      language: DBLanguage[data.language as keyof typeof DBLanguage],
      businessPhone: data.businessPhone,
      personalIdentificationNumber: data.personalIdentificationNumber,
      pin: data.pin,
      mainBranchId: data.mainBranchId || undefined,
      currentBranchId: data.currentBranchId || undefined,
      secondaryBranches:
        data.secondaryBranches.map(
          (item: TyrioSelectInputOption) => item.value
        ) || [],
    });

    if (data.role === Ids.SUPER_ADMIN && !isSuperAdmin) {
      ToastHelper.showToast(
        'Role',
        ToastType.WARNING,
        undefined,
        `You can not create/edit user with role Super Admin!`
      );
      setPartialUserData({});
      return;
    }

    const body = {
      userId: userId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobilePhone: data.mobilePhone,
      businessPhone: data.businessPhone,
      personalIdentificationNumber: data.personalIdentificationNumber,
      pin: data.pin,
      birthday: data?.birthday?.toString() || '',
      remark: data.remark,
      roleId: data.role,
      language: DBLanguage[data.language as keyof typeof DBLanguage],
      mainBranchId: data.mainBranchId,
      currentBranchId: data.currentBranchId || undefined,
      secondaryBranches:
        data.secondaryBranches.map(
          (branch: TyrioSelectInputOption) => branch.value
        ) || [],
    };
    if (userId) updateUserMutation.mutate(body);
    else {
      createUserMutation.mutate({
        ...body,
        userRole: DBUserRole.CLIENT,
        currentBranchId: body.mainBranchId,
        clientId,
      });
    }
  };

  const deleteUserMutation = useMutation(
    () =>
      api.fetch<DBUserApi['getOne']>('delete_user', {
        userId: userId,
      }),
    {
      mutationKey: 'delete_user',
      onSuccess: () => {
        queryClient.invalidateQueries('get_users');
        if (isCompanySettingsRoute)
          history.push(`/dashboard/company-settings/users`);
        else history.push(`/dashboard/users/`);
        ToastHelper.showToast(
          'User',
          ToastType.SUCCESS,
          ToastMessageType.DELETE
        );
      },
      onError: () => {
        ToastHelper.showToast('User', ToastType.ERROR, ToastMessageType.ERROR);
      },
    }
  );

  const handleOnCancel = () => {
    if (user?.roleId === Ids.SUPER_ADMIN) {
      if (isCompanySettingsRoute)
        history.push(`/dashboard/company-settings/users/`);
      else history.push(`/dashboard/users`);
    } else {
      if (isCompanySettingsRoute) history.push(`/dashboard/company-settings`);
      else history.push(`/dashboard`);
    }
  };

  const handleDeleteUser = () => {
    if (userId) setIsDeleteModalVisible(true);
  };

  const handleChangeUserStatus = () => {
    if (userId === loggedUser) {
      ToastHelper.showToast(
        'User',
        ToastType.WARNING,
        undefined,
        `You can not deactivate yourself !`
      );
      return;
    } else if (userId && permissions.edit) {
      updateUserMutation.mutate({
        userId: userId,
        active: !user_data?.active,
      });
    }
  };

  return (
    <PageTemplateContent>
      {isDeleteModalVisible && (
        <DeleteModal
          LBAction={() => setIsDeleteModalVisible(false)}
          RBAction={() => {
            loggedUser === userId
              ? ToastHelper.showToast(
                  'User',
                  ToastType.WARNING,
                  undefined,
                  `You can not delete yourself !`
                )
              : deleteUserMutation.mutate();
            setIsDeleteModalVisible(false);
          }}
          itemName={user_data?.firstName || ''}
        />
      )}
      {!isUserDataFetching && !isUserDataLoading && (
        <FormController<z.infer<typeof zodSchema>>
          onCancel={handleOnCancel}
          permissions={permissions}
          form={form}
          key={user_data?.id ?? 'form-controller'}
          initialValues={{
            firstName: user_data?.firstName ?? partialUserData?.firstName,
            lastName: user_data?.lastName ?? partialUserData?.lastName,
            email: user_data?.email ?? partialUserData?.email,
            mobilePhone: user_data?.mobilePhone ?? partialUserData?.mobilePhone,
            businessPhone:
              user_data?.businessPhone ?? partialUserData?.businessPhone,
            personalIdentificationNumber:
              user_data?.personalIdentificationNumber ??
              partialUserData?.personalIdentificationNumber,
            birthday: user_data?.birthday
              ? moment(user_data?.birthday).toDate()
              : partialUserData?.birthday
              ? moment(partialUserData.birthday).toDate()
              : null,
            remark: user_data?.remark ?? partialUserData?.remark,
            pin: user_data?.pin ?? '',
            role: user_data?.role.id ?? partialUserData?.roleId ?? '',
            language:
              user_data?.language ?? partialUserData?.language ?? 'Croatian',
            mainBranchId:
              user_data?.mainBranchId ??
              partialUserData?.mainBranchId ??
              undefined,
            currentBranchId: user_data?.currentBranchId ?? undefined,
            secondaryBranches:
              user_data?.secondaryBranches?.map((item) => ({
                label: item.branchName,
                value: item.id,
              })) ?? [],
          }}
          zodSchema={zodSchema}
          active={user_data ? user_data.active : true}
          onSubmit={(user_data) => handleUpsertUser(user_data)}
          onDelete={handleDeleteUser}
          onChangeStatus={handleChangeUserStatus}
          title={
            userId
              ? [user_data?.firstName, user_data?.lastName].join(' ')
              : 'Create new user'
          }
          gridSpacing={4}
          objectId={user_data?.id}
          shouldReset={false}
        />
      )}
    </PageTemplateContent>
  );
};
export default UserDetailsForm;
