import {
  DBAppointmentStatus,
  DBAppointments,
  DBBillingAddress,
  DBClient,
  DBClientCustomerOrder,
  DBCurrency,
  DBCustomerOrderItem,
  DBCustomerOrderTypeEnum,
  DBDeliveryAddress,
  DBDeliveryTypes,
  DBPaymentStatusEnum,
  DBPaymentTypes,
  DBSalesChannel,
  DBShopTypes,
  DBTrackingInfo,
  DBUser,
  DBWarehouseShelfs,
  DBWorkOrders,
  PaymentTypeEnum,
  Prisma,
} from '@prisma/client';
import { DBWorkOrdersCreate, IWorkOrderLineItem } from './work-orders';

export enum DBCustomerOrderStatusEnum {
  PENDING = 'PENDING',
  NEW = 'NEW',
  ORDERED = 'ORDERED',
  IN_PROCESS = 'IN_PROCESS',
  ON_HOLD = 'ON_HOLD',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  COLLECTED = 'COLLECTED',
  FITTED = 'FITTED',
  CANCELLED = 'CANCELLED',
  RETURN_REQUESTED = 'RETURN_REQUESTED',
  RETURNED = 'RETURNED',
  EXCHANGE_REQUESTED = 'EXCHANGE_REQUESTED',
  EXCHANGED = 'EXCHANGED',
}

interface DBCustomerOrderItemFilters {
  searchType?: string;
  searchKeyword?: string;
  salesChannelKeys?: DBSalesChannel[];
  startDate?: Date;
  endDate?: Date;
  orderStatus?: DBCustomerOrderStatusEnum | string;
  //TODO Find type for this because real type 'qs.parsedQs' has linting problems
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sidebarFilterValues?: any;
  myOrders?: string;
  category?: string;
  paymentMethod?: string;
  branch?: string;
  suppliers?: string;
  paymentStatus?: string;
}

interface DBCustomerOrderItemRequestBody {
  filters: DBCustomerOrderItemFilters;
  page?: number;
  pageSize?: number;
}

interface LastUpdateDetails {
  lastUpdatedBy: string;
  lastUpdateTime: Date;
}

export type AppointmentItem = DBAppointments & {
  reservedBox: DBWarehouseShelfs | null;
  workOrders: DBWorkOrders | null;
};

interface DBCustomerOrderItemResponse extends DBCustomerOrderItem {
  trackingInfoData: DBTrackingInfo[] | null;
  order: DBClientCustomerOrder | null;
  client: DBClient | null;
  customer: Prisma.JsonValue | null;
  assignedTo: DBUser | null;
  shop: DBShopTypes | null;
  payment: DBPaymentTypes | null;
  delivery: DBDeliveryTypes | null;
  deliveryAddress: DBDeliveryAddress | null;
  billingAddress: DBBillingAddress | null;
  workOrders?: DBWorkOrders[];
  appointments?: AppointmentItem[];
}

export interface ICustomerOrder {
  id: string;
  customer: CustomerPayload | null;
  customerRemark: string;
  details: DBCustomerOrderItemDetails;
  deliveryAddress: DBDeliveryAddress | null;
  erpOrder: string | null;
  payment: {
    code: string;
    paymentType: PaymentTypeEnum;
    name: string;
  } | null;
  paymentDetails: PaymentDetails;
  summary: SummaryDetails;
  services: CustomerOrderServices[];
  lineItems: DBOrderItems[];
  workOrders: { id: string; lineItems: IWorkOrderLineItem[] }[];
  billingAddress: DBBillingAddress;
  orderNumber: string;
}

interface DBCustomerOrderItemCountByStatus {
  _count: number;
  orderStatus: DBCustomerOrderStatusEnum;
}

interface DBCustomerOrderOnePayload {
  id: string;
}

export interface PaymentDetails {
  cardInfo?: CardInfoDetails;
  paymentStatus?: string;
}

interface DBCustomerOrderUpdatePayload {
  orderStatus?: OrderStatusProps;
  deliveryId?: string;
  orderType?: DBCustomerOrderTypeEnum;
  paymentStatus?: DBPaymentStatusEnum;
  internalRemark?: string;
  paymentDetails?: PaymentDetails;
  address: DBCustomerOrderUpdateAddressPayload;
  lastEditedBy: LastUpdateDetails;
}

export interface DBCustomerOrderUpdateAddressPayload {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editedDeliveryAddress?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editedBillingAddress?: any;
  editedBy: string;
  updatedAt: string;
}

export interface CardInfoDetails {
  cardType?: DBCardType;
  instalment?: number;
  transactionId?: string;
}

enum CustomerType {
  B2B,
  B2C,
}

export interface CompanyInfo {
  companyName: string;
  vat: string;
  partnerErpID?: string;
  motErpID?: string;
  partnerPaymentDelay?: number;
}

export interface CustomerPayload {
  customerType?: CustomerType;
  firstName: string;
  lastName: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  email: string;
  mobilePhone: string;
  newsletter: boolean;
  phoneNumberDetails: PhoneNumberDetails;
  companyInfo?: CompanyInfo;
  partnerErpID?: string;
  motErpID?: string;
}

export interface PhoneNumberDetails {
  countryCode: string;
  phoneNumber: string;
}

export interface DBCustomerOrderItemDetails {
  number: string;
  uid?: string;
  ipAddress?: string;
  acceptedTerms?: Date;
  customerRemark?: string;
  customerReference?: string;
  status?: DBCustomerOrderStatusEnum;
  subStatus?: OrderSubStatuses | InProcessSubStatuses;
  vatPercent?: number;
  currency?: DBCurrency;
  salesCampaignName?: string;
  couponCode?: string;
  createdAt?: Date;
  updatedAt?: Date;
  url?: string;
  vehicleName?: string;
  vehicleLpn?: string;
}

export interface SummaryDetails {
  subtotalExVat: number;
  discountExVat: number;
  deliveryExVat: number;
  servicesExVat: number;
  vatAmount: number;
  grandTotalIncVat: number;
}

export interface IBillingAddressCompanyInfo {
  companyName: string;
  companyInvoice: boolean;
  vat: string;
}

export interface MainAddressProps {
  id?: string;
  firstName: string;
  lastName: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  phoneNumberDetails: PhoneNumberDetails;
}

export type CustomerOrderDeliveryAddress = MainAddressProps & {
  companyInfo?: {
    companyName: string;
  };
};

export type CustomerOrderBillingAddress = MainAddressProps & {
  email: string;
  companyInfo: IBillingAddressCompanyInfo;
};

interface DBCustomerOrderItemCreatePayload {
  orderId?: string;
  orderDetails: DBCustomerOrderItemDetails;
  shop?: {
    code?: string;
  };
  payment: {
    code: string;
    paymentStatus: DBPaymentStatusEnum;
    cardInfo?: {
      name?: string;
      instalment?: number;
      transactionId?: string;
    };
  };
  delivery?: {
    code: string;
  };
  customer?: CustomerPayload;
  deliveryAddress?: CustomerOrderDeliveryAddress;
  billingAddress?: CustomerOrderBillingAddress;
  items: DBOrderItems[];
  appointments?: CreateServiceReservationReq[];
  vehicleInfo?: VehicleInfo[];
  summary: SummaryDetails;
  lastEditedBy: LastUpdateDetails;
  erpOrder?: string;
  branchErpId?: string;
  workOrders?: DBWorkOrdersCreate[];
  services?: CustomerOrderServices[];
  mainWarehouseId?: number;
}

export interface CustomerOrderServices {
  erpId: string;
  code: string;
  name: string;
  price: number;
  quantity: number;
  id?: string;
}

export interface CreateServiceInfo {
  serviceId: string;
  quantity: number;
}
export interface CreateServiceReservationReq {
  services: CreateServiceInfo[];
  boxId: string;
  appointmentDateFrom: string;
  appointmentDateTo: string;
  remark: string;
  status?: DBAppointmentStatus;
  isActive?: boolean;
}

export interface VehicleInfo {
  model: string;
  license: string;
}

interface DBOrderItems {
  extUid: string;
  erpId?: string;
  ean: string;
  manufacturerCode: string;
  brand: string;
  sku: string;
  productName: string;
  euLabel?: {
    euDirectiveNumber?: string;
    rollingResistance?: string;
    wetGrip?: string;
    noisePerformance?: number;
    noiseClassType?: string;
    eprelId?: string;
  };
  quantity: number;
  sellingPrice: number;
  cashDiscount: number;
  deliveryDateFrom: Date;
  deliveryDateTo: Date;
  purchasing: {
    supplierSettings: {
      supplierId: string;
      supplierName: string;
      supplierSlug: string;
    };
    locationSettings: {
      supplierLocationId: string;
      supplierLocationSlug: string;
    };
    purchasePrice: number;
    stockQuantity: number;
    supplierCode: string;
    entryPriceAAKG?: number;
  };
  received?: number;
  dot?: string;
  reservedOnLocation?: {
    stockLocationId: string;
    qrCode: string;
    reserved: number;
  }[];
}

export enum DBCardType {
  VISA = 'visa',
  MASTERCARD = 'MasterCard®',
  MAESTRO = 'Maestro®',
  DINERS = 'Diners',
}

export enum OrderSubStatuses {
  Sent = 'Sent',
  Confirmed = 'Confirmed',
  Partially_confirmed = 'Partially confirmed',
  Shipped = 'Shipped',
  Partially_shipped = 'Partially shipped',
  Received = 'Received',
  Partially_received = 'Partially received',
  Mixed = 'Mixed',
  Backorder = 'Backorder',
  Supplier_declined = 'Supplier_declined',
  Cancelled = 'Cancelled',
}

export enum InProcessSubStatuses {
  Ready_for_picking = 'Ready for picking',
  Picking_in_progress = 'Picking in progress',
  Ready_for_shipping = 'Ready for shipping',
  Ready_for_fitting = 'Ready for fitting',
  Ready_for_collection = 'Ready for collection',
  Missing_items = 'Missing items',
  Problem_reported = 'Problem reported',
}

export interface OrderStatusProps {
  status: DBCustomerOrderStatusEnum;
  subStatus: OrderSubStatuses | InProcessSubStatuses | string;
}

export interface DBCustomerOrderUpdatePaymentPayload {
  orderStatus: string;
  paymentStatus: DBPaymentStatusEnum;
  cardType: DBCardType;
  instalment: number;
  transactionId: string;
}

interface DBCustomerOrderItemApi {
  list: {
    requestBody: DBCustomerOrderItemRequestBody;
    request: DBCustomerOrderItemRequestBody;
    response: { data: DBCustomerOrderItemResponse[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBCustomerOrderOnePayload;
    request: DBCustomerOrderOnePayload;
    response: DBCustomerOrderItemResponse;
  };
  create: {
    requestBody: DBCustomerOrderItemCreatePayload;
    requestParams: never;
    request: never;
    response: never;
  };
  updateOne: {
    requestBody: DBCustomerOrderUpdatePayload;
    requestParams: never;
    request: DBCustomerOrderUpdatePayload | DBCustomerOrderOnePayload;
    response: DBCustomerOrderItem;
  };
  updateAddress: {
    requestBody: DBCustomerOrderUpdateAddressPayload;
    requestParams: never;
    request: DBCustomerOrderUpdateAddressPayload | DBCustomerOrderOnePayload;
    response: DBCustomerOrderItem;
  };
}
export {
  DBCustomerOrderItemApi,
  DBCustomerOrderItemCountByStatus,
  DBCustomerOrderItemFilters,
  DBCustomerOrderItemResponse,
  DBCustomerOrderItemCreatePayload,
  DBOrderItems,
};
