import { PermissionsValues } from '@tyrio/forms';
import { LandingPage } from '@tyrio/ui-library';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Fiscalization from '../features/company/pages/Fiscalization';
import MainInfo from '../features/company/pages/MainInfo';
import Branches from '../pages/company-settings/Branches';
import DashboardCompanySettings from '../pages/dashboard/CompanySettings';
import { tyrioIcons } from '@tyrio/ui-library';
import { EDIOrderConfig } from '../features/EDIOrderConfig/EDIOrderConfig';
import General from '../features/company/pages/General';
import RoleForm from '../pages/company-settings/RoleForm';
import { PaymentMethodRight } from '../pages/company-settings/PaymentMethodFrom';
import { UsersForm } from '../pages/company-settings/UsersForm';
import Printers from '../pages/company-settings/Printers';
import { ShippingCouriersPage } from '../pages/dashboard/shipping-couriers/ShippingCouriersPage';
import { DeliveryPage } from '../pages/dashboard/delivery-methods/DeliveryPage';
import { DocumentsLookPage } from '../pages/company-settings/documents-look/DocumentsLookPage';

interface CompanySettingsRouterProps {
  clientId: string;
  permissions: PermissionsValues;
}

export const CompanySettingsRouter = ({
  clientId,
  permissions,
}: CompanySettingsRouterProps) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <DashboardCompanySettings>
      <Switch>
        <Route exact path={`${path}/company-info`}>
          <MainInfo clientId={clientId} permissions={permissions} />
        </Route>
        <Route exact path={`${path}/branches`}>
          <LandingPage
            icon={tyrioIcons.companyBranchesIconeWhite}
            title={'Welcome to Branches settings'}
            subtitle={'Choose branch from the list or create new.'}
            buttonText={'Create new branch'}
            onClick={() => history.push(`${path}/branches/new`)}
          />
        </Route>
        <Route exact path={`${path}/branches/new`}>
          <Branches />
        </Route>
        <Route exact path={`${path}/order-config`}>
          <EDIOrderConfig />
        </Route>
        <Route exact path={`${path}/branches/:branchId`}>
          <Branches />
        </Route>
        <Route exact path={`${path}/fiscalization`}>
          <Fiscalization />
        </Route>
        <Route exact path={`${path}/general`}>
          <General />
        </Route>
        <Route exact path={`${path}/roles/new`}>
          <RoleForm />
        </Route>
        <Route exact path={`${path}/roles/:roleId`}>
          <RoleForm />
        </Route>
        <Route exact path={`${path}/payment-method/new`}>
          <PaymentMethodRight isNew={true} />
        </Route>
        <Route exact path={`${path}/payment-method/:id`}>
          <PaymentMethodRight isNew={false} />
        </Route>
        <Route exact path={`${path}/users/new`}>
          <UsersForm />
        </Route>
        <Route exact path={`${path}/users/:userId`}>
          <UsersForm />
        </Route>
        <Route exact path={`${path}/printers`}>
          <Printers />
        </Route>
        <Route exact path={`${path}/delivery-method/new`}>
          <DeliveryPage isNew={true} />
        </Route>
        <Route exact path={`${path}/delivery-method/:id`}>
          <DeliveryPage isNew={false} />
        </Route>
        <Route exact path={`${path}/shipping-couriers/:id`}>
          <ShippingCouriersPage />
        </Route>
        <Route exact path={`${path}/documents-look`}>
          <DocumentsLookPage />
        </Route>
      </Switch>
    </DashboardCompanySettings>
  );
};
