import { DBSupplierContact } from '@prisma/client';

interface DBUpsertSupplierContactPayload {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  businessPhone: string;
  mobilePhone: string;
  remark: string;
}

interface DBSupplierContactApi {
  list: {
    requestBody: never;
    request: never;
    response: never[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierContactPayload;
    requestParams: never;
    request: DBUpsertSupplierContactPayload;
    response: DBSupplierContact;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierContactApi };
