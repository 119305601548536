import { DBAddress, DBClient, DBUser } from '@prisma/client';

interface DBUserLoginPayload {
  email: string;
  password: string;
}
interface DBTokenValidationPayload {
  token: string;
  type: string;
}
interface DBGetUserTokenPayload {
  email?: string;
  phoneNumber?: string;
}

export type LoggedUser = DBUser & {
  client?: (DBClient & { address?: DBAddress }) | null | undefined;
};
interface DBUserAuthApi {
  validateToken: {
    requestBody: DBTokenValidationPayload;
    requestParams: never;
    request: DBTokenValidationPayload;
    response: { token: string; user: boolean; uid: string };
  };
  login: {
    requestBody: DBUserLoginPayload;
    requestParams: never;
    request: DBUserLoginPayload;
    response: { token: string; user: DBUser };
  };
  me: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBUser & {
      client?: (DBClient & { address?: DBAddress }) | null | undefined;
    };
  };
  getToken: {
    requestBody: DBGetUserTokenPayload;
    requestParams: never;
    request: DBGetUserTokenPayload;
    response: { token: string | null };
  };
}

export { DBUserAuthApi };
