/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DBClient,
  DBClientSupplierOrder,
  DBClientSupplierOrderItem,
  DBConnectionProtocol,
  DBOrderStatus,
  Prisma,
} from '@prisma/client';
import { ConnectionShape } from './supplier-client-settings';

export interface PurchaseOrderItem {
  id: string;
  lineItems: Prisma.JsonValue;
  customerOrderId: string | null;
}

export interface ClientSupplierDeliveryAddress {
  address_line_1: string;
  address_line_2: string;
  city: string;
  country: string;
  zipCode: string;
}

interface DBClientSupplierOrderCreateInput {
  orderId?: string;
  branchId?: number;
  supplierId: string;
  lineItems: any[];
  orderNumberSufix?: string;
  remark?: string;
  supplierRemark?: string;
  toEmail: string;
  fromEmail: string;
  orderNumberPrefix: string;
  cc?: string;
  deliveryAddress?: ClientSupplierDeliveryAddress;
  connectionProtocol: DBConnectionProtocol;
  connection: ConnectionShape;
  orderReference?: string;
  reference?: string;
}

interface DBClientSupplierCancelOrder {
  status: DBOrderStatus;
  cancelReason: string;
}

export interface PurchaseOrderLineItemMeta {
  ordered: number;
  received: number;
  backorder: number;
  confirmed: number;
}

export interface OrderLineItemMeta {
  [productId: string]: PurchaseOrderLineItemMeta;
}

interface DBClientSupplierOrderApi {
  list: {
    requestBody: never;
    request: never;
    response: DBClientSupplierOrder[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBClientSupplierOrder;
  };
  create: {
    requestBody: DBClientSupplierOrderCreateInput;
    requestParams: never;
    request: DBClientSupplierOrderCreateInput;
    response: DBClientSupplierOrderItem;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBClient;
  };
  updateRef: {
    requestBody: { orderNumberSufix: string };
    requestPrams: { id: string };
    request: { orderNumberSufix: string } & { id: string };
    response: DBClientSupplierOrderItem;
  };
  cancelOrder: {
    requestBody: DBClientSupplierCancelOrder;
    requestPrams: { id: string | undefined };
    request: DBClientSupplierCancelOrder & { id: string | undefined };
    response: DBClientSupplierOrderItem;
  };
}
export { DBClientSupplierOrderApi };
