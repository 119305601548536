import api from '@tyrio/api-factory';
import { DBPosTypesApi, PosItemStockType } from '@tyrio/dto';
import { isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { BadgeContent } from '../../../context/POSContext';

export const useGetBranchesStock = (
  ean: string,
  warehouseId?: string,
  badgeContent?: BadgeContent,
  setBadgeContent?: (a: BadgeContent) => void,
  selectedStock?: PosItemStockType,
  shouldShowDot?: boolean
) => {
  const { data: warehouseStock } = useQuery(
    [
      `get_branches_stock_${ean}_${selectedStock}_${shouldShowDot}_{${warehouseId}}`,
      { shouldShowDot, warehouseId },
    ],
    () =>
      api.fetch<DBPosTypesApi['getBranchesStock']>(`get_branches_stock`, {
        ean,
        inWarehouse: false,
        shouldShowDot,
        warehouseId,
      }),
    {
      enabled: !isEmpty(ean),
      onSuccess: (res) => {
        let sum = 0;
        res.map((r) =>
          r.lineItems.map((item) => (sum += item.quantity - item.reserved))
        );
        if (setBadgeContent && badgeContent)
          setBadgeContent({ ...badgeContent, BRANCHES: sum });
      },
    }
  );

  const { data: onStock, isFetching: isOnStockFetching } = useQuery(
    [
      `get_pos_on_stock_${warehouseId}_${ean}_${shouldShowDot}`,
      { shouldShowDot },
    ],
    () =>
      api.fetch<DBPosTypesApi['getBranchesStock']>(`get_pos_on_stock`, {
        warehouseId,
        ean,
        shouldShowDot,
      }),
    {
      enabled: selectedStock === 'CURRENT',
    }
  );

  const { data: upcomingStock } = useQuery(
    [
      `get_upcoming_on_stock_${warehouseId}_${ean}_${shouldShowDot}`,
      { shouldShowDot },
    ],
    () =>
      api.fetch<DBPosTypesApi['getBranchesStock']>(`get_upcoming_on_stock`, {
        warehouseId,
        ean,
        shouldShowDot,
      }),
    {
      enabled: selectedStock === 'CURRENT',
    }
  );

  return { warehouseStock, onStock, upcomingStock, isOnStockFetching };
};
