import React from 'react';
import { DBSalesOrdersApi } from '@tyrio/dto';

export interface SalesOrderQty {
  supplier: Record<string, Record<string, number>>;
  stock: Record<string, Record<string, number>>;
}

export interface SalesOrdersFlowContextShape {
  customerOrderId: string;
  dirty: boolean;
  data: DBSalesOrdersApi['prepareOrder']['response'];
  /**
   * `supplierId => productId`
   */
  qty: {
    supplier: Record<string, Record<string, number>>;
    stock: Record<string, Record<string, number>>;
  };
  setQty: (
    supplierId: string,
    productId: string,
    qty: number,
    key: 'supplier' | 'stock',
    hardSet?: boolean
  ) => void;
  /**
   * `productId => supplierId`
   */
  selected: Record<string, string>;
  setSelected: (supplierId: string, productId: string) => void;
  setScreen: (screen: 'process_order' | 'purchase_order') => void;
  screen: 'process_order' | 'purchase_order';
}
export const SalesOrdersFlowContext =
  React.createContext<SalesOrdersFlowContextShape>({
    customerOrderId: '',
    dirty: false,
    data: {
      orderRef: '',
      products: {},
      requested: {},
      suppliers: {},
      supplierQty: {},
      prices: {},
      deliveryDates: {},
      purchased: {},
      warehouses: {},
      warehouseQty: {},
      lineItems: [],
    },
    selected: {},
    qty: { supplier: {}, stock: {} },
    setQty: (_supplierId: string, _productId: string, _qty: number) => null,
    setSelected: (_supplierId: string, _productId: string) => null,
    setScreen: (_screen: 'process_order' | 'purchase_order') => null,
    screen: 'process_order',
  });
