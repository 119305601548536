import { Autocomplete, TextField } from '@mui/material';
import { DBClientSupplierOrderItemFilters } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useEffect, useState } from 'react';

type MultiSelectProps = Omit<
  DBClientSupplierOrderItemFilters,
  'searchType' | 'searchKeyword' | 'startData' | 'endDate' | 'orderStatus'
>;

interface MultiSelectInputProps {
  attributeKey: keyof MultiSelectProps;
  label: string;
  values: {
    value: string | number;
    label: string;
  }[];
  filters: DBClientSupplierOrderItemFilters;
  setFilters: (value: DBClientSupplierOrderItemFilters) => void;
  defaultValue?: {
    value: string | number;
    label: string;
  }[];
  disabled?: boolean;
  setFilterValue?: (data: Partial<qs.ParsedQs>) => void;
}

const MultiSelectInput = ({
  attributeKey,
  label,
  values,
  filters,
  setFilters,
  defaultValue,
  disabled,
  setFilterValue,
}: MultiSelectInputProps) => {
  const [initialTypes, setInitialTypes] = useState<TyrioSelectInputOption[]>(
    defaultValue ?? []
  );

  useEffect(() => {
    if (defaultValue) setInitialTypes(defaultValue);
  }, [defaultValue]);

  return (
    // TODO: prevent changing height
    <Autocomplete
      id="tags-outlined"
      disableCloseOnSelect
      multiple
      fullWidth
      disablePortal
      disabled={disabled}
      options={values}
      defaultValue={initialTypes}
      // we don't need this?
      // value={initialTypes ?? defaultValue}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      ChipProps={{
        clickable: true,
        style: {
          background: 'white',
          color: 'black',
          border: '1px solid gray',
        },
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(__, value) => {
        setFilters({
          ...filters,
          [attributeKey]: value.map((item) => item.value),
        });
        setFilterValue &&
          setFilterValue({
            selected: undefined,
            [attributeKey]: value.map((item) => item.value.toString()),
          });
      }}
    />
  );
};

export default MultiSelectInput;
