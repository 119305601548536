/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DBClientDeliveryCouriers,
  DBDeliveryCustomerOrdersTypeEnum,
  DBDeliveryLocation,
  DBDeliveryType,
  DBDeliveryTypes,
  DBDeliveryZone,
  DBRole,
  DBShippingCourier,
  DeliveryType,
} from '@prisma/client';

export interface DefaultSettings {
  daysToDeliver?: number;
  maxDaysToDeliver?: number;
  minOrderQuantity?: number;
  maxPackageWeight?: number;
  cashOnDelivery?: boolean;
  cardOnDellivery?: boolean;
  blacklistZIPCodes?: string[];
  whitelistZIPCodes?: string[];
}

export interface DefaultPrices {
  priceType?:
    | 'pricePerOrder'
    | 'pricePerPackage'
    | 'pricePerWeight'
    | 'pricePerProductCategory';
  deliveryPrice?: number;
  freeDelivery?: boolean;
  minOrderAmount?: number;
  minOrderQuantity2?: number;
}

export interface DefaultSurcharges {
  CODSurcharge?: string;
  CCODSurcharge?: string;
  minQuantitySurcharge?: number;
}

export interface ZoneSettings {
  name: string;
  daysToDeliver: number;
  maxDaysToDeliver: number;
  country?: string[];
  minOrderQuantity?: number;
  maxPackageWeight?: number;
  cashOnDelivery?: boolean;
  cardOnDelivery?: boolean;
  zipCodes?: string[];
}

export interface DeliveryZones {
  id?: string | undefined;
  deliveryTypeId?: string | undefined;
  zoneSettings: ZoneSettings;
  priceList: DefaultPrices;
  surcharges: DefaultSurcharges;
}

interface UpdateDeliveryType {
  id: string;
  code?: string;
  name?: string;
  shippingChannel?: DBDeliveryCustomerOrdersTypeEnum;
  deliveryType?: DBDeliveryType;
  deliveryTypeEnum?: DeliveryType;
  sortOrder?: string;
  description?: string;
  deliveryLocation?: DBDeliveryLocation | '';
  dbDeliveryCouriers?: { label: string; value: string }[];
  defaultSettings?: DefaultSettings;
  defaultPrices?: DefaultPrices;
  defaultSurcharges?: DefaultSurcharges;
  zones?: DeliveryZones[];
}

interface DBDeliveryTypesCreatePayload {
  code: string;
  name: string;
  shippingChannel?: DBDeliveryCustomerOrdersTypeEnum;
  deliveryType: DBDeliveryType;
  deliveryTypeEnum?: DeliveryType;
  sortOrder?: string;
  description?: string;
  deliveryLocation?: DBDeliveryLocation | '';
  dbDeliveryCouriers?: { label: string; value: string }[];
  defaultSettings?: DefaultSettings;
  defaultPrices?: DefaultPrices;
  defaultSurcharges?: DefaultSurcharges;
  zones?: DeliveryZones[];
}

interface ClientCourier {
  shippingCourier: DBShippingCourier;
}
export interface DeliveryCourier extends DBClientDeliveryCouriers {
  clientCourier: ClientCourier;
}
export interface OneDeliveryType extends DBDeliveryTypes {
  dbDeliveryCouriers: DeliveryCourier[];
  zones?: DBDeliveryZone[];
}

export interface IDeliveryMethods {
  [key: string]: boolean;
}

interface DBDeliveryTypesApi {
  list: {
    requestBody: { branchId?: string };
    request: { branchId?: string };
    response: DBDeliveryTypes[] & { zones?: DBDeliveryZone[] };
  };
  getOne: {
    requestBody: { id: string };
    requestParams: { id: string };
    request: { id: string };
    response: OneDeliveryType;
  };
  create: {
    requestBody: DBDeliveryTypesCreatePayload;
    requestParams: never;
    request: DBDeliveryTypesCreatePayload;
    response: DBRole;
  };
  updateOne: {
    requestBody: UpdateDeliveryType;
    requestParams: UpdateDeliveryType;
    request: UpdateDeliveryType;
    response: OneDeliveryType;
  };
  changeStatus: {
    requestBody: { id: string; active: boolean };
    requestParams: { id: string };
    request: { id: string; active: boolean };
    response: OneDeliveryType;
  };
}

export { DBDeliveryTypesApi };
