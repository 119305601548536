interface StatusInfo {
  title: string;
  desc: string;
}

interface StatusMapping {
  [key: string]: StatusInfo | undefined;
}

const statusMapping: StatusMapping = {
  CANCELLED: {
    title: 'CANCELLED',
    desc: 'Parcel data cancelled after PDF label was created',
  },
  CANCELLED_AND_SENT: {
    title: 'CANCELLED AND SENT',
    desc: 'Parcel data cancelled after it was sent to DPD',
  },
  CONSOLIDATION: {
    title: 'CONSOLIDATION',
    desc: 'Parcel is consolidated before export',
  },
  CREATED: {
    title: 'CREATED',
    desc: 'Parcel data was inserted, and parcel number was assigned',
  },
  CUSTOMS_CLEARANCE_PROCESS: {
    title: 'CUSTOMS CLEARANCE PROCESS',
    desc: 'Parcel is at customs clearance process',
  },
  DELETED: {
    title: 'DELETED',
    desc: 'Parcel deleted',
  },
  DELIVERED: {
    title: 'DELIVERED',
    desc: 'Parcel was delivered to receiver',
  },
  DELIVERED_BY_DRIVER_TO_DPD_PARCELSHOP: {
    title: 'DELIVERED BY DRIVER TO DPD PARCELSHOP',
    desc: 'Parcel was delivered to PUDO location',
  },
  DELIVERY_ATTEMPT_NOT_SUCCESSFUL: {
    title: 'DELIVERY ATTEMPT NOT SUCCESSFUL',
    desc: 'Parcel was not delivered to receiver',
  },
  DRIVERS_PICK_UP: {
    title: 'DRIVERS PICK UP',
    desc: "Courier picked up the parcel at sender's address",
  },
  DRIVERS_RETURN: {
    title: 'DRIVERS RETURN',
    desc: 'Courier returned the parcel to DPD depot after unsuccessful delivery',
  },
  EXPORT_IMPORT_CLEARED: {
    title: 'EXPORT IMPORT CLEARED',
    desc: 'Parcel passed the customs clearance',
  },
  HUB_SCAN: {
    title: 'HUB SCAN',
    desc: 'Parcel arrived in sorting depot',
  },
  INBOUND: {
    title: 'INBOUND',
    desc: 'Parcel arrived at delivery depot',
  },
  INBOUND_EXCEPTION: {
    title: 'INBOUND EXCEPTION',
    desc: 'Parcel is at warehouse and will be out for delivery next working day',
  },
  LOADING: {
    title: 'LOADING',
    desc: 'Parcel was loaded to swap body',
  },
  NOT_YET_PRINTED: {
    title: 'NOT YET PRINTED',
    desc: 'Parcel created but not printed',
  },
  OUT_FOR_DELIVERY: {
    title: 'OUT FOR DELIVERY',
    desc: 'Parcel is out for delivery to receiver',
  },
  PICK_UP: {
    title: 'PICK UP',
    desc: 'Parcel entered DPD depot',
  },
  PREPARED_TO_CANCEL: {
    title: 'PREPARED TO CANCEL',
    desc: 'Parcel data will be cancelled',
  },
  PREPARED_TO_SEND: {
    title: 'PREPARED TO SEND',
    desc: 'Parcel data is ready to be transmitted to DPD',
  },
  PRINTED: {
    title: 'PRINTED',
    desc: 'PDF label was created',
  },
  RETURN_TO_SENDER: {
    title: 'RETURN TO SENDER',
    desc: 'Parcel returned to sender',
  },
  SENT: {
    title: 'SENT',
    desc: 'Parcel data was transmitted to DPD',
  },
  SYSTEM_RETURN: {
    title: 'SYSTEM RETURN',
    desc: 'Parcel was prepared to be returned to sender',
  },
  UNKNOWN: {
    title: 'UNKNOWN',
    desc: 'No data at the moment',
  },
  WAREHOUSE: {
    title: 'WAREHOUSE',
    desc: 'Parcel is in DPD depot',
  },
};

export default statusMapping;
