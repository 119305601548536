import React, { useCallback, useEffect, useState } from 'react';
import {
  PageGrid,
  PageTemplateHeader,
} from '../../components/PageTemplateV2/PageTemplateV2';
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import { useClientSuppliersQuery } from '../../hooks/api/useClientSuppliersQuery';
import { EDIOrderListSkeleton } from './helper-components/ListSkeleton';
import { useBranchQuery } from '../../hooks/api/useBranchQuery';
import {
  ToastHelper,
  ToastMessageType,
  ToastType,
  tyrioMUI,
} from '@tyrio/ui-library';
import { DBSupplierClientSettings } from '@prisma/client';
import { DBBranchesApi } from '@tyrio/dto';
import _ from 'lodash';
import { useUpdateClientSupplierMutation } from '../../hooks/api/useUpdateClientSupplierMutation';
export const EDIOrderConfig = () => {
  const {
    data: clientSuppliers,
    isFetching: clientSuppliersFetching,
    refetch: refetchClientSuppliers,
  } = useClientSuppliersQuery();

  const {
    data: branches,
    isFetching: isBranchesFetching,
    refetch: refetchBranches,
  } = useBranchQuery();

  const updateMutation = useUpdateClientSupplierMutation();

  const [selectedSupplier, setSelectedSupplier] =
    useState<DBSupplierClientSettings | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<
    DBBranchesApi['getOne']['response'] | null
  >(null);

  const [partyIdValue, setPartyIdValue] = useState('');

  useEffect(() => {
    if (selectedSupplier && selectedBranch) {
      setPartyIdValue(
        _.get(
          selectedSupplier,
          `branchEdiConfiguration.${selectedBranch.id}`
        ) ?? ''
      );
    }
  }, [selectedBranch, selectedSupplier]);

  const handleClickSave = useCallback(async () => {
    if (selectedSupplier && selectedBranch) {
      const oldEdiConfiguration = _.get(
        selectedSupplier,
        'branchEdiConfiguration',
        {}
      ) as Record<number, string>;

      await updateMutation.mutateAsync({
        id: selectedSupplier.id,
        branchEdiConfiguration: {
          ...oldEdiConfiguration,
          [selectedBranch.id]: partyIdValue,
        },
      });
      await refetchClientSuppliers();
      await refetchBranches();

      ToastHelper.showToast(
        'Success',
        ToastType.SUCCESS,
        ToastMessageType.CUSTOM_ERROR,
        'Successfully updated branch configuration.'
      );
    }
  }, [
    partyIdValue,
    refetchBranches,
    refetchClientSuppliers,
    selectedBranch,
    selectedSupplier,
    updateMutation,
  ]);

  const configurable =
    selectedSupplier && selectedBranch && !updateMutation.isLoading;
  return (
    <PageGrid style={{ borderRadius: 22 }}>
      <PageTemplateHeader title="Order configuration" />
      <ContentWrapper>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={3}>
            <Typography>
              Before the automatic orders can be enabled, you must set up your
              branches/locations to properly work with the supplier order
              systems.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/**
           * SUPPLIER LIST
           */}
          <Grid item xs={4}>
            <Typography variant={'h5'}>Select supplier</Typography>
            <Divider />
            <List>
              {clientSuppliersFetching && <EDIOrderListSkeleton />}

              {clientSuppliers?.data.map((clientSupplier) => {
                const supplierName = clientSupplier.supplier?.companyShortName;
                const firstLetter = supplierName?.[0] ?? 'T';
                const labelId = clientSupplier.id;
                return (
                  <ListItem
                    key={clientSupplier.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        checked={selectedSupplier?.id === clientSupplier.id}
                        onChange={() => setSelectedSupplier(clientSupplier)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => setSelectedSupplier(clientSupplier)}
                    >
                      <ListItemAvatar>
                        <Avatar alt={`${supplierName}`}>{firstLetter}</Avatar>
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={supplierName} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          {/**
           * LOCATIONS LIST
           */}
          <Grid item xs={4}>
            <Typography variant={'h5'}>Select branch/location</Typography>
            <Divider />
            <List>
              {isBranchesFetching && <EDIOrderListSkeleton />}

              {branches?.data.map((branch) => {
                const branchName = branch.branchName;
                const firstLetter = branchName[0];
                const labelId = `branch-${branch.id}`;
                return (
                  <ListItem
                    key={branch.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        checked={selectedBranch?.id === branch.id}
                        onChange={() => setSelectedBranch(branch)}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton onClick={() => setSelectedBranch(branch)}>
                      <ListItemAvatar>
                        <Avatar alt={`${branchName}`}>{firstLetter}</Avatar>
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={branchName} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          {/**
           * Configuration
           * */}
          <Grid item xs={4}>
            <Typography
              variant={'h5'}
              color={
                configurable
                  ? tyrioMUI.muiTheme.palette.primary.main
                  : tyrioMUI.muiTheme.palette.grey['400']
              }
            >
              Configure
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />

            <Typography
              variant={'h6'}
              sx={{ marginBottom: 2 }}
              color={
                configurable
                  ? tyrioMUI.muiTheme.palette.primary.main
                  : tyrioMUI.muiTheme.palette.grey['400']
              }
            >
              EDI Order configuration
            </Typography>

            <TextField
              sx={{ marginBottom: 4 }}
              id={`party-id-${selectedSupplier?.id ?? 'NONE'}-${
                selectedBranch?.id ?? 'NONE'
              }`}
              label="Party ID"
              variant="outlined"
              disabled={!configurable}
              value={partyIdValue}
              onChange={(e) => setPartyIdValue(e.target.value)}
            />
            <Button
              onClick={handleClickSave}
              variant={'contained'}
              disabled={!configurable || updateMutation.isLoading}
              color="info"
            >
              {updateMutation.isLoading && (
                <CircularProgress size={18} sx={{ marginRight: 2 }} />
              )}
              Save
            </Button>
          </Grid>
        </Grid>
      </ContentWrapper>
    </PageGrid>
  );
};

const ContentWrapper = styled.div`
  padding: 22px;
`;
