import {
  DBBranch,
  DBBranchType,
  DBClient,
  DBClientRebateCalculation,
  DBCountry,
  Prisma,
} from '@prisma/client';

interface DBGetBranchesRequest {
  search: string;
  page?: number;
  pageSize?: number;
}

interface DBBranchesUpsertRequest {
  branchName: string;
  branchType: DBBranchType[];
  mainWarehouseId: number;
  isActive: boolean;
  countryId: string;
  zipCode: string;
  city: string;
  address: string;
  email?: string;
  webSite?: string;
  businessPhone?: string;
  qualifiedPersonJobTitle: string;
  qualifiedPersonFirstName: string;
  qualifiedPersonLastName: string;
  qualifiedPersonEmail?: string;
  qualifiedPersonBusinessPhone?: string;
  qualifiedPersonMobilePhone?: string;
  latitude?: number;
  longitude?: number;
  branchNumber?: number;
  services?: Prisma.InputJsonValue;
  paymentMethods?: Prisma.InputJsonValue;
  printers?: Prisma.InputJsonValue;
  deliveryMethods?: Prisma.InputJsonValue;
  erpId?: string;
  rebateCalculationId?: string;
}

interface DBBranchOnePayload {
  id: string;
}

type DBBranchRestResponse = {
  country?: DBCountry | null;
  client?: DBClient;
  rebateCalculation?: DBClientRebateCalculation | null;
};

export interface EntityStatus {
  id: string;
  isActive: boolean;
}

export interface IServices {
  [key: string]: boolean;
}

export interface IBranches {
  id: number;
  clientId: string;
  city: string;
  branchName: string;
  erpId: string | null;
  address: string;
  zipCode: string;
  branchNumber?: number;
}

export type DBBranchResponse = DBBranch & DBBranchRestResponse;

interface DBBranchesApi {
  list: {
    requestBody: DBGetBranchesRequest;
    request: DBGetBranchesRequest;
    response: { data: DBBranchResponse[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBBranchOnePayload;
    request: DBBranchOnePayload;
    response: DBBranchResponse;
  };
  create: {
    requestBody: DBBranchesUpsertRequest;
    requestParams: never;
    request: DBBranchesUpsertRequest;
    response: DBBranch;
  };
  updateOne: {
    requestBody: DBBranchesUpsertRequest;
    requestParams: DBBranchOnePayload;
    request: DBBranchesUpsertRequest | DBBranchOnePayload;
    response: DBBranch;
  };
}
export { DBBranchesApi };
