/**
 * Catalog generics types
 */

export type RawImport = Record<string, string>;

export type UnfinishedData = {
  // eslint-disable-next-line
  product: Record<string, any>;
  // eslint-disable-next-line
  model: Record<string, any>;
};
