import { DBFittingBoxSettings, Prisma } from '@prisma/client';
import { ShelfMeta } from './stock-locations';

interface ServiceCategoryResponse {
  id: string;
  name: string;
}

interface DBFittingBoxExtendedSettings extends DBFittingBoxSettings {
  workingTime: Prisma.JsonValue;
  serviceCategories: ServiceCategoryResponse[];
  shelf?: {
    id: string;
    meta: ShelfMeta;
  };
}

interface FittingBoxesReq {
  branchId: number;
}

export interface FittingBoxesRes {
  data: FittingBoxesResData[];
  branchName: string;
}

export interface FittingBoxesResData extends DBFittingBoxExtendedSettings {
  shelf: {
    id: string;
    meta: ShelfMeta;
  };
}

interface DBFittingBoxSettingsApi {
  create: {
    requestBody: DBFittingBoxExtendedSettings;
    requestParams: never;
    request: DBFittingBoxExtendedSettings;
    response: DBFittingBoxSettings;
  };
  updateOne: {
    requestBody: DBFittingBoxExtendedSettings;
    requestParams: never;
    request: DBFittingBoxExtendedSettings;
    response: DBFittingBoxSettings;
  };
  getMany: {
    requestBody: { branchId: string };
    requestParams: never;
    request: { branchId: string };
    response: FittingBoxesRes;
  };
  delete: {
    requestBody: DBFittingBoxExtendedSettings;
    requestParams: never;
    request: DBFittingBoxExtendedSettings;
    response: DBFittingBoxExtendedSettings;
  };
  getSettingsByBranch: {
    requestBody: FittingBoxesReq;
    requestParams: FittingBoxesReq;
    request: FittingBoxesReq;
    response: FittingBoxesRes;
  };
}

export { DBFittingBoxExtendedSettings, DBFittingBoxSettingsApi };
