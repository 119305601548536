import { Prisma } from '@prisma/client';

interface DBSupplierEmailConnectionApi {
  list: {
    requestBody: never;
    request: never;
    response: never[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: Prisma.DBEmailConnectionCreateInput;
    requestParams: never;
    request: never;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierEmailConnectionApi };
