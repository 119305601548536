import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { DBWarehouseZones } from '@prisma/client';
import { ZoneStagingType } from '@tyrio/dto';
import _ from 'lodash';
import { useContext } from 'react';
import { parseEnumToArray } from '../../../../../helpers/enum-parser';
import { WarehouseContext } from '../../../context/WarehouseContext';
import { format } from '../helper/helper';

interface ZoneCardProps {
  zone: DBWarehouseZones;
  subzonesTypes: string[];
  subzonesCount?: number;
}

export const ZoneCard = (props: ZoneCardProps) => {
  const { selected, setSelected, activeStep, setActiveStep } =
    useContext(WarehouseContext);

  const { zone, subzonesCount, subzonesTypes } = props;

  const settings = (att: string) => {
    return _.get(zone.settings, att);
  };

  return (
    <ZoneCardContainer
      background={settings('color')}
      onClick={() => {
        activeStep !== 'UPLOAD' && setActiveStep('DETAILS');
        setSelected({
          current: [...selected.current, 'subzone'],
          locations: {
            ...selected?.locations,
            zone: zone,
          },
        });
      }}
    >
      <HeaderContainer id="header">
        <CardHeader>
          <ZoneColor color={settings('color')} />
          <CardHeaderText>ZONE {zone.code}</CardHeaderText>
        </CardHeader>
        {zone.code === 'X' && <BoxInfo>FITTING</BoxInfo>}
      </HeaderContainer>
      {zone.description && (
        <ZoneDescription>{zone.description}</ZoneDescription>
      )}
      <ZoneContent>
        <Grid>
          <DescriptionItemWrapper>
            <ZoneShortDetailsLabel>Status:</ZoneShortDetailsLabel>
            <ZoneStatus>
              <ZoneStatusIcon isActive={settings('isActive')} />
              <ZoneShortDetailsValue>
                {settings('isActive') ? 'Active' : 'Inactive'}
              </ZoneShortDetailsValue>
            </ZoneStatus>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <ZoneShortDetailsLabel>Subzones:</ZoneShortDetailsLabel>
            <ZoneShortDetailsValue>{subzonesCount}</ZoneShortDetailsValue>
          </DescriptionItemWrapper>
          <DescriptionItemWrapper>
            <SubzoneTypeWrapper>
              {parseEnumToArray(ZoneStagingType).map((item) => {
                const type = format(item.value as string);
                return (
                  <SubzoneTypes isExist={subzonesTypes.includes(type)}>
                    {type}
                  </SubzoneTypes>
                );
              })}
            </SubzoneTypeWrapper>
          </DescriptionItemWrapper>
        </Grid>
      </ZoneContent>
    </ZoneCardContainer>
  );
};

const ZoneCardContainer = styled.div<{ background: string }>`
  background: #fff;
  width: 250px;
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  &:hover {
    cursor: pointer;
    border: 1px solid #c9ccd1;

    #header {
      background: #c9ccd1;
    }
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
`;

const BoxInfo = styled.div`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
  color: #e75524;
`;

const HeaderContainer = styled.div`
  background: #dfe3e8;
  padding: 12px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: #c9ccd1;
  }
`;

const CardHeaderText = styled.span`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;

const ZoneColor = styled.div<{ color: string }>`
  background: ${(prop) => prop.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
`;

const ZoneShortDetailsLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: #919eab;
  margin-right: 5px;
`;

const ZoneShortDetailsValue = styled.span`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.30000001192092896px;
  color: black;
`;

const ZoneContent = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ZoneStatus = styled.div`
  display: flex;
  align-items: center;
`;

const ZoneStatusIcon = styled.div<{ isActive: boolean }>`
  background: ${(prop) => (prop.isActive ? '#2e7d32' : '#d32f2f')};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 2px;
`;

const DescriptionItemWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

const SubzoneTypeWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 5px;
  gap: 5px;
`;

const ZoneDescription = styled.div`
  padding: 6px;
  background-color: #13ad5c;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.30000001192092896px;
`;

const SubzoneTypes = styled.span<{ isExist: boolean }>`
  font-size: 12px;
  font-weight: ${(prop) => (prop.isExist ? 600 : 400)};
  letter-spacing: 0.30000001192092896px;
  color: ${(prop) => (prop.isExist ? '#2e7d32' : '#919eab')};
`;
