import { SupplierShape } from './supplier-types';
import csv2json from 'csvtojson';
import { handlePreprocessConversions } from '../../helpers/handlePreprocessConversions';
import { GUMAX_PRICELIST_RULESET } from './gumax/pricelist';
import { GUMAX_STOCKLIST_RULESET } from './gumax/stocklist';
import { TRule } from '../typings';

export const GUMAX_SUPPLIER: SupplierShape = {
  weight: 10,
  override: true,
  ruleset: [] as TRule[],
  pricelistRuleset: GUMAX_PRICELIST_RULESET,
  stockRuleset: GUMAX_STOCKLIST_RULESET,
  canSaveWithoutNecessaryFields: true,
  canSaveWithoutEan: false,
  stockDefaults: { model: {}, product: {} },
  pricelistDefaults: { model: {}, product: {} },
  catalogDefaults: {
    product: {
      productName2: '',
      dirtyFields: '{}',
    },
    model: {
      season: '',
      usage: null,
      dataSource: 'GUMAX',
      dirtyFields: '{}',
    },
  },
  preprocessKeys: {
    netnet_eur: {
      ',': '.',
    },
  },
  hardCodes: { 1: { product: {}, model: {} } },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async preprocessPricelist(file: any) {

    const items = file.items.item; // Extract the array of items
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const json = items.map((item: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newItem: any = {};
        for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
                newItem[key] = item[key];
            }
        }
        return newItem;
    });
    
// dodat DOT rule
    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async preprocessStockList(file: any) {

    const items = file.items.item; // Extract the array of items
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const json = items.map((item: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newItem: any = {};
        for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
                newItem[key] = item[key];
            }
        }
        return newItem;
    });

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
  async preprocessCatalog(file: string) {
    const json = await csv2json({ delimiter: ';' }).fromString(file);

    return handlePreprocessConversions(json, this.preprocessKeys);
  },
};
