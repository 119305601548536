import { DBDeliveryTypeEnum, DBShippingDays } from '@prisma/client';
import { DBSupplierClientPackageCostApi } from './supplier-client-package-cost';

interface DBUpsertSupplierClientShippingSettingsPayload {
  id?: string;
  locationId: string;
  supplierClientPackageCost: DBSupplierClientPackageCostApi['create']['requestBody'][];
  isActive: boolean;
  minOrderQty?: number;
  minQtySurchange?: number;
  defaultCostPerPackage?: number;
  dropShipping: boolean;
  cod: boolean;
  codSurchange?: number;
  deliveryToIsland: boolean;
  islandSurchange?: number;
  daysToDeliver?: number;
  maxDaysToDeliver?: number;
  nextShippingDays: DBShippingDays[];
  locationClosedFrom: Date | null;
  locationClosedUntil: Date | null;
  hideStockNonWorkingDays: boolean;
  showBeforeReopened?: number;
  defaultShippingOption: DBDeliveryTypeEnum;
}

interface DBSupplierClientShippingSettingsApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierClientShippingSettingsPayload;
    requestParams: never;
    request: DBUpsertSupplierClientShippingSettingsPayload;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierClientShippingSettingsApi };
