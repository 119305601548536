import { DBUserRole } from '@prisma/client';
import { UserRolePermissions } from '@tyrio/forms';

export interface FormData {
  roleName: string;
  roleDescription: string;
  type: DBUserRole;
  permissions: UserRolePermissions[];
  active?: boolean;
}

export const defaultAdminPermissions = [
  {
    id: 1,
    label: 'Admin Users',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  {
    id: 2,
    label: 'Suppliers',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  {
    id: 3,
    label: 'Clients',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  {
    id: 4,
    label: 'Shipping Couriers',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  {
    id: 5,
    label: 'Catalogues',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: 51,
        label: 'Product Items',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 52,
        label: 'Product Categories',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 53,
        label: 'Product Attributes',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: 6,
    label: 'Settings',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: 61,
        label: 'Roles',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 62,
        label: 'Countries',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
];

export const defaultClientPermissions = [
  {
    id: 1,
    label: 'Purchasing',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: 11,
        label: 'Price Compare',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 12,
        label: 'Purchase Orders',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 13,
        label: 'Customer Orders',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 14,
        label: 'Supplier Settings',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: 2,
    label: 'Catalogues',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: 21,
        label: 'Product Items',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: 3,
    label: 'Settings',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: 31,
        label: 'Roles',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 32,
        label: 'Users',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 33,
        label: 'Company Settings',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: 4,
    label: 'Sales',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: 41,
        label: 'Customer Orders',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 42,
        label: 'Price Calculation',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 43,
        label: 'Customer Rebates',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
  {
    id: 5,
    label: 'WMS',
    values: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
    childs: [
      {
        id: 51,
        label: 'Stock Locations',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 52,
        label: 'Stock List',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 53,
        label: 'Transfer Orders',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
      {
        id: 54,
        label: 'Stock IN',
        values: {
          view: false,
          create: false,
          edit: false,
          delete: false,
        },
      },
    ],
  },
];
