type FormGridWidths =
  | 1
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 5
  | 5.5
  | 6
  | 6.5
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

type FormGridSizeOption = 'auto' | FormGridWidths;

export type FormGridSize = {
  xs?: FormGridSizeOption;
  sm?: FormGridSizeOption;
  md?: FormGridSizeOption;
  lg?: FormGridSizeOption;
  xl?: FormGridSizeOption;
};
interface TyrioInputBase {
  id: string;
  idPrefix?: string;
  width: FormGridSize | string;
  placeholder?: string;
  label?: string;
  checked?: boolean;
  active?: boolean;
  disabled?: boolean;
  startIcon?: string; //TODO: Investigate how to import icon on button
  endIcon?: string;
  value?: string | number | Date | boolean | null;
}

// If we don't have a finished component, we will use a placeholder with the component name

export interface TyrioRepeater {
  id: string;
  hfid?: string;
  idPrefix?: string;
  addRepeaterButtonText: string;
  single?: boolean;
  deleteRepeaterButtonText: string;
  width: FormGridSize;
  type: 'form.repeater';
  inputs: TyrioFormInput[];
}

export interface TyrioRepeaterSeparator {
  id: string;
  width: FormGridSize;
  type: 'form.repeater.separator';
}

export interface TyrioStringInput extends TyrioInputBase {
  type: 'input.string';
  inputType?: 'password' | 'number' | 'search' | undefined;
  variant?: 'standard' | 'filled' | 'outlined';
  disabled?: boolean;
  hidden?: boolean;
  required?: boolean;
  helperText?: string;
  hasPadding?: boolean;
}

export interface TyrioPhoneInput extends TyrioInputBase {
  type: 'input.phone';
  variant?: 'standard' | 'filled' | 'outlined';
  disabled?: boolean;
}

export interface TyrioDatepickerInput extends TyrioInputBase {
  type: 'input.datepicker';
  mode: 'calendardate' | 'date' | 'datetime';

  disabled?: boolean;
}

export interface TyrioViesInput extends TyrioInputBase {
  type: 'input.vies';
  required?: boolean;
}

export interface TyrioButtonInput extends TyrioInputBase {
  type: 'input.button';
}

export interface TyrioCheckboxInput extends TyrioInputBase {
  type: 'input.checkbox';
  value?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
}

export interface TyrioSectionInput extends TyrioInputBase {
  type: 'label.section';
  label?: string;
  dynamicLabel?: {
    parent: string;
    children: string[];
  };
  inlineLable?: boolean;
  hidden?: boolean;
}

export interface TyrioAddNewPartnerInput extends TyrioInputBase {
  type: 'input.partner';
  isVisible: boolean;
}

export interface TyrioInputDivider extends TyrioInputBase {
  type: 'input.divider';
  id: string;
}

export interface TyrioTextareaInput extends TyrioInputBase {
  type: 'input.textarea';
}

export interface TyrioSelectInputOption {
  label: string;
  value: string | number;
  mainCategory?: boolean;
  disabled?: boolean;
  code?: string;
}

export interface TyrioSelectInput extends TyrioInputBase {
  type: 'input.select';
  options: TyrioSelectInputOption[];
  initialValue?: number | string;
  disabled?: boolean;
  watchKey?: string;
  required?: boolean;
  hasPadding?: boolean;
  hidden?: boolean;
}

interface TyrioRadioInputOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface TyrioRadioInput extends TyrioInputBase {
  type: 'input.radio';
  options: TyrioRadioInputOption[];
  initialValue?: number | string;
  subInputs?: { value: string; inputs: TyrioFormInput[] }[];
}

export interface TyrioImagesComponent extends TyrioInputBase {
  type: 'component.images';
}

export interface TryioUserPhoto extends TyrioInputBase {
  type: 'component.userPhoto';
}

export interface TyrioMultiselectInput extends TyrioInputBase {
  type: 'input.multiselect';
  options: TyrioSelectInputOption[];
  watchKey?: string;
  hasPadding?: boolean;
  hidden?: boolean;
}

export interface TyrioSwitchInput extends TyrioInputBase {
  type: 'input.switch';
  color?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'default';
  hasPadding?: boolean;
}

export interface TyrioChangeModel {
  id: string;
  width: FormGridSize;
  type: 'change.model';
}

export interface PermissionsValues {
  view: boolean;
  create: boolean;
  edit: boolean;
  delete: boolean;
}

export interface UserRolePermissions {
  id: string;
  label: string;
  values: PermissionsValues;
  childs?: UserRolePermissions[];
}

export type TyrioFormInput =
  | TyrioStringInput
  | TyrioPhoneInput
  | TyrioSelectInput
  | TyrioCheckboxInput
  | TyrioDatepickerInput
  | TyrioRadioInput
  | TyrioSwitchInput
  | TyrioSectionInput
  | TyrioTextareaInput
  | TyrioImagesComponent
  | TryioUserPhoto
  | TyrioRepeater
  | TyrioRepeaterSeparator
  | TyrioViesInput
  | TyrioButtonInput
  | TyrioChangeModel
  | TyrioMultiselectInput
  | TyrioAddNewPartnerInput
  | TyrioInputDivider;

export interface TyrioFormTab {
  tabTitle: string;
  /** Ensures that this tag is initially selected */
  initial?: boolean;
  inputs: TyrioFormInput[];
}
export interface FormShape {
  tabs: TyrioFormTab[];
}

export interface GetValueProps {
  id: string | boolean;
  value: boolean;
}

export type FormGridProps = FormGridSize;

export interface TyrioFormError {
  message: string;
  type: 'invalid_type';
  ref: unknown;
}
