/** eslint-disable @typescript-eslint/no-unused-vars */
/** eslint-disable  @typescript-eslint/no-explicit-any */

import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { v4 } from 'uuid';

import { splitSpecialMarkingString } from './operations.helpers';
import {
  BRANDS,
  categorySlugMap,
  NOISE_CLASS_MAP,
  SPECIAL_MARKING,
  COLOR_GROUP_MAPPING,
  COLOR_FINISH_MAPPING,
} from '@tyrio/shared-vars';
import md5 from 'md5';
import * as _ from 'lodash';
import { generateProductModelUid } from '../suppliers/global';

export function processSpecialMarkings(
  _input: RawImport,
  _rule: TRule,
  data?: UnfinishedData
) {
  // throw new Error('FOO');
  // Default special markings
  const specialMarking: Record<string, boolean> = {
    flank: false,
    seal: false,
    silent: false,
    electric: false,
    oem: false,
    // TODO: collect this info before pre-processing
    demo: false,
    other: false,
  };

  const specialMarkingsString = data?.product['specialMarking'] || '';
  const sidewallString = data?.model['sidewall'] || '';
  const combinedString = [specialMarkingsString, sidewallString].join(' ');

  const specialMarkingsArray = splitSpecialMarkingString(combinedString);

  specialMarkingsArray.forEach((marking) => {
    const m = marking.toUpperCase() as keyof typeof SPECIAL_MARKING;

    const matchedMarking = SPECIAL_MARKING[m];

    matchedMarking?.type.forEach((type) => {
      if (!specialMarking[type]) {
        specialMarking[type] = true;
      }
    });
  });

  return {
    product: {
      ...specialMarking,
    },
    model: {},
  };
}

// TODO: Use slugs for this
const wheelCategories = [8, 72, 73, 74, 75];
const tyreCategories = [1, 30, 31, 32, 33, 34, 35];
const motoTyreCategories = [2, 36, 37, 38, 39, 40, 41, 42];
const truckTyreCategories = [4, 50, 51, 52, 53, 54];
const atvTyreCategories = [3, 43, 44, 45, 46, 47, 48, 49];
const bicycleTyresCategories = [5, 55, 56, 57, 58, 59, 60, 61];
const agroTyresCategories = [7, 65, 66, 67, 68, 69, 70, 71];
const tubesCategories = [9, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86];
const goKartTyres = [6, 62, 63, 64];
const thuleCategories = [12, 95, 96, 97, 98, 114, 116];

/**
 *
 * SIZE GENERATOR
 */
export function generateProductSize(
  _input: RawImport,
  _rule: TRule,
  data?: UnfinishedData
) {
  const keys = [];
  if (!data) return '';
  const { width, height, construction, rimDiameter } = data.product;

  /** Tyre size rules **/
  if (
    [...tyreCategories, ...truckTyreCategories, ...atvTyreCategories].includes(
      data?.model['category_id']
    )
  ) {
    const splitKey = width >= 20 && width <= 50 ? 'x' : '/';

    keys.push(width);

    if (height) {
      keys.push(splitKey);
      keys.push(height);
    }

    keys.push(' ');
    keys.push(construction);
    keys.push(' ');
    keys.push(rimDiameter);

    return keys.join('').replace(/ +(?= )/g, '');
  }

  /** Tyre size rules **/
  if (wheelCategories.includes(data?.model['category_id'])) {
    keys.push(width);
    keys.push('x');
    keys.push(rimDiameter);

    return keys.join('').replace(/ +(?= )/g, '');
  }

  if (motoTyreCategories.includes(data?.model['category_id'])) {
    keys.push(width);

    if (height) {
      if (width >= 10 && width <= 30 && construction === '-') {
        keys.push('x');
      } else {
        keys.push('/');
      }
      keys.push(height);
    }

    const constructionKey =
      construction === 'D' || construction === '-' ? '-' : construction;
    keys.push(' ');
    keys.push(constructionKey);
    keys.push(' ');
    keys.push(rimDiameter);

    return keys.join('').replace(/ +(?= )/g, '');
  }

  if (bicycleTyresCategories.includes(data?.model['category_id'])) {
    keys.push(rimDiameter);
    keys.push('x');
    keys.push(width);

    return keys.join('').replace(/ +(?= )/g, '');
  }

  return '';
}

export function generateProductSize2(
  _input: RawImport,
  _rule: TRule,
  data?: UnfinishedData
) {
  const keys = [];
  if (!data) return '';
  const { width2, rimDiameter2, construction } = data.product;

  const height2 = '';

  if (bicycleTyresCategories.includes(data?.model['category_id']) && width2) {
    keys.push(rimDiameter2);
    keys.push('x');
    keys.push(width2);

    return keys.join('').replace(/ +(?= )/g, '');
  }

  if (motoTyreCategories.includes(data?.model['category_id']) && width2) {
    keys.push(width2);

    if (height2) {
      if (width2 >= 10 && width2 <= 30 && construction === '-') {
        keys.push('x');
      } else {
        keys.push('/');
      }
      keys.push(height2);
    }

    const constructionKey =
      construction === 'D' || construction === '-' ? '-' : construction;
    keys.push(' ');
    keys.push(constructionKey);
    keys.push(' ');
    keys.push(rimDiameter2);

    return keys.join('').replace(/ +(?= )/g, '');
  }

  return '';
}

export function generateSKUForCategory(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData
) {
  if (!data) return '';
  const product = data.product;
  const model = data.model;

  const {
    uid: productId,
    rimDiameter: rimDiameterValue,
    // rimDiameter2: rimDiameter2Value,
    width: widthValue,
    height: heightValue,
    holeNumber,
    holeCircle,
    manufacturerCode
  } = product;
  const { season, brand } = model;

  const width =
    String(widthValue)
      ?.replace(/\.?0+$/, '')
      .replace(/[^a-zA-Z0-9]/g, '') ?? '';
  // const height =
  //   heightValue === '0' || !heightValue
  //     ? 'R'
  //     : String(heightValue).replace(/[^a-zA-Z0-9]/g, '');
  const height = String(heightValue)?.replace(/[^a-zA-Z0-9]/g, '') ?? '';
  const rimDiameter =
    String(rimDiameterValue)?.replace(/[^a-zA-Z0-9]/g, '') ?? '';
  // const rimDiameter2 =
  //   String(rimDiameter2Value).replace(/[^a-zA-Z0-9]/g, '') ?? '';
  const brandString = brand?.replace(/[^a-zA-Z0-9]/g, '').substring(0, 3) ?? '';
  const productIdLastFourChars =
    productId?.substring(productId.length - 4) ?? '';
  const productIdLastTenChars =
    productId?.substring(productId.length - 10) ?? '';

  const seasonFirstLetter = season?.charAt(0);

  const generateName = (): string => {
    /** Tyre product name rules **/
    if (
      tyreCategories.includes(data?.model['category_id']) ||
      truckTyreCategories.includes(data?.model['category_id'])
    ) {
      return `${seasonFirstLetter}${width}${height}${rimDiameter}${brandString}${productIdLastFourChars}`;
    }

    if (motoTyreCategories.includes(data?.model['category_id'])) {
      return `M${width}${height}${rimDiameter}${brandString}${productIdLastFourChars}`;
    }

    if (atvTyreCategories.includes(data?.model['category_id'])) {
      return `Q${width}${height}${rimDiameter}${brandString}${productIdLastFourChars}`;
    }

    if (bicycleTyresCategories.includes(data?.model['category_id'])) {
      return `B${rimDiameter}${brandString}${productIdLastFourChars}`;
    }

    if (goKartTyres.includes(data?.model['category_id'])) {
      return `GK${width}${height}${rimDiameter}${brandString}${productIdLastFourChars}`;
    }

    if (agroTyresCategories.includes(data?.model['category_id'])) {
      return `AG${width}${height}${rimDiameter}${brandString}${productIdLastFourChars}`;
    }

    if (wheelCategories.includes(data?.model['category_id'])) {
      let pcdCleanValue = '';

      if (Array.isArray(holeCircle) && holeCircle.length > 2) {
        if (Array.isArray(holeNumber) && holeNumber.length === 1) {
          pcdCleanValue = `${holeNumber[0]}H`;
        } else {
          pcdCleanValue = 'BLANK';
        }
      } else {
        pcdCleanValue =
          holeNumber[0]?.toString() + holeCircle[0]?.toString().split('.')[0];
      }
      // let firstHoleCircle = holeCircle;
      // if (typeof holeCircle === 'string') {
      //   firstHoleCircle = holeCircle.split('/')[0];
      // }
      return `F${rimDiameter}${pcdCleanValue}${brandString}${productIdLastFourChars}`;
    }

    if (tubesCategories.includes(data?.model['category_id'])) {
      return `T${rimDiameter}${brandString}${productIdLastFourChars}`;
    }

    if (thuleCategories.includes(data?.model['category_id'])) {
      return `THULE${manufacturerCode}${productIdLastFourChars}`;
    }

    return `${brand.slice(0, 3)}${manufacturerCode.slice(0, 6)}${productIdLastTenChars}`;
  };

  return generateName()
    .replace(/ +(?= )/g, '')
    .toUpperCase();
}

/**
 *
 * PRODUCT NAME GENERATOR
 */
export function generateProductName(
  _input: RawImport,
  _rule: TRule,
  data?: UnfinishedData
) {
  if (!data) return '';
  const product = data.product;
  const model = data.model;

  const commMark = product['commMark'] ? 'C' : '';
  const ms = product['ms'] ? 'M+S' : '';

  const {
    cb: cbValue,
    compound: compoundValue,
    et: etValue,
    loadIndex: loadIndexValue,
    loadIndex2: loadIndex2Value,
    pcd: pcdValue,
    holeNumber: holeNumberValue,
    pr: prValue,
    rfd: rfdValue,
    rimDiameter: rimDiameterValue,
    size: sizeValue,
    size2: size2Value,
    specialMarking: specialMarkingValue,
    speedIndex: speedIndexValue,
    speedIndex2: speedIndex2Value,
    tubeType: tubeTypeValue,
  } = product;
  const {
    modelName,
    sidewall: sidewallValue,
    color: colorValue,
    wheelPosition: wheelPositionValue,
  } = model;
  const pr = prValue ? `${prValue}PR` : '';
  const cb = cbValue ? `CB${cbValue}` : '';

  const color = colorValue ?? '';
  const compound = compoundValue ?? '';
  const et = etValue ?? '';
  const loadIndex = loadIndexValue ?? '';
  const loadIndex2 = loadIndex2Value ?? '';
  const pcd = pcdValue ?? '';
  const rfd = rfdValue ?? '';
  const rimDiameter = rimDiameterValue ?? '';
  const size = sizeValue ?? '';
  const size2 = size2Value ?? '';
  const specialMarking = specialMarkingValue ?? '';
  const speedIndex = speedIndexValue ?? '';
  const speedIndex2 = speedIndex2Value ?? '';
  const tubeType = tubeTypeValue ?? '';
  const wheelPosition = wheelPositionValue ?? '';
  const holeNumber = holeNumberValue ?? '';
  const sidewall = sidewallValue ?? '';

  let axle = '';
  switch (wheelPosition.toLowerCase()) {
    case 'front':
      axle = 'F';
      break;
    case 'rear':
      axle = 'R';
      break;
    case 'frontrear':
    case 'front/rear':
      axle = 'F/R';
      break;
    default:
      axle = '';
  }

  const generateName = (): string => {
    /** Tyre product name rules **/
    if (
      tyreCategories.includes(data?.model['category_id']) ||
      truckTyreCategories.includes(data?.model['category_id'])
    ) {
      // const loadIndexCombo = [loadIndex, loadIndex2]
      //   .filter((x) => !!x)
      //   .join('/');
      // return `${size} ${commMark} ${loadIndexCombo}${speedIndex} ${modelName} ${rfd} ${specialMarking} ${sidewall} ${pr} ${ms}`;

      const loadSpeedCombo1 = `${loadIndex}${speedIndex}`;
      const loadSpeedCombo2 =
        loadIndex2 && speedIndex2 ? `(${loadIndex2}${speedIndex2})` : '';

      return `${size}${commMark} ${loadSpeedCombo1} ${loadSpeedCombo2} ${rfd} ${modelName} ${sidewall} ${specialMarking} ${pr} ${ms}`;
    }

    if (
      motoTyreCategories.includes(data?.model['category_id']) ||
      atvTyreCategories.includes(data?.model['category_id'])
    ) {
      return `${size} ${loadIndex}${speedIndex} ${modelName} ${sidewall} ${rfd} ${specialMarking} ${compound} ${axle} ${tubeType} ${ms}`;
    }

    if (bicycleTyresCategories.includes(data?.model['category_id'])) {
      return `${size} (${size2}) ${modelName} ${color} ${sidewall} ${rfd} ${specialMarking} ${compound} ${axle} ${tubeType}`;
    }

    if (wheelCategories.includes(data?.model['category_id'])) {
      let pcdCleanValue = '';
      let etCleanValue = '';
      let pcdArray: string[] = [];
      let etArray: (string | number)[] = [];

      if (typeof pcd === 'string') {
        pcdArray = pcd.split(',');
      } else if (Array.isArray(pcd)) {
        pcdArray = pcd;
      }

      if (pcdArray.length > 2) {
        if (Array.isArray(holeNumber) && holeNumber.length === 1) {
          pcdCleanValue = `PCD ${holeNumber[0]}H Blank`;
        } else {
          pcdCleanValue = 'PCD Blank';
        }
      } else {
        pcdCleanValue = pcdArray.join(' | ');
      }

      if (typeof et === 'string') {
        etArray = et.split(',').map((val) => Number(val));
      } else if (Array.isArray(et)) {
        etArray = et;
      }

      etArray = etArray.filter((val) => !isNaN(val as number)) as number[]; // Sort the array in ascending order

      if (etArray.length > 1) {
        etCleanValue = `${etArray[0]}-${etArray[etArray.length - 1]}`;
      } else if (etArray.length === 1) {
        etCleanValue = etArray[0].toString(); // Convert number to string
      } else {
        etCleanValue = ' Blank';
      }

      return `${modelName} ${size} ${pcdCleanValue} ET${etCleanValue} ${cb} ${specialMarking} ${color}`;
    }

    if (agroTyresCategories.includes(data?.model['category_id'])) {
      return `${size} ${loadIndex}${speedIndex} ${modelName} ${sidewall} ${rfd} ${specialMarking} ${pr} ${ms}`;
    }

    if (tubesCategories.includes(data?.model['category_id'])) {
      let productText = 'Tube ';
      if (data?.model['category_id'] == 83) {
        productText = 'Mousse ';
      }
      if (data?.model['category_id'] == 84) {
        productText = '';
      }
      return `${productText}${rimDiameter} ${modelName}`;
    }

    return `${modelName}`;
  };

  return generateName().replace(/ +(?= )/g, '');
}

export function updateProductEuDirectiveNumber(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData
) {
  if (data?.product['euDirectiveNumber']) {
    return {};
  }
  if (
    data &&
    Object.values(NOISE_CLASS_MAP['1222/2009']).includes(
      data?.product['noiseClassType']
    )
  ) {
    data.product['euDirectiveNumber'] = '1222/2009';
  }

  if (
    data &&
    Object.values(NOISE_CLASS_MAP['2020/740']).includes(
      data?.product['noiseClassType']
    )
  ) {
    data.product['euDirectiveNumber'] = '2020/740';
  }

  return data;
}

export function getCategoryNameFromSlug(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData
) {
  if ('sourceKey' in rule && !rule.sourceKey?.startsWith('c.'))
    throw new Error(
      'Source key for getCategoryNameFromSlug must start with `c.`'
    );

  const slug = 'sourceKey' in rule ? rule.sourceKey?.slice(2) : null;
  if (!slug)
    throw new Error(
      'Source key must be passed to the getCategoryNameFromSlug function.'
    );

  const category = categorySlugMap[input[slug]];

  if (!data) {
    data = { model: {}, product: {} };
  }
  if (!data?.model) {
    data.model = {};
  }

  data.model['category_id'] = category?.id ?? undefined;

  return data;
}

export function generateBrandName(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData
) {
  if (!data) {
    data = { model: {}, product: {} };
  }
  if (!data?.model) {
    data.model = {};
  }

  // Transform brand name to uppercase
  const brand = data.model['brand']
    .toUpperCase()
    .replace('BF-GOODRICH', 'BFGOODRICH')
    .replace('BF GOODRICH', 'BFGOODRICH');

  data.model['brand'] = brand;

  return data;
}

export function generateModelHoles(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData
) {
  if (!data) {
    data = { model: {}, product: {} };
  }
  if (!data?.model) {
    data.model = {};
  }

  let wheelHoles = '';

  if (Array.isArray(data.product['holeNumber']) && data.product['holeNumber'].length === 1) {
    wheelHoles = 'H' + data.product['holeNumber'][0];
  } else {
    wheelHoles = 'BLANK';
  }

  data.model['wheelHoles'] = wheelHoles;

  return data;
}

export function fixEprelUrl(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData
) {
  if (!data) {
    data = { model: {}, product: {} };
  }
  if (!data?.product) {
    data.product = {};
  }

  // Change eprel to new link
  const eprelUrl = data.product['eprelUrl']?.replace(
    'https://eprel.ec.europa.eu/qr/',
    'https://eprel.ec.europa.eu/screen/product/tyres/'
  );

  data.product['eprelUrl'] = eprelUrl;

  return data;
}

export function generateWidthHeight(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData
) {
  if (!data) {
    data = { model: {}, product: {} };
  }
  if (!data?.product) {
    data.product = {};
  }

  if (data.product['height'] === '0' || data.product['height'] === '-') {
    data.product['height'] = '';
  }

  if (
    tyreCategories.includes(data?.model['category_id']) &&
    parseFloat(data.product['height']) > 80
  ) {
    data.product['height'] = '';
  }

  // Add two decimals to width lower than 20
  if (
    [...tyreCategories, ...truckTyreCategories].includes(
      data?.model['category_id']
    ) &&
    parseFloat(data.product['width']) <= 20
  ) {
    data.product['width'] = parseFloat(data.product['width'])
      .toFixed(2)
      .toString();
  }

  if (
    [...motoTyreCategories].includes(data?.model['category_id']) &&
    parseFloat(data.product['width']) < 10
  ) {
    data.product['width'] = parseFloat(data.product['width'])
      .toFixed(2)
      .toString();
  }

  return data;
}

export function generateRandomUUIDV4(
  _input: RawImport,
  _rule: TRule,
  _data?: UnfinishedData
) {
  return v4();
}

/**
 * Generate UID based on EAN/Manufacturer Code combination
 */
const generateProductUid = (
  ean: string,
  manufacturerCode?: string,
  brand?: string
): string | undefined => {
  if (ean && !ean.includes('480000') && !ean.startsWith('999')) {
    return md5(ean);
  }
  if (!manufacturerCode) {
    return undefined;
  }
  if (brand) {
    return md5(brand.toUpperCase() + manufacturerCode);
  }
  return md5(manufacturerCode);
};

export function generateProductUUID(
  _input: RawImport,
  _rule: TRule,
  data?: UnfinishedData
) {
  const ean = data?.product['ean'];
  const manufacturerCode = data?.product['manufacturerCode'];
  const brand = data?.model['brand'];

  const uid = generateProductUid(ean, manufacturerCode, brand);

  return {
    product: {
      uid: uid,
    },
    model: {},
  };
}

export function globalGenerateProductModelUid(
  _input: RawImport,
  _rule: TRule,
  data?: UnfinishedData
) {
  const uid = generateProductModelUid(data?.model ?? {});

  if (data?.model) data.model['uid'] = uid;

  if (data?.product) data.product['productModelUid'] = uid;

  return data;
}

// export function mapImages(
//   _input: RawImport,
//   _rule: TRule,
//   _data?: UnfinishedData
// ) {
//   return ['foobar'];
// }

export function setDefaultOrigin(
  _input: RawImport,
  _rule: TRule,
  data?: UnfinishedData
) {
  const origin = data?.product['origin'];
  const brand = data?.model['brand'];

  if (!origin) {
    const brandDetails = _.get(BRANDS, brand);
    if (!brandDetails) return {};

    return {
      model: {},
      product: {
        origin: brandDetails.origin,
      },
    };
  }
  return {};
}

// Original colorCombo
// export function generateColorCombo(
//   input: RawImport,
//   rule: TRule,
//   data: UnfinishedData
// ) {
//   // const colorValue = (input['color'] ?? '').toLowerCase() as keyof typeof COLOR;

//   const colorValue = (data.model['color'] ?? '').toLowerCase() as keyof typeof COLOR;

//   if (!data) data = { product: {}, model: {} };
//   data.model = {
//     ...(data.model ?? {}),
//     color: '',
//     colorGroup: '',
//     colorFinish: [],
//   };

//   if (colorValue) {
//     const matchedColor = COLOR[colorValue];

//     data.model = {
//       ...(data.model ?? {}),
//       color: matchedColor?.name,
//       colorGroup: matchedColor?.colorGroup,
//       colorFinish: matchedColor?.colorFinish ?? [],
//     };
//   }

//   return data;
// }

export function generateColorCombo(
  input: RawImport,
  rule: TRule,
  data: UnfinishedData
) {
  if (!data) data = { product: {}, model: {} };

  const colorValue = (data.model['color'] ?? '').toLowerCase();

  if (!colorValue) {
    data.model = {
      ...(data.model ?? {}),
      colorGroup: '',
      colorFinish: [],
    };
    return data;
  }

  let colorGroup = 'other'; // Default to 'other' if no color group found in the color value

  // Iterate through each color group in colorGroupMappings
  for (const group in COLOR_GROUP_MAPPING) {
    // Iterate through each value in the array for the current color group
    for (const value of COLOR_GROUP_MAPPING[group]) {
      // Check if the color value includes the current value
      if (colorValue.includes(value)) {
        colorGroup = group; // Assign the color group
        break; // Break out of the inner loop since we found a match
      }
    }
    if (colorGroup !== 'other') {
      break; // Break out of the outer loop if we found a match
    }
  }

  // Initialize finishes array
  const finishes: string[] = [];

  // Iterate through each color finish mapping
  for (const finish in COLOR_FINISH_MAPPING) {
    // Iterate through each value in the array for the current color finish
    for (const value of COLOR_FINISH_MAPPING[finish]) {
      // Check if the color value includes the current value
      if (colorValue.includes(value)) {
        // Add the finish to the finishes array if it's not already included
        if (!finishes.includes(finish)) {
          finishes.push(finish);
        }
        break; // Break out of the inner loop since we found a match
      }
    }
  }

  data.model = {
    ...(data.model ?? {}),
    color: colorValue,
    colorGroup,
    colorFinish: finishes.length > 0 ? finishes : ['standard'], // If no finishes found, default to ['standard']
  };

  return data;
}
