import { DBDCollectionOrderType, Prisma } from '@prisma/client';

export interface IOpenOrders {
  orderId: string;
  reference: string | null;
  lineItems: Prisma.JsonValue;
  remark?: string;
  deliveryDate?: Date;
  customerOrderNumber?: string;
}

export interface DBCollectionOrder {
  id: string[]; // id of all orders in this collection
  reference: string; //VAT FOR CUSTOMER ORDERS, BRANCH ID FOR TRANSFER ORDERS
  tableName: string; //dBCustomerOrderItem || dBTransferOrderItem
  type: string; //CO || TO
  destination: string; //CO - Company name TO - Destination branch name
  destinationAddress: string;
  destinationId: string;
  ready: number;
  open: number;
  openOrders: IOpenOrders[];
  readyOrders: IOpenOrders[];
  withoutCustomerInfo?: boolean;
}
export interface OrderStatus {
  status: string;
  subStatus: string;
}

export interface DBCollectionBody {
  orderId: string;
  orderType: DBDCollectionOrderType;
  orderDetails?: object;
  stockOutItems?: string[];
  recipient: string;
  recipientSignatureId: string;
  isOrderComplete?: boolean;
  scannedItems?: { ean: string; qty: number }[];
}

interface DBCollectionApi {
  create: {
    requestBody: DBCollectionBody;
    requestParams: never;
    request: never;
    response: never;
  };
  list: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBCollectionOrder[];
  };
}

export { DBCollectionApi };
