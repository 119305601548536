import styled from '@emotion/styled';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import IconButton from '@mui/material/IconButton';
import { DBProduct } from '@prisma/client';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useMemo } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { SalesOrdersFlowContext } from '../SalesOrdersFlowContext';
import { OrderAccordionHeaderCounter } from './OrderAccordionHeaderCounter';
import { OrderItem } from './OrderItem';
import { UseInquireMultipleEansState } from '../../../hooks/api/useInquireEansMultipleSuppliers';
import { Divider, Typography } from '@mui/material';
import { get, sumBy, values } from 'lodash';
import { LagerItem } from './LagerItem';

interface OrderAccordionProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  product: DBProduct & { model: { uid: string; brand: string | null } };
  inquiryState: UseInquireMultipleEansState;
}

export const OrderAccordion = ({
  isOpen,
  setIsOpen,
  product,
  inquiryState,
}: OrderAccordionProps) => {
  const ctx = useContext(SalesOrdersFlowContext);

  const suppliers = ctx.data.suppliers;
  const supplierQty = ctx.data.supplierQty?.[product.uid];

  const warehouseQty = ctx.data.warehouseQty?.[product.uid];

  const purchased = values(ctx.data.purchased);
  const purchasedQty = sumBy(purchased, product.uid);

  const requestedQty = ctx.data.requested[product.uid] ?? 0;

  const fulfilledProductQty = useMemo(() => {
    const supplierValue =
      sumBy(Object.values(ctx?.qty?.supplier), product?.uid) ?? 0;

    const stockValue = sumBy(Object.values(ctx?.qty?.stock), product?.uid) ?? 0;

    const value = stockValue + supplierValue;

    if (purchasedQty > 0) {
      const supplierId = ctx.selected[product.uid];
      const prevQty = get(ctx.data.purchased[supplierId], product.uid) ?? 0;

      const currentQty = value - prevQty;

      return currentQty;
    }

    if (!ctx.selected[product.uid]) return 0;

    return value;
  }, [ctx.data.purchased, ctx.qty, ctx.selected, product.uid, purchasedQty]);

  const mainSupplier = get(ctx.data.mainSupplierId, `${product.uid}`, '');

  const mainSupplierPrice =
    ctx.data.prices[mainSupplier]?.[product.uid]?.['price'];

  const prices = useMemo(() => {
    return Object.values(ctx.data.prices ?? {})
      .map((prices) => {
        const priceObj = prices[product.uid];
        if (!priceObj) return Infinity;

        return (
          (priceObj.price ?? 0) +
          (priceObj.ecoTax ?? 0) +
          (priceObj.shipping ?? 0)
        );
      })
      .sort((a, b) => a - b);
  }, [ctx.data.prices, product.uid]);

  const lowestProductPrice = prices[0] ?? Infinity;

  const mapping = useMemo(() => {
    return Object.keys(supplierQty || {})
      .sort((a, b) => {
        if (a === mainSupplier) return -1;
        if (b === mainSupplier) return 1;

        const supplierAPrice = ctx.data.prices?.[a]?.[product.uid];
        const supplierBPrice = ctx.data.prices?.[b]?.[product.uid];

        const aPrice =
          (supplierAPrice?.price ?? 0) +
          (supplierAPrice?.ecoTax ?? 0) +
          (supplierAPrice?.shipping ?? 0);

        const bPrice =
          (supplierBPrice?.price ?? 0) +
          (supplierBPrice?.ecoTax ?? 0) +
          (supplierBPrice?.shipping ?? 0);

        const price = aPrice - bPrice;

        if (price > 0) return 1;
        else return -1;
      })
      .map((supplierId) => {
        const priceObj = ctx.data.prices?.[supplierId]?.[product.uid] ?? null;

        const price =
          (priceObj?.price ?? 0) +
          (priceObj?.ecoTax ?? 0) +
          (priceObj?.shipping ?? 0);

        const resolvedSupplierQty =
          inquiryState.inquiryState?.[supplierId]?.[priceObj?.ean]?.qty;
        let resolvedSupplierDeliveryDate: string | object =
          inquiryState.inquiryState?.[supplierId]?.[priceObj?.ean]
            ?.deliveryDate;

        if (
          !resolvedSupplierDeliveryDate ||
          typeof resolvedSupplierDeliveryDate !== 'string'
        ) {
          resolvedSupplierDeliveryDate = '';
        }
        const resolvingSupplierInquiry =
          inquiryState.inquiryLoadingState?.[supplierId];

        if (price === 0) return null;

        return {
          price,
          supplierId,
          priceObj,
          resolvedSupplierQty,
          resolvedSupplierDeliveryDate,
          resolvingSupplierInquiry,
        };
      })
      .filter((x) => !!x);
  }, [
    mainSupplier,
    ctx.data.prices,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(inquiryState),
    inquiryState.inquiryState,
    inquiryState.inquiryLoadingState,
    product.uid,
    supplierQty,
  ]);

  const lager = useMemo(() => {
    return Object.keys(warehouseQty || {}).map((warehouse) => {
      return {
        branchId: warehouse,
      };
    });
  }, [warehouseQty]);

  const lineItem = ctx.data.lineItems.find((f) => f.ean === product.ean);

  return (
    <AccordionWrapper id="order_accordion">
      <AccordionHeader>
        {/* <Checkbox color="info" sx={{ marginRight: '28px' }} /> */}
        <HeaderTitleWrapper>
          <p>
            {product?.model?.brand} {product.productName}
          </p>
          <div>
            <span>EAN: {product.ean}</span>
            <span>IPC: {product.manufacturerCode}</span>
            <span>
              SLUG: {lineItem?.purchasing?.supplierSettings?.supplierSlug ?? ''}
            </span>
            <span>PRICE:{lineItem?.purchasing?.purchasePrice ?? ''}</span>
          </div>
        </HeaderTitleWrapper>
        <OrderAccordionHeaderCounter
          fulfilled={fulfilledProductQty}
          requested={ctx.data.requested[product.uid] ?? 0}
        />
        <IconButton onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
      </AccordionHeader>
      <AnimatePresence initial={true}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.2, ease: [0.04, 0.62, 0.73, 0.98] }}
          >
            {requestedQty !== 0 ? (
              <AccordionContent>
                <Scrollbars
                  style={{
                    width: '100%',
                    height: lager.length > 0 && mapping.length > 0 ? 500 : 216,
                    paddingRight: 16,
                    marginRight: 16,
                  }}
                >
                  <ShowDivider
                    text="Warehouse stock"
                    showDivider={lager.length > 0 && mapping.length > 0}
                  />

                  <OrderItemContainer>
                    {lager.map((item: { branchId: string }, index) => {
                      return (
                        <OrderItemGridElement key={index + item.branchId}>
                          <LagerItem
                            branchId={item.branchId}
                            product={product}
                            purchasedQuantity={purchasedQty ?? 0}
                          />
                        </OrderItemGridElement>
                      );
                    })}
                  </OrderItemContainer>

                  <ShowDivider
                    text="Supplier stock"
                    showDivider={lager.length > 0 && mapping.length > 0}
                  />

                  <OrderItemContainer>
                    {mapping.map(
                      (
                        {
                          resolvedSupplierQty,
                          resolvedSupplierDeliveryDate,
                          resolvingSupplierInquiry,
                          supplierId,
                          price,
                          priceObj,
                        }: // eslint-disable-next-line
                        any,
                        index
                      ) => {
                        return (
                          <OrderItemGridElement key={index}>
                            <OrderItem
                              product={product}
                              supplier={suppliers[supplierId]}
                              lowestProductPrice={lowestProductPrice}
                              mainSupplierPrice={mainSupplierPrice}
                              price={price}
                              resolvedSupplierQty={resolvedSupplierQty}
                              resolvedSupplierDeliveryDate={
                                resolvedSupplierDeliveryDate
                              }
                              resolvingSupplierInquiry={
                                resolvingSupplierInquiry
                              }
                              originalPrice={
                                ctx.data.prices?.[supplierId]?.[product.uid]
                                  ?.original
                              }
                              isMainSupplier={
                                priceObj?.mainSupplierId === supplierId
                              }
                              requestedQuantity={
                                ctx.data.requested?.[product.uid] ?? 0
                              }
                              supplierQuantity={
                                ctx.data.supplierQty?.[product.uid]?.[
                                  supplierId
                                ] ?? 0
                              }
                              purchasedQuantity={purchasedQty ?? 0}
                            />
                          </OrderItemGridElement>
                        );
                      }
                    )}
                  </OrderItemContainer>
                </Scrollbars>
              </AccordionContent>
            ) : (
              <AccordionContent>
                <OrderItemContainer style={{ paddingBottom: '16px' }}>
                  <Typography>Item is processed</Typography>
                </OrderItemContainer>
              </AccordionContent>
            )}
          </motion.section>
        )}
      </AnimatePresence>
    </AccordionWrapper>
  );
};

const ShowDivider = ({
  text,
  showDivider,
}: {
  text: string;
  showDivider: boolean;
}) => {
  return showDivider ? (
    <Divider style={{ padding: '15px 0' }}>
      <Typography>{text}</Typography>
    </Divider>
  ) : null;
};

const AccordionWrapper = styled.div`
  border: 1px solid #dfe3e8;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: #f4f6f8;
  padding: 8px 16px;
  min-height: 60px;
`;
const AccordionContent = styled.div`
  display: block;
  box-sizing: border-box;
  //padding-top: 4px;
`;
const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  //padding: 16px 0 0 16px;
  padding: 16px 16px 0 16px;
`;
const OrderItemGridElement = styled.div`
  flex: 1;
  height: 150px;
  max-width: 146px;
  min-width: 146px;
  border-radius: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const HeaderTitleWrapper = styled.div`
  flex: 2;
  //max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  p {
    display: block;
    margin: 0;
    padding: 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    align-items: center;
    letter-spacing: 0.3px;

    color: #212b36;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      display: flex;
      align-items: center;
      letter-spacing: 0.3px;

      color: #919eab;
      margin-right: 24px;
      // &:first-child {
      //   margin-right: 24px;
      // }
    }
  }
`;
