import {
  DBCustomerOrderItem,
  DBOrderDocument,
  DBWorkOrders,
  DeliveryType,
  WorkOrdersStatus,
} from '@prisma/client';
import {
  CreateServiceReservationReq,
  DBOrderItems,
  InProcessSubStatuses,
  OrderStatusProps,
} from './customer-order-item';
import { InboundInvoiceItem } from './inbound-invoice';

export interface ISourceWarehouseDetails {
  branchId: number | null | undefined;
  erpId: string;
  quantity: number;
  ean: string;
  isSupplierStock: boolean;
}
export interface IWorkOrderLineItem {
  erpId: string;
  ean: string;
  productName: string;
  brand: string;
  quantity: number;
  received: number;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  sku: string;
  sellingPrice: number;
  cashDiscount?: number;
  reservedOnLocation?: {
    stockLocationId: string;
    qrCode: string;
    reserved: number;
  }[];
  dot: string;
  branchId?: number | null;
  sourceWarehouseDetails?: ISourceWarehouseDetails[];
}

// these are order details:
// issuedBy - person who created the work order - this information is sourced from Ritam
export interface IStockOutInfo {
  user: string;
  userId: string;
  issueDate: Date;
  numOfPackages?: number;
  issuedBy: {
    firstName: string;
    lastName: string;
  };
}

export interface DBWorkOrdersCreate {
  customerOrderId?: string;
  erpBranchId: string;
  erpOrderId?: string | null;
  lineItems: IWorkOrderLineItem[];
  webOrderId?: string | null;
  orderStatus: OrderStatusProps;
  externalStatus?: WorkOrdersStatus;
  orderNumber?: string | null;
  updateCustomerOrderStatus?: boolean;
  appointments?: CreateServiceReservationReq[];
  defaultWorkOrderId?: string;
  createRecordInRitam?: boolean;
  stockOutData?: IStockOutInfo;
  serviceErpBranchId?: string | null;
  isPending?: boolean;
  skipReservation?: boolean;
  ritamImport?: boolean;
}

export interface DBWorkOrdersUpdate {
  lineItems?: DBOrderItems[];
  orderStatus?: OrderStatusProps;
  externalStatus?: WorkOrdersStatus;
}

export type ExtendedCustomerOrder = DBCustomerOrderItem & {
  workOrders: DBWorkOrders[];
};

export type UpdateAfterInboundResponse = ({
  createdWorkOrder: DBWorkOrders[];
  ritamResponse: string;
} | null)[];

export interface IUpdateAfterInboundResponse {
  customerOrderReference: string;
  response: UpdateAfterInboundResponse;
}

export const deliveryTypeToSubStatusMap: Record<string, string> = {
  [DeliveryType.WMS_COLLECTION]: InProcessSubStatuses.Ready_for_collection,
  [DeliveryType.WMS_SHIPPING]: InProcessSubStatuses.Ready_for_shipping,
  [DeliveryType.WMS_DISPATCH]: InProcessSubStatuses.Ready_for_shipping,
};

export interface DBWorkOrdersApi {
  create: {
    requestBody: DBWorkOrdersCreate;
    requestParams: never;
    request: DBWorkOrdersCreate;
    response: { data: DBWorkOrders[]; ritamResponse: string };
  };
  updateOne: {
    requestBody: DBWorkOrdersUpdate;
    requestParams: never;
    request: never;
    response: DBWorkOrders;
  };
  getOne: {
    requestBody: never;
    requestParams: { customerOrderId: string };
    request: { customerOrderId: string };
    response: DBWorkOrders;
  };
  preparePrintData: {
    requestBody: never;
    requestParams: { id: string };
    request: { id: string };
    response: string;
  };
  deliveryNotes: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBOrderDocument[];
  };
  cancelOne: {
    requestBody: { workOrder: DBWorkOrders };
    requestParams: never;
    request: { workOrder: DBWorkOrders };
    response: DBWorkOrders;
  };
  updateAfterInvoiceMany: {
    requestBody: { body: InboundInvoiceItem[] };
    requestParams: { body: InboundInvoiceItem[] };
    request: { body: InboundInvoiceItem[] };
    response: (IUpdateAfterInboundResponse | null)[];
  };
}
