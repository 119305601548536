import { Alert, CircularProgress, Typography } from '@mui/material';
import { CustomModal } from '../../../components/Modal/Modal';
import { useWS } from '../../../context/WSContext';
import { useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { DBWorkOrdersApi } from '@tyrio/dto';
import { useAuth } from '../../../context/AuthContext';
import _ from 'lodash';

export const WorkOrdersModal = ({
  open,
  handleClose,
  isLoading,
  data,
}: {
  open: boolean;
  handleClose: () => void;
  isLoading: boolean;
  data?: DBWorkOrdersApi['updateAfterInvoiceMany']['response'];
}) => {
  const { state } = useWS();
  const { user } = useAuth();

  const userId = user?.id;

  const text = useMemo(() => {
    if (userId)
      return Object.values(state?.workOrdersUpdate?.[userId] ?? {}).map(
        (a) => a?.response
      );
    else return [];
  }, [state?.workOrdersUpdate, userId]);

  const mapData = (
    data: DBWorkOrdersApi['updateAfterInvoiceMany']['response']
  ) => {
    if (_.isEmpty(data) || !data) return null;

    return (data ?? []).map((item) => {
      const res = item?.response ?? [];

      return (
        <Alert>
          <Typography>
            Customer order: {item?.customerOrderReference ?? ''}
          </Typography>
          <Typography>
            Work order number:{' '}
            {res?.[0]?.createdWorkOrder[0]?.orderNumber ?? ''}
          </Typography>
          <Typography>
            Ritam response: {res?.[0]?.ritamResponse ?? ''}
          </Typography>
        </Alert>
      );
    });
  };

  return (
    <div style={{ background: 'white' }}>
      <CustomModal
        open={open}
        handleClose={handleClose}
        child={
          <Wrapper>
            {isLoading && <CircularProgress />}
            <Typography>
              {isLoading
                ? 'Work orders update is in process. Please wait...'
                : 'Work orders updated'}
            </Typography>
            {isLoading ? mapData(text) : mapData(data ?? [])}
          </Wrapper>
        }
      />
    </div>
  );
};

const Wrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
