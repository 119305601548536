import styled from '@emotion/styled';
import { DBUser, DBUserRole } from '@prisma/client';
import { Sidebar } from '@tyrio/ui-library';
import React, { Dispatch, SetStateAction } from 'react';
import { Ids } from '../../../constants/ids';
import {
  menuLinksAdmin,
  menuLinksClientOthers,
  menuLinksClientSuperAdmin,
} from '../../../constants/menu.constants';
import { onLogout } from '../../../helpers/firebase';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../components/Errors/ErrorPage';

interface MainLayoutProps {
  children?: React.ReactNode;
  user: DBUser;
  roleName?: string;
  viewSettings: boolean;
  viewProductCatalog: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const MainLayout = ({
  children,
  user,
  roleName,
  viewProductCatalog,
  viewSettings,
  open,
  setOpen,
}: MainLayoutProps) => {
  let menuLinks;
  user.userRole === DBUserRole.ADMIN
    ? (menuLinks = menuLinksAdmin)
    : user.roleId === Ids.SUPER_ADMIN
    ? (menuLinks = menuLinksClientSuperAdmin)
    : (menuLinks = menuLinksClientOthers);

  return (
    <Wrapper>
      <Sidebar
        contents={menuLinks}
        user={user || {}}
        roleName={roleName}
        viewProductCatalog={viewProductCatalog}
        viewSettings={viewSettings}
        open={open}
        setOpen={setOpen}
        onLogout={onLogout}
      />
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Layout sidebaropen={open}>{children}</Layout>
      </ErrorBoundary>
    </Wrapper>
  );
};

export const SIDEBAR_CLOSED_WIDTH = 70;
export const SIDEBAR_OPEN_WIDTH = 250;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  min-height: 100vh;
  background-color: #f5f6f8;
  @media (pointer: fine) {
    width: calc(100vw - 17px);
  }
`;

const Layout = styled.div<{ sidebaropen: boolean }>`
  transition: all 0.3s ease;
  position: relative;
  right: 0;
  width: ${(props) =>
    props.sidebaropen
      ? `calc(100% - ${SIDEBAR_OPEN_WIDTH}px)`
      : `calc(100% - ${SIDEBAR_CLOSED_WIDTH}px)`};

  z-index: 0;
`;

export default MainLayout;
