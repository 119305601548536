import {
  DBBranchWarehouseAreas,
  DBWarehouseZones,
  DBWarehouseSubzones,
  DBWarehouseFloors,
  DBWarehouseRacks,
  DBWarehouseSections,
  DBWarehouseShelfs,
  DBWarehouseBins,
} from '@prisma/client';

export type WarehouseResponse = DBBranchWarehouseAreas & {
  zones: DBWarehouseZones[];
  subzones: DBWarehouseSubzones[];
  floors: DBWarehouseFloors[];
  racks: DBWarehouseRacks[];
  sections: DBWarehouseSections[];
  shelfs: DBWarehouseShelfs[];
  bins: DBWarehouseBins[];
};

export type WarehouseLocations =
  | 'zone'
  | 'subzone'
  | 'floor'
  | 'rack'
  | 'section'
  | 'shelf'
  | 'bin';

export interface Locations {
  zone: DBWarehouseZones | null;
  subzone: DBWarehouseSubzones | null;
  floor: DBWarehouseFloors | null;
  rack: DBWarehouseRacks | null;
  section: DBWarehouseSections | null;
  shelf: DBWarehouseShelfs | null;
  bin: DBWarehouseBins | null;
}

export interface SelectedLocation {
  current: WarehouseLocations[];
  locations: Locations;
}

interface DBWarehouseApi {
  list: {
    requestBody: never;
    request: never;
    response: never[];
  };
}

export { DBWarehouseApi };
