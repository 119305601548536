import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField/TextField';
import { tyrioUI } from '@tyrio/ui-library';
import qs from 'qs';
import _ from 'lodash';
import { useEffect, useState } from 'react';

interface SearchProps {
  filters: { key: string; label: string }[];
  setSearchInput: (e: string) => void;
  searchInput: string;
  setSearchFilter: (e: string) => void;
  searchFilter: string;
  selectedFilters?: qs.ParsedQs;
  setSelectedFilters: (value: Partial<qs.ParsedQs>) => void;
  dataCount: number;
  itemsPerPage: number;
}
const Search = ({
  filters,
  setSearchInput,
  searchInput,
  setSearchFilter,
  searchFilter,
  selectedFilters,
  setSelectedFilters,
  dataCount,
  itemsPerPage,
}: SearchProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setSearchFilter(event.target.value as string);
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const borderRadiusValue = width < 475 ? '8px' : '8px 0px 0px 8px';

  useEffect(() => {
    if (dataCount > 0) {
      const lastPossiblePage = Math.ceil(dataCount / itemsPerPage);

      if (
        selectedFilters?.['page'] &&
        Number(selectedFilters['page']) > lastPossiblePage
      ) {
        setSelectedFilters({ page: lastPossiblePage.toString() });
      }
    }
  }, [dataCount, itemsPerPage, selectedFilters, setSelectedFilters]);

  return (
    <Container>
      <SearchContainer>
        <StyledFormControl>
          <InputLabel id="search-method-select-label">Search method</InputLabel>
          <Select
            labelId="search-method-select-label"
            id="search-method"
            value={searchFilter}
            defaultValue={'modelName'}
            label="Search method"
            onChange={handleChange}
            style={{
              borderRadius: borderRadiusValue,
            }}
          >
            <MenuItem value={'modelName'}>Model name</MenuItem>
            <MenuItem value={'uid'}>UID</MenuItem>
            <MenuItem value={'ean'}>EAN</MenuItem>
            <MenuItem value={'manufacturerCode'}>Manufacturer code</MenuItem>
            <MenuItem value={'size'}>Size</MenuItem>
            <MenuItem value={'productName'}>Product name</MenuItem>
            <MenuItem value={'brand'}>Brand</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledText
          id="search-products"
          variant="outlined"
          label="Search products"
          value={searchInput ? searchInput : ''}
          defaultValue={''}
          onChange={(e) => setSearchInput(e.target.value)}
          InputLabelProps={{
            shrink: !!searchInput,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </SearchContainer>
      <StyledFormGroup row={true}>
        {filters.map((el) => (
          <FormControlLabel
            control={
              <Checkbox
                color="info"
                data-cy={`product-checkbox-${el.key}`}
                checked={!!selectedFilters?.[el.key]}
                onClick={(e) => {
                  setSelectedFilters({
                    [el.key]:
                      _.get(e, 'target.checked') === true
                        ? _.get(e, 'target.checked')
                        : undefined,
                  });
                }}
              />
            }
            label={el.label}
            key={el.label}
          />
        ))}
      </StyledFormGroup>
    </Container>
  );
};

const StyledFormControl = styled(FormControl)`
  width: 250px;
  border-radius: 8px 0px 0px 8px;

  @media (max-width: 475px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const StyledText = styled(TextField)`
  width: 100%;

  & fieldset {
    border-radius: 0px 8px 8px 0px;
  }

  @media (max-width: 475px) {
    & fieldset {
      border-radius: 8px;
    }
  }
`;

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 900px) {
    justify-content: center;
    margin-top: 16px;
  }

  @media (max-width: 475px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${tyrioUI.spacing.l}px;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 475px) {
    flex-direction: column;
  }
`;

export default Search;
