import { DBClient, DBStockListImportStatusEnum, Prisma } from '@prisma/client';

export type ImportType = 'ftp' | 'api';

export interface RitamApiImportSettings {
  username: string;
  password: string;
}

export interface ImportSettings {
  settings: {
    type: ImportType;
    isActive: boolean;
  };
  ftp: {
    host: string;
    path: string;
    port: number;
    username: string;
    password: string;
  };
}

export interface ParsedStockListCronFtp {
  RitamArtID: number;
  Manufacturer_Code: string;
  Ean: string;
  Supplier_Code: string;
  Brand: string;
  Article_Name_Supplier: string;
  StockQty: number;
  Average_Purchase_Price_HRK_Netto: number;
  Price_HRK_VPC: number;
  b2b_sale_discount: number;
  b2c_sale_discount: number;
  Active: boolean;
  StockID: number;
  Location?: string;
  Dot?: string;
  Reserved?: number;
}

export interface ParsedStockListCronApi {
  List: ParsedStockListCronItemApi[];
}

export interface ParsedStockListCronItemApi {
  ean: string;
  dot: string;
  manufacturerCode: string;
  articleCodeSupplier: string;
  averagePurchasePriceEurNetto: string;
  stockQty: string;
  stockQtyReserved: string;
  priceEurVpc: string;
  stockId: string;
  articleNameSupplier: string;
  brand: string;
  ritamArtId: string;
  location?: string;
}

export interface ParsedStockListCronItem {
  ean: string;
  dot: string;
  manufacturerCode: string;
  articleCodeSupplier: string;
  averagePurchasePriceEurNetto: number;
  stockQty: number;
  stockQtyReserved: number;
  priceEurVpc: number;
  stockId: number;
  articleNameSupplier: string;
  brand: string;
  ritamArtId: number;
  location?: string;
}

export interface ClientInfo {
  id: string;
  stockListImportSettings: ImportSettings;
}

export interface DBStockListImportLogRes {
  id: string;
  clientId: string;
  startedAt: Date;
  finishedAt: Date | null;
  request: number;
  response: number;
  errors: number;
  status: DBStockListImportStatusEnum | null;
}

export interface GetLogsRes {
  data: DBStockListImportLogRes[];
  count: number;
}

export interface GetLogsReq {
  page: number;
}

export interface GetSettingsRes {
  page: number;
}

export interface GetByIdReq {
  id: string;
}

export interface GetByIdRes {
  request: Prisma.InputJsonValue;
  response: Prisma.InputJsonValue;
  errors: Prisma.InputJsonValue;
  ftpInfo: Prisma.InputJsonValue;
  apiInfo: Prisma.InputJsonValue;
}

export interface DBStockListCronApi {
  post_settings: {
    requestBody: ImportSettings;
    request: ImportSettings;
    response: DBClient;
  };
  get_logs: {
    requestBody: GetLogsReq;
    request: GetLogsReq;
    response: GetLogsRes;
  };
  get_settings: {
    requestBody: never;
    request: never;
    response: ImportSettings;
  };
  get_id: {
    requestBody: GetByIdReq;
    request: GetByIdReq;
    response: GetByIdRes;
  };
}
