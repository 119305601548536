import { DBAppointmentStatus } from '@prisma/client';
import { VehicleInfo } from './customer-order-item';
import { ShelfMeta } from './stock-locations';
import { IWorkOrderLineItem } from './work-orders';
import { IModalData } from './stock-out-list';

export interface AppointmentsRes {
  boxId: string;
  count: number;
  name?: string;
  appointments: Date[];
}

export interface AppointmentLineItems {
  ean: string;
  productName: string;
  sku: string;
  brand: string;
  quantity: number;
}

export interface CompleteAppointmentReq {
  orderId: string;
  appointmentId: string;
  completedServices: string[];
  completedBoxId: string;
}

export interface GetServiceDetailsReq {
  ids: string[];
}

export type GetServiceDetailsRes = Record<string, GetServiceDetailsObj>;

export interface WorkingHours {
  end: string;
  start: string;
}

export interface DaySchedule {
  work: WorkingHours;
  break: WorkingHours;
  isOpen: boolean;
}

export interface WorkingTime {
  friday: DaySchedule;
  monday: DaySchedule;
  sunday: DaySchedule;
  tuesday: DaySchedule;
  saturday: DaySchedule;
  thursday: DaySchedule;
  wednesday: DaySchedule;
}

export interface Resource {
  id: string;
  shelfId: string;
  title: string;
  description: string;
  stepper: number;
  minLength: number;
  serviceCategories: string[];
  workingTime: WorkingTime;
  isActive: boolean;
  meta: ShelfMeta;
}

export interface ServiceInfo {
  id: string;
  name: string;
  serviceDuration: number;
  qty: number;
  totalDuration: number;
}

export interface GetServiceDetailsObj {
  id: string;
  name: string;
  services: ServiceInfo[];
}

export interface AppointmentReq {
  id: string;
  name: string;
  duration: number;
  rounded: number;
  services: Record<string, ServiceInfo>;
  appointmentDateFrom?: Date;
  appointmentDateTo?: Date;
  resource?: Resource;
}

export interface AppointmentServices {
  service: {
    id: string;
    name: string;
  };
  quantity: number;
}

export interface AppointmentOrders {
  id: string;
  appointmentDateFrom: string;
  appointmentDateTo: string;
  vehicleInfo: VehicleInfo;
  lineItems: AppointmentLineItems[];
  remark: string;
  services: {
    service: AppointmentServices[];
  };
  order: {
    id: string;
    reference: string;
    isItemsPicked: boolean;
  };
  reservedBox: {
    id: string;
    qrCode: string;
    displayName: string;
    meta: ShelfMeta;
  };
  workOrders: {
    id: string;
    orderNumber: string;
    lineItems: IWorkOrderLineItem[];
  };
}

export interface CheckAppointmentsReq {
  phoneNumber: string;
}

export interface CheckAppointmentsRes {
  id: string;
  status: DBAppointmentStatus;
  appointmentDateFrom: string;
  appointmentDateTo: string;
  createdBy: string;
  createdAt: string;
  vehicleInfo: {
    year: string;
    brand: string;
    model: string;
    licensePlateNumber: string;
  };
  remark: string;
  services: Record<string, ServiceInfo>;
  reservedBox: {
    id: string;
    meta: ShelfMeta;
  };
  expectedDurationTime: string;
  orderReference: string;
  customer: {
    firstName: string;
    lastName: string;
    phoneNumberDetails: string;
  };
  serviceType: {
    id: string;
    name: string;
  };
}

export interface GetAllAppointmentsReq {
  date: Date;
  boxes: string[];
}

export interface ILineItem {
  title: string;
  subtitle: string;
  orderNr: string;
  quantity: number;
  date: string;
  time: string;
  orderDetails: IModalData[];
}

export interface GetFittingResponse {
  today: ILineItem[];
  tomorrow: ILineItem[];
}

interface DBAppointmentApi {
  list: {
    requestBody: never;
    request: never;
    response: AppointmentsRes[];
  };
  appointmentOrders: {
    requestBody: never;
    request: never;
    response: AppointmentOrders[];
  };
  checkAppointments: {
    requestBody: CheckAppointmentsReq;
    request: CheckAppointmentsReq;
    response: CheckAppointmentsRes[];
  };
  getServiceDetails: {
    requestBody: GetServiceDetailsReq;
    request: GetServiceDetailsReq;
    response: GetServiceDetailsRes;
  };
  all: {
    requestBody: GetAllAppointmentsReq;
    request: GetAllAppointmentsReq;
    response: CheckAppointmentsRes[];
  };
  get_fitting: {
    requestBody: never;
    request: never;
    response: GetFittingResponse;
  };
}
export { DBAppointmentApi };
