import styled from '@emotion/styled/macro';
import { CircularProgress } from '@mui/material';
import api from '@tyrio/api-factory';
import { InboundInvoiceApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { startCase } from 'lodash';
import { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { CartHeader } from '../../components/Cart/Header';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import {
  getVATKey,
  InboundInvoiceContext,
} from '../../context/InboundInvoiceContext';
import { TableFooter } from './components/TableFoooter';
import { useCalculatePrice } from './helpers/calculation';
import { initialValues } from './helpers/initial-values';
import { useCreateInboundInvoice } from './query/create';
import { Table } from './Table/Table';
import { InboundInvoiceTabs } from './Tabs/InboundInvoiceTabs';
import { InboundInvoiceValidation } from './helpers/zod-validation';
import _ from 'lodash';
import moment from 'moment';
import { DBTrafficType } from '@prisma/client';
import { transportModeData } from '../../helpers/transport-mode';

export const InboundInvoice = () => {
  const history = useHistory();
  const {
    isVatIncluded,
    setIsVatIncluded,
    setPreparedInvoices,
    input,
    setInput,
    splitInvoice,
    preparedInvoices,
    isEU,
  } = useContext(InboundInvoiceContext);

  const { data, isLoading } = useQuery(
    ['get_prepared_invoices', input?.ids?.replace(/^"|"$/g, '')],
    async () => {
      return await api.fetch<InboundInvoiceApi['getPrepared']>(
        'get_prepared_invoices',
        { stockInIds: input ? input?.ids?.replace(/^"|"$/g, '') : '' }
      );
    },
    {
      enabled: !!(input && input.ids && input?.ids.length > 0),
      onSuccess(data: InboundInvoiceApi['getPrepared']['response']) {
        console.log(data, '===> PREPARED INBOUND INVOICE DATA');
        setPreparedInvoices(data);
      },
    }
  );

  const {
    totalQty,
    itemsPrice,
    variableCost,
    customsTotal,
    subTotalExlVat,
    grandTotal,
    vat,
    vatAmount,
  } = useCalculatePrice(
    preparedInvoices?.items ?? [],
    preparedInvoices?.vatChargeType ?? 'V05'
  );

  const { createInvoice } = useCreateInboundInvoice(setInput);

  const generateDefaultValues = useCallback(() => {
    return initialValues(data, transportModeData);
  }, [data]);

  const {
    register,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: { ...generateDefaultValues() } as any,
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUseNativeValidation: true,
    resolver: (formValues) => {
      const errors: Record<string, string> = {};
      const validationResult =
        InboundInvoiceValidation(isEU).safeParse(formValues);

      // Check if there are errors
      if (validationResult.success === false) {
        const paths: string[] = [];

        validationResult.error.errors.forEach((error) => {
          const path = error.path[0].toString();
          paths.push(path);
          errors[path] = 'This field is required!';
        });
      }

      return {
        values: formValues,
        errors,
      };
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    const transportMode = transportModeData
      .find((item) => item.value === data.modeOfTransport)
      ?.label.replace(/\d+ - /, '')
      .replaceAll(' ', '_')
      .toUpperCase() as DBTrafficType;

    const body: InboundInvoiceApi['create']['requestBody'] = {
      branchId: Number(data.branchId),
      date: data.date,
      supplierName: data.supplierName,
      vatChargeType: getVATKey(data.vatChargeType) ?? 'R1',
      currency: data.currency ?? 'EUR',
      exchangeRate: data.exchangeRate,
      supplierInvoice: data.supplierInvoiceNumber,
      issueDate: moment(data.invoiceIssueDate).toDate(),
      delay: _.toNumber(data.delay),
      dueDate: moment(data.invoiceDueDate).toDate(),
      internalRemark: data.internalRemark,
      deliveryTermsType: data.deliveryTermsAndConditions,
      transactionNature: Number(data.natureOfTransaction),
      transportMode: transportMode ?? undefined,
      dispatchingCountryId: data.dispatchingCountry,
      summary: {
        totalQty,
        purchaseAmount: _.round(itemsPrice, 2),
        variableCost,
        customs: _.round(customsTotal, 2),
        subtotalExVat: _.round(subTotalExlVat, 2),
        vat,
        vatAmount,
        grandTotalIncVat: _.round(grandTotal, 2),
      },
      items: preparedInvoices?.items ?? [],
      splitInvoice,
      variableCost: {
        domestic: data.domestic ?? 0,
        domesticOther: data.domesticOther ?? 0,
        international: data.international ?? 0,
        internationalOther: data.internationalOther ?? 0,
        totalVarCost: data.totalVarCost ?? 0,
      },
      partnerOib: preparedInvoices?.partner?.viesVatNumber ?? '',
      stockIns: Object.keys(input.checkedStockIns),
    };

    createInvoice(body);
  };

  useEffect(() => {
    reset(generateDefaultValues());
  }, [generateDefaultValues, reset]);

  useEffect(() => {
    if (Object.values(errors)?.length > 0)
      ToastHelper.showToast(
        'Inbound invoice',
        ToastType.ERROR,
        ToastMessageType.ERROR,
        Object.values(errors)?.length === 1
          ? 'Field ' + startCase(Object.keys(errors)[0]) + ' has errors.'
          : 'Fields ' +
              Object.keys(errors).map((err) => ' ' + startCase(err)) +
              ' have errors.'
      );
  }, [errors, handleSubmit]);

  return (
    <PageTemplateWrapper>
      <PageTemplateContent
        style={{
          width: 'calc(100% - 736px)',
          transition: 'all 0.3s ease',
        }}
      >
        <CartHeader
          isVatIncluded={isVatIncluded}
          setIsVatIncluded={setIsVatIncluded}
          onDelete={() => history.goBack()}
          text="INBOUND INVOICE"
          shouldShowVat={false}
          showEmptyCart={false}
        />

        {preparedInvoices && preparedInvoices.items.length > 0 && !isLoading ? (
          <TableWrapper>
            <Table />
          </TableWrapper>
        ) : (
          <LoaderWrapper>
            <CircularProgress />
          </LoaderWrapper>
        )}

        <FooterWrapper>
          <TableFooter
            isVatIncluded={isVatIncluded}
            totalQty={totalQty}
            purchasePrice={itemsPrice}
            variableCost={variableCost}
            customs={customsTotal}
            subTotalExlVat={subTotalExlVat}
            grandTotal={grandTotal}
            vat={vat}
          />
        </FooterWrapper>
      </PageTemplateContent>
      <PageTemplateContent
        style={{
          background: 'white',
          minWidth: '500px',
          maxWidth: '500px',
          marginRight: '0px',
          height: 'calc(100svh - 44px)',
          zIndex: 99,
          transition: 'all 0.3s ease',
        }}
      >
        <InboundInvoiceTabs
          errors={errors}
          control={control}
          register={register}
          setValue={setValue}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          watch={watch}
        />
      </PageTemplateContent>
    </PageTemplateWrapper>
  );
};

const FooterWrapper = styled.div`
  position: sticky;
  top: 100%;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
