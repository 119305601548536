import {
  DBClientSupplierOrderItem,
  DBCustomerOrderItem,
  DBOrderDocument,
  DBStockInItems,
  DBTransferOrderItem,
} from '@prisma/client';

interface DBOrderDocumentsPayload {
  id?: string;
  type: string;
}

interface DBOrderDocumentPayload {
  id: string;
  files: FormData;
}

interface DBOrderDocumentOnePayload {
  id: string;
}

interface DBOrderDocumentResponse extends DBOrderDocument {
  customerOrderItem: DBCustomerOrderItem | null;
  orderItem: DBClientSupplierOrderItem | null;
  transferOrderItem: DBTransferOrderItem | null;
  stockInOrderItem: DBStockInItems | null;
}

interface DBOrderDocumentApi {
  list: {
    requestBody: never;
    request: DBOrderDocumentsPayload;
    response: DBOrderDocumentResponse[];
  };
  getOne: {
    requestBody: never;
    requestParams: DBOrderDocumentOnePayload;
    request: DBOrderDocumentOnePayload;
    response: DBOrderDocumentResponse;
  };
  create: {
    requestBody: DBOrderDocumentPayload;
    requestParams: never;
    request: DBOrderDocumentPayload;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBOrderDocumentApi };
