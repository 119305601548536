import { Box } from '@mui/material';
import { AddressTextField } from '../../../../../customer-orders/components/AddressCard/AddressTextField';
import { checkIfValidPhoneNumber } from '../../../../../supplier-form/helpers/regex';
import { SelectList } from '../../../../../supplier-form/helpers/components';
import { usePosCartCtx } from '../../../../../../context/PosCartContext';

import {
  CustomerFormWrapper,
  RowWrapper,
} from '../../../../styles/CustomerForm.style';
import { vehicleInputFields } from '../../../../constants/vehicle-constants';
import { Dispatch, SetStateAction } from 'react';
import { useGetBrands } from '../Appointment/queries/get-brands';
import { useGetModels } from '../Appointment/queries/get-models';

interface VehicleFormProps {
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

export const VehicleForm = ({ setIsDirty }: VehicleFormProps) => {
  // CONTEXT DATA
  const { vehicleDetails, setVehicleDetails } = usePosCartCtx();

  //   FUNCTIONS
  const handleChange = (id: string, value: string) => {
    if (id === 'mobile_phone') {
      const isValid = handlePhoneNumberValidation('+' + value);
      if (!isValid) return;
    }
    setVehicleDetails({
      ...vehicleDetails,
      [id]: value,
    });
    setIsDirty(true);
  };

  const handlePhoneNumberValidation = (e: string) => {
    if (!checkIfValidPhoneNumber(e) || e.length > 13) {
      return false;
    } else {
      return true;
    }
  };

  const { brands } = useGetBrands();
  const { models } = useGetModels({ brand: vehicleDetails['brand'] ?? '' });

  const dropdownInput = {
    brand: brands,
    model: models,

    year: [
      { label: '2022', value: '2022' },
      { label: '2023', value: '2023' },
    ],
  };

  return (
    <CustomerFormWrapper>
      {vehicleInputFields.map((fields) => {
        return (
          <RowWrapper>
            {fields.map((field) => {
              if (field.id === 'brand' || field.id === 'model')
                return (
                  <Box sx={{ width: field.customWidth }}>
                    <SelectList
                      label={field.placeholder}
                      value={vehicleDetails[field.id] ?? ''}
                      defaultValue={vehicleDetails[field.id] ?? ''}
                      dropdownData={dropdownInput[field.id]}
                      disabled={
                        field.id === 'model' && !vehicleDetails['brand']
                      }
                      onChange={({ target: { name, value } }) =>
                        handleChange(name, value)
                      }
                      name={field.id}
                    />
                  </Box>
                );
              else
                return (
                  <Box
                    sx={{
                      width: field.customWidth,
                      display: 'flex',
                    }}
                  >
                    <AddressTextField
                      id={field.id}
                      placeholder={field.placeholder}
                      value={vehicleDetails[field.id] ?? ''}
                      handleChange={({ target: { id, value } }) =>
                        handleChange(id, value)
                      }
                      disabled={false}
                      customPadding={'16px 8px'}
                      topLabelBorder={5}
                      fontSize={'16px'}
                      fontWeight={400}
                      helperText={field?.helperText}
                    />
                  </Box>
                );
            })}
          </RowWrapper>
        );
      })}
    </CustomerFormWrapper>
  );
};
