import api from '@tyrio/api-factory';
import { InboundInvoiceApi } from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { InputShape } from '../../../context/InboundInvoiceContext';

export const useCreateInboundInvoice = (
  setInput: Dispatch<SetStateAction<InputShape>>
) => {
  const history = useHistory();
  const { data, mutate } = useMutation(
    ['create_inbound_invoice'],
    (body: InboundInvoiceApi['create']['requestBody']) =>
      api.fetch<InboundInvoiceApi['create']>('create_inbound_invoice', {
        ...body,
      }),
    {
      onSuccess: () => {
        setInput({
          ids: '',
          checkedStockIns: {},
        });
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Successfully created inbound invoice.'
        );
        ToastHelper.showToast(
          'Success',
          ToastType.SUCCESS,
          ToastMessageType.CREATE,
          'Successfully created ritam invoice.'
        );
        history.push(
          `/dashboard/stock-in?searchType=reference&searchKeyword=&branchId=all&supplier=all&orderStatus=new`
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Inbound invoice',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  return { data, createInvoice: mutate };
};
