import styled from '@emotion/styled/macro';
import { CircularProgress, Divider, SelectChangeEvent } from '@mui/material';
import api from '@tyrio/api-factory';
import {
  DBStockInListApi,
  DBStockInListRequest,
  DBStockInOrderItemCountByStatus,
  GetOrderPricesRes,
  SSE_ENTITY_KEYS,
  StockInOrderMeta,
} from '@tyrio/dto';
import { backIcon } from '@tyrio/ui-library';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { queryClient } from '../../../query-client';
import NoDataFound from '../../components/NoData/NoDataFound';
import {
  LoaderWrapper,
  PageTemplateWrapper,
} from '../../components/PageTemplate/PageTemplate';
import StyledPagination from '../../components/Pagination/Pagination';
import { useAuth } from '../../context/AuthContext';
import InboundInvoiceProvider from '../../context/InboundInvoiceContext';
import { StockInContext } from '../../context/StockInContext';
import { useWS } from '../../context/WSContext';
import { useDebounce } from '../../hooks/useDebounce';
import { useUpdateStockIn } from './details/queries/update-stock-in';
import StockInOrderDetails from './details/StockInOrderDetails';
import StockInTableFilters from './filter/StockInOrderTableFilters';
import StockInTable from './StockInTable';

interface StockInOverviewProps {
  sidebarOpen: boolean;
}

export const StockInOverview = ({ sidebarOpen }: StockInOverviewProps) => {
  const history = useHistory();
  const { user } = useAuth();
  const { state, socket } = useWS();

  const {
    setIsDirty,
    selectedOrderData,
    setSelectedOrderData,
    checked,
    setChecked,
    areAllChecked,
    setAreAllChecked,
    checkedSupplier,
    setCheckedSupplier,
    filterValues,
  } = useContext(StockInContext);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const debouncedSearch = useDebounce(
    { filterValues: _.omit(filterValues, 'selected'), page, pageSize },
    500
  );

  const { data: stockInData, isFetching } = useQuery(
    ['get_stock_in_list', { debouncedSearch }],
    () =>
      searchStockIn({
        ...(filterValues as unknown as DBStockInListRequest),
        page: page,
        pageSize: pageSize,
      }),
    {
      enabled: true,
    }
  );

  useMemo(() => {
    if (filterValues) setPage(1);
  }, [filterValues]);

  const areAllTrue = useMemo(() => {
    const checkedValues = Object.values(checked).map((item) => item);
    const state =
      checkedValues.every((value) => value === true) &&
      _.size(checked) === stockInData?.count;
    return state;
  }, [checked, stockInData?.count]);

  const searchStockIn = async (filterValues: DBStockInListRequest) => {
    return await api.fetch<DBStockInListApi['list']>('get_stock_in_list', {
      ...filterValues,
    });
  };

  const { data: countData } = useQuery(
    ['get_si_count_by_status', { debouncedSearch }],
    () => getCountData(),
    { enabled: true }
  );

  const getCountData = async () => {
    return await api.fetch<{
      request: DBStockInListApi['list']['request'];
      requestBody: DBStockInListApi['list']['requestBody'];
      response: DBStockInOrderItemCountByStatus[];
    }>('get_si_count_by_status', {
      ...filterValues,
      page: page,
      pageSize: pageSize,
    });
  };

  const handleChangePageSize = (event: SelectChangeEvent) => {
    setPageSize(parseInt(event.target.value));
    setPage(1);
  };

  const count = stockInData?.count;

  const totalPageNumber =
    stockInData && count
      ? count / pageSize - Math.floor(count / pageSize) === 0
        ? count / pageSize
        : Math.floor(count / pageSize) + 1
      : 1;

  const refetchData = () => {
    queryClient.refetchQueries('get_stock_in_list');
    queryClient.refetchQueries('get_si_count_by_status');
    queryClient.refetchQueries('get_stock_in_by_id');
  };

  const { updateOrder, updateFavoriteStatusMutation } = useUpdateStockIn(
    selectedOrderData,
    setSelectedOrderData,
    refetchData,
    user?.id ?? ''
  );

  const setFavorite = () => {
    updateFavoriteStatusMutation(selectedOrderData?.id || '');
  };

  const setLockStatus = () => {
    const key =
      state?.locks &&
      state?.locks[`${SSE_ENTITY_KEYS.STOCK_IN_ORDER}/${selectedOrderData?.id}`]
        ? 'unlock'
        : 'lock';

    socket?.emit(key, {
      entityName: SSE_ENTITY_KEYS.STOCK_IN_ORDER,
      entityId: selectedOrderData?.id,
    });
  };

  const handleSave = () => {
    setIsDirty(false);
    const requestBody: DBStockInListApi['updateOne']['requestBody'] = {
      status: selectedOrderData?.status,
      orderMeta: selectedOrderData?.orderMeta as unknown as StockInOrderMeta,
      priceMeta: selectedOrderData?.priceMeta as GetOrderPricesRes,
      remark: selectedOrderData?.remark ?? '',
    };
    updateOrder(requestBody);
  };

  const handleAllChecked = () => {
    const toggleCheckedState = (newState: boolean) => {
      Object.keys(checked).forEach((item) => {
        setChecked((prevState) => ({
          ...prevState,
          [item]: newState,
        }));
      });
    };

    const updateCheckedItems = () => {
      if (!stockInData?.data) return;

      stockInData.data.forEach((item) => {
        const supplierId = (item.orderMeta as unknown as StockInOrderMeta)
          .supplierId;

        if (filterValues?.['supplierId'] !== 'all') {
          if (item.status === 'CONFIRMED') {
            const orderMeta = item.orderMeta as unknown as StockInOrderMeta;
            setCheckedSupplier({
              id: orderMeta.supplierId,
              name: orderMeta.supplierName,
            });
            setChecked((prevState) => ({
              ...prevState,
              [item.id]: true,
            }));
          }
        }

        if (supplierId === checkedSupplier.id && item.status === 'CONFIRMED')
          setChecked((prevState) => ({
            ...prevState,
            [item.id]: true,
          }));
      });

      setAreAllChecked(true);
    };

    if (areAllChecked) {
      toggleCheckedState(false);
      setAreAllChecked(false);
    } else {
      updateCheckedItems();
    }
  };

  useEffect(
    function handleCheckedCompleted() {
      const checkedValues = Object.values(checked).map((item) => item);
      const state = checkedValues.every((value) => value === false);
      if (state) setAreAllChecked(false);
      Object.keys(checked).forEach((item) => {
        const isCompletedOneChecked = stockInData?.data.find(
          (stockInItem) =>
            item === stockInItem.id && stockInItem.status === 'COMPLETED'
        );
        if (isCompletedOneChecked)
          setChecked((prevState) => ({
            ...prevState,
            [isCompletedOneChecked.id]: false,
          }));
      });
    },
    [checked, setAreAllChecked, setChecked, stockInData?.data]
  );

  return (
    <PageTemplateWrapper
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        right: 0,
        position: 'absolute',
      }}
    >
      <InboundInvoiceProvider>
        <Container
          open={sidebarOpen}
          orderSelected={selectedOrderData === null}
        >
          <HeaderWrapperTop>
            <BackButton onClick={() => history.push('/dashboard')}>
              <img src={backIcon} alt="back-icon" />
            </BackButton>
            <HeaderText>Stock IN list</HeaderText>
          </HeaderWrapperTop>
          <Divider />

          <StockInTableFilters
            isSidebarOpen={selectedOrderData !== null}
            countOrders={countData || []}
          />
          {isFetching ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : stockInData?.data && stockInData.count > 0 ? (
            <>
              <StockInTable
                data={stockInData?.data}
                handleAllChecked={handleAllChecked}
                areAllTrue={areAllTrue}
                isSupplierFiltered={filterValues['supplierId'] !== 'all'}
              />
              <StyledPagination
                totalPageNumber={totalPageNumber}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                selectedOrderData={selectedOrderData}
                handleChangePageSize={handleChangePageSize}
                menuItems={[20, 50, 100, 200]}
              />
            </>
          ) : (
            <NoDataFound />
          )}
        </Container>

        {selectedOrderData !== null && (
          <StockInDetailsWrapper>
            <StockInOrderDetails
              setFavorite={setFavorite}
              setLockStatus={setLockStatus}
              handleSave={handleSave}
            />
          </StockInDetailsWrapper>
        )}
      </InboundInvoiceProvider>
    </PageTemplateWrapper>
  );
};

const Container = styled.div<{
  orderSelected: boolean;
  open: boolean;
}>`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 8px;
  transition: linear 0.4s;
  position: relative;
  left: 0;
  height: calc(100svh - 44px);
  width: ${(prop) => (prop.orderSelected ? '100%' : `calc(100% - 816px)`)};
`;

export const HeaderText = styled.span`
  margin-left: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

export const HeaderWrapperTop = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
`;

export const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  height: 22px;
`;

const StockInDetailsWrapper = styled.div`
  min-width: 800px;
  max-width: 800px;
  width: 100%;
  margin-left: 16px;
  position: relative;
  right: 0;
  height: (100svh - 44px);
`;
