import { DBCurrency } from '@prisma/client';

interface DBUpsertSupplierClientPriceCalculationPayload {
  id?: string;
  supplierCurrency: DBCurrency;
  ecoTax: boolean;
  rebate?: number;
  yearlyBonus?: number;
}

interface DBSupplierClientPriceCalculationApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierClientPriceCalculationPayload;
    requestParams: never;
    request: DBUpsertSupplierClientPriceCalculationPayload;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierClientPriceCalculationApi };
