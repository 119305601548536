import { DBClientShippingCourier, DBShippingCourier } from '@prisma/client';

export interface ClientShippingCourierPayload {
  shippingCourierId: string;
  username?: string;
  password?: string;
  clientNumber?: string;
}
export interface ClientShippingResponse extends DBClientShippingCourier {
  shippingCourier: DBShippingCourier;
}

interface DBCourierOnePayload {
  id?: string;
}

export interface ChangeStatusPayload {
  id: string;
  active: boolean;
}
interface UpdateClientShippingCourierPayload {
  id: string;
  username?: string;
  password?: string;
  clientNumber?: string;
}

export interface ClientShippingCourierApi {
  list: {
    request: never;
    requestBody: never;
    response: ClientShippingResponse[];
  };

  create: {
    request: string[];
    requestBody: string[];
    response: DBClientShippingCourier;
  };

  updateOne: {
    request: UpdateClientShippingCourierPayload;
    requestBody: UpdateClientShippingCourierPayload;
    response: DBClientShippingCourier;
    requestParam: DBCourierOnePayload;
  };

  getOne: {
    request: DBCourierOnePayload;
    requestParam: DBCourierOnePayload;
    requestBody: never;
    response: ClientShippingResponse;
  };

  changeStatus: {
    request: ChangeStatusPayload;
    requestParam: DBCourierOnePayload;
    requestBody: ChangeStatusPayload;
    response: ClientShippingResponse;
  };
}
