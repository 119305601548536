import api, { ENDPOINTS } from '@tyrio/api-factory';
import { PosApi } from '@tyrio/dto';
import _ from 'lodash';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../context/AuthContext';
import { POSContext } from '../../../context/POSContext';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';

export const useGetPosData = (
  filterValues: qs.ParsedQs,
  shouldLoadMore?: boolean,
  setShouldLoadMore?: Dispatch<SetStateAction<boolean>>
) => {
  const { user } = useAuth();
  const { isBranchesStockExpanded, isSupplierStockExpanded } =
    useContext(POSContext);

  const {
    setMyStockItems,
    setBranchesStockItems,
    supplierStockItems,
    setSupplierStockItems,
    selectedWarehouseId,
    selectedCustomer,
  } = useContext(POSContext);

  const myStockItemsQueryKey = 'get_my_stock_pos_list';
  const branchesStockItemsQueryKey = 'get_branches_stock_pos_list';
  const supplierStockItemsQueryKey = 'get_supplier_stock_pos_list';

  const formSets =
    filterValues?.['size']?.length === 2 &&
    (filterValues?.['categoryId'] === '1' ||
      filterValues?.['categoryId'] === '2');

  const queryFn = (key: keyof typeof ENDPOINTS) => {
    return api.fetch<PosApi['list']>(key, {
      query: {
        ...filterValues,
        formSets: _.toString(formSets),
      },
      warehouseId:
        selectedWarehouseId?.toString() ?? user?.currentBranchId?.toString(),
    });
  };

  const { status: myStockItemsStatus } = useQuery(
    [myStockItemsQueryKey, filterValues],
    () => queryFn(myStockItemsQueryKey),
    {
      onSuccess: (res) => {
        setMyStockItems(res);
      },
    }
  );

  const { status: branchesStockItemsStatus } = useQuery(
    [branchesStockItemsQueryKey, filterValues],
    () => queryFn(branchesStockItemsQueryKey),
    {
      enabled: isBranchesStockExpanded,
      onSuccess: (res) => {
        setBranchesStockItems({ ...res });
      },
    }
  );

  const { status: supplierStockItemsStatus } = useQuery(
    [supplierStockItemsQueryKey, filterValues],
    () => queryFn(supplierStockItemsQueryKey),
    {
      enabled: isSupplierStockExpanded,
      onSuccess: (res) => {
        if (!_.isEqual(supplierStockItems, res) && shouldLoadMore) {
          setSupplierStockItems({
            matchedSets: [
              ...supplierStockItems.matchedSets,
              ...res.matchedSets,
            ],
            unmatchedItems: [
              ...supplierStockItems.unmatchedItems,
              ...res.unmatchedItems,
            ],
          });
          setShouldLoadMore && setShouldLoadMore(false);
        } else setSupplierStockItems(res);
      },
      onError: (err: unknown) => {
        if (err instanceof AxiosError)
          ToastHelper.showToast(
            'Supplier stock',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            err?.response?.data?.error?.name ?? ''
          );
      },
    }
  );

  return {
    myStockItemsStatus,
    branchesStockItemsStatus,
    supplierStockItemsStatus,
  };
};
