import { DBProductApi } from './product';
import { DBSupplierType } from '@prisma/client';

interface PriceCompareByCodeEntry {
  value: string;
  qty: number;
}

export interface SinglePriceComparisonShape {
  productYear: number;
  value: number | null;
  suggestedRetailPrice: number | null;
  quantity: number;
  ecoTax: number;
  bonus: number;
  shipping: number;
}

export interface SupplierPriceComparisonShape {
  name: string;
  supplierType: DBSupplierType;
}

export interface PriceComparisonShape {
  products: Record<string, DBProductApi['getOne']['response']>;
  prices: Record<string, Record<string, SinglePriceComparisonShape>>;
  mainSupplier?: Record<string, SupplierPriceComparisonShape>;
  suppliers: Record<string, SupplierPriceComparisonShape>;
}

interface PriceComparePayload {
  supplierIds: string[];
  mainSupplier?: string;
  codeType: 'ean' | 'manufacturerCode' | 'sku';
  codes: PriceCompareByCodeEntry[];
}

interface PriceCompareDimensionsPayload {
  supplierIds: string[];
  mainSupplier?: string;
  dimensions: string[];
  brands?: string[];
  categoryId?: string;
  seasons?: string[];
  classes?: Record<string, boolean>;
  shouldShowRunFlat?: boolean;
  sizeFavorites?: boolean;
  subcategoryId?: string;
}

interface DBPriceCompareApi {
  compareCode: {
    requestBody: PriceComparePayload;
    requestParams: never;
    request: PriceComparePayload;
    response: PriceComparisonShape;
  };
  compareDimensions: {
    requestBody: PriceCompareDimensionsPayload;
    requestParams: never;
    request: PriceCompareDimensionsPayload;
    response: PriceComparisonShape;
  };
}

export { DBPriceCompareApi };
