import { BranchesStockResponse, SupplierStockResponse } from '@tyrio/dto';
import { StockItems } from '../StockItemDetails/StockItems';
import { StockInfoHeader } from './StockInfoHeader';

interface StockInfoProps {
  data: BranchesStockResponse[] | SupplierStockResponse[];
  text:
    | 'BRANCHES STOCK'
    | 'SUPPLIERS STOCK'
    | 'ON STOCK'
    | 'UPCOMING ON STOCK'
    | '';
  showText?: boolean;
}

export const StockInfo = ({ data, text, showText = true }: StockInfoProps) => {
  return (
    <div>
      {data.length > 0 && (
        <div>
          {text && showText && <StockInfoHeader text={text} />}
          {data.map((item: BranchesStockResponse | SupplierStockResponse) => (
            <StockItems
              data={item}
              stockType={text ?? ''}
              key={
                text !== 'SUPPLIERS STOCK'
                  ? (item as unknown as BranchesStockResponse).branchId
                  : (item as unknown as SupplierStockResponse).supplierName
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};
