import { DBCurrency } from '@prisma/client';

interface DBUpsertSupplierClientPaymentSettingsPayload {
  id?: string;
  supplierIBAN: string;
  supplierSwift: string;
  supplierCurrency: DBCurrency;
  remark: string;
}

interface DBSupplierClientPaymentSettingsApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierClientPaymentSettingsPayload;
    requestParams: never;
    request: DBUpsertSupplierClientPaymentSettingsPayload;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierClientPaymentSettingsApi };
