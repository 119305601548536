import { DBRole, DBUserRole } from '@prisma/client';

export interface PermissionsValues {
  view: boolean;
  create: boolean;
  edit: boolean;
  delete: boolean;
}

export interface UserRolePermissions {
  id: string;
  label: string;
  values: PermissionsValues;
  childs?: {
    id: string;
    label: string;
    values: PermissionsValues;
  }[];
}

export interface UserRoleForm {
  roleName: string;
  roleDescription: string;
  type: DBUserRole;
  permissions: UserRolePermissions[];
}

interface DBCreateRolePayload {
  roleName: string;
  type?: DBUserRole;
  roleDescription: string;
  active: boolean;
  permissions: UserRolePermissions[];
}

interface DBRoleOnePayload {
  id: string;
}

interface DBUpdateRolePayload {
  roleName: string;
  roleDescription: string;
  permissions: UserRolePermissions[];
}

interface DBGetRolesPayload {
  search?: string;
  clientPredefined?: boolean;
  page?: number;
  pageSize?: number;
  isActive?: boolean;
}

interface DBRoleApi {
  list: {
    requestBody: DBGetRolesPayload;
    request: DBGetRolesPayload;
    response: { data: DBRole[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBRoleOnePayload;
    request: DBRoleOnePayload;
    response: DBRole;
  };
  create: {
    requestBody: DBCreateRolePayload;
    requestParams: never;
    request: DBCreateRolePayload;
    response: DBRole;
  };
  updateOne: {
    requestBody: DBUpdateRolePayload;
    requestParams: DBRoleOnePayload;
    request: DBUpdateRolePayload | DBRoleOnePayload;
    response: DBRole;
  };
}

export { DBRoleApi };
