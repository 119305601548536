/* eslint-disable react/jsx-no-useless-fragment */
import styled from '@emotion/styled';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  FormGridProps,
  TyrioFormError,
  TyrioSelectInput,
  TyrioSelectInputOption,
} from '../types';

interface TyrioInputSelectProps {
  id: string;
  item: TyrioSelectInput;
  gridProps?: FormGridProps;
  error?: TyrioFormError;
  control: Control;
  register: UseFormRegister<FieldValues>;
  filter?: TyrioSelectInputOption[];
  customDisabled?: boolean;
}
export const TyrioInputSelect = ({
  id,
  item,
  gridProps,
  error,
  control,
  register,
  filter,
  customDisabled,
}: TyrioInputSelectProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isUserProfilePage =
    location.pathname.split('/')?.[2] === 'users' ||
    location.pathname.split('/')?.[3] === 'users';

  return !item.hidden ? (
    <GridContainer item {...gridProps} hasPadding={item?.hasPadding ?? true}>
      <FormControl
        fullWidth
        error={!!error}
        style={{ marginBottom: error?.message && '-23px' }}
      >
        <Controller
          name={id}
          control={control}
          render={({ field }) => {
            if (item.options.length >= 5 && !isUserProfilePage) {
              return (
                <Autocomplete
                  disablePortal
                  disableClearable
                  id={id}
                  value={
                    item.options.find((opt) => opt.value === field.value) ||
                    field.value ||
                    null
                  }
                  onChange={(e, x) => field.onChange(x.value)}
                  disabled={item.disabled}
                  options={item.options}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string')
                      return (
                        item.options.find((el) => el.value === option)?.label ||
                        ''
                      );
                    else
                      return (
                        item.options.find((el) => el.value === option.value)
                          ?.label || ''
                      );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  style={{ background: 'white' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t(`${item.label}`)}
                      disabled={item.disabled}
                      required={item.required}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: true,
                      }}
                      error={!!error}
                    />
                  )}
                />
              );
            } else
              return (
                <>
                  <InputLabel
                    id={item.id + '_label'}
                    shrink={customDisabled ? true : undefined}
                  >
                    {t(`${item.label}`)}
                  </InputLabel>

                  <Select
                    labelId={item.id + '_label'}
                    id={id}
                    defaultValue={item.initialValue}
                    style={{ background: 'white' }}
                    value={field.value}
                    MenuProps={{
                      PaperProps: {
                        sx: { maxHeight: 500 },
                      },
                      autoFocus: false,
                    }}
                    label={t(`${item.label}`)}
                    disabled={
                      ((item.id === 'businessType' ||
                        item.id === 'countryId') &&
                        customDisabled) ||
                      item.disabled
                    }
                    error={!!error?.message}
                    required={item.required}
                    {...register(id)}
                  >
                    {item.options
                      .filter((i) => !filter?.includes(i))
                      .map((option) => (
                        <MenuItem
                          disabled={option.disabled}
                          key={option.value}
                          value={option.value}
                          style={{
                            marginLeft: option.mainCategory ? 0 : '10px',
                          }}
                        >
                          {t(option.label)}
                        </MenuItem>
                      ))}
                  </Select>
                </>
              );
          }}
        />
        {error?.message && <FormHelperText>{t(error?.message)}</FormHelperText>}
      </FormControl>
    </GridContainer>
  ) : null;
};

const GridContainer = styled(Grid)<{ hasPadding?: boolean }>`
  padding-bottom: ${(props) => (props.hasPadding ? '22px' : null)};
  display: flex;
  align-items: center;
`;
