import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { InboundLineItem } from '@tyrio/dto';
import { useInboundInvoiceCtx } from '../../../context/InboundInvoiceContext';
import { formatCurrencyWithoutSymbol } from '../../../helpers/currency-format';
import { useCalculatePricePerRow } from '../helpers/calculation';

interface ItemProps {
  lineItem: InboundLineItem;
  productIdx: number;
  itemIdx: number;
}

const renderTextField = (
  value: number,
  setValue: (updatedField: string, updatedValue: number) => void,
  key: string,
  sx: { marginLeft?: string; backgroundColor?: string; width?: string }
) => {
  return (
    <TextField
      variant="outlined"
      size="small"
      type="number"
      sx={sx}
      value={
        Number(value) !== 0 && Number(value) - Math.floor(Number(value)) === 0
          ? Number(value).toString().replace(/^0+/, '')
          : Number(value) - Math.floor(Number(value)) !== 0
          ? Number(value)
          : 0
      }
      onChange={(e) => setValue(key, Number(e.target.value))}
    />
  );
};

export const Item = ({ lineItem, productIdx, itemIdx }: ItemProps) => {
  const { setPreparedInvoices, preparedInvoices } = useInboundInvoiceCtx();

  const { itemsPrice, grandTotal } = useCalculatePricePerRow(
    lineItem,
    preparedInvoices?.vatChargeType ?? 'V05'
  );

  const handleLineItemChange = (updatedField: string, updatedValue: number) => {
    if (preparedInvoices) {
      const updatedInvoice = { ...preparedInvoices };
      if (updatedInvoice) {
        const updatedItems = [...(preparedInvoices.items ?? [])];

        const updatedLineItems = [...updatedItems[itemIdx].lineItems];

        updatedLineItems[productIdx] = {
          ...updatedLineItems[productIdx],
          [updatedField]: updatedValue,
        };

        updatedItems[itemIdx] = {
          ...updatedItems[itemIdx],
          lineItems: updatedLineItems,
        };

        updatedInvoice.items = updatedItems;

        setPreparedInvoices(updatedInvoice);
      }
    }
  };

  return (
    <Wrapper>
      <ProductDetailsWrapper>
        <ProductDetailsText>{lineItem.productName}</ProductDetailsText>
        <ProductDescription>{`EAN: ${lineItem.ean}   IPC: ${lineItem.ipc}    Supplier code: ${lineItem.supplierCode}`}</ProductDescription>
      </ProductDetailsWrapper>
      <Wrap>
        {renderTextField(lineItem.quantity, handleLineItemChange, 'quantity', {
          marginLeft: '5px',
          backgroundColor: 'white',
          width: '50px',
        })}

        {renderTextField(
          Number(lineItem.purchasePrice.toFixed(4)),
          handleLineItemChange,
          'purchasePrice',
          { marginLeft: '5px', backgroundColor: 'white', width: '80px' }
        )}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {renderTextField(
            Number(lineItem.rebate.toFixed(2)),
            handleLineItemChange,
            'rebate',
            {
              marginLeft: '5px',
              backgroundColor: 'white',
              width: '60px',
            }
          )}

          <Text>%</Text>
        </div>

        <Text>{formatCurrencyWithoutSymbol(lineItem.variableCost)}</Text>

        {preparedInvoices?.vatChargeType === 'V05D' && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {renderTextField(
              Number(lineItem.customsPer.toFixed(5)),
              handleLineItemChange,
              'customsPer',
              {
                marginLeft: '5px',
                backgroundColor: 'white',
                width: '80px',
              }
            )}

            <Text>%</Text>
          </div>
        )}

        <Text>{formatCurrencyWithoutSymbol(itemsPrice)}</Text>
        <DiscountWrapper>
          <div
            style={{
              display: 'flex',
              width: '60px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {renderTextField(
              Number(lineItem.margin.toFixed(2)),
              handleLineItemChange,
              'margin',
              {
                backgroundColor: 'white',
              }
            )}

            <Text>%</Text>
          </div>
          <InputWrapper>
            {renderTextField(
              Number(lineItem.sellingPrice.toFixed(2)),
              handleLineItemChange,
              'sellingPrice',
              {
                backgroundColor: 'white',
              }
            )}
          </InputWrapper>
          <InputWrapper>
            <Text>{formatCurrencyWithoutSymbol(grandTotal)}</Text>
          </InputWrapper>
        </DiscountWrapper>
      </Wrap>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-height: 72px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 2px 16px 16px;
  border-bottom: 1px solid #dfe3e8;
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70px;
  gap: 5px;
  white-space: nowrap;
  overflow-x: auto;
  width: calc(100% - 400px);
`;

const Text = styled.div`
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  justify-content: center;
`;

const ProductDetailsText = styled.div`
  font-family: Barlow;
  font-weight: 400;
  max-height: 40px;
`;

export const ProductDescription = styled.div`
  font-family: Barlow;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #919eab;
`;

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
`;

const DiscountWrapper = styled.div`
  width: 240px;
  height: 68px;
  background-color: #f4f6f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

const InputWrapper = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;
`;
