import { DBCustomPriceCatalog } from '@prisma/client';
import { DBProductApi } from './product';

interface DBCustomPriceCatalogApi {
  list: {
    requestBody: CustomPriceCatalogSearch;
    request: CustomPriceCatalogSearch;
    response: { count: number; data: DBCustomPriceCatalog[] };
  };
  create: {
    requestBody: AddPriceReq;
    requestParams: never;
    request: AddPriceReq;
    response: DBCustomPriceCatalog;
  };
  updateOne: {
    requestBody: UpdatePriceReq;
    requestParams: UpdatePriceReq;
    request: UpdatePriceReq;
    response: DBCustomPriceCatalog;
  };
  checkEAN: {
    requestBody: CheckEanReq;
    requestParams: CheckEanReq;
    request: CheckEanReq;
    response: CheckEanRes;
  };
  updateStatus: {
    requestBody: UpdateStatusReq;
    requestParams: UpdateStatusReq;
    request: UpdateStatusReq;
    response: DBCustomPriceCatalog;
  };
  delete: {
    requestBody: CheckEanReq;
    requestParams: CheckEanReq;
    request: CheckEanReq;
    response: DBCustomPriceCatalog;
  };
}

export type CustomPriceStockType = 'all' | 'stock-list' | 'product-catalog';

export interface CustomPriceCatalogSearch {
  keyword: string;
  page: number;
  type: CustomPriceStockType;
}

export interface AddPriceReq {
  calculatedPrice: number | null;
  newPrice: number;
  ean: string;
  productMeta: DBProductApi['getOne']['response'];
}

interface CheckEanRes {
  productInfo: DBProductApi['getOne']['response'];
  price: string;
}

interface CheckEanReq {
  ean: string;
}

export interface UpdatePriceReq {
  price: number;
  ean: string;
  meta: CustomPriceMeta;
}

export interface UpdateStatusReq {
  active: boolean;
  ean: string;
  meta: CustomPriceMeta;
}

export interface CustomPriceMeta {
  updatedAt: string;
  createdBy: string;
  lastUpdateBy: string;
}

export { DBCustomPriceCatalogApi };
