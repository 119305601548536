import styled from '@emotion/styled';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DBAppointmentStatus } from '@prisma/client';
import { AppointmentReq, CheckAppointmentsRes } from '@tyrio/dto';
import { QuantityComponent } from '@tyrio/wms-ui-library';
import _ from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { v4 as uuidv4 } from 'uuid';
import { useWS } from '../../../../../../../../src/context/WSContext';
import { useAuth } from '../../../../../../../context/AuthContext';
import { usePosCartCtx } from '../../../../../../../context/PosCartContext';
import { useAppointmentsHelper } from '../appointment-helper';
import { AppointmentPopover } from '../components/popover/AppointmentPopover';
import {
  _appointment,
  _break,
  _hm,
  addTime,
  findOverlappingEvent,
  getDayOfWeek,
  getMinMaxDate,
  getSelectedAppointmentInfo,
  hasEventsWithinHour,
  range,
} from '../helpers/event.helper';
import { CustomTimeSlotWrapperProps, EventProps } from '../helpers/types';
import getMinAndMaxHour from '../hooks/get-min-and-max-hour';
import TimeOverlay from './TimeOverlay';
import './calendar.css';
import { useGetAppointments } from './queries/get-appointments';
import { useGetFittingBoxSettings } from './queries/get-boxes';

const localizer = momentLocalizer(moment);

const blocked = [
  {
    id: uuidv4(),
    start: '2024-05-29 12:00:00.000',
    end: '2024-05-29 12:30:00.000',
    resourceId: '405abc97-5875-4b12-ab04-85ed2d879ce5',
  },
];

interface AppointmentsCalendarProps {
  onClose?: Dispatch<SetStateAction<boolean>>;
}

export const AppointmentsCalendarV2 = (props: AppointmentsCalendarProps) => {
  const { onClose } = props;

  //Init data
  const initialDate = moment().startOf('day').toDate();

  //States
  const [date, setDate] = useState<Date>(initialDate);
  const [current, setCurrent] = useState<EventProps | null>(null);
  const [hoveredBoxes, setHoveredBoxes] = useState<Set<string>>(new Set());

  //Hooks
  const ws = useWS();
  const { user } = useAuth();
  const { updateCategoryDuration, getAppointments } = useAppointmentsHelper();
  const {
    appointmentDetails,
    selectedAppointment,
    vehicleDetails,
    customerDetails,
    setAppointmentDetails,
  } = usePosCartCtx();

  //DB call
  const {
    data: boxes,
    resources,
    minMaxHour,
    status: resStatus,
    branchName,
  } = useGetFittingBoxSettings();
  const { data: appointments, status } = useGetAppointments({ boxes, date });

  //Helpers
  const selectedAppointmentInfo = getSelectedAppointmentInfo(
    appointmentDetails,
    selectedAppointment
  );

  const hours = range(minMaxHour.min, minMaxHour.max - 1);

  const { minDate, maxDate } = getMinMaxDate(date, minMaxHour, resources);

  const min = minDate.toDate();
  const max = maxDate.toDate();

  //Components
  const CustomEvent = ({ event }: { event: EventProps }) => {
    const [open, setOpen] = useState(false);

    const differenceInMinutes = moment(
      event.appointment.appointmentDateTo
    ).diff(moment(event.appointment.appointmentDateFrom), 'minutes');

    let textColor = '#579bde';
    let background = '#e8f1fa';

    const borderRadius = '4px';

    if (event.appointment.status === 'NEW') {
      background = '#E4D4FC';
      textColor = '#6F40B480';
    }

    if (event.appointment.status === 'COMPLETED') {
      background = '#65C994';
      textColor = 'white';
    }

    if (event.isOverlap) {
      background = '#FFE3E1';
      textColor = '#E85B51';
    }

    if (event.source === 'current' && !event.isOverlap) {
      background = '#D0F3E9';
      textColor = '#47BE7E';
    }

    if (event.isCurrentUser) {
      background = '#1976D2';
      textColor = 'white';
    }

    if (event.source === 'break') {
      background = '#F4F6F8';
      textColor = '#F4F6F8';
    }

    if (event.source === 'blocked') {
      background = '#454F5B';
      textColor = '#F4F6F8';
    }

    const handleTooltipClose = () => {
      setOpen(false);
    };

    return (
      <HtmlTooltip
        arrow
        placement="right"
        onClose={handleTooltipClose}
        open={open}
        onClick={() => {
          if (event.source === 'db') setOpen(true);

          if (event.source === 'current' && selectedAppointment) {
            const appointments = appointmentDetails?.[
              'appointments'
            ] as unknown as Record<string, AppointmentReq>;

            appointments[selectedAppointment].appointmentDateFrom =
              current?.start;
            appointments[selectedAppointment].appointmentDateTo = current?.end;
            appointments[selectedAppointment].resource =
              resources[event.resourceId];

            setAppointmentDetails({
              ...appointmentDetails,
              appointments: appointments,
            } as unknown as Record<string, string>);

            ws.socket?.emit('add-appointment', {
              appointment: current?.appointment,
              resourceId: event.resourceId,
              source: 'socket',
            });

            setCurrent(null);
          }
        }}
        title={
          <Grid>
            {event.source === 'db' ? (
              <Grid sx={{ width: '400px', padding: '16px' }}>
                <AppointmentPopover appointment={event.appointment} />
              </Grid>
            ) : null}
          </Grid>
        }
      >
        <CustomEventStyled background={background} borderRadius={borderRadius}>
          <CustomEventSlotTime textColor={textColor}>
            {`${moment(event.appointment.appointmentDateFrom).format(
              'HH:mm'
            )} - ${moment(event.appointment.appointmentDateTo).format(
              'HH:mm'
            )} `}
          </CustomEventSlotTime>
          {differenceInMinutes > 20 &&
            event.appointment.status !== 'IN_PROCESS' && (
              <CustomEventLicencePlate textColor={textColor}>
                {`${event.appointment.vehicleInfo.licensePlateNumber}`}
              </CustomEventLicencePlate>
            )}
        </CustomEventStyled>
      </HtmlTooltip>
    );
  };

  //Get events
  const getEvents = () => {
    const final: EventProps[] = [];

    const deactivatedResources = Object.values(resources)
      .filter((item) => !item.isActive)
      .map((item) => item.id);

    if (!_.isEmpty(resources) && ws.state?.appointments) {
      Object.values(ws.state?.appointments)?.forEach((item) => {
        final.push({
          appointment: item.appointment,
          resourceId: item.resourceId,
          source: 'socket',
          start: new Date(item.appointment.appointmentDateFrom),
          end: new Date(item.appointment.appointmentDateTo),
          isOverlap: false,
          isCurrentUser: item.userId === user?.id,
        });
      });
    }

    if (appointments) {
      appointments.forEach((item) => {
        final.push({
          appointment: item,
          resourceId: Object.values(resources).find(
            (v) => v.shelfId === item.reservedBox.id
          )?.id as string,
          source: 'db',
          start: new Date(item.appointmentDateFrom),
          end: new Date(item.appointmentDateTo),
          isOverlap: false,
          isCurrentUser: false,
        });
      });
    }

    blocked.forEach((item) => {
      final.push({
        appointment: _appointment(item.start, item.end),
        resourceId: item.resourceId,
        source: 'blocked',
        start: moment(item.start).toDate(),
        end: moment(item.end).toDate(),
        isOverlap: false,
        isCurrentUser: false,
      });
    });

    const today = getDayOfWeek(date);

    Object.values(resources).forEach((resource) => {
      const isResourceOpenToday = _.get(
        resource.workingTime,
        `${today}.isOpen`
      );

      const breakSchedule = _.get(resource.workingTime, `${today}.break`);
      const workSchedule = _.get(resource.workingTime, `${today}.work`);

      let breakStart = addTime(date, _hm(breakSchedule.start));
      let breakEnd = addTime(date, _hm(breakSchedule.end));

      const breakStartTmp = breakStart;
      const breakEndDateTmp = breakEnd;

      if (
        !deactivatedResources.includes(resource.id) &&
        isResourceOpenToday !== false
      ) {
        const { minHour, maxHour } = getMinAndMaxHour(Object.values(resources));

        breakStart = addTime(date, { h: minHour, m: 0 });
        breakEnd = addTime(date, { h: maxHour, m: 0 });

        const workStart = addTime(date, _hm(workSchedule.start));
        const workEnd = addTime(date, _hm(workSchedule.end));

        if (!breakStart.isSame(workStart)) {
          final.push(_break(breakStart, workStart, resource.id));
        }

        if (!breakEnd.isSame(workEnd)) {
          final.push(_break(workEnd, breakEnd, resource.id));
        }

        final.push(_break(breakStartTmp, breakEndDateTmp, resource.id));
      } else {
        const { minHour, maxHour } = getMinAndMaxHour(Object.values(resources));

        breakStart = addTime(date, { h: minHour, m: 0 });
        breakEnd = addTime(date, { h: maxHour + 1, m: 0 });

        final.push(_break(breakStart, breakEnd, resource.id));
      }
    });

    if (current) final.push(current);

    return final;
  };

  const CustomTimeSlotWrapper = (props: CustomTimeSlotWrapperProps) => {
    const isSlotSelected =
      selectedAppointmentInfo?.appointmentDateFrom &&
      selectedAppointmentInfo?.appointmentDateTo;

    const { value, resource } = props;

    const id = `${value.toString()}-${resource}`;

    const handleHover = () => {
      if (
        !_.isUndefined(selectedAppointmentInfo?.appointmentDateFrom) &&
        !_.isUndefined(selectedAppointmentInfo?.appointmentDateTo)
      )
        return;

      if (moment(value).isBefore(moment())) return;

      if (selectedAppointment === null) return;

      if (!hoveredBoxes.has(id)) {
        const isEqualStart = getEvents().find(
          (item) => _.isEqual(item.start, value) && resource === item.resourceId
        );

        if (!_.isUndefined(isEqualStart)) {
          setCurrent(null);
          return;
        }

        const appointments = appointmentDetails?.[
          'appointments'
        ] as unknown as Record<string, AppointmentReq>;

        if (selectedAppointment) {
          const selected = appointments[selectedAppointment];

          const startTime = value;
          const resourceInfo = _.get(resources, resource as string);

          let serviceLength = selected.rounded;
          const minSlotLength = resourceInfo.minLength;

          if (serviceLength < minSlotLength) {
            serviceLength = Math.ceil(minSlotLength / 10) * 10;
          }

          const numberOfSlots = Math.ceil(serviceLength / resourceInfo.stepper);
          const addMinutes = numberOfSlots * resourceInfo.stepper;

          let endTime = moment(value).add(addMinutes, 'minutes').toDate();

          const events = getEvents().filter(
            (item) => item.resourceId === resource && item.source !== 'current'
          );

          let diff = 0;
          let isOverlap = false;

          do {
            const { difference, event } = findOverlappingEvent(
              startTime,
              endTime,
              events
            );

            diff = difference;

            if (difference > 0 && difference < addMinutes) {
              endTime = moment(event?.start).toDate();
            } else {
              break;
            }

            diff = moment(endTime).diff(startTime, 'minutes');

            if (diff < minSlotLength) {
              isOverlap = true;
              setCurrent(null);
              return;
            }

            if (diff < addMinutes) {
              isOverlap = true;
            }

            if (diff < resourceInfo.stepper) {
              isOverlap = true;
            }
          } while (diff > 0 && diff < addMinutes);

          if (diff >= addMinutes) {
            setCurrent(null);
            return;
          }

          const res = hasEventsWithinHour(startTime, events);

          const mins = moment(startTime).minute();

          if (res.length === 0 && mins !== 0) {
            isOverlap = true;
          }

          const id = uuidv4();

          setCurrent({
            start: startTime,
            end: endTime,
            resourceId: resource,
            source: 'current',
            isOverlap: isOverlap,
            isCurrentUser: false,
            appointment: {
              id: id,
              status: DBAppointmentStatus.IN_PROCESS,
              appointmentDateFrom: startTime.toString(),
              appointmentDateTo: endTime.toString(),
              createdBy: `${user?.firstName} ${user?.lastName}`,
              createdAt: moment().toString(),
              vehicleInfo: {
                year: vehicleDetails['year'],
                brand: vehicleDetails['brand'],
                model: vehicleDetails['model'],
                licensePlateNumber: vehicleDetails['licence_plate'],
              },
              remark: '',
              services: {},
              reservedBox: {
                id: resource,
                meta: resources[resource as string].meta,
              },
              expectedDurationTime: endTime.toString(),
              orderReference: 'in-progress',
              customer: {
                firstName: customerDetails['first_name'],
                lastName: customerDetails['last_name'],
                phoneNumberDetails: customerDetails['mobile_phone'],
              },
              serviceType: {
                id: selected.id,
                name: selected.name,
              },
            } as CheckAppointmentsRes,
          });

          setHoveredBoxes((prev) => new Set(prev).add(id));
        }
      }
    };

    const handleLeave = () => {
      setCurrent(null);
      setHoveredBoxes((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    };

    return (
      <Grid
        style={{
          zIndex: 2,
          maxHeight: '25px',
          cursor: isSlotSelected ? 'default' : 'pointer',
          color: 'transparent',
        }}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        slot
      </Grid>
    );
  };

  const handleChangeDay = (operation: 'today' | 'prev' | 'next') => {
    let temp = new Date(date);

    if (operation === 'next') temp.setDate(date.getDate() + 1);

    if (operation === 'prev') temp.setDate(date.getDate() - 1);

    if (operation === 'today') temp = initialDate;

    setDate(temp);
  };

  const isToday = moment().isSame(moment(date), 'day');

  const myElementRef = useRef<HTMLDivElement | null>(null);

  const scrollToCurrentTime = () => {
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({
        block: 'start',
        inline: 'start',
      });
    }
  };

  const now = moment().hour().toString();
  const currentHour = `${now}:00`;

  useEffect(() => {
    if (isToday) scrollToCurrentTime();
  }, [isToday, status]);

  return (
    <>
      {status === 'success' &&
        resStatus === 'success' &&
        (!_.isEmpty(resources) ? (
          <Container>
            <Header>
              <ButtonGroup>
                <Button
                  key="one"
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => {
                    handleChangeDay('prev');
                  }}
                >
                  Previous
                </Button>
                <Button
                  key="two"
                  color={isToday ? 'info' : 'primary'}
                  variant={isToday ? 'contained' : 'outlined'}
                  onClick={() => {
                    handleChangeDay('today');
                  }}
                >
                  Today
                </Button>
                <Button
                  key="three"
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={() => {
                    handleChangeDay('next');
                  }}
                >
                  Next
                </Button>
              </ButtonGroup>
              <DatePickerContainer>
                <DayInfo>{moment(date).format('dddd')}</DayInfo>
                <DatePicker
                  label="datePicker"
                  inputFormat="dd.MM.yyyy"
                  value={date ?? new Date()}
                  onChange={() => {
                    console.log('test');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Date"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DateRangeIcon />
                          </InputAdornment>
                        ),
                        endAdornment: null,
                      }}
                    />
                  )}
                />
              </DatePickerContainer>
              {selectedAppointmentInfo !== null && (
                <QuantityContainer>
                  <AccessTimeIcon color="info" />
                  <DurationTitle>Service duration</DurationTitle>
                  <QuantityComponent
                    quantity={selectedAppointmentInfo.rounded}
                    maxAvailableQuantity={1000}
                    showButton={false}
                    cart={true}
                    qtyIncrement={10}
                    changeCartItemQuantity={(v) => {
                      const item = _.get(
                        appointmentDetails?.['appointments'],
                        selectedAppointment as string
                      ) as AppointmentReq;
                      const { updated } = updateCategoryDuration(
                        getAppointments(),
                        item,
                        v
                      );

                      setAppointmentDetails({
                        ...appointmentDetails,
                        appointments: updated as unknown as string,
                      });

                      ws.socket?.emit('update-appointment-time', {
                        id: selectedAppointment,
                        start: new Date(),
                        end: new Date(),
                      });
                    }}
                  />
                </QuantityContainer>
              )}
            </Header>
            <ScrollSync>
              <AppointmentsContainer container>
                <ScrollSyncPane>
                  <HoursContainer item className="scroll-container">
                    <HourIconWrapper sx={{ borderRight: '1px solid #dfe3e8' }}>
                      <AccessTimeIcon color="info" fontSize="medium" />
                    </HourIconWrapper>
                    <LeftHourWrapper>
                      {hours.map((hour, index) => {
                        const isFirst = index === 0;
                        const isLast = index === hours.length - 1;
                        const isCurrent = hour === currentHour;

                        return (
                          <HourItem
                            ref={isCurrent ? myElementRef : null}
                            key={index}
                            isFirst={isFirst}
                            sx={{
                              borderBottom: isLast
                                ? '1px solid #DFE3E8'
                                : 'none',
                            }}
                          >
                            {hour}
                          </HourItem>
                        );
                      })}
                    </LeftHourWrapper>
                  </HoursContainer>
                </ScrollSyncPane>
                <ScrollSyncPane>
                  <CalendarContainer item className="scroll-container" xs>
                    <CalendarResourcesContainer>
                      <ResourcesWrapper display="flex">
                        {Object.values(resources).map((resource, index) => {
                          const isWarning =
                            !_.isNull(selectedAppointment) &&
                            !resource.serviceCategories.includes(
                              selectedAppointmentInfo?.id as string
                            );

                          return (
                            <ResourceItem key={index} flex={1}>
                              <Box display={'flex'} alignItems={'center'}>
                                <BoxTitle isWarning={isWarning}>
                                  {resource.title}
                                </BoxTitle>
                                {isWarning && (
                                  <WarningIcon
                                    color="warning"
                                    sx={{ marginLeft: '5px' }}
                                  />
                                )}
                              </Box>
                              <BoxSubtitle isWarning={isWarning}>
                                {resource.description}
                              </BoxSubtitle>
                            </ResourceItem>
                          );
                        })}
                      </ResourcesWrapper>
                      <CalendarWrapper>
                        <Calendar
                          localizer={localizer}
                          resources={Object.values(resources)}
                          selectable={false}
                          view="day"
                          views={['day']}
                          events={getEvents()}
                          step={10}
                          timeslots={6}
                          defaultDate={date}
                          min={min}
                          max={max}
                          date={date}
                          popup={false}
                          dayLayoutAlgorithm="no-overlap"
                          scrollToTime={date}
                          components={{
                            event: CustomEvent,
                            timeSlotWrapper:
                              CustomTimeSlotWrapper as React.ComponentType,
                          }}
                          eventPropGetter={(_event: EventProps) => {
                            const newStyle = {
                              backgroundColor: 'transparent',
                              color: 'black',
                              borderRadius: '0px',
                              border: 'none',
                              opacity: 0.8,
                              display: 'block',
                              zIndex: 2,
                              outline: 'none',
                            };

                            return {
                              style: newStyle,
                            };
                          }}
                        />
                        {isToday && (
                          <TimeOverlay
                            {...minMaxHour}
                            res={Object.values(resources).length}
                          />
                        )}
                      </CalendarWrapper>
                    </CalendarResourcesContainer>
                  </CalendarContainer>
                </ScrollSyncPane>
                <ScrollSyncPane>
                  <HoursContainer item className="scroll-container">
                    <HourIconWrapper sx={{ borderLeft: '1px solid #dfe3e8' }}>
                      <AccessTimeIcon color="info" fontSize="medium" />
                    </HourIconWrapper>
                    <RightHourWrapper>
                      {hours.map((hour, index) => (
                        <HourItem key={index} isFirst={index === 0}>
                          {hour}
                        </HourItem>
                      ))}
                    </RightHourWrapper>
                  </HoursContainer>
                </ScrollSyncPane>
              </AppointmentsContainer>
            </ScrollSync>
            {selectedAppointment !== null && onClose && (
              <Footer>
                <Button color="info" variant="outlined">
                  CANCEL
                </Button>
                <Button
                  color="info"
                  variant="contained"
                  onClick={() => onClose(true)}
                >
                  CONFIRM APPOINTMENT
                </Button>
              </Footer>
            )}
          </Container>
        ) : (
          <Alert severity="warning" sx={{ width: '100%' }}>
            <AlertTitle>Branch settings is not configured</AlertTitle>
            Please add working time for {branchName}
          </Alert>
        ))}
      {status === 'loading' && resStatus === 'loading' && (
        <Container>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={20}
          >
            <CircularProgress size={20} />
            <Typography ml={1}>Loading appointments</Typography>
          </Box>
        </Container>
      )}
    </>
  );
};

const Container = styled(Grid)``;

const Header = styled(Grid)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const DatePickerContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

const QuantityContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

const AppointmentsContainer = styled(Grid)`
  border: 1px solid #dfe3e8;
  height: 100%;
`;

const HoursContainer = styled(Grid)`
  width: 60px;
  max-height: calc(100vh - 250px);
  overflow: scroll;
  position: relative;
`;

const HourIconWrapper = styled(Box)`
  width: 60px;
  height: 59.2px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dfe3e8;
  border-right: 1px solid #dfe3e8;
`;

const LeftHourWrapper = styled(Box)`
  position: relative;
  border-right: 1px solid #dfe3e8;
`;

const RightHourWrapper = styled(Box)`
  position: relative;
  border-left: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
`;

const HourItem = styled(Box)<{
  isFirst: boolean;
}>`
  height: 150px;
  border-top: ${({ isFirst }) => (isFirst ? 'none' : '1px solid #DFE3E8')};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  display: flex;
  justify-content: center;
  padding-top: 2px;
`;

const CalendarContainer = styled(Grid)`
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 250px);
`;

const CalendarResourcesContainer = styled(Grid)`
  min-height: 100%;
  position: relative;
`;

const ResourcesWrapper = styled(Box)`
  height: 59.9px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 200;
`;

const ResourceItem = styled(Box)`
  border-left: 1px solid #dfe3e8;
  min-width: 140px;
  border-bottom: 1px solid #dfe3e8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
`;

const CalendarWrapper = styled(Box)`
  width: 100%;
`;

const DurationTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
`;

const DayInfo = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  margin-right: 5px;
`;

const Footer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    padding: '8px',
    maxWidth: '400px',
    width: '400px',
  },
}));

const CustomEventStyled = styled(Box)<{
  background: string;
  borderRadius: string;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  background: ${({ background }) => background};
  border-radius: ${({ borderRadius }) => borderRadius};
  margin: 0;
`;

const CustomEventSlotTime = styled(Typography)<{
  textColor: string;
}>`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.30000001192092896px;
  color: ${({ textColor }) => textColor};
`;

const CustomEventLicencePlate = styled(Typography)<{
  textColor: string;
}>`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.30000001192092896px;
  color: ${({ textColor }) => textColor};
`;

const BoxTitle = styled(Typography)<{
  isWarning: boolean;
}>`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.30000001192092896px;
  color: ${({ isWarning }) => (isWarning ? '#E75524' : '#212b36')};
`;

const BoxSubtitle = styled(Typography)<{
  isWarning: boolean;
}>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
  color: ${({ isWarning }) => (isWarning ? '#E75524' : '#212b36')};
`;
