/* eslint-disable @typescript-eslint/no-empty-function */
import { DBStockInItemsResponse, StockInLineItem } from '@tyrio/dto';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { InboundInvoiceContext } from './InboundInvoiceContext';
import { useFilter } from '../hooks/useFilter';

interface CheckedSupplierShape {
  id: string;
  name: string;
}

interface IStockInContext {
  isUploadInProcess: boolean;
  setIsUploadInProcess: Dispatch<SetStateAction<boolean>>;
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  selectedOrderData: DBStockInItemsResponse | null;
  setSelectedOrderData: Dispatch<SetStateAction<DBStockInItemsResponse | null>>;
  checked: Record<string, boolean>;
  setChecked: Dispatch<SetStateAction<Record<string, boolean>>>;
  areAllChecked: boolean;
  setAreAllChecked: Dispatch<SetStateAction<boolean>>;
  checkedSupplier: CheckedSupplierShape;
  setCheckedSupplier: Dispatch<SetStateAction<CheckedSupplierShape>>;
  checkedLineItems: StockInLineItem[];
  setCheckedLineItems: Dispatch<SetStateAction<StockInLineItem[]>>;
  filterValues: qs.ParsedQs;
  setFilterValue: (data: Partial<qs.ParsedQs>) => void;
}

export const StockInContext = React.createContext<IStockInContext>({
  isUploadInProcess: false,
  setIsUploadInProcess: () => {},
  isDirty: false,
  setIsDirty: () => {},
  selectedOrderData: null,
  setSelectedOrderData: () => null,
  checked: {},
  setChecked: () => {},
  areAllChecked: false,
  setAreAllChecked: () => {},
  checkedSupplier: { id: '', name: '' },
  setCheckedSupplier: () => {},
  checkedLineItems: [],
  setCheckedLineItems: () => [],
  filterValues: {},
  setFilterValue: () => {},
});

interface StockInProviderProps {
  children: React.ReactNode;
}

const StockInProvider = ({ children }: StockInProviderProps) => {
  const { input } = useContext(InboundInvoiceContext);
  const [isUploadInProcess, setIsUploadInProcess] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const [selectedOrderData, setSelectedOrderData] =
    useState<DBStockInItemsResponse | null>(null);
  const [checked, setChecked] = useState<Record<string, boolean>>({});
  const [areAllChecked, setAreAllChecked] = useState(false);
  const [checkedSupplier, setCheckedSupplier] = useState<CheckedSupplierShape>({
    id: input.checkedSupplier?.id ?? '',
    name: input.checkedSupplier?.name ?? '',
  });

  const [checkedLineItems, setCheckedLineItems] = useState<StockInLineItem[]>(
    []
  );

  const { filterValues, setFilterValue } = useFilter({
    searchType: 'reference',
    searchKeyword: '',
    branchId: 'all',
    supplierId: 'all',
    startDate: new Date(
      new Date().setDate(new Date().getDate() - 30)
    ).toDateString(),
    endDate: new Date(
      new Date().setDate(new Date().getDate() + 1)
    ).toDateString(),
    orderStatus: 'all',
    selected: '',
  });

  return (
    <StockInContext.Provider
      value={{
        isUploadInProcess,
        setIsUploadInProcess,
        isDirty,
        setIsDirty,
        selectedOrderData,
        setSelectedOrderData,
        checked,
        setChecked,
        areAllChecked,
        setAreAllChecked,
        checkedSupplier,
        setCheckedSupplier,
        checkedLineItems,
        setCheckedLineItems,
        filterValues,
        setFilterValue,
      }}
    >
      {children}
    </StockInContext.Provider>
  );
};

export default StockInProvider;

export const useStockInCtx = () => React.useContext(StockInContext);
