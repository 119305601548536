import {
  DBBranch,
  DBBranchWarehouseAreas,
  DBWarehouseBins,
  DBWarehouseFloors,
  DBWarehouseRacks,
  DBWarehouseSections,
  DBWarehouseShelfs,
  DBWarehouseSubzones,
  DBWarehouseZones,
  Prisma,
  ShelfType,
} from '@prisma/client';

export enum Code {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}

export enum ShowForCategories {
  CAR_TYRES = '1',
  MOTO_TYRES = '2',
  TRUCK_TYRES = '4',
}

export const ProductCategory = {
  1: 'carTyres',
  2: 'motoTyres',
  4: 'tyreTrucks',
};

export enum ZoneType {
  STORAGE = 'STORAGE',
  STAGING = 'STAGING',
  MOBILE = 'MOBILE',
}

export enum ZoneStagingType {
  MOBILE_STORAGE = 'MOBILE_STORAGE',
  RECEIVING = 'RECEIVING',
  ON_HOLD = 'ON_HOLD',
  MISSING = 'MISSING',
  DAMAGED = 'DAMAGED',
  STATIC_STORAGE = 'STATIC_STORAGE',
  PACKING = 'PACKING',
  COLLECTION = 'COLLECTION',
  DISPATCH = 'DISPATCH',
  SHIPPING = 'SHIPPING',
  FITTING = 'FITTING',
  SERVICE = 'SERVICE',
  RETURNS = 'RETURNS',
}

export enum ShelfStorageType {
  SINGLE_ITEM = 'SINGLE_ITEM',
  PALLET = 'PALLET',
  BOX_BIN = 'BOX_BIN',
  DOUBLE_ROW_RACK = 'DOUBLE_ROW_RACK',
}

export interface DBCreateStockLocation {
  id?: string;
  branchId: number;
  zones: Zones[];
}

export interface Zones {
  code: string;
  description: string;
  settings: {
    color: string;
    isActive: boolean;
  };
  subzones: SubZones[];
}

export interface SubZones {
  code: string;
  name?: string;
  description: string;
  settings: {
    isActive: boolean;
    type: string;
    stagingType: string;
  };
  floors: Floors[];
}

export interface Floors {
  code: number;
  description: string;
  settings: {
    isActive: boolean;
  };
  racks: Racks[];
}

export interface Racks {
  code: string;
  description: string;
  settings: {
    isActive: boolean;
    mixedSKU: boolean;
    productCategory: string[];
    isHotel: boolean;
    isRecommended: boolean;
  };
  sections: Sections[];
}

export interface Sections {
  code: number;
  description: string;
  settings: {
    isActive: boolean;
    mixedSKU: boolean;
    productCategory: string[];
    isHotel: boolean;
    isRecommended: boolean;
    latitude: string;
    longitude: string;
  };
  shelfs: Shelfs[];
}

export interface Shelfs {
  code: number;
  qrCode: string;
  description: string;
  settings: ShelfSettings;
  bins: Bin[];
}

export interface ShelfSettings {
  isActive: boolean;
  shelfType: ShelfType;
  mixedSKU: boolean;
  productCategory: string[];
  isHotel: boolean;
  isRecommended: boolean;
  width: number;
  height: number;
  depth: number;
  minHeight?: number;
}

export interface Bin {
  code: string;
  qrCode: string;
  description: string;
  settings: {
    isActive: boolean;
    mixedSKU: boolean;
  };
}

export interface UpdateProductLocation {
  oldLocationQrCode: string;
  newLocationQrCode: string;
  products: {
    ean: string;
    prodYear?: string;
    dot: string;
    quantity: number;
  }[];
  moveType?: 'switch_location' | 'stock_out' | 'putaway';
}

export interface GetAllLocationsBody {
  ean: string[];
  // example: {ean: nrOfOrderedItems} , {"4019238034752":2}
  requiredQty: Record<string, number>;
}

export interface LocationDetails {
  type: string;
  data: DBWarehouseShelfs | DBWarehouseBins;
  branchDetails: DBBranchWarehouseAreas & { branch: DBBranch };
}

export interface LineItems {
  ean: string;
  ipc: string;
  sku: string;
  quantity: number;
  clientStockListId: string;
  productionYear: string;
  productName: string;
  dot: string;
}

export interface LocationMeta {
  qrCode: string;
  displayName: string;
  zoneColor: string;
  subzoneType: string;
  shelfDescription: string;
  subzoneStagingType: ZoneStagingType;
  width: number;
  height: number;
  depth: number;
  minHeight?: number;
  shelfType: ShelfType;
  isHotel?: boolean;
  isRecommended?: boolean;
  productCategory?: string;
  subzoneName?: string;
}

export interface GetAllLocationsSingleItem {
  locationId: string;
  locationMeta: LocationMeta;
  lineItems?: LineItems[];
  qtyMeta?: { [key: string]: number };
}

export interface GetAllLocationsResponse {
  data: GetAllLocationsSingleItem[];
  locationsCount: number;
  itemsCount: number;
}

export interface MoveLocations {
  newLocation: string;
  oldLocationData: Record<string, Record<string, number>>;
}

export interface GetAllLocationsConvertedResponse {
  id: string;
  branchId: number;
  bins: Record<string, DBWarehouseBins>;
  shelfs: Record<string, DBWarehouseShelfs>;
}

export interface ShelfMeta {
  branchId: number;
  zoneColor: string;
  parentRack: Code;
  parentZone: Code;
  parentFloor: number;
  parentShelf: number;
  subzoneType: ZoneType;
  subzoneName?: string;
  parentSection: number;
  parentSubzone: Code;
  shelfDescription: string;
  parentWarehouseId: string;
  subzoneStagingType: ZoneStagingType;
  parentWarehouseName: string;
}

export interface LocationResponse {
  id: string;
  ean: string;
  dot: string;
  clientStockListId: string;
  quantity: number;
  reserved: number;
  locationMeta: Prisma.JsonValue;
  shelfId: string | null;
  binId: string | null;
}

export type LocationResponse1 = LocationResponse & {
  clientStockList: { id: string; quantity: number; putaway: number };
  putaway: number;
};

export type LocationResponseExtended1 = LocationResponse1 & {
  take: number;
  putaway: number;
};

export type LocationResponseExtended = LocationResponse & { take: number };

export interface LocationInfo {
  ean: string;
  dot: string;
  productDimensions: Prisma.JsonValue;
}

export interface ILocationInfo {
  quantity: number;
  id: string;
  locationMeta: Prisma.JsonValue;
  shelfId: string | null;
  binId: string | null;
  productDimensions?: Prisma.JsonValue;
}

export interface PartialWarehouse {
  shelfType: string | null;
  id: string;
  settings: Prisma.JsonValue;
  qrCode: string;
  meta?: Prisma.JsonValue;
}
export type RecommendedLocation = PartialWarehouse & {
  shelfs: LocationInfo[];
};

export interface IScannedProduct {
  ean: string;
  dot: string;
  quantity: number;
  prodYear?: string;
}
export interface RecommendedLocationBody {
  scannedProducts: IScannedProduct[];
  oldLocation: string;
}

export interface DBStockLocationsApi {
  list: {
    requestBody: never;
    request: never;
    response: DBBranchWarehouseAreas[];
  };
  getOne: {
    requestBody: never;
    requestParams: {
      id: string;
    };
    request: {
      id: string;
    };
    response: DBBranchWarehouseAreas;
  };
  create: {
    requestBody: DBCreateStockLocation;
    requestParams: never;
    request: DBCreateStockLocation;
    response: DBBranchWarehouseAreas;
  };
  uploadWarehouse: {
    requestBody: WarehousePreviewReq;
    requestParams: never;
    request: WarehousePreviewReq;
    response: WarehouseBranchResponse;
  };
  updateOne: {
    requestBody: DBUpdateStockLocation;
    requestParams: {
      id: string;
    };
    request: DBUpdateStockLocation;
    response: WarehouseBranchResponse;
  };
  getLocations: {
    requestBody: GetAllLocationsBody;
    requestParams: never;
    request: GetAllLocationsBody;
    response: GetAllLocationsResponse;
  };
  warehouse: {
    requestBody: never;
    requestParams: WarehouseDetailsBranchReq;
    request: WarehouseDetailsBranchReq;
    response: WarehouseBranchResponse | null;
  };
  warehouseBranches: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBBranch[] | null;
  };
  getWarehouseLocations: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: GetAllLocationsConvertedResponse[];
  };
}

interface WarehousePreviewReq {
  branchId: number;
  isPreview: boolean;
}

interface WarehouseDetailsBranchReq {
  id: string;
}

export type WarehouseBranchResponse =
  | DBBranchWarehouseAreas & {
      zones: DBWarehouseZones[] | null;
      subzones: DBWarehouseSubzones[] | null;
      floors: DBWarehouseFloors[] | null;
      sections: DBWarehouseSections[] | null;
      racks: DBWarehouseRacks[] | null;
      shelfs: DBWarehouseShelfs[] | null;
      bins: DBWarehouseBins[] | null;
    };

export interface UpdateWarehouseReq {
  create?: {
    zones?: string[];
    subzones?: string[];
    floors?: string[];
    racks?: string[];
    sections?: string[];
    shelfs?: string[];
    bins?: string[];
  };
  update?: {
    zones?: string[];
    subzones?: string[];
    floors?: string[];
    racks?: string[];
    sections?: string[];
    shelfs?: string[];
    bins?: string[];
  };
}

export interface DBUpdateStockLocation {
  id: string;
  warehouse: WarehouseBranchResponse;
  meta: UpdateWarehouseReq;
}

export interface WarehouseMeta {
  branchId?: number;
  zoneColor?: string;
  parentRack?: Code;
  parentZone?: Code;
  parentFloor?: number;
  parentShelf?: number;
  subzoneName?: string;
  subzoneType?: ZoneType;
  parentSection?: number;
  parentSubzone?: Code;
  shelfDescription?: string;
  parentWarehouseId?: string;
  subzoneStagingType?: ZoneStagingType;
  parentWarehouseName?: string;
}

export interface WarehouseSettings {
  color?: string;
  type?: string;
  stagingType?: string;
  isActive?: boolean;
  mixedSKU?: boolean;
  productCategory?: string[];
  isHotel?: boolean;
  isRecommended?: boolean;
  width?: number;
  height?: number;
  depth?: number;
  minHeight?: number;
  shelfType?: string;
}
