import {
  DBClientRebateCalculation,
  DBCurrency,
  DBInvoiceType,
  DBLanguage,
  DBParnerDeliveryTermsTypes,
  DBPartner,
  DBPartnerBank,
  DBPartnerContact,
  DBPartnerLocation,
  DBPartnerSupplierType,
  DBRebateGroupType,
  DBTrafficType,
} from '@prisma/client';
import { SudregResponse } from './partner-sudreg';

interface UpdatePartnerPayload {
  partnerId?: string;
  supplierId?: string;
  active?: boolean;
  companyOfficialName?: string;
  businessType?: string;
  companyDisplayName?: string;
  vatPostingGroup?: string;
  viesValidated?: boolean;
  companyRegistrationNumber?: string;
  viesVatNumber?: string;
  countryId?: string;
  zipCode?: string;
  city?: string;
  address?: string;
  remark?: string;
  DBPartnerContact?: Partial<DBPartnerContact[]>;
  DBPartnerBank?: Partial<DBPartnerBank[]>;
  customerPartnerType?: boolean;
  supplierPartnerType?: boolean;
  rebateCalculationId?: string;
  customerType?: DBRebateGroupType;
  paymentDelay?: number;
  creditLimit?: number;
  currency?: DBCurrency;
  invoices?: DBInvoiceType[];
  emails?: string;
  supplierType?: DBPartnerSupplierType;
  deliveryTermsType?: DBParnerDeliveryTermsTypes;
  transactionNature?: number;
  transportMode?: DBTrafficType;
  paymentTerms?: DBCurrency;
  DBPartnerLocation?: Partial<DBPartnerLocation[]>;
  exclusivePartnership?: boolean;
  createB2BAccount?: boolean;
  language?: DBLanguage;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobilePhone?: string;
  businessPhone?: string;
  b2bRemark?: string;
  customerVat?: string;
  supplierVat?: string;
  erpId?: string;
}

interface CreatePartnerPayload {
  active?: boolean;
  companyOfficialName: string;
  businessType: string;
  companyDisplayName?: string;
  vatPostingGroup: string;
  viesValidated: boolean;
  companyRegistrationNumber?: string;
  viesVatNumber: string;
  zipCode: string;
  city: string;
  address: string;
  remark?: string;
  countryId?: string;
  DBPartnerContact?: DBPartnerContact[];
  DBPartnerBank?: DBPartnerBank[];
  customerPartnerType?: boolean;
  supplierPartnerType?: boolean;
  rebateCalculationId?: string;
  customerType?: DBRebateGroupType;
  paymentDelay?: number;
  creditLimit?: number;
  currency?: DBCurrency;
  invoices?: DBInvoiceType[];
  emails?: string;
  supplierType?: DBPartnerSupplierType;
  deliveryTermsType?: DBParnerDeliveryTermsTypes;
  transactionNature?: number;
  transportMode?: DBTrafficType;
  paymentTerms?: DBCurrency;
  DBPartnerLocation?: DBPartnerLocation[];
  customerVat?: string;
  supplierVat?: string;
  erpId?: string;
  supplierId?: string;
}

interface DBPartnerOnePayload {
  partnerId: string;
}

interface DBPartnerListPayload {
  search?: string;
  page?: number;
  pageSize?: number;
}

export type PartnerResponse = DBPartner & {
  DBPartnerContact: DBPartnerContact[] | null;
  DBPartnerBank: DBPartnerBank[] | null;
  DBPartnerLocation: DBPartnerLocation[] | null;
  rebateCalculation: DBClientRebateCalculation | null;
};

export type PartnerContactsShape = Record<
  string,
  {
    firstName: string;
    lastName: string;
    email: string;
    id?: string;
    department?: string;
    businessPhone?: string;
    mobilePhone?: string;
    remark?: string;
  }
>;

export type PartnerBanksShape = Record<
  string,
  {
    bankName: string;
    iban: string;
    swift: string;
    id?: string;
    currency?: string;
  }
>;

export type PartnerLocationsShape = Record<
  string,
  {
    locationName: string;
    zipCodeLocation: string;
    cityLocation: string;
    addressLocation: string;
    id?: string;
    invoiceAddress?: boolean;
    shippingAddress?: boolean;
    emailLocation?: string;
    businessPhoneLocation?: string;
  }
>;

interface DBPartnerApi {
  list: {
    requestBody: never;
    request: DBPartnerListPayload;
    response: { data: PartnerResponse[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBPartnerOnePayload;
    request: DBPartnerOnePayload;
    response: PartnerResponse | null;
  };
  getMany: {
    requestBody: { search?: string };
    request: { search?: string };
    response: DBPartner[];
  };
  create: {
    requestBody: CreatePartnerPayload;
    requestParams: never;
    request: CreatePartnerPayload;
    response: DBPartner;
  };
  updateOne: {
    requestBody: UpdatePartnerPayload;
    requestParams: UpdatePartnerPayload;
    request: UpdatePartnerPayload;
    response: DBPartner;
  };
  sudreg: {
    requestBody: never;
    requestParams: { identificator: string };
    request: { identificator: string };
    response: SudregResponse;
  };
}

export { DBPartnerApi };
