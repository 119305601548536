import { TRule } from '../typings';
import { RawImport, UnfinishedData } from '@tyrio/dto';
import { genericGenerateSpecialMarkings } from './operations.helpers';

export function globalGetIntersprintModelName(input: RawImport, rule: TRule) {
  if (rule.type !== 'rule.global.operate') {
    throw new Error(
      'globalGetIntersprintModelName requires rule to be of type TOperateRule'
    );
  }

  const brandFull = ' ' + input['brand description'].trim() + ' ';
  const brand4 = ' ' + input['brand description'].substring(0, 4).trim() + ' ';
  const brand2 = ' ' + input['brand description'].substring(0, 2).trim() + ' ';

  const indexFull = input['description'].indexOf(brandFull);
  const index4 = input['description'].indexOf(brand4);
  const index2 = input['description'].indexOf(brand2);

  let modelName = '';

  if (indexFull !== -1) {
    modelName = input['description'].substring(indexFull + brandFull.length);
  } else if (index4 !== -1 && input['brand description'] !== 'CONTINENTAL') {
    modelName = input['description'].substring(index4 + brand4.length);
  } else if (index2 !== -1) {
    modelName = input['description'].substring(index2 + brand2.length);
  }

  const data = {
    product: {},
    model: {
      modelName: modelName,
    },
  } as Partial<UnfinishedData>;

  return data;
}

export function getIntersprintSpecialMarkings(
  input: RawImport,
  rule: TRule,
  data?: UnfinishedData
): Partial<UnfinishedData> {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'getInterSprintpecialMarkings requires rule to be of type TGlobalOperateRule'
    );

  const inputValues = data?.model['modelName']
    .replace(/\s*\(\s*/g, ' (')
    .replace(/\s*\)\s*/g, ') ')
    .replace('R01', 'RO1')
    .replace('(*)', '*');

  const output = genericGenerateSpecialMarkings(
    inputValues,
    () => inputValues.includes('RFT') || inputValues.includes('RUNFLAT'),
    true
  );

  if (!output?.product?.['specialMarking']) {
    output.product = {
      ...output.product,
      specialMarking: '',
    };
  }

  return output;
}

export function globalGetIntersprintLoadSpeedIndex(
  input: RawImport,
  rule: TRule
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetIntersprintLoadIndex requires rule to be of type TOperateRule'
    );

  const loadIndexMatches = input['LI/SI'].match(/\d+/g);
  const speedIndexMatches = input['LI/SI'].match(/[a-zA-Z]+/g);

  const data = {
    product: {
      loadIndex: loadIndexMatches ? loadIndexMatches[0] : '',
      speedIndex: speedIndexMatches ? speedIndexMatches[0] : '',
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}

export function globalGetIntersprintSpecialWidthHeight(
  input: RawImport,
  rule: TRule
) {
  if (rule.type !== 'rule.global.operate')
    throw new Error(
      'globalGetIntersprintSpecialWidthHeight requires rule to be of type TOperateRule'
    );

  const widthHeightMap: { [key: string]: string } = {
    '750': '7.50',
    '1250': '12.50',
    '1050': '10.50',
    '400': '4.00',
    '350': '3.50',
    '410': '4.10',
    '1150': '11.50',
    '1350': '13.50',
    '1450': '14.50',
    '500': '5.00',
  };

  let width = input['width tyre'];
  let height = input['aspect ratio'];

  // Check if the provided width is in the list
  if (width in widthHeightMap) {
    // Replace width with corresponding value from map
    width = widthHeightMap[width];
  }

  // Check if the provided width is in the list
  if (width.length > 4) {
    // Extract width from the description
    const parts = input['description'].toUpperCase().split('X');
    if (parts.length === 2) {
      height = width;
      width = parts[0].trim();
    }
  }

  if (height == '80') {
    height = '';
  }

  const data = {
    product: {
      width,
      height,
    },
    model: {},
  } as Partial<UnfinishedData>;

  return data;
}
