import { Drawer } from '@mui/material';
import api from '@tyrio/api-factory';
import {
  DBDimensionsApi,
  DBPriceCompareApi,
  DBSupplierClientSettingsApi,
} from '@tyrio/dto';
import * as _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ProductReviewWithStats } from '../../features/price-comparison/Components/ProductReviewWithStats';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { OrderScreenV2 } from './components/OrderScreenV2';
import { PriceCompareTableDimensionV2 } from './components/PriceCompareTableDimensionV2';
import { PriceCompareTableV2 } from './components/PriceCompareTableV2';
import { PriceComparisonIntro } from './components/PriceComparisonIntro';
import { PriceComparisonContext, tomorrow } from './components/context';
import {
  CompareCodeData,
  CompareDimensionsData,
  OrderDataInterface,
  PriceCompareInputShape,
} from './types';
import { ProductSidebarGlobal } from '@tyrio/products';

const PRICE_COMPARE_KEY = '@@pc';
const drawerWidth = 450;
export interface PriceComparisonRootProps {
  onProcessOrderClicked: (data: OrderDataInterface) => void;
}

export const PriceComparisonFlow = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onProcessOrderClicked,
}: PriceComparisonRootProps) => {
  const [input, setInput] = useLocalStorage<PriceCompareInputShape>(
    PRICE_COMPARE_KEY,
    {
      screen: 'intro',
      dirty: false,
      type: 'code',
      isOrderAutomaticallyFilled: false,
      code: {
        searchType: 'ean',
      },
    }
  );

  const resetInputValue = (
    newData: Partial<PriceCompareInputShape>,
    oldValue: PriceCompareInputShape
  ) => {
    Object.entries(newData).forEach(([key, value]) => {
      if (!_.isEqual(value, _.get(oldValue, key))) _.set(oldValue, key, value);
    });
  };

  const setInputValue = useCallback(
    (newData: Partial<PriceCompareInputShape>, hardSet?: boolean) => {
      setInput((oldValue: PriceCompareInputShape) => {
        if (hardSet) {
          return Object.assign(
            {},
            { dirty: true, ...newData }
          ) as PriceCompareInputShape;
        } else {
          resetInputValue(newData, oldValue);
          return { ...oldValue };
        }
      });
    },
    [setInput]
  );

  const { isFetching: isFetchingSuppliers, data: suppliersResponse } = useQuery(
    ['supplier_list'],
    () =>
      api.fetch<DBSupplierClientSettingsApi['list']>(
        'get_supplier_client_settings',
        {}
      ),
    {}
  );

  const { data: dimensions, isFetching: isFetchingDimensions } = useQuery(
    ['get_dimensions'],
    () => api.fetch<DBDimensionsApi['list']>('get_dimensions', undefined),
    {}
  );

  const suppliers = useMemo(() => {
    return (
      (suppliersResponse?.data
        .filter((item) => item.isActive)
        .map((s) => {
          if (!s.supplier) return null;
          return {
            id: s.supplier.id,
            name: s.supplier.companyShortName,
            supplierType: s.supplier.supplierType,
          };
        })
        .filter((x) => !!x) as Array<{
        id: string;
        name: string;
        supplierType: string;
      }>) || []
    );
  }, [suppliersResponse?.data]);

  const reset = useCallback(() => {
    if (window.confirm('Are you sure? All your current progress will be lost!'))
      setInput({
        order: {},
        orderResponse: {},
        orderDeliveryBranches: {},
        serviceAddressBranches: {},
        internalRemarks: {},
        supplierRemarks: {},
        reference: '',
        transportPriority: 'RUSH',
        requestedDeliveryCalendarDate: tomorrow(),
        screen: 'intro',
        dirty: false,
        suppliers,
        supplierTransferList: {
          left: suppliers.map((s) => s.id),
          right: [],
        },
        type: 'code',
        dimensions: {},
        code: {
          searchType: 'ean',
          list: {},
        },
        showSupplierNames: true,
        mainSupplier: null,
        codeResponse: null,
        expandedKeys: [],
        showCode: false,
        showStockZero: false,
        showPercentages: false,
        includeEcoTax: false,
        includeDeliveryCost: false,
        includeBonus: false,
        isOrderAutomaticallyFilled: false,
        sidebarOpen: false,
        qty: {},
        openRow: null,
        workOrderData: { order: {}, serviceAddressBranches: {} },
      });
  }, [setInput, suppliers]);

  const clearDirtyForm = useCallback(() => {
    setInputValue({ dirty: false });
  }, [setInputValue]);

  const { isLoading: isFetchingCodeRequest, mutateAsync: mutateCode } =
    useMutation(
      ['price_comparison'],
      (payload: CompareCodeData) =>
        api.fetch<DBPriceCompareApi['compareCode']>('compare_prices_by_code', {
          supplierIds: payload.supplierIds,
          mainSupplier: payload.mainSupplier,
          codeType: payload.codeType as 'ean' | 'manufacturerCode' | 'sku',
          codes: payload.codes,
        }),
      {
        onSuccess: (data) => {
          const qty = input.type === input.lastFetchSource ? input.qty : {};
          setInputValue(
            {
              ...input,
              qty,
              lastFetchSource: 'code',
              screen: 'table',
              codeResponse: data,
            },
            true
          );
        },
      }
    );

  const {
    isLoading: isFetchingDimensionsRequest,
    mutateAsync: mutateAsyncDimensions,
  } = useMutation(
    ['price_comparison_dimensions'],
    (payload: CompareDimensionsData) =>
      api.fetch<DBPriceCompareApi['compareDimensions']>(
        'compare_prices_by_dimension',
        {
          supplierIds: payload.supplierIds,
          mainSupplier: payload.mainSupplier,
          dimensions: payload.dimensions,
          brands: payload.brands,
          classes: payload.classes,
          categoryId: payload.categoryId,
          seasons: payload.seasons,
          shouldShowRunFlat: payload.shouldShowRunFlat,
          sizeFavorites: payload.sizeFavorites,
          subcategoryId: payload.subcategoryId,
        }
      ),
    {
      onSuccess: (data) => {
        const qty = input.type === input.lastFetchSource ? input.qty : {};
        setInputValue(
          {
            ...input,
            qty,
            screen: 'table',
            lastFetchSource: 'dimension',
            codeResponse: data,
          },
          true
        );
      },
    }
  );

  const hasPlacedOrders = useMemo(
    () => Object.keys(input.orderResponse ?? {}).length > 0,
    [input.orderResponse]
  );

  const processOrder = useCallback(() => {
    if (hasPlacedOrders) {
      setInputValue({ screen: 'order' });
      return;
    }
    const order: OrderDataInterface = {};
    const products = input.codeResponse?.products;
    const prices = input.codeResponse?.prices;
    const orderSuppliers = _.merge(
      {},
      input.codeResponse?.suppliers || {},
      input.codeResponse?.mainSupplier || {}
    );

    Object.keys(orderSuppliers).forEach((supplierId) => {
      const supplierQty = input.qty[supplierId] || {};

      Object.keys(supplierQty).forEach((productId) => {
        const chosenQty = supplierQty[productId];
        if (products && prices && chosenQty > 0) {
          const productToOrder = {
            productName: products[productId].productName,
            uid: products[productId].uid,
            sku: products[productId].sku,
            ipc: products[productId].manufacturerCode,
            ean: products[productId].ean,
            quantity: chosenQty,
            price: prices[productId][supplierId].value || 0,
            suggestedRetailPrice:
              prices[productId][supplierId].suggestedRetailPrice || 0,
            shipping: prices[productId][supplierId].shipping || 0,
            bonus: prices[productId][supplierId].bonus || 0,
            ecoTax: prices[productId][supplierId].ecoTax || 0,
            productYear:
              prices[productId][supplierId].productYear ||
              new Date().getFullYear(),
          };

          if (!order[supplierId]) order[supplierId] = [];

          order[supplierId].push(productToOrder);
        }
      });
    });

    setInputValue({ screen: 'order', sidebarOpen: false, order });
    // onProcessOrderClicked(order);
  }, [
    hasPlacedOrders,
    input.codeResponse?.mainSupplier,
    input.codeResponse?.prices,
    input.codeResponse?.products,
    input.codeResponse?.suppliers,
    input.qty,
    setInputValue,
  ]);

  const selectedProduct = useMemo(() => {
    if (input.openRow) return input.codeResponse?.products?.[input.openRow];
    return null;
  }, [input.codeResponse?.products, input.openRow]);

  const canSidebarOpen = !!selectedProduct;

  return (
    <PriceComparisonContext.Provider
      value={{
        mode: 'purchase_orders',
        data: input,
        hasPlacedOrders,
        setInputValue,
        clearDirtyForm,
        suppliers,
        isFetchingSuppliers,
        reset,
        dimensions: dimensions || [],
        isFetchingDimensions,
        mutateCode,
        mutateDimensions: mutateAsyncDimensions,
        processOrder,
        isFetchingCodeRequest,
        isFetchingDimensionsRequest,
        canSidebarOpen,
      }}
    >
      {(input.screen === 'intro' || !input.codeResponse) && (
        <PriceComparisonIntro />
      )}
      {input.screen === 'table' && input.codeResponse && (
        <>
          {input.lastFetchSource === 'dimension' && (
            <PriceCompareTableDimensionV2 RES={input.codeResponse} />
          )}
          {input.lastFetchSource === 'code' && (
            <PriceCompareTableV2 RES={input.codeResponse} />
          )}
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}
            variant="persistent"
            anchor="right"
            open={canSidebarOpen && input.sidebarOpen}
          >
            {selectedProduct && (
              <ProductSidebarGlobal product={selectedProduct} />
            )}
            {selectedProduct && (
              <ProductReviewWithStats
                selectedProduct={selectedProduct}
                numberOfAvailableItems={0}
                numberOfBackorderItems={0}
                numberOfReservedItems={0}
              />
            )}
          </Drawer>
        </>
      )}
      {input.screen === 'order' && <OrderScreenV2 />}
    </PriceComparisonContext.Provider>
  );
};
