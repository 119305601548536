import { DBDocumentsLook, DBFile } from '@prisma/client';

interface DBDocumentsLookPayload {
  logoId?: string;
  headerText?: string;
  mainTermsText?: string;
  paymentTermsText?: string;
  footerText?: string;
}

interface DBDocumentsLookApi {
  get: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: (DBDocumentsLook & { logo: DBFile }) | null;
  };
  create: {
    requestBody: DBDocumentsLookPayload;
    requestParams: never;
    request: DBDocumentsLookPayload;
    response: DBDocumentsLook & { logo: DBFile };
  };
  update: {
    requestBody: DBDocumentsLookPayload;
    requestParams: never;
    request: DBDocumentsLookPayload;
    response: DBDocumentsLook & { logo: DBFile };
  };
}

export { DBDocumentsLookApi, DBDocumentsLookPayload };
