import * as z from "zod"
import { ShelfType } from ".prisma/client"
import { CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel, CompleteDBWarehouseSections, RelatedDBWarehouseSectionsModel, CompleteDBWarehouseBins, RelatedDBWarehouseBinsModel, CompleteDBStockListItemLocation, RelatedDBStockListItemLocationModel, CompleteDBAppointments, RelatedDBAppointmentsModel, CompleteDBFittingBoxSettings, RelatedDBFittingBoxSettingsModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBWarehouseShelfsModel = z.object({
  id: z.string(),
  warehouseAreaId: z.string(),
  sectionId: z.string(),
  code: z.number().int(),
  displayName: z.string(),
  qrCode: z.string(),
  description: z.string(),
  settings: jsonSchema,
  meta: jsonSchema,
  shelfType: z.nativeEnum(ShelfType).nullish(),
})

export interface CompleteDBWarehouseShelfs extends z.infer<typeof DBWarehouseShelfsModel> {
  warehouseArea: CompleteDBBranchWarehouseAreas
  section: CompleteDBWarehouseSections
  bins: CompleteDBWarehouseBins[]
  shelfs: CompleteDBStockListItemLocation[]
  reservedBox: CompleteDBAppointments[]
  completedBox: CompleteDBAppointments[]
  box?: CompleteDBFittingBoxSettings | null
}

/**
 * RelatedDBWarehouseShelfsModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBWarehouseShelfsModel: z.ZodSchema<CompleteDBWarehouseShelfs> = z.lazy(() => DBWarehouseShelfsModel.extend({
  warehouseArea: RelatedDBBranchWarehouseAreasModel,
  section: RelatedDBWarehouseSectionsModel,
  bins: RelatedDBWarehouseBinsModel.array(),
  shelfs: RelatedDBStockListItemLocationModel.array(),
  reservedBox: RelatedDBAppointmentsModel.array(),
  completedBox: RelatedDBAppointmentsModel.array(),
  box: RelatedDBFittingBoxSettingsModel.nullish(),
}))
