// import {
//   DBDeliveryCustomerOrdersTypeEnum,
//   DBDeliveryTypes,
//   DBRole,
// } from '@prisma/client';
//
// interface DBDeliveryTypesCreatePayload {
//   code: string;
//   name: string;
//   shippingChannel?: DBDeliveryCustomerOrdersTypeEnum;
// }
//
// interface DBDeliveryTypesApi {
//   list: {
//     requestBody: never;
//     request: never;
//     response: DBDeliveryTypes[];
//   };
//   getOne: {
//     requestBody: never;
//     requestParams: never;
//     request: never;
//     response: DBRole;
//   };
//   create: {
//     requestBody: DBDeliveryTypesCreatePayload;
//     requestParams: never;
//     request: DBDeliveryTypesCreatePayload;
//     response: DBRole;
//   };
//   updateOne: {
//     requestBody: never;
//     requestParams: never;
//     request: never;
//     response: never;
//   };
// }
//
// export { DBDeliveryTypesApi };

import { DBClientSupplierOrderItem } from '@prisma/client';
import {
  ClientConnectionData,
  HttpsConnectionDetail,
} from './supplier-client-settings';

import { RULESET_KEYS } from '@tyrio/shared-vars';

export interface EDIInquiryItemRequest {
  ean: string;
  quantity: number;
}

export interface ProductDetailsDataInterface {
  productName: string;
  sku: string;
  ipc: string;
  ean: string;
  quantity: number;
  price: number;
  shipping: number;
  ecoTax: number;
  bonus: number;
  productYear: number;
  uid: string;
  brand?: string;
}

export interface DBEdiOrderInquiryBody {
  items: EDIInquiryItemRequest[];
  branchId: number;
  supplierClientSettingsId: string;
  supplierId: string;
  connection: HttpsConnectionDetail;
  adminConnectionId: string;
  preferredDeliveryDate?: string;
  transportPriority: 'RUSH' | 'REPL';
}

export interface EdiCreateOrderResponse {
  errorCode: string;
  orderId: string;
  orderLines: Array<{
    ean: string;
    manufacturerCode: string;
    orderId: string;
    deliveryDate: string;
    confirmedQuantity: string;
  }>;

  createdOrder: DBClientSupplierOrderItem;
}

export interface DataShape {
  customerOrderId?: string;
  reference: string;
  supplierId: string;
  supplierClientSettingsId: string;
  supplierRemark: string;
  remark: string;
  deliveryAddress: {
    country: string;
    address_line_1: string;
    address_line_2: string;
    zipCode: string;
    city: string;
  };
  preferredDeliveryDate?: string;
  transportPriority: 'RUSH' | 'REPL';
  connectionProtocol: string;
  connection: ClientConnectionData;
  lineItems: ProductDetailsDataInterface[];
  rulesetKey: keyof typeof RULESET_KEYS;
  orderNumberPrefix: string;
  orderNumberSufix: string;
  branchId: number;
  toEmail?: string;
  fromEmail?: string;
  cc?: string;
}

export interface EDIOrderRequestLineItem {
  uid: string;
  productName: string;
  ean: string;
  ipc: string;
  sku: string;
  price: number;
  qty: number;
  total: number;
  productYear: string;
  orderTimestamp: Date;
}

export interface EDICreateOrderBody {
  customerOrderId?: string;
  reference: string;
  supplierId: string;
  supplierClientSettingsId: string;
  alias: string;
  notes: string;
  deliveryAddress: {
    country: string;
    address_line_1: string;
    address_line_2: string;
    zipCode: string;
    city: string;
  };
  items: Array<{ code: string; quantity: number }>;
  connection: ClientConnectionData;
  lineItems: EDIOrderRequestLineItem[];
  rulesetKey: keyof typeof RULESET_KEYS;
  orderNumberPrefix: string;
  orderNumberSufix: string;
  preferredDeliveryDate?: string;
  transportPriority: 'RUSH' | 'REPL';
  supplierRemark: string;
  remark: string;
  branchId: number;
  lineItemsMeta: Record<
    string,
    { ordered: number; confirmed: number; received: number; backorder: number }
  >;
  subtotal: number;
  ecoTax: number;
  delivery: number;
  total: number;
}

export interface DBEdiOrderApi {
  inquire: {
    requestBody: DBEdiOrderInquiryBody;
    requestParams: never;
    request: DBEdiOrderInquiryBody;
    /** Response key is EAN */
    response: Record<
      string,
      {
        ean: string;
        qty: number;
        deliveryDate: string;
        deliveryAcceptableRange: number;
      }
    >;
  };
  create_edi_order: {
    requestBody: EDICreateOrderBody;
    requestParams: never;
    request: EDICreateOrderBody;
    response: { purchaseOrder: EdiCreateOrderResponse; ritamResponse: string };
  };
  order_status: {
    requestBody: never;
    requestParams: { orderId: string };
    request: { orderId: string };
    response: (EDIOrderStatusResponse | null)[];
  };
}

export interface EDIOrderUpdateShape {
  date: Date;
  confirmed: number;
  cancelled: number;
  open: number;
  ordered: number;
  despatchDate?: string;
  despatchReference?: string;
  despatchQty?: number;
}
export interface EDIOrderStatusShape {
  ordered: number;
  confirmed: number;
  cancelled: number;
  backorder: number;
}

/**
 * EAN as key of record
 */
export type EDIOrderStatusResponse = Record<
  string,
  {
    updates: EDIOrderUpdateShape[];
    status: EDIOrderStatusShape;
  }
>;
