import {
  DBClientSupplierOrder,
  DBClientSupplierOrderItem,
  DBDeliveryTypeEnum,
  DBOrderDocument,
  DBOrderStatus,
  DBSalesChannel,
  DBSupplier,
  Prisma,
} from '@prisma/client';
import { DBCustomerOrderStatusEnum } from './customer-order-item';

interface DBClientSupplierOrderItemFilters {
  searchType?: string;
  searchKeyword?: string;
  salesChannelKeys?: DBSalesChannel[];
  supplierIds?: string[];
  startDate?: Date;
  endDate?: Date;
  orderStatus?: DBOrderStatus | string;
  myOrders?: string;
  category?: string;
  paymentMethod?: string;
  branch?: string;
  suppliers?: string;
  paymentStatus?: string;
}

export interface PurchaseOrdersByEanResponse {
  createdAt: Date;
  orderName: string;
  supplierName: string;
  deliveryTime: Date;
  reserved: number;
  available: number;
  documents?: DBOrderDocument[];
  reference: string;
}

export interface PurchaseOrderLineItem {
  ean: string;
  ipc: string;
  qty: number;
  sku: string;
  uid: string;
  price: number;
  suggestedRetailPrice: number;
  total: number;
  productName: string;
  orderTimestamp: Date;
  productYear?: number;
  ecoTax?: number;
  transpostCost?: number;
}

interface DBClientSupplierOrderItemRequestBody {
  filters: DBClientSupplierOrderItemFilters;
  page?: number;
  pageSize?: number;
}

interface DBClientSupplierOrderItemCountByStatus {
  _count: number;
  orderStatus: DBOrderStatus | DBCustomerOrderStatusEnum;
}

interface DBClientSupplierOrderItemResponse extends DBClientSupplierOrderItem {
  supplier: DBSupplier;
  order: DBClientSupplierOrder;
}

interface DBOrderOnePayload {
  id: string;
}

interface DBOrderUpdatePayload {
  orderStatus?: DBOrderStatus;
  deliveryType?: DBDeliveryTypeEnum;
  remark?: string;
  supplierRemark?: string;
  orderLineItemMeta?: Prisma.InputJsonValue;
}

interface ActiveOrdersResponse {
  id: string;
  orderNumber: string;
  supplierId: string;
}

interface ActiveOrdersPayload {
  id?: string;
}

interface DBClientSupplierOrderItemApi {
  list: {
    requestBody: DBClientSupplierOrderItemRequestBody;
    request: DBClientSupplierOrderItemRequestBody;
    response: { data: DBClientSupplierOrderItemResponse[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBOrderOnePayload;
    request: DBOrderOnePayload;
    response: DBClientSupplierOrderItemResponse;
  };
  create: {
    requestBody: Prisma.DBClientSupplierOrderItemCreateInput;
    requestParams: never;
    request: never;
    response: DBClientSupplierOrderItem;
  };
  updateOne: {
    requestBody: DBOrderUpdatePayload;
    requestParams: DBOrderOnePayload;
    request: DBOrderUpdatePayload | DBOrderOnePayload;
    response: DBClientSupplierOrderItem;
  };
  getActive: {
    requestBody: never;
    requestParams: ActiveOrdersPayload;
    request: ActiveOrdersPayload;
    response: ActiveOrdersResponse[];
  };
}
export {
  DBClientSupplierOrderItemApi,
  DBClientSupplierOrderItemCountByStatus,
  DBClientSupplierOrderItemFilters,
  DBClientSupplierOrderItemResponse,
};
