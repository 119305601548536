export interface ClientPrinters {
  name: string;
  id: string;
}

export interface SetDefaultPrinters {
  a4Documents?: ClientPrinters[];
  posReceipts?: ClientPrinters[];
  thermalLabels?: ClientPrinters[];
}

interface ClientPrintersApi {
  create: {
    request: SetDefaultPrinters;
    requestBody: SetDefaultPrinters;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any;
  };
}

export { ClientPrintersApi };
