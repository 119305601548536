import * as z from "zod"
import { DBBranchType } from ".prisma/client"
import { CompleteDBClient, RelatedDBClientModel, CompleteDBCountry, RelatedDBCountryModel, CompleteDBUser, RelatedDBUserModel, CompleteDBShopTypes, RelatedDBShopTypesModel, CompleteDBBranchWarehouseAreas, RelatedDBBranchWarehouseAreasModel, CompleteDBClientStockList, RelatedDBClientStockListModel, CompleteDBClientSupplierOrderItem, RelatedDBClientSupplierOrderItemModel, CompleteDBDeliveryAddress, RelatedDBDeliveryAddressModel, CompleteDBFittingBoxSettings, RelatedDBFittingBoxSettingsModel, CompleteDBClientRebateCalculation, RelatedDBClientRebateCalculationModel } from "./index"

// Helper schema for JSON fields
type Literal = boolean | number | string
type Json = Literal | { [key: string]: Json } | Json[]
const literalSchema = z.union([z.string(), z.number(), z.boolean()])
const jsonSchema: z.ZodSchema<Json> = z.lazy(() => z.union([literalSchema, z.array(jsonSchema), z.record(jsonSchema)]))

export const DBBranchModel = z.object({
  id: z.number().int(),
  branchName: z.string(),
  branchType: z.nativeEnum(DBBranchType).array(),
  mainWarehouseId: z.number().int().nullish(),
  clientId: z.string(),
  isActive: z.boolean(),
  countryId: z.string().nullish(),
  zipCode: z.string(),
  city: z.string(),
  address: z.string(),
  email: z.string().nullish(),
  webSite: z.string().nullish(),
  businessPhone: z.string().nullish(),
  qualifiedPersonJobTitle: z.string(),
  qualifiedPersonFirstName: z.string(),
  qualifiedPersonLastName: z.string(),
  qualifiedPersonEmail: z.string().nullish(),
  qualifiedPersonBusinessPhone: z.string().nullish(),
  qualifiedPersonMobilePhone: z.string().nullish(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  branchNumber: z.number().int(),
  services: jsonSchema,
  paymentMethods: jsonSchema,
  printers: jsonSchema,
  deliveryMethods: jsonSchema,
  erpId: z.string().nullish(),
  rebateCalculationId: z.string().nullish(),
})

export interface CompleteDBBranch extends z.infer<typeof DBBranchModel> {
  mainWarehouse?: CompleteDBBranch | null
  children: CompleteDBBranch[]
  client: CompleteDBClient
  country?: CompleteDBCountry | null
  usersMain: CompleteDBUser[]
  usersSecondary: CompleteDBUser[]
  currents: CompleteDBUser[]
  shops: CompleteDBShopTypes[]
  warehouseAreas: CompleteDBBranchWarehouseAreas[]
  stockListItems: CompleteDBClientStockList[]
  DBClientSupplierOrderItem: CompleteDBClientSupplierOrderItem[]
  deliveryAddress: CompleteDBDeliveryAddress[]
  boxes: CompleteDBFittingBoxSettings[]
  rebateCalculation?: CompleteDBClientRebateCalculation | null
}

/**
 * RelatedDBBranchModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedDBBranchModel: z.ZodSchema<CompleteDBBranch> = z.lazy(() => DBBranchModel.extend({
  mainWarehouse: RelatedDBBranchModel.nullish(),
  children: RelatedDBBranchModel.array(),
  client: RelatedDBClientModel,
  country: RelatedDBCountryModel.nullish(),
  usersMain: RelatedDBUserModel.array(),
  usersSecondary: RelatedDBUserModel.array(),
  currents: RelatedDBUserModel.array(),
  shops: RelatedDBShopTypesModel.array(),
  warehouseAreas: RelatedDBBranchWarehouseAreasModel.array(),
  stockListItems: RelatedDBClientStockListModel.array(),
  DBClientSupplierOrderItem: RelatedDBClientSupplierOrderItemModel.array(),
  deliveryAddress: RelatedDBDeliveryAddressModel.array(),
  boxes: RelatedDBFittingBoxSettingsModel.array(),
  rebateCalculation: RelatedDBClientRebateCalculationModel.nullish(),
}))
