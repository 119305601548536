interface DBUpsertSupplierClientPackageCostPayload {
  id?: string;
  categoryIds: number[];
  rimDiameters: number[];
  widths: number[];
  costPerPackage: number;
}

interface DBSupplierClientPackageCostApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: DBUpsertSupplierClientPackageCostPayload;
    requestParams: never;
    request: DBUpsertSupplierClientPackageCostPayload;
    response: never;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}
export { DBSupplierClientPackageCostApi };
