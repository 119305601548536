import styled from '@emotion/styled/macro';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Grid, List, TextField, Typography } from '@mui/material';
import { DBInboundInvoice, DBPartner } from '@prisma/client';
import api from '@tyrio/api-factory';
import {
  DBCountryApi,
  InboundInvoiceItem,
  InboundInvoiceSummary,
  isEUCountry,
} from '@tyrio/dto';
import { tyrioUI } from '@tyrio/ui-library';
import { startCase } from 'lodash';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useQuery } from 'react-query';
import { PriceItem } from '../../customer-orders/components/PriceItem';
import FormSectionWithTitle from '../../products/pages/components/FormSectionWithTitle';

interface InvoiceDetailsProps {
  selectedInvoice: DBInboundInvoice & { partner?: DBPartner | null };
  setSelectedInvoice: Dispatch<
    SetStateAction<(DBInboundInvoice & { partner?: DBPartner | null }) | null>
  >;
}

export const InvoiceDetails = ({
  selectedInvoice,
  setSelectedInvoice,
}: InvoiceDetailsProps) => {
  const iconsStyledProps = { width: '20px', height: '20px', cursor: 'pointer' };

  const isEu = isEUCountry(selectedInvoice?.dispatchingCountryId ?? '');

  const { data } = useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      enabled: isEu,
    }
  );

  return (
    <Container>
      <Header>
        <BasicOrderInfoContainer>
          <div>{String(selectedInvoice?.internalReference)}</div>
          <TimeInfo>
            {moment(selectedInvoice?.createdAt).format('DD.MM.YYYY - h:mm:ss')}
          </TimeInfo>
        </BasicOrderInfoContainer>

        <Grid sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <CloseIcon
            sx={iconsStyledProps}
            onClick={() => setSelectedInvoice(null)}
          />
        </Grid>
      </Header>
      <div
        style={{
          display: 'flex',
          gap: '5px',
          marginTop: '20px',
          padding: '16px',
          width: '100%',
        }}
      >
        <Grid
          sx={{
            display: 'flex',
            padding: '12px',
            height: '130px',
            cursor: 'default',
            width: '100%',
            border: '1px solid #dfe3e8',
            borderRadius: '8px',
          }}
        >
          <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Label>{'Partner'}</Label>
            <DetailsText weight="600" size="16px">
              {selectedInvoice.supplierName}
            </DetailsText>
            <DetailsText weight="400" size="14px">
              {`OIB: ${selectedInvoice.partnerOib}`}
            </DetailsText>
            <DetailsText weight="400" size="14px">
              {`${selectedInvoice.partner?.city}, ${selectedInvoice?.partner?.zipCode}, ${selectedInvoice?.partner?.address}`}
            </DetailsText>
          </Grid>
        </Grid>
        {isEu && (
          <Grid
            sx={{
              display: 'flex',
              padding: '12px',
              height: '130px',
              cursor: 'default',
              width: '100%',
              border: '1px solid #dfe3e8',
              borderRadius: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Label>{'Intrastat'}</Label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <TimeInfo>Delivery terms type:</TimeInfo>
                <TimeInfo>{selectedInvoice.deliveryTermsType}</TimeInfo>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <TimeInfo>Transport mode:</TimeInfo>
                <TimeInfo>
                  {startCase(selectedInvoice.transportMode ?? '')}
                </TimeInfo>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <TimeInfo>Transport nature:</TimeInfo>
                <TimeInfo>{selectedInvoice.transactionNature}</TimeInfo>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <TimeInfo>Dispatching country:</TimeInfo>
                <TimeInfo>
                  {data && data.length > 0
                    ? data?.find(
                        (item) =>
                          item.id === selectedInvoice.dispatchingCountryId
                      )?.name
                    : ''}
                </TimeInfo>
              </div>
            </div>
          </Grid>
        )}
      </div>

      <ProductsTable>
        <Grid item lg={12} xs={12} style={{ padding: '8px' }}>
          <FormSectionWithTitle label={'Invoice items'} />
        </Grid>
        <TableHead>
          <StockWrapper>
            <Typography variant="body2">Purchase price</Typography>
            <Typography variant="body2">Quantity</Typography>
            <Typography variant="body2">Total</Typography>
          </StockWrapper>
        </TableHead>

        <ProductItemContainer>
          {selectedInvoice &&
            selectedInvoice.items &&
            Object.values(
              selectedInvoice.items as unknown as InboundInvoiceItem[]
            ).map((invoice, idx) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  key={idx}
                >
                  <ReferenceInfo>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                      }}
                    >
                      <Typography variant="subtitle1">Purchase:</Typography>
                      <TimeInfo
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {invoice.purchaseOrder}
                      </TimeInfo>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                      }}
                    >
                      <Typography variant="subtitle1">Supplier:</Typography>
                      <TimeInfo
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {invoice.supplierOrder}
                      </TimeInfo>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                      }}
                    >
                      <Typography variant="subtitle1">Stock In:</Typography>
                      <TimeInfo
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {invoice.stockInOrder}
                      </TimeInfo>
                    </div>
                  </ReferenceInfo>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <List>
                        {invoice.lineItems.map((item, itemIndex) => (
                          <Box
                            key={itemIndex}
                            sx={{
                              borderBottom: '1px solid #eee',
                            }}
                          >
                            <Grid container spacing={2} alignItems="center">
                              <ProductDetailsWrapper>
                                <ProductDetails>
                                  <div
                                    style={{
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      maxWidth: '370px',
                                    }}
                                  >{`${item.productName}`}</div>
                                  <EanIpcSkuWrapper>
                                    <div>EAN: {`${item.ean} `}</div>
                                    <div>IPC: {`${item.ipc} `}</div>
                                    <div>SUPPLIER CODE: {`${item.sku} `}</div>
                                  </EanIpcSkuWrapper>
                                </ProductDetails>
                              </ProductDetailsWrapper>

                              <Wrapper
                                background={'#f4f6f8'}
                                id="quantity_wrapper"
                              >
                                <NumberWrapper>
                                  {item.purchasePrice}
                                </NumberWrapper>
                                <NumberWrapper>{item.quantity}</NumberWrapper>

                                <NumberWrapper>{item.grandTotal}</NumberWrapper>
                              </Wrapper>
                            </Grid>
                          </Box>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
        </ProductItemContainer>
      </ProductsTable>

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}
      >
        <ProductCardsWrapper
          style={{
            width: '55%',
            background: '#DFE3E8',
            padding: 0,
          }}
        >
          <div style={{ padding: '8px' }}>
            <PriceItem
              title="Subtotal:"
              price={
                (selectedInvoice?.summary as unknown as InboundInvoiceSummary)
                  .subtotalExVat
              }
              fontSize="14px"
            />
            <PriceItem
              title={`VAT (${
                (selectedInvoice?.summary as unknown as InboundInvoiceSummary)
                  .vat
              }%):`}
              price={
                (selectedInvoice?.summary as unknown as InboundInvoiceSummary)
                  .vatAmount
              }
              fontSize="14px"
            />
            <Divider
              style={{
                padding: '5px',
                color: '#454f5b',
              }}
            />
          </div>

          <TotalWrapper>
            <DropdownWrapper style={{ justifyContent: 'space-between' }}>
              <Typography color={'white'} fontSize={'18px'} fontWeight={700}>
                Total:
              </Typography>
              <Typography color={'white'} fontWeight={700}>
                {
                  (selectedInvoice?.summary as unknown as InboundInvoiceSummary)
                    .grandTotalIncVat
                }
              </Typography>
            </DropdownWrapper>
          </TotalWrapper>
        </ProductCardsWrapper>
      </div>

      <RemarkWrapper>
        <TextField
          label="Remark"
          variant="outlined"
          value={selectedInvoice.internalRemark}
          disabled={true}
        />
      </RemarkWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: calc(100svh - 44px);
  overflow-y: auto;
`;

const BasicOrderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeInfo = styled.div<{ size?: string }>`
  font-weight: 400;
  font-size: ${(props) => props.size ?? '14px'};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
  background: #454f5b;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: 700;
  font-size: 24px;
  color: white;
`;

const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #919eab;
`;

const DetailsText = styled.div<{ weight: string; size: string }>`
  font-weight: ${(props) => (props.weight ? props.weight : '600')};
  font-size: ${(props) => (props.size ? props.size : '600')};
`;

const ProductsTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 900px;
  overflow-y: auto;
`;

const ProductItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100svh - 700px);
`;

const TableHead = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 10px;
  border-bottom: 1px solid #dfe3e8;
`;

const StockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  align-items: flex-end;
  width: 100%;
  max-width: 300px;
  justify-content: space-around;
`;

const RemarkWrapper = styled.div`
  padding: 16px;
  position: sticky;
  top: 100%;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const EanIpcSkuWrapper = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  max-width: 400px;
  flex-direction: row;
  color: #919eab;
`;

const ProductDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 250px);
  padding: 16px;
`;

const Wrapper = styled.div<{
  background?: string;
}>`
  width: 100%;
  min-width: 250px;
  max-width: 250px;
  height: 100%;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.background ? props.background : '#f4f6f8')};
  border-radius: 8px 0px 0px 8px;

  &:hover {
    background: #ecf0f4;
  }
`;

const NumberWrapper = styled.div<{
  color?: string;
  clickable?: boolean;
}>`
  display: flex;
  width: 100%;
  max-width: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${(props) => (props.color === 'green' ? '600' : '400')};
  color: ${(props) => (props.color ? props.color : 'black')};
  &:hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : '')};
  }
`;

const ReferenceInfo = styled.div`
  display: flex;
  flexd-irection: row;
  align-items: center;
  padding: 8px;
  gap: 10px;
  height: 40px;
  background: #454f5b;
  color: white;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 5px;
  padding: 0 16px;
`;

const ProductCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${tyrioUI.colors.black.B40};
  padding: 8px 0px;
  padding-left: 16px;
  border-radius: 8px;
`;

const TotalWrapper = styled.div`
  background: #454f5b;
  padding: 0 8px 8px 8px;
  border-radius: 0 0 8px 8px;
`;
