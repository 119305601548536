import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

const PosLoading = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

const Container = styled.div`
  height: calc(100svh - 110px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PosLoading;
