import {
  DBRole,
  DBShopSalesChannels,
  DBShopTypes,
  DBShopTypesEnum,
} from '@prisma/client';

interface DBShopTypesCreatePayload {
  code: string;
  shopName: string;
  shopURL: string;
  shopType?: DBShopTypesEnum;
  salesChannel?: DBShopSalesChannels;
  branchId: number;
}

interface DBShopTypesApi {
  list: {
    requestBody: never;
    request: never;
    response: DBShopTypes[];
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: DBRole;
  };
  create: {
    requestBody: DBShopTypesCreatePayload;
    requestParams: never;
    request: DBShopTypesCreatePayload;
    response: DBRole;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}

export { DBShopTypesApi };
