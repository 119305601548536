import api from '@tyrio/api-factory';
import {
  CardInfoDetails,
  DBCustomerOrderItemApi,
  DBCustomerOrderItemResponse,
  DBCustomerOrderUpdateAddressPayload,
  Modules,
  OrderStatusProps,
  SSE_ENTITY_KEYS,
} from '@tyrio/dto';
import { ToastHelper, ToastMessageType, ToastType } from '@tyrio/ui-library';
import { AxiosError } from 'axios';
import qs from 'qs';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import OrderSidebarLayout from '../../components/Orders/SidebarLayout/OrderSidebarLayout';
import { ComponentType } from '../purchase-orders/helpers/types';
import CustomerOrderDetailsTabs from './CustomerOrderDetailsTabs';
import { CustomerOrdersDetailsWrapper } from './CustomerOrdersOverview';

import { useAuth } from '../../context/AuthContext';
import { useWS } from '../../context/WSContext';

interface CustomerOrdersSidebarProps {
  isDirty: boolean;
  isUploadInProcess: boolean;
  setIsUploadInProcess: Dispatch<SetStateAction<boolean>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
  isProcessOrder?: boolean;
  preparePurchaseOrder?: () => void;
  canContinueProcessOrder?: boolean;
  showGoBackToCustomerOrders?: boolean;
  setIsWarningModalOpen?: (a: boolean) => void;
  isWarningModalOpen?: boolean;
  invisibleButton?: boolean;
}
type PaymentInfo = {
  cardInfo: CardInfoDetails;
};

export const CustomerOrdersSidebar = ({
  isDirty,
  setIsUploadInProcess,
  isUploadInProcess,
  setIsDirty,
  isProcessOrder,
  preparePurchaseOrder,
  canContinueProcessOrder,
  showGoBackToCustomerOrders = false,
  setIsWarningModalOpen,
  isWarningModalOpen,
  invisibleButton,
}: CustomerOrdersSidebarProps) => {
  const history = useHistory();
  const params: { orderId?: string } = useParams();
  const selectedKeyBase = useMemo(() => {
    if (typeof params['orderId'] === 'string')
      return { selectedKey: params['orderId'], fromParams: true };
    let search = history.location.search;
    if (search.startsWith('?')) search = search.substring(1);

    const parsed = qs.parse(search);
    return {
      selectedKey: (parsed?.['selected'] as string) ?? null,
      fromParams: false,
    };
  }, [history.location.search, params]);

  const selectedKey = selectedKeyBase.selectedKey;

  const queryClient = useQueryClient();

  const [customerOrder, setCustomerOrder] =
    useState<DBCustomerOrderItemResponse | null>(null);

  const { isFetching } = useQuery(
    ['customer_order', selectedKey],
    () =>
      api.fetch<DBCustomerOrderItemApi['getOne']>('get_customer_order_by_id', {
        id: selectedKey ?? '',
      }),
    {
      enabled: !!selectedKey,

      onSuccess: (data) => {
        setCustomerOrder(data);
      },
    }
  );

  const { user } = useAuth();
  const { state, socket } = useWS();

  const [addressUpdateData, setAddressUpdateData] =
    useState<DBCustomerOrderUpdateAddressPayload>({
      editedDeliveryAddress: '',
      editedBillingAddress: '',
      editedBy: '',
      updatedAt: '',
    });

  const updateOrderMutation = useMutation(
    (request: DBCustomerOrderItemApi['updateOne']['request']) => {
      return api.fetch<DBCustomerOrderItemApi['updateOne']>(
        'update_customer_order',
        {
          id: selectedKey,
          ...request,
        }
      );
    },
    {
      mutationKey: 'update_customer_order',
      onSuccess: () => {
        setIsDirty(false);
        getTableCountData();
        ToastHelper.showToast(
          'Customer order',
          ToastType.SUCCESS,
          ToastMessageType.UPDATE
        );
      },
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data.error.name;
          ToastHelper.showToast(
            'Customer order',
            ToastType.ERROR,
            ToastMessageType.ERROR,
            errorMessage ?? 'An error occurred!'
          );
        }
        throw error;
      },
    }
  );

  const handleSave = () => {
    const paymentDetails =
      customerOrder?.paymentDetails as unknown as PaymentInfo;
    const requestBody: DBCustomerOrderItemApi['updateOne']['requestBody'] = {
      orderStatus: customerOrder?.orderStatus as unknown as OrderStatusProps,
      deliveryId: customerOrder?.deliveryId ?? '',
      orderType: customerOrder?.orderType,
      internalRemark: customerOrder?.internalRemark ?? '',
      paymentStatus: customerOrder?.paymentStatus,
      paymentDetails: paymentDetails &&
        paymentDetails.cardInfo && {
          cardInfo: {
            cardType: paymentDetails.cardInfo.cardType,
            instalment: paymentDetails.cardInfo.instalment,
            transactionId: paymentDetails.cardInfo.transactionId,
          },
        },
      address: addressUpdateData,
      lastEditedBy: {
        lastUpdatedBy: `${user?.firstName} ${user?.lastName}`,
        lastUpdateTime: new Date(),
        module: Modules.UPDATE,
      },
    };
    updateOrderMutation.mutate(requestBody);
  };

  const updateFavoriteStatusMutation = useMutation(
    (id: string) =>
      api.fetch<DBCustomerOrderItemApi['getOne']>(
        'customer_order_change_favourite_status',
        {
          id: id,
        }
      ),
    {
      mutationKey: 'change_favorite_status',
      onSuccess: () => {
        const isFavorited = customerOrder?.favoritedBy.includes(user?.id ?? '');
        getTableCountData();
        customerOrder &&
          setCustomerOrder({
            ...customerOrder,
            favoritedBy: isFavorited
              ? customerOrder.favoritedBy.filter((item) => item !== user?.id)
              : [...customerOrder.favoritedBy, user?.id ?? ''],
          });
        ToastHelper.showToast(
          'Order',
          ToastType.SUCCESS,
          undefined,
          `Order successfully ${
            isFavorited ? 'removed from' : 'added to'
          } favorites`
        );
      },
      onError: () => {
        ToastHelper.showToast(
          'Order',
          ToastType.ERROR,
          undefined,
          'An error occured!'
        );
      },
    }
  );

  const setFavorite = () => {
    updateFavoriteStatusMutation.mutate(customerOrder?.id ?? '');
  };

  const setLockStatus = () => {
    const key =
      state?.locks &&
      state?.locks[`${SSE_ENTITY_KEYS.CUSTOMER_ORDER}/${customerOrder?.id}`]
        ? 'unlock'
        : 'lock';

    socket?.emit(key, {
      entityName: SSE_ENTITY_KEYS.CUSTOMER_ORDER,
      entityId: customerOrder?.id,
    });
  };

  const getTableCountData = () => {
    queryClient.refetchQueries('get_customer_orders');
    queryClient.refetchQueries('customer_order');
    queryClient.refetchQueries('count_customer_orders_by_status');
  };

  const closeOrder = useCallback(() => {
    // setOpen(null);
    let search = history.location.search;
    if (search.startsWith('?')) search = search.substring(1);

    const q = qs.parse(search);

    if (q['selected']) {
      delete q['selected'];
    }

    history.push({
      pathname: '/dashboard/customer-orders',
      search: qs.stringify(q),
    });
  }, [history]);

  if (!selectedKey) return null;
  return (
    <CustomerOrdersDetailsWrapper id="customer_order_sidebar">
      {customerOrder && (
        <OrderSidebarLayout
          selectedOrderData={customerOrder}
          closeOrder={closeOrder}
          setFavorite={setFavorite}
          setLockStatus={setLockStatus}
          handleSave={handleSave}
          handleNextStep={() => null}
          isUploadInProcess={isUploadInProcess}
          isProcessOrder={isProcessOrder}
          isFetching={isFetching}
          canContinueProcessOrder={canContinueProcessOrder}
          preparePurchaseOrder={preparePurchaseOrder}
          orderTabs={
            <CustomerOrderDetailsTabs
              selectedOrderData={customerOrder}
              setSelectedOrderData={setCustomerOrder}
              setIsDirty={setIsDirty}
              setIsUploadInProcess={setIsUploadInProcess}
              setAddressUpdateData={setAddressUpdateData}
              isProcessOrder={isProcessOrder}
            />
          }
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          componentType={ComponentType.customer}
          showGoBackToCustomerOrders={showGoBackToCustomerOrders}
          setIsWarningModalOpen={setIsWarningModalOpen}
          isWarningModalOpen={isWarningModalOpen}
          invisibleButton={invisibleButton}
        />
      )}
    </CustomerOrdersDetailsWrapper>
  );
};
