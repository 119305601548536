import styled from '@emotion/styled/macro';
import { ArrowForward, Cancel } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import {
  CancelModal,
  RouteRouterPrompt,
  Title,
  ToastHelper,
  ToastMessageType,
  ToastType,
  TyrioTab,
  TyrioTabs,
  TyrioTabsRefType,
  tyrioUI,
} from '@tyrio/ui-library';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import {
  PageTemplateContent,
  PageTemplateWrapper,
} from '../../../components/PageTemplate/PageTemplate';
import { useDebounce } from '../../../hooks/useDebounce';
import api from '@tyrio/api-factory';
import {
  DBDimensionsApi,
  DBProductCategoryApi,
  DBSupplierClientSettingsApi,
} from '@tyrio/dto';

import { DBSupplier } from '@prisma/client';
import { TyrioSelectInputOption } from '@tyrio/forms';
import { BRAND_DROPDOWN_DATA, SEASON_DROPDOWN_DATA } from '@tyrio/shared-vars';
import CodeTypeComparisonForm, {
  CodeTypes,
} from '../../../features/price-comparison/CodeTypeComparisonForm';
import DimTypeComparisonForm, {
  DimTypeComparisonFormRefType,
} from '../../../features/price-comparison/DimTypeComparisonForm';
import SupplierComparisonForm from '../../../features/price-comparison/SupplierComparisonForm';

import { useHistory } from 'react-router-dom';
import {
  CompareCodeData,
  CompareDimensionsData,
} from '../../price-compare/types';

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
    return '';
  };
};

// Hook
function useExitPrompt(bool: boolean) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}

interface DashboardPriceComparisonProps {
  onComparePricesClicked: (
    data?: CompareCodeData,
    dimensionData?: CompareDimensionsData
  ) => void;
}
const DashboardPriceComparison = (props: DashboardPriceComparisonProps) => {
  const ref = useRef<TyrioTabsRefType | null>(null);
  const dimRef = useRef<DimTypeComparisonFormRefType | null>(null);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const history = useHistory();

  useExitPrompt(true);

  useExitPrompt(true);

  const [search] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  // Leaving this search in case its needed.
  const [searchCategories] = useState('');

  const [selectedCodeType, setSelectedCodeType] = useState<string>(
    CodeTypes.ean
  );
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);
  const [selectedMainSupplier, setSelectedMainSupplier] = useState<
    string | undefined
  >(undefined);
  const [selectedArrayOfCodes, setSelectedArrayOfCodes] = useState<
    { value: string; qty: number }[]
  >([]);
  const [isDirty, setIsDirty] = useState(false);
  const [supplierList, setSupplierList] = useState<DBSupplier[]>([]);

  const { isFetching, isError, data } = useQuery(
    ['supplier_list', { debouncedSearch }],
    () => searchSuppliers(),
    {
      onSuccess: (data) => {
        const suppliers = data.data.map(
          (item) => item.supplier
        ) as DBSupplier[];
        setSupplierList(suppliers);
      },
    }
  );

  const resetData = useCallback(() => {
    setSelectedArrayOfCodes([]);
    setIsDirty(false);
  }, []);

  const canTabsChange = useCallback(() => {
    if (selectedArrayOfCodes.length === 0 && !isDirty) {
      return true;
    }
    const confirmation = window.confirm(
      'Any changes made will be lost. Are you sure you want to continue?'
    );

    if (confirmation) {
      resetData();
    }
    return confirmation;
  }, [isDirty, resetData, selectedArrayOfCodes.length]);

  const { data: allCategories } = useQuery(
    ['category_list', { searchCategories }],
    () => searchProductCategories(searchCategories),
    {}
  );

  const mainCategoriesDropdownData = useMemo(() => {
    const tempMainCategories: TyrioSelectInputOption[] = [];
    allCategories?.forEach((cat) => {
      if (!cat.parent_category_id) {
        tempMainCategories.push({
          value: cat.id.toString(),
          label: cat.name,
        });
      }
    });
    return tempMainCategories;
  }, [allCategories]);

  const { data: dimensions } = useQuery(
    ['get_dimensions'],
    () => getAllDimensions(),
    {}
  );

  const searchProductCategories = async (search: string) => {
    return await api.fetch<DBProductCategoryApi['list']>('category', {
      search: search,
    });
  };

  const searchSuppliers = async () => {
    return await api.fetch<DBSupplierClientSettingsApi['list']>(
      'get_supplier_client_settings',
      {}
    );
  };

  const getAllDimensions = async () => {
    return await api.fetch<DBDimensionsApi['list']>(
      'get_dimensions',
      undefined
    );
  };

  const handleComparePricesClicked = () => {
    if (selectedSuppliers.length === 0) {
      ToastHelper.showToast(
        'Price comparison',
        ToastType.ERROR,
        ToastMessageType.CUSTOM_ERROR,
        'You must choose at least one supplier for prices to be compared.'
      );
      return;
    }
    if (ref.current?.selectedIdx === 0) {
      if (selectedArrayOfCodes.length === 0) {
        ToastHelper.showToast(
          'Price comparison',
          ToastType.ERROR,
          ToastMessageType.CUSTOM_ERROR,
          'You must add at least one product for prices to be compared.'
        );
        return;
      }
      const dataToBeCompared: CompareCodeData = {
        supplierIds: selectedSuppliers,
        mainSupplier: selectedMainSupplier,
        codes: selectedArrayOfCodes,
        codeType: selectedCodeType,
      };
      props.onComparePricesClicked(dataToBeCompared, undefined);
      return;
    }

    const allData = dimRef.current?.getAllData();
    dimRef.current?.showErrors(false);

    if (allData) {
      const dataToBeComparedByDimension: CompareDimensionsData = {
        supplierIds: selectedSuppliers,
        dimensions: allData.dimensions,
        mainSupplier: selectedMainSupplier,
        categoryId: allData.category,
        brands: allData.brands,
        seasons: allData.seasons,
        shouldShowRunFlat: allData.shouldShowRunFlat,
      };

      if (allData.hasErrors) {
        dimRef.current?.showErrors(true);
      } else {
        props.onComparePricesClicked(undefined, dataToBeComparedByDimension);
      }
    }
  };

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = function (event) {
        event.preventDefault();
        event.returnValue = '';
      };
    }
  }, [isDirty]);

  return (
    <PageTemplateWrapper>
      {!isCancelModalVisible && (
        <RouteRouterPrompt
          when={selectedArrayOfCodes.length > 0 || selectedSuppliers.length > 0}
          navigate={(path) => history.push(path)}
          shouldBlockNavigation={() => true}
        />
      )}
      {isCancelModalVisible && (
        <CancelModal
          LBAction={() => setIsCancelModalVisible(false)}
          RBAction={() => {
            setIsCancelModalVisible(false);
            resetData();
            history.push('/dashboard');
          }}
        />
      )}
      {isFetching && (
        <PageTemplateContent>
          <CircularProgress />
        </PageTemplateContent>
      )}
      {isError && <PageTemplateContent />}
      {data && data?.data.length !== 0 && !isError && !isFetching && (
        <PageTemplateContent>
          <HeaderWrapper>
            <Title>Price comparison</Title>
          </HeaderWrapper>
          <ContentContainer>
            <TabsWrapper
              style={{ maxWidth: 'calc(100vw - 730px)', width: '100%' }}
            >
              <TyrioTabs ref={ref} canTabChange={canTabsChange}>
                <TyrioTab title="Search by code" key="code_search">
                  <TabContentWrapper style={{ width: '100%' }}>
                    <CodeTypeComparisonForm
                      codeType={CodeTypes.ean}
                      onCodeChange={(value) => {
                        setSelectedCodeType(value);
                      }}
                      onListChange={(value) => {
                        setSelectedArrayOfCodes(value);
                      }}
                      setIsDirty={setIsDirty}
                    />
                  </TabContentWrapper>
                </TyrioTab>
                <TyrioTab title="Search by dimension" key="dimension_search">
                  <TabContentWrapper>
                    <DimTypeComparisonForm
                      ref={dimRef}
                      brands={BRAND_DROPDOWN_DATA}
                      dimensions={dimensions || []}
                      categories={mainCategoriesDropdownData}
                      seasons={SEASON_DROPDOWN_DATA}
                      setIsDirty={setIsDirty}
                    />
                  </TabContentWrapper>
                </TyrioTab>
              </TyrioTabs>
            </TabsWrapper>
            <SuppliersWrapper>
              <SupplierComparisonForm
                suppliers={supplierList}
                onChangeMainSupplier={(value) => {
                  setSelectedMainSupplier(value);
                  setIsDirty(true);
                }}
                onChangeSelectedSuppliers={(value) => {
                  setSelectedSuppliers(value);
                  setIsDirty(true);
                }}
              />
            </SuppliersWrapper>
          </ContentContainer>
          <FooterWrapper>
            <Button
              variant={'outlined'}
              color={'info'}
              startIcon={<Cancel color={'info'}></Cancel>}
              onClick={() => {
                setIsCancelModalVisible(true);
              }}
            >
              Cancel
            </Button>
            <Button
              variant={'contained'}
              color={'info'}
              onClick={handleComparePricesClicked}
              endIcon={<ArrowForward></ArrowForward>}
              style={{ marginRight: `${tyrioUI.spacing.l}px` }}
            >
              Compare prices
            </Button>
          </FooterWrapper>
        </PageTemplateContent>
      )}
    </PageTemplateWrapper>
  );
};

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: ${tyrioUI.spacing.xl}px ${tyrioUI.spacing.l}px;

  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
`;

const TabsWrapper = styled.div``;

const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FooterWrapper = styled.div`
  margin: 100px ${tyrioUI.spacing.xl}px ${tyrioUI.spacing.s}px
    ${tyrioUI.spacing.xl}px;
  padding: ${tyrioUI.spacing.l}px;
  border-top: 1px solid ${tyrioUI.colors.black.B40};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const SuppliersWrapper = styled.div`
  padding-top: 70px;
  width: 60%;
`;

export default DashboardPriceComparison;
