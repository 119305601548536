import { DBClient } from '@prisma/client';

type PriceCalculationRoundingType = 'lower' | 'average' | 'higher';

type DotTrackingOptions = 'weekOfProd' | 'yearOfProd' | 'noDotTracking';

interface CarModel {
  id: string;
  name: string;
}

interface SettingsPayload {
  priceCalculationRoundingType: PriceCalculationRoundingType;
  pricePerHour: number;
  defaultDeliveryType?: string;
  dotTracking?: DotTrackingOptions;
  isDotTrackingMandatory?: boolean;
  blackList?: CarModel[];
  ritamApiUsername: string;
  ritamApiPassword: string;
}

interface ClientSettings {
  priceCalculationRoundingType: PriceCalculationRoundingType;
  pricePerHour: number;
  defaultDeliveryType?: string;
  dotTracking?: DotTrackingOptions;
  isDotTrackingMandatory?: boolean;
  blackList?: CarModel[];
  ritamApiUsername: string;
  ritamApiPassword: string;
}

interface DBSettingsApi {
  list: {
    requestBody: never;
    request: never;
    response: never;
  };
  getOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  create: {
    requestBody: SettingsPayload;
    requestParams: never;
    request: SettingsPayload;
    response: DBClient;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
}

export {
  PriceCalculationRoundingType,
  DBSettingsApi,
  SettingsPayload,
  ClientSettings,
  DotTrackingOptions,
};
