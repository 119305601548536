/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DBClient,
  DBClientRebateCalculation,
  DBPriceRound,
  DBRebateCalculationType,
  DBRebateGroupType,
} from '@prisma/client';

interface DBClientRebateCalculationResponse extends DBClientRebateCalculation {
  client: DBClient;
}

interface DBClientRebateCalculationRequest {
  search: string;
  page?: number;
  pageSize?: number;
}

interface DBClientRebateCalculationOnePayload {
  id: string;
}

interface DBClientRebateCalculationUpsertRequest {
  isActive?: boolean;
}

interface DBCustomerRebateUpsertPayload {
  id?: string;
  groupName: string;
  groupType: DBRebateGroupType;
  rebate: number;
  discount: number;
  calculationType: DBRebateCalculationType;
  priceRound: DBPriceRound;
  rules?: any;
}

interface DBClientRebateCalculationApi {
  list: {
    requestBody: DBClientRebateCalculationRequest;
    request: DBClientRebateCalculationRequest;
    response: { data: DBClientRebateCalculationResponse[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBClientRebateCalculationOnePayload;
    request: DBClientRebateCalculationOnePayload;
    response: DBClientRebateCalculation | null;
  };
  create: {
    requestBody: DBCustomerRebateUpsertPayload;
    requestParams: never;
    request: DBCustomerRebateUpsertPayload;
    response: DBClientRebateCalculation;
  };
  updateOne: {
    requestBody: DBClientRebateCalculationUpsertRequest;
    requestParams: DBClientRebateCalculationOnePayload;
    request:
      | DBClientRebateCalculationUpsertRequest
      | DBClientRebateCalculationOnePayload;
    response: DBClientRebateCalculation;
  };
}
export { DBClientRebateCalculationApi };
