import {
  DBCurrency,
  DBInboundInvoice,
  DBParnerDeliveryTermsTypes,
  DBPartner,
  DBSupplierClientSettings,
  DBTrafficType,
  DBVatChargeTypeEnum,
} from '@prisma/client';

export interface InboundLineItem {
  productName: string;
  ean: string;
  uid: string;
  ipc: string;
  sku: string;
  supplierCode: string;
  quantity: number;
  purchasePrice: number;
  rebate: number;
  variableCost: number;
  customsPer: number;
  customsPrice: number;
  total: number;
  margin: number;
  vat: number;
  sellingPrice: number;
  grandTotal: number;
}

export interface InboundInvoiceSummary {
  totalQty: number;
  purchaseAmount: number;
  variableCost: number;
  customs: number;
  subtotalExVat: number;
  vat: number;
  vatAmount: number;
  grandTotalIncVat: number;
}

export interface InboundInvoiceVarCost {
  domestic: number;
  domesticOther: number;
  international: number;
  internationalOther: number;
  totalVarCost: number;
}

export interface InboundInvoiceItem {
  reference: string;
  purchaseOrder: string;
  supplierOrder: string;
  stockInOrder: string;
  lineItems: InboundLineItem[];
}

export type VatChargeType = 'V05' | 'V05EU' | 'V05D';

export interface InboundInvoiceResponse {
  branchId: number;
  branchName: string;
  date: Date | null;
  supplier: DBSupplierClientSettings | null;
  partner?: DBPartner;
  transportCost: number;
  items: InboundInvoiceItem[];
  vatChargeType?: VatChargeType;
}

export interface InboundInvoicePayload {
  branchId: number;
  date: Date;
  supplierName: string;
  vatChargeType: DBVatChargeTypeEnum;
  currency: DBCurrency;
  exchangeRate: number;
  supplierInvoice: string;
  issueDate: Date;
  delay: number;
  dueDate: Date;
  internalRemark?: string;
  deliveryTermsType?: DBParnerDeliveryTermsTypes;
  transactionNature?: number;
  transportMode?: DBTrafficType;
  dispatchingCountryId?: string;
  items: InboundInvoiceItem[];
  summary: InboundInvoiceSummary;
  splitInvoice: boolean;
  variableCost: InboundInvoiceVarCost;
  partnerOib: string;
  stockIns: string[];
}

interface InboundInvoiceApi {
  list: {
    requestBody: never;
    request: never;
    response: DBInboundInvoice[];
  };
  getOne: {
    requestBody: never;
    requestParams: { id: string };
    request: { id: string };
    response: DBInboundInvoice;
  };
  create: {
    requestBody: InboundInvoicePayload;
    requestParams: never;
    request: InboundInvoicePayload;
    response: DBInboundInvoice;
  };
  updateOne: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: never;
  };
  getPrepared: {
    requestBody: never;
    requestParams: { stockInIds: string };
    request: { stockInIds: string };
    response: InboundInvoiceResponse;
  };
}

export { InboundInvoiceApi };
