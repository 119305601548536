import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, Chip, Grid, Stack, Switch, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DBCustomPriceCatalog } from '@prisma/client';
import { CustomPriceMeta, DBProductApi } from '@tyrio/dto';
import { CancelModal, tyrioUI } from '@tyrio/ui-library';
import _ from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { formatDate } from '../../../../helpers/date-helper';
import { currency } from '../price-calculation/helper/parser';
import AddNewPrice from './CustomPriceCatalogCreateModal';
import UpdatePrice from './CustomPriceCatalogUpdateModal';
import { useDeletePrice } from './queries/delete-custom-price';
import { useUpdateStatus } from './queries/update-status';

interface Props {
  item: DBCustomPriceCatalog;
}

type ModalType = 'new' | 'update' | 'delete';

function Row(props: Props) {
  const { item } = props;

  //PROPS
  const product =
    item.productMeta as unknown as DBProductApi['getOne']['response'];

  const meta = item.meta as unknown as CustomPriceMeta;

  //STATES
  const [modals, setModals] = useState({
    new: false,
    update: false,
    delete: false,
  });

  const handleOpen = (type: ModalType) => {
    setModals({ ...modals, [type]: true });
  };

  const handleClose = (type: ModalType) => {
    setModals({ ...modals, [type]: false });
  };

  //MUTATIONS
  const { updateStatus } = useUpdateStatus();
  const { deletePrice } = useDeletePrice();

  return (
    <Grid>
      <AddNewPrice
        open={modals.new}
        close={() => {
          handleClose('new');
        }}
      />
      <UpdatePrice
        open={modals.update}
        close={() => {
          handleClose('update');
        }}
        item={item}
      />
      {modals.delete && (
        <CancelModal
          LBAction={() => {
            handleClose('delete');
          }}
          RBAction={() => {
            deletePrice.mutate({ ean: item.ean });
          }}
          text="Are you sure you want to delete this price?"
        />
      )}
      <TableRowStyled>
        <StyledTableCell sx={{ minWidth: '180px', maxWidth: '180px' }}>
          {item.ean}
          {item.calculatedPrice && !_.isEmpty(item.productMeta) && (
            <CellDetails color="#2e7d32">on stock</CellDetails>
          )}
          {!item.calculatedPrice && !_.isEmpty(item.productMeta) && (
            <CellDetails color="#d32f2f">not on stock</CellDetails>
          )}
        </StyledTableCell>

        {!_.isEmpty(item.productMeta) ? (
          <Grid sx={{ display: 'flex' }}>
            <StyledTableCell sx={{ minWidth: '150px', maxWidth: '150px' }}>
              {product?.model?.brand}
              <CellDetails>{product?.model?.season}</CellDetails>
            </StyledTableCell>
            <StyledTableCell sx={{ maxWidth: '150px', minWidth: '150px' }}>
              {product.model?.category?.parent_category_name
                ? product.model?.category.parent_category_name
                : product.model?.category?.name}
              <CellDetails>{product.model?.category?.name}</CellDetails>
            </StyledTableCell>
            <StyledTableCell
              style={{
                display: 'flex',
                alignItems: 'center',
                overflowX: 'auto',
                minWidth: '430px',
                maxWidth: '430px',
              }}
            >
              {product.productName}
            </StyledTableCell>
          </Grid>
        ) : (
          <StyledTableCell
            style={{
              overflowX: 'auto',
              minWidth: '730px',
              maxWidth: '730px',
            }}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              There is no data about product details.
            </Alert>
          </StyledTableCell>
        )}

        <Grid sx={{ minWidth: '180px', maxWidth: '180px' }}>
          {`${moment(formatDate(new Date(item.createdAt))).format(
            'DD.MM.YYYY'
          )}`}
          <CellDetails>{meta.createdBy}</CellDetails>
        </Grid>
        <Grid sx={{ minWidth: '180px', maxWidth: '180px' }}>
          {`${moment(formatDate(new Date(meta.updatedAt))).format(
            'DD.MM.YYYY'
          )}`}
          <CellDetails>{meta.lastUpdateBy}</CellDetails>
        </Grid>
        <Grid
          style={{ overflowX: 'auto', minWidth: '140px', maxWidth: '140px' }}
        >
          {item.calculatedPrice ? (
            `${Number(item.calculatedPrice).toFixed(2)} ${currency}`
          ) : (
            <Chip label="not defined" color="warning" size="small" />
          )}
        </Grid>
        <Grid
          style={{ overflowX: 'auto', minWidth: '140px', maxWidth: '140px' }}
        >
          {`${Number(item.price).toFixed(2)} ${currency}`}
        </Grid>
        <Grid
          style={{ overflowX: 'auto', minWidth: '100px', maxWidth: '100px' }}
        >
          <Tooltip title="Toggle price status" placement="top">
            <Switch
              size="small"
              color="success"
              defaultChecked={item.active}
              onChange={() => {
                updateStatus.mutate({
                  ean: item.ean,
                  active: item.active,
                  meta: item.meta as unknown as CustomPriceMeta,
                });
              }}
            />
          </Tooltip>
        </Grid>
        <Grid>
          <Stack direction="row" alignItems="center">
            <Tooltip title="Update price" placement="top">
              <EditIcon
                color="info"
                sx={{ cursor: 'pointer', height: '20px' }}
                onClick={() => {
                  handleOpen('update');
                }}
              />
            </Tooltip>
            <Tooltip title="Delete price" placement="top">
              <DeleteIcon
                color="error"
                sx={{ cursor: 'pointer', height: '20px' }}
                onClick={() => {
                  handleOpen('delete');
                }}
              />
            </Tooltip>
          </Stack>
        </Grid>
      </TableRowStyled>
    </Grid>
  );
}

interface CollapsibleTableProps {
  items: DBCustomPriceCatalog[];
}

export default function CollapsibleTable(props: CollapsibleTableProps) {
  const { items } = props;
  return (
    <TableContainer component={Paper} sx={{ marginTop: '15px' }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow
            sx={{
              background: '#ECF0F4',
              color: '#000000de',
              fontSize: '14px',
            }}
          >
            <CustomPriceTableCell>
              <CustomPriceTableHeadWrapper>
                <Grid
                  sx={{
                    width: '100%',
                    maxWidth: '894px',
                    minWidth: '894px',
                  }}
                >
                  Product details
                </Grid>
                <Grid
                  sx={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Grid sx={{ width: '180px' }}>
                    <CustomPriceTableHeadItem>Created</CustomPriceTableHeadItem>
                  </Grid>
                  <Grid sx={{ width: '180px' }}>
                    <CustomPriceTableHeadItem>Updated</CustomPriceTableHeadItem>
                  </Grid>
                  <Grid sx={{ width: '140px' }}>
                    <CustomPriceTableHeadItem>
                      Calculated price
                    </CustomPriceTableHeadItem>
                  </Grid>
                  <Grid sx={{ width: '140px' }}>
                    <CustomPriceTableHeadItem>
                      Custom price
                    </CustomPriceTableHeadItem>
                  </Grid>
                  <Grid sx={{ width: '100px' }}>
                    <CustomPriceTableHeadItem>Active</CustomPriceTableHeadItem>
                  </Grid>
                  <Grid>
                    <CustomPriceTableHeadItem>Actions</CustomPriceTableHeadItem>
                  </Grid>
                </Grid>
              </CustomPriceTableHeadWrapper>
            </CustomPriceTableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{}}>
          {items.map((item) => {
            return <Row key={item.id} item={item} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const CustomPriceTableHeadItem = styled.span`
  color: ${tyrioUI.colors.black.B90};
  font-size: 14px;
`;

const CustomPriceTableCell = styled(TableCell)`
  padding: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
`;

const CustomPriceTableHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px 16px 16px;
`;

const TableRowStyled = styled(Grid)`
  display: flex;
  width: 100%;
  cursor: pointer;

  height: 72px;
  border-bottom: 1px solid ${tyrioUI.colors.black.B40};
  align-items: center;
  &:hover {
    background: ${tyrioUI.colors.black.B10};
    border-bottom: 1px solid ${tyrioUI.colors.black.B40};
    #quantity_wrapper {
      background: ${tyrioUI.colors.black.B30};
    }
    #product_name_details {
      color: #e75524;
      font-weight: 500;
    }
  }
`;

const StyledTableCell = styled(Grid)`
  border: 0;
  font-weight: 400;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  padding: 6px 16px;
  &:last-child {
    padding-right: 0;
  }
`;

export const CellDetails = styled.div<{ color?: string }>`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.color ?? '#919eab'};
`;
