import {
  DBClient,
  DBCustomerOrderItem,
  DBDispatch,
  DBDispatchOrderType,
  DBFile,
  DBTransferOrderItem,
  Prisma,
} from '@prisma/client';
import { OrderStatusProps } from './customer-order-item';

interface DBDispatchOnePayload {
  id: string;
  orderType: string;
}

export interface OrderShape {
  orderId: string;
  lineItems: Prisma.JsonValue;
  reference: string | null;
  remark?: string | null;
  customer?: Prisma.JsonValue | null;
  branchId?: number;
  deliveryDateFrom?: Date | null;
  deliveryDateTo?: Date | null;
  orderStatus: OrderStatusProps;
  inboundBranchId?: number;
  deliveryAddressId?: string;
  clientId?: string;
  customerOrderNumber?: string;
  withoutCustomerInfo?: boolean;
  branchName?: string;
}

export interface DBDispatchOrdersResponse {
  deliveryId: string | null;
  destination: string;
  destinationAddress: string;
  destinationCity: string;
  orderType: string; //'TRANSFER_ORDER' | 'CUSTOMER_ORDER'
  open: number;
  ready: number;
  openOrders: OrderShape[];
  readyOrders: OrderShape[];
}

// create dispatched order payload
interface DBDispatchedPayload {
  orderType: DBDispatchOrderType;
  orderId: string;
  orderDetails: Prisma.JsonValue;
  deliveryId: string;
  deliveryDetails: Prisma.JsonValue;
  lineItems: Prisma.JsonArray;
  recipient: string;
  recipientSignatureId: string;
  clientId: string;
  startedAt: Date;
}

// dispatched order response
export interface DBDispatchedResponse extends DBDispatch {
  client: DBClient;
  file: DBFile;
}

export interface DispacthedOrderDetails {
  reference: string | null;
  remark: string | null;
}

export interface DispatchedDeliveryDetails {
  destination: string;
  destinationAddress: string;
  destinationCity: string;
}

interface DBDispatchApi {
  list: {
    requestBody: never;
    requestParams: never;
    request: never;
    response: { data: DBDispatchOrdersResponse[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBDispatchOnePayload;
    request: DBDispatchOnePayload;
    response: DBCustomerOrderItem | DBTransferOrderItem | null;
  };
  create: {
    requestBody: DBDispatchedPayload[];
    requestParams: never;
    request: DBDispatchedPayload[];
    response: { data: DBDispatch[]; count: number };
  };
}

export { DBDispatchApi };
