import { DBCompanyContact } from '@prisma/client';

interface DBCreateCompanyContactPayload {
  clientId: string;
  firstName: string;
  lastName: string;
  email: string;
  businessPhone: string;
  mobilePhone: string;
  remark: string;
}

type DBUpdateCompanyContactPayload = {
  id: string;
  clientId: string;
  firstName: string;
  lastName: string;
  email: string;
  businessPhone: string;
  mobilePhone: string;
  remark: string;
};

interface DBCompanyContactOnePayload {
  id: string;
}
interface DBCompanyContactApi {
  list: {
    requestBody: never;
    request: never;
    response: DBCompanyContact[];
  };
  getOne: {
    requestBody: never;
    requestParams: DBCompanyContactOnePayload;
    request: DBCompanyContactOnePayload;
    response: DBCompanyContact;
  };
  create: {
    requestBody: DBCreateCompanyContactPayload;
    requestParams: never;
    request: DBCreateCompanyContactPayload;
    response: { company_contact: DBCompanyContact };
  };
  updateOne: {
    requestBody: DBUpdateCompanyContactPayload;
    requestParams: DBCompanyContactOnePayload;
    request: DBUpdateCompanyContactPayload | DBCompanyContactOnePayload;
    response: DBCompanyContact;
  };
}
export { DBCompanyContactApi };
