/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled/macro';
import { Grid } from '@mui/material';
import { DBParnerDeliveryTermsTypes } from '@prisma/client';
import api from '@tyrio/api-factory';
import { DBCountryApi } from '@tyrio/dto';
import { TyrioSelectInputOption } from '@tyrio/ui-library';
import { useState } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { transportModeData } from '../../../helpers/transport-mode';
import { getAutocompleteSingleField } from '../../form-helper';

interface IntrastatProps {
  errors: FieldErrors;
  control: Control;
  onSubmit: (data: any) => void;
  handleSubmit: any;
}

export const Intrastat = ({
  errors,
  control,
  onSubmit,
  handleSubmit,
}: IntrastatProps) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<TyrioSelectInputOption[]>([]);
  const deliveryTermsTypes = Object.values(DBParnerDeliveryTermsTypes).map(
    (item) => ({
      label: item.toUpperCase(),
      value: item,
    })
  );

  useQuery(
    ['country_list'],
    () => api.fetch<DBCountryApi['list']>('get_countries'),
    {
      onSuccess: (data: DBCountryApi['list']['response']) => {
        const dropdownItems = data.map(
          (item: DBCountryApi['getOne']['response']) => ({
            label: item.name,
            value: item.id,
          })
        );
        setCountries(dropdownItems);
      },
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <Grid item style={{ width: '100%' }}>
          {getAutocompleteSingleField(
            t('Delivery terms and conditions'),
            'deliveryTermsAndConditions',
            () => false,
            errors,
            control,
            { lg: 4, xs: 12 },
            {},
            deliveryTermsTypes
          )}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {getAutocompleteSingleField(
            t('Nature of transaction'),
            'natureOfTransaction',
            () => false,
            errors,
            control,
            { lg: 4, xs: 12 },
            {},
            [
              11, 12, 21, 22, 23, 31, 32, 33, 34, 41, 42, 51, 52, 60, 71, 72,
              80, 91, 99,
            ].map((item) => ({
              label: item.toString(),
              value: item.toString(),
            }))
          )}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {getAutocompleteSingleField(
            t('Mode of transport'),
            'modeOfTransport',
            () => false,
            errors,
            control,
            { lg: 4, xs: 12 },
            {},
            transportModeData
          )}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {getAutocompleteSingleField(
            t('Dispatching country'),
            'dispatchingCountry',
            () => false,
            errors,
            control,
            { lg: 4, xs: 12 },
            {},
            countries
          )}
        </Grid>
      </Wrapper>
    </form>
  );
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 16px;
`;
