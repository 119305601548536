import { DBAdmin, DBRole, DBUser } from '@prisma/client';
import { DBUserApi } from './user';

interface DBGetAdminsRequest {
  isRoleIncluded: boolean;
  isUserIncluded: boolean;
  search: string;
  page?: number;
  pageSize?: number;
}

interface DBCreateAdminPayload {
  user: DBUserApi['create']['request'];
}

interface DBUpdateAdminPayload {
  active?: boolean;
  remark?: string;
  user?: DBUserApi['updateOne']['requestBody'];
}

type DBAdminPayload = DBAdmin & {
  user: (DBUser & { role: DBRole | null }) | null;
};

interface DBAdminOnePayload {
  id: string;
}
interface DBAdminApi {
  list: {
    requestBody: DBGetAdminsRequest;
    request: DBGetAdminsRequest;
    response: { data: DBAdminPayload[]; count: number };
  };
  getOne: {
    requestBody: never;
    requestParams: DBAdminOnePayload;
    request: DBAdminOnePayload;
    response: DBAdminPayload;
  };
  create: {
    requestBody: DBCreateAdminPayload;
    requestParams: never;
    request: DBCreateAdminPayload;
    response: DBAdmin;
  };
  updateOne: {
    requestBody: DBUpdateAdminPayload;
    requestParams: DBAdminOnePayload;
    request: DBUpdateAdminPayload | DBAdminOnePayload;
    response: DBAdmin;
  };
}

export { DBAdminApi, DBGetAdminsRequest };
